import ClassNames from 'classnames'
import { ErrorMessage, Field, useField } from 'formik'
import React, { useEffect, useState } from 'react'
import Input from '../../input'
import DatePicker from 'react-date-picker'
import './input.sass'
import styles from './date-format.module.sass'
import format from 'date-fns/format'
import { connect } from 'formik'

const DateFormatControl = ({
  name,
  labelClassName,
  type,
  className,
  label,
  disabled,
  placeholder,
  ...props
}) => {
  const [field, meta, helpers] = useField({ name })
  const htmlFor = `${type}-${Math.random()}`
  const [value, onChange] = useState(field?.value ? field.value : null)

  // console.log(value, name);
  useEffect(() => {
    if (value == null) {
      helpers.setValue(value)
    }
    if (typeof value === 'object' && value !== null) {
      helpers.setValue(format(value, 'P'))
    }
    if (typeof value !== 'object') {
      helpers.setValue(value)
    }
    console.log(157);
  }, [value])

  const onChangeHandler = (value) => {
    console.log(value, 'This is handler')
    helpers.setTouched()
    props.formik.validateField(name)
    if (value !== null) {
      if (format(value, 'P').length > 10) {
        return
      }
    }
    onChange(value)   
  }

  return (
    <div className={ClassNames(styles.wrapper, className)}>
      <label
        className={ClassNames(styles.label, labelClassName, {
          [styles.label_error]: meta.error && meta.touched,
        })}
        htmlFor={htmlFor}
      >
        {label}
      </label>
      <div
        className={ClassNames(styles.input__container, {
          [styles.error_border]: meta.error && meta.touched,
        })}
      >
        <DatePicker
          locale="en-En"
          format="MM-dd-y"
          dayPlaceholder="dd"
          monthPlaceholder="mm"
          yearPlaceholder="year"
          disableCalendar={true}
          //minDate={new Date('02-01-1900')}
          //maxDate={new Date()}
          onChange={onChangeHandler}
          value={value}
        />
      </div>
      <div className={styles.error}>
        <ErrorMessage name={name} />
      </div>
    </div>
  )
}

export default connect(DateFormatControl)
