import auth from "./auth";
import home from "./home-page";
import user from "./user";
import timelines from "./timelines";
import search from "./search";
import friends from "./friends";
import activities from "./activities";
import alert from "../../components/ui/alert/alertReducer";
import comments from "./comments";
import combineSearch from "./combine-search";
import support from "./support";
import settings from "./settings";
import payments from "./payments";

export const reducers = {
  auth,
  combineSearch,
  friends,
  activities,
  search,
  timelines,
  home,
  user,
  alert,
  comments,
  support,
  settings,
  payments,
};
