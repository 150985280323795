// import { lazy } from "react";
import Activity from "../screens/activity";
import FriendList from "../screens/friend-list";
import FriendProfile from "../screens/friend-profile";
import Friends from "../screens/friends/Friends";
import MediaScreen from "../screens/media/MediaScreen";
import Login from "../screens/login";
import RestorePassword from "../screens/restore-password"; 
import NewPassword from "../screens/new-password";
// import NoMatch from "../screens/nomatch/NoMatch";
import Onboarding from "../screens/onboarding";
import Register from "../screens/register";
import Settings from "../screens/settins/Settings";
import Test from "../screens/test";
import Timeline from "../screens/timeline";
import Event from "../screens/event";
import MobileSidebar from "../screens/settins/src/components/sidebar/mobile/mobileSidebar";

import { SearchResults } from "../screens/search-results";
import { lazy } from "react";
import CreateTimeline from "../screens/create-timeline/CreateTimeline";
import Aside from "../components/aside"
// const Login = lazy(() => import('../screens/login'))
// const Register = lazy(() => import('../screens/register'))
// const HomePage = lazy(() => import('../screens/home'))
// const Timeline = lazy(() => import('../screens/timeline'))
// const OwnerEvent = lazy(() => import("../screens/owner-event"));
export const SEARCH_RESULTS = "SEARCH_RESULTS";
export const TIMELINE = "TIMELINE";
export const Routes = [
  {
    id: TIMELINE,
    path: "/",
    exact: true,
    component: Timeline,
    auth: true,
  },

  {
    path: "/activity",
    exact: true,
    component: Activity,
    auth: true,
  },
  {
    path: "/friend/:id/event/:eventId/media",
    exact: true,
    component: MediaScreen,
    auth: true,
  },
  {
    path: "/activity?",
    exact: true,
    component: Activity,
    auth: true,
  },
  {
    path: "/settings/mobile",
    exact: true,
    component: MobileSidebar,
    auth: true,
  },
  {
    path: "/settings/:action",
    exact: true,
    component: Settings,
    auth: true,
  },
  {
    path: "/testing",
    exact: true,
    component: Test,
    auth: true,
  },
  {
    path: "/settings",
    exact: true,
    component: Settings,
    auth: true,
  },
  {
    path: "/onboarding",
    exact: true,
    component: Onboarding,
    auth: true,
  },
  // {
  //   path: "/",
  //   exact: true,
  //   component: HomePage,
  //   auth: true,
  // },
  {
    path: "/friends",
    exact: true,
    component: Friends,
    auth: true,
  },
  {
    path: "/friends/:alias",
    exact: true,
    component: Friends,
    auth: true,
  },
  {
    path: "/friend/:id",
    exact: true,
    component: FriendProfile,
    auth: true,
  },
  {
    path: "/home/:id",
    exact: true,
    component: FriendProfile,
    auth: true,
  },
  {
    path: "/friend/:id/friend-list",
    exact: true,
    component: FriendList,
    auth: true,
  },
  {
    path: "/friend/:id/event/:eventId",
    exact: true,
    component: Event,
    auth: true,
  },
  {
    id: SEARCH_RESULTS,
    path: "/timelines/search",
    exact: true,
    component: SearchResults,
    auth: true,
  },
  {
    path: "/login",
    exact: true,
    component: Login,
    auth: false,
  },
  {
    path: "/registration",
    exact: true,
    component: Register,
    auth: false,
  },
  {
    path: "/restore-access",
    exact: true,
    component: RestorePassword,
    auth: false,
  },
  {
    path: "/reset-password/:token?/:email?",
    exact: false,
    component: NewPassword,
    auth: false,
  },
  {
    path: "/new-entry",
    exact: true,
    component: CreateTimeline,
    auth: true,
  },
  {
    path: "/mobile-profile",
    exact: true,
    component: Aside,
    auth: true,
  },
];

export const getRouteConfig = (id) => {
  const route = Routes.find((e) => e.id === id);
  if (!route) {
    return null;
  }
  const { component, ...props } = route;
  return props;
};
