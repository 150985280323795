import React from "react";

export const HomeIcon = () => {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M17.5 7.23384L11.684 1.25299C10.7077 0.249004 9.12479 0.249003 8.14848 1.25299L2.5 7.06158V19.5H8.33333V14.3583C8.33333 13.4117 9.07953 12.6444 10 12.6444C10.9205 12.6444 11.6667 13.4117 11.6667 14.3583V19.5H17.5V7.23384ZM9.32699 2.46491L4.16667 7.77151V17.7861H6.66667V14.3583C6.66667 12.4651 8.15905 10.9304 10 10.9304C11.8409 10.9304 13.3333 12.4651 13.3333 14.3583V17.7861H15.8333V7.94377L10.5055 2.46491C10.1801 2.13025 9.65243 2.13025 9.32699 2.46491Z"
        fill="#1D2A64"
      />
    </svg>
  );
};
export const TimelineIcon = () => {
  return (
    <svg
      width="21"
      height="20"
      viewBox="0 0 21 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M1.5 4C1.5 2.89543 2.39543 2 3.5 2H17.5C18.6046 2 19.5 2.89543 19.5 4V16C19.5 17.1046 18.6046 18 17.5 18H3.5C2.39543 18 1.5 17.1046 1.5 16V4ZM7.5 5C7.5 4.44772 7.94772 4 8.5 4H16.5C17.0523 4 17.5 4.44772 17.5 5V15C17.5 15.5523 17.0523 16 16.5 16H8.5C7.94772 16 7.5 15.5523 7.5 15V5Z"
        fill="#1D2A64"
      />
    </svg>
  );
};
export const MediaIcon = () => {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M5.09091 5.83333C4.63904 5.83333 4.27273 6.20643 4.27273 6.66667C4.27273 7.1269 4.63904 7.5 5.09091 7.5C5.54278 7.5 5.90909 7.1269 5.90909 6.66667C5.90909 6.20643 5.54278 5.83333 5.09091 5.83333Z"
        fill="#1D2A64"
      />
      <path
        d="M14.0909 6.66667C14.0909 6.20643 14.4572 5.83333 14.9091 5.83333C15.361 5.83333 15.7273 6.20643 15.7273 6.66667C15.7273 7.1269 15.361 7.5 14.9091 7.5C14.4572 7.5 14.0909 7.1269 14.0909 6.66667Z"
        fill="#1D2A64"
      />
      <path
        d="M5.09091 9.16667C4.63904 9.16667 4.27273 9.53976 4.27273 10C4.27273 10.4602 4.63904 10.8333 5.09091 10.8333C5.54278 10.8333 5.90909 10.4602 5.90909 10C5.90909 9.53976 5.54278 9.16667 5.09091 9.16667Z"
        fill="#1D2A64"
      />
      <path
        d="M14.0909 10C14.0909 9.53976 14.4572 9.16667 14.9091 9.16667C15.361 9.16667 15.7273 9.53976 15.7273 10C15.7273 10.4602 15.361 10.8333 14.9091 10.8333C14.4572 10.8333 14.0909 10.4602 14.0909 10Z"
        fill="#1D2A64"
      />
      <path
        d="M5.09091 12.5C4.63904 12.5 4.27273 12.8731 4.27273 13.3333C4.27273 13.7936 4.63904 14.1667 5.09091 14.1667C5.54278 14.1667 5.90909 13.7936 5.90909 13.3333C5.90909 12.8731 5.54278 12.5 5.09091 12.5Z"
        fill="#1D2A64"
      />
      <path
        d="M14.0909 13.3333C14.0909 12.8731 14.4572 12.5 14.9091 12.5C15.361 12.5 15.7273 12.8731 15.7273 13.3333C15.7273 13.7936 15.361 14.1667 14.9091 14.1667C14.4572 14.1667 14.0909 13.7936 14.0909 13.3333Z"
        fill="#1D2A64"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M3.45455 2.5C2.09894 2.5 1 3.61929 1 5V15C1 16.3807 2.09894 17.5 3.45455 17.5H16.5455C17.9011 17.5 19 16.3807 19 15V5C19 3.61929 17.9011 2.5 16.5455 2.5H3.45455ZM16.5455 4.16667H3.45455C3.00268 4.16667 2.63636 4.53976 2.63636 5V15C2.63636 15.4602 3.00268 15.8333 3.45455 15.8333H16.5455C16.9973 15.8333 17.3636 15.4602 17.3636 15V5C17.3636 4.53976 16.9973 4.16667 16.5455 4.16667Z"
        fill="#1D2A64"
      />
    </svg>
  );
};
export const SettingsIcon = () => {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M15.446 17.2794L17.26 15.4653L16.6211 13.1443L16.8995 12.4795L19 11.2942V8.72891L16.9054 7.53869L16.6317 6.8751L17.2787 4.55283L15.4633 2.74014L13.1421 3.37885L12.4788 3.10074L11.2934 1H8.72809L7.53786 3.09458L6.87442 3.3682L4.55253 2.72048L2.74003 4.53298L3.37885 6.85464L3.10074 7.51792L1 8.7033V11.2679L3.09269 12.4607L3.36661 13.1249L2.71979 15.4468L4.53298 17.26L6.85464 16.6212L7.518 16.8993L8.7034 18.9992H11.2681L12.4601 16.9064L13.1241 16.6326L15.446 17.2794ZM15.6067 11.3301L14.8934 13.0336L15.4296 14.9816L14.9645 15.4466L13.0207 14.9051L11.3164 15.608L10.3169 17.3628H9.65871L8.66747 15.6069L6.96578 14.8934L5.0168 15.4297L4.55253 14.9654L5.09402 13.0216L4.39118 11.3174L2.63632 10.3171V9.65887L4.39304 8.66761L5.10657 6.96584L4.5703 5.01686L5.03361 4.55355L6.9774 5.0958L8.68232 4.39265L9.6803 2.63638H10.3378L11.3291 4.3931L13.0308 5.10663L14.9802 4.57026L15.4456 5.03501L14.9042 6.97844L15.6073 8.6832L17.3636 9.68119V10.3387L15.6067 11.3301ZM10 13.2727C8.19256 13.2727 6.72731 11.8075 6.72731 9.99998C6.72731 8.1925 8.19256 6.72725 10 6.72725C11.8075 6.72725 13.2728 8.1925 13.2728 9.99998C13.2728 11.8075 11.8075 13.2727 10 13.2727ZM11.6364 10C11.6364 10.9037 10.9037 11.6364 10 11.6364C9.09626 11.6364 8.36364 10.9037 8.36364 10C8.36364 9.09626 9.09626 8.36364 10 8.36364C10.9037 8.36364 11.6364 9.09626 11.6364 10Z"
        fill="#1D2A64"
      />
    </svg>
  );
};
export const ActivityIcon = () => {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M7.5 18.7495L8.75 12.4995L3.75 10.6245L12.5 1.24951L11.25 7.49951L16.25 9.37451L7.5 18.7495Z"
        stroke="#1D2A64"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export const ArrowIcon = ({ style }) => {
  return (
    <svg
      style={style}
      width="40"
      height="40"
      viewBox="0 0 40 40"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect width="40" height="40" rx="16" fill="#1D2A64" />
      <path
        d="M12 20L16.7368 14M12 20L16.7368 26M12 20H28"
        stroke="white"
        strokeWidth="2"
        strokeLinecap="round"
      />
    </svg>
  );
};

export const BirtProfileIcon = ({ style }) => {
  return (
    <svg
      {...style}
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M20 8H4C3.44772 8 3 8.44772 3 9V11C3 11.5523 3.44772 12 4 12H20C20.5523 12 21 11.5523 21 11V9C21 8.44772 20.5523 8 20 8Z"
        stroke="#1D2A64"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M12 8V21"
        stroke="#1D2A64"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M19 12V19C19 19.5304 18.7893 20.0391 18.4142 20.4142C18.0391 20.7893 17.5304 21 17 21H7C6.46957 21 5.96086 20.7893 5.58579 20.4142C5.21071 20.0391 5 19.5304 5 19V12"
        stroke="#1D2A64"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M7.5 7.99999C6.83696 7.99999 6.20107 7.7366 5.73223 7.26776C5.26339 6.79892 5 6.16304 5 5.49999C5 4.83695 5.26339 4.20107 5.73223 3.73223C6.20107 3.26339 6.83696 2.99999 7.5 2.99999C8.46469 2.98319 9.41003 3.45125 10.2127 4.34316C11.0154 5.23506 11.6383 6.5094 12 7.99999C12.3617 6.5094 12.9846 5.23506 13.7873 4.34316C14.59 3.45125 15.5353 2.98319 16.5 2.99999C17.163 2.99999 17.7989 3.26339 18.2678 3.73223C18.7366 4.20107 19 4.83695 19 5.49999C19 6.16304 18.7366 6.79892 18.2678 7.26776C17.7989 7.7366 17.163 7.99999 16.5 7.99999"
        stroke="#1D2A64"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};
export const HomeProfileIcon = ({ style }) => {
  return (
    <svg
      {...style}
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M5 12H3L12 3L21 12H19"
        stroke="#1D2A64"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M5 12V19C5 19.5304 5.21071 20.0391 5.58579 20.4142C5.96086 20.7893 6.46957 21 7 21H17C17.5304 21 18.0391 20.7893 18.4142 20.4142C18.7893 20.0391 19 19.5304 19 19V12"
        stroke="#1D2A64"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M9 21V15C9 14.4696 9.21071 13.9609 9.58579 13.5858C9.96086 13.2107 10.4696 13 11 13H13C13.5304 13 14.0391 13.2107 14.4142 13.5858C14.7893 13.9609 15 14.4696 15 15V21"
        stroke="#1D2A64"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};
export const GeoProfileIcon = ({ style }) => {
  return (
    <svg
      {...style}
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M12 14C13.6569 14 15 12.6569 15 11C15 9.34315 13.6569 8 12 8C10.3431 8 9 9.34315 9 11C9 12.6569 10.3431 14 12 14Z"
        stroke="#1D2A64"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M17.657 16.657L13.414 20.9C13.039 21.2746 12 22.5001 12 22.5001C12 22.5001 10.962 21.2746 10.587 20.9L6.343 16.657C5.22422 15.5382 4.46234 14.1127 4.15369 12.5609C3.84504 11.009 4.00349 9.40053 4.60901 7.93874C5.21452 6.47696 6.2399 5.22755 7.55548 4.34852C8.87107 3.46949 10.4178 3.00031 12 3.00031C13.5822 3.00031 15.1289 3.46949 16.4445 4.34852C17.7601 5.22755 18.7855 6.47696 19.391 7.93874C19.9965 9.40053 20.155 11.009 19.8463 12.5609C19.5377 14.1127 18.7758 15.5382 17.657 16.657Z"
        stroke="#1D2A64"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};
export const SocialStatusProfileIcon = ({ style }) => {
  return (
    <svg
      {...style}
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M10 17C13.866 17 17 13.866 17 10C17 6.13401 13.866 3 10 3C6.13401 3 3 6.13401 3 10C3 13.866 6.13401 17 10 17Z"
        stroke="#1D2A64"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M8.04017 14.3096C8.23332 12.6419 9.09291 11.2135 10.3222 10.2596C11.5191 9.33068 13.0666 8.8517 14.6906 9.03979C15.4739 9.1305 16.1166 9.35806 16.7699 9.71205M18.6646 11.2258C19.6433 12.4354 20.1536 14.0225 19.9605 15.6902C19.5792 18.9819 16.6017 21.3413 13.31 20.9601C10.8016 20.6696 9.12587 19.296 8.50049 17"
        stroke="#1D2A64"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M16.8664 5.26532L16.5395 5.23673C15.7439 5.16716 15.1131 4.53638 15.0435 3.7408L15.015 3.41389C14.9907 3.13658 15.0904 2.86275 15.2872 2.66592C15.655 2.29814 16.2513 2.29814 16.619 2.66592L17.6143 3.66122C17.9821 4.029 17.9821 4.62529 17.6143 4.99307C17.4175 5.1899 17.1437 5.28957 16.8664 5.26532Z"
        stroke="#1D2A64"
        strokeWidth="1.5"
      />
    </svg>
  );
};
export const WorksProfileIcon = ({ style }) => {
  return (
    <svg
      {...style}
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M19 7H5C3.89543 7 3 7.89543 3 9V18C3 19.1046 3.89543 20 5 20H19C20.1046 20 21 19.1046 21 18V9C21 7.89543 20.1046 7 19 7Z"
        stroke="#1D2A64"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M8 7V5C8 4.46957 8.21071 3.96086 8.58579 3.58579C8.96086 3.21071 9.46957 3 10 3H14C14.5304 3 15.0391 3.21071 15.4142 3.58579C15.7893 3.96086 16 4.46957 16 5V7"
        stroke="#1D2A64"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M12 12V12.01"
        stroke="#1D2A64"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M3 13C5.79158 14.4067 8.87403 15.1394 12 15.1394C15.126 15.1394 18.2084 14.4067 21 13"
        stroke="#1D2A64"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};
export const StudiedProfileIcon = ({ style }) => {
  return (
    <svg
      {...style}
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M9 4V22M6 4H17C17.5304 4 18.0391 4.21071 18.4142 4.58579C18.7893 4.96086 19 5.46957 19 6V18C19 18.5304 18.7893 19.0391 18.4142 19.4142C18.0391 19.7893 17.5304 20 17 20H6C5.73478 20 5.48043 19.8946 5.29289 19.7071C5.10536 19.5196 5 19.2652 5 19V5C5 4.73478 5.10536 4.48043 5.29289 4.29289C5.48043 4.10536 5.73478 4 6 4V4Z"
        stroke="#1D2A64"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M13 8H15"
        stroke="#1D2A64"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M13 12H15"
        stroke="#1D2A64"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};
