import React, { useState } from "react";
import styles from "./comments.module.sass";
import CommentsTitle from "./CommentTitle";
import UserComment from "./UserComment";
import CommentInput from "./CommentInput";
import classNames from "classnames";

const CommentSection = ({
  isFriendEvent,
  event,
  setWarning,
  eventId,
  mediaId,
  comments,
  setSize,
  openGallery,
}) => {
  const [editData, setEditData] = useState({});

  const closeEdit = () => {
    setEditData({});
  };

  const getEditDataHandler = (objectWithData) => {
    setEditData(objectWithData);
  };

  const clearIdData = () => {
    setEditData({});
  };

  return (
    <section
      className={classNames(styles.commentSection, {
        [styles.mediaComments]: mediaId,
      })}
    >
      <CommentsTitle commentsQuantity={comments?.length || 0} />
      <div className={styles.separationWrapper}>
        <div className={styles.commentsWrapper}>
          {comments &&
            comments.length > 0 &&
            comments?.map((comment) => {
              return (
                <UserComment
                  isLiked={comment.isLiked}
                  getEditData={getEditDataHandler}
                  key={comment.id}
                  comment={comment}
                  eventId={eventId}
                  mediaId={mediaId}
                />
              );
            })}
        </div>
        {event?.permissions === "0001" ||
        event?.permissions === "0000" ? null : (
          <CommentInput
            openGallery={openGallery}
            setSize={setSize}
            setWarning={setWarning}
            mediaId={mediaId}
            isFriendEvent={isFriendEvent}
            clearIdData={clearIdData}
            closeEdit={closeEdit}
            editData={editData}
          />
        )}
      </div>
    </section>
  );
};
export default CommentSection;
