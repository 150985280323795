import { useEffect } from "react";

export const useGlobalClose = (ref, handleClose) => {
  useEffect(() => {
    const closeInput = ({ target }) => {
      if (!ref?.current?.contains(target)) {
        console.log("error");
        handleClose();
      }
    };
    window.addEventListener("click", closeInput);
    return () => window.removeEventListener("click", closeInput);
  }, [handleClose, ref]);

  return {};
};

export const useGlobalCloseSmiles = (ref, handleClose, state) => {
  useEffect(() => {
    const closeInput = ({ target }) => {
      if (!ref?.current?.contains(target) && state) {
        handleClose();
      }
    };
    window.addEventListener("click", closeInput);
    return () => window.removeEventListener("click", closeInput);
  }, [handleClose, ref, state]);

  return {};
};
