import React, { useEffect, useLayoutEffect, useRef } from "react";
import styles from "./gallery.module.sass";
import Typography from "../../../components/ui/typography";

import CommentSection from "../../../components/activity-comments";
import VideoPlayer from "../video-player/VideoPlayer";
import { useDispatch, useSelector } from "react-redux";
import {
  getMediaComments,
  mediaCommentsSelector,
} from "../../../store/reducers/comments";
import { useParams } from "react-router";
import { useGlobalCloseSmiles } from "../../../hooks/useCloseMenu";
import ShareModal from "../../../components/more-button/ShareModal";

const GalleryModal = ({ item, openGallery, handleClose, event, getItem }) => {
  const { eventId } = useParams();
  const [arrowClicked, setClicker] = React.useState(false);
  const ref = useRef(null);
  const dispatch = useDispatch();
  const comments = useSelector(mediaCommentsSelector);

  const [currentIdx, setIdx] = React.useState(0);

  const [openActions, setOpenActions] = React.useState(false);
  const [shareModal, setShareModal] = React.useState(false);

  const shareRef = React.useRef(null);

  const handleShareOptions = () => {
    setOpenActions(false);
    setShareModal(true);
  };
  const handleCloseShare = () => {
    setShareModal(false);
  };
  useGlobalCloseSmiles(shareRef, handleCloseShare, shareModal);

  useGlobalCloseSmiles(ref, handleClose, openGallery);

  useEffect(() => {
    const currentIdx = event?.media
      .map((media) => {
        return media.id;
      })
      .indexOf(item?.id);

    setIdx(currentIdx);
  }, []);

  useEffect(() => {
    if (arrowClicked) {
      getItem(event?.media[currentIdx]);
    }
  }, [currentIdx]);
  useLayoutEffect(() => {
    setClicker(false);
  }, []);
  useEffect(() => {
    dispatch(getMediaComments({ eventId: eventId, mediaId: item?.id }));
  }, [dispatch, eventId, item?.id]);

  const handleChangeImage = (e) => {
    setClicker(true);
    if (e.target?.className.indexOf("prev") !== -1 && currentIdx > 0) {
      setIdx((prevState) => prevState - 1);
    }
    if (
      e.target?.className.indexOf("next") !== -1 &&
      currentIdx !== event?.media.length - 1
    ) {
      console.log("working");
      setIdx((prevState) => prevState + 1);
    }
  };

  return (
    <div className={styles.modalOverlay}>
      <div ref={ref} className={styles.modalOverlay__modal}>
        <div className={styles.modalHeader}>
          <div className={styles.modalHeader__memberInfo}>
            <div className={styles.modalHeader__memberInfo__imageWrapper}>
              <img
                src={item?.uploaded_by.profile_photo_path}
                alt="User avatar"
              />
            </div>
            <Typography
              className={styles.modalHeader__memberInfo__nameTitle}
              component="h3"
              variant="title"
            >
              <span className={styles.spacingReq}>
                {item?.uploaded_by.firstName}
              </span>
              <span>{item?.uploaded_by.lastName}</span>
            </Typography>
          </div>
          {shareModal && (
            <ShareModal
              event={event}
              shareRef={shareRef}
              className={styles.shareModal}
            />
          )}

          <div className={styles.modalHeader__buttonWrapper}>
            <button
              type="button"
              className={styles.shareBtn}
              onClick={handleShareOptions}
            >
              <svg
                className={styles.shareSvg}
                width="16"
                height="16"
                viewBox="0 0 16 16"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  fillRule="evenodd"
                  clipRule="evenodd"
                  d="M8.41271 9.00013L13.9996 3.41421V5.99982C13.9996 6.5522 14.4474 7 14.9998 7C15.5522 7 16 6.5522 16 5.99982V0.861539C16 0.385724 15.6143 0 15.1385 0H9.99873C9.44645 0 8.99873 0.447715 8.99873 1C8.99873 1.55228 9.44645 2 9.99873 2H12.5852L6.9985 7.58566C6.60785 7.97623 6.60785 8.60955 6.9985 9.00013C7.38904 9.3906 8.02217 9.3906 8.41271 9.00013ZM15.0027 11.0002C15.0027 10.4478 14.5549 10 14.0025 10C13.4502 10 13.0024 10.4478 13.0024 11.0002V14H2.00036V3H5.00109C5.55337 3 6.00109 2.55228 6.00109 2C6.00109 1.44772 5.55337 1 5.00109 1H2.00036C0.895593 1 0 1.89543 0 3V14C0 15.1046 0.895593 16 2.00036 16H13.0024C14.1071 16 15.0027 15.1046 15.0027 14V11.0002Z"
                  fill="#1D2A64"
                />
              </svg>
              Share
            </button>
            <button
              onClick={() => handleClose()}
              className={styles.modalHeader__buttonWrapper__closeBtn}
            >
              <svg
                width="10"
                height="10"
                viewBox="0 0 10 10"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M9.67711 0.322887C9.2466 -0.107629 8.54859 -0.107629 8.11808 0.322887L5 3.44096L1.88192 0.322887C1.45141 -0.107629 0.753404 -0.107629 0.322887 0.322887C-0.107629 0.753404 -0.107629 1.45141 0.322887 1.88192L3.44096 5L0.322887 8.11807C-0.107629 8.54859 -0.107629 9.2466 0.322887 9.67711C0.753404 10.1076 1.45141 10.1076 1.88192 9.67711L5 6.55904L8.11807 9.67711C8.54859 10.1076 9.2466 10.1076 9.67711 9.67711C10.1076 9.2466 10.1076 8.54859 9.67711 8.11807L6.55904 5L9.67711 1.88192C10.1076 1.45141 10.1076 0.753404 9.67711 0.322887Z"
                  fill="#1D2A64"
                />
              </svg>
            </button>
          </div>
        </div>
        <div className={styles.contentWrapper}>
          <div className={styles.contentWrapper__viewBox}>
            <button
              onClick={(e) => handleChangeImage(e)}
              className={styles.prevPhoto}
              type="button"
            ></button>
            <button
              type="button"
              onClick={(e) => handleChangeImage(e)}
              className={styles.nextPhoto}
            ></button>
            {item?.type.indexOf("video") === 0 ? (
              <VideoPlayer url={item?.url} />
            ) : (
              <img alt="galleryView" src={item?.url} />
            )}
          </div>
          <CommentSection
            event={event}
            mediaId={item?.id}
            eventId={eventId}
            comments={comments}
          />
        </div>
      </div>
    </div>
  );
};
export default GalleryModal;
