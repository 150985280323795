import { all, call, spawn } from "redux-saga/effects";
import { authSaga } from "../reducers/auth";
import { userSaga } from "../reducers/user";
import { timelinesSagas } from "../reducers/timelines";
import { sagas as searchSagas } from "../reducers/search";
import { friendsSaga } from "../reducers/friends";
import { activities } from "../reducers/activities";
import { commentsSaga } from "../reducers/comments";
import { settingsSaga } from "../reducers/settings";
import { support } from "../reducers/support";
import { sagas as combineSearch } from "../reducers/combine-search";
import {payments} from "../reducers/payments";
export default function* sagas() {
  const sagas = [
    authSaga,
    userSaga,
    timelinesSagas,
    searchSagas,
    friendsSaga,
    activities,
    commentsSaga,
    combineSearch,
    support,
    settingsSaga,
    payments,
  ];

  const retry = sagas.map((saga) => {
    return spawn(function* () {
      while (true) {
        try {
          yield call(saga);
          break;
        } catch (error) {
          console.log(error);
        }
      }
    });
  });

  yield all(retry);
}
