export const GOOGLE_SDK_URL = "https://apis.google.com/js/api.js";

export const isGoogleReady = () => {
  return !!window.gapi;
};

export const isGoogleAuthReady = () => {
  return !!window.gapi.auth;
};

export const onApiLoad = () => {
  window.gapi.load("auth");
  window.gapi.load("picker");
};

export const doAuth = (callback) => {
  window.gapi.auth.authorize(
    {
      client_id:
        "687400870600-sf75qi1m9qkknrkt5o9smp9iluog98ha.apps.googleusercontent.com",
      scope: [
        "https://www.googleapis.com/auth/photoslibrary",
        "https://www.googleapis.com/auth/photoslibrary.readonly",
        "https://www.googleapis.com/auth/photoslibrary.readonly.appcreateddata",
      ],
      immediate: false,
    },
    callback
  );
};
