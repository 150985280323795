import Typography from "../../components/ui/typography";
import { Tab, TabContainer } from "../../components/tabs";
import styles from "./activity.module.sass";
import Notification from "./notification";
import { useDispatch, useSelector } from "react-redux";
import useQuery from "../../hooks/useQuery";
import { useHistory } from "react-router";
import { useEffect } from "react";
import useQueryReRender from "../../hooks/useQueryReRender";
import {
  activitySelector,
  friendNotificationSelector,
  getNotifications,
  getFriendNotification,
} from "../../store/reducers/activities";
import Chip from "../../components/ui/chip";

const Activity = () => {
  const location = useSelector(({ router: { location } }) => location);
  const history = useHistory();
  const dispatch = useDispatch();
  const queryParams = useQuery();
  const friendsNotification = useSelector(friendNotificationSelector);
  const activity = useSelector(activitySelector);
  let currentQueryParam = queryParams.get("notification");

  const handleTabChange = (value) => {
    queryParams.set("notification", value);
    history.push(`${location.pathname}?${queryParams.toString()}`);
  };

  useEffect(() => {
    dispatch(getNotifications());
    dispatch(getFriendNotification());
  }, [dispatch]);

  useQueryReRender((query) => {
    const { notification } = query;
    switch (notification) {
      case "past":
        // console.log(notification, "past");
        break;
      case "new":
        // console.log(notification, "new");
        break;
      case "friends":
        // dispatch(getFriendNotification());
        break;
      default:
    }
  });

  return (
    <div className={styles.wrapper}>
      <header className={styles.activity_header}>
        <Title />

        {currentQueryParam === "new" && (
          <Typography component="span" variant="smallText">
            Found <b>{activity?.new?.meta.total}</b> new notifications
          </Typography>
        )}
        {currentQueryParam === "past" && (
          <Typography component="span" variant="smallText">
            Found <b>{activity?.past?.meta.total}</b> past notifications
          </Typography>
        )}
      </header>
      <TabContainer
        handleChange={handleTabChange}
        initialState={queryParams.get("notification") || "new"}
      >
        <Tab value="new">New Notifications</Tab>
        <Tab value="past">Past Notifications</Tab>
        <Chip isVisible={!!(friendsNotification && friendsNotification.length)}>
          <Tab value="friends">Connections Requests</Tab>
        </Chip>
      </TabContainer>
      <Notification />
    </div>
  );
};

export default Activity;

const Title = () => (
  <Typography component="h1" variant="pageTitle">
    Activity
  </Typography>
);
