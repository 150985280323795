import { createAction, handleActions } from "redux-actions";
import {
  all,
  call,
  takeLatest,
  fork,
  put,
  take,
  takeEvery,
} from "redux-saga/effects";
import { create } from "yup/lib/Reference";
import { resource } from "../../api";
import { getUser } from "./user";
import { successMessage } from "../../components/ui/alert/alertReducer";
const namespace = `activities`;

const initialState = {
  past: null,
  new: null,
  friends: null,
};

const GET_NOTIFICATION = `${namespace}/GET_NOTIFICATION`;

const GET_FRIEND_NOTIFICATION = `${namespace}/GET_FRIEND_NOTIFICATION`;
const SET_FRIEND_NOTIFICATION = `${namespace}/SET_FRIEND_NOTIFICATION`;

const GET_NEW_NOTIFICATION = `${namespace}/GET_NEW_NOTIFICATION`;
const SET_NEW_NOTIFICATION = `${namespace}/SET_NEW_NOTIFICATION`;

const GET_PAGE = `${namespace}/GET_PAGE`;

const ACCEPT_FRIEND = `${namespace}/ACCEPT_FRIEND`;

const SET_TO_PAST = `${namespace}/SET_TO_PAST`;
const GET_PAST_NOTIFICATIONS = `${namespace}/GET_PAST_NOTIFICATIONS`;
const SET_PAST_NOTIFICATIONS = `${namespace}/SET_PAST_NOTIFICATIONS`;

const ACCEPT_EVENT = `${namespace}/ACCEPT_EVENT`;
const SET_ACCEPT = `${namespace}/SET_ACCEPT`;
export const acceptEvent = createAction(ACCEPT_EVENT);

export const getNotifications = createAction(GET_NOTIFICATION);
export const getFriendNotification = createAction(GET_FRIEND_NOTIFICATION);
export const setFriendNotification = createAction(SET_FRIEND_NOTIFICATION);
export const acceptFriend = createAction(ACCEPT_FRIEND);

export const getPage = createAction(GET_PAGE);

export const getNewNotification = createAction(GET_NEW_NOTIFICATION);
export const setNewNotification = createAction(SET_NEW_NOTIFICATION);

export const getPastNotifications = createAction(GET_PAST_NOTIFICATIONS);
export const setPastNotifications = createAction(SET_PAST_NOTIFICATIONS);

export const setNotificationAsPast = createAction(SET_TO_PAST);

export default handleActions(
  {
    [SET_FRIEND_NOTIFICATION]: (state, { payload }) => ({
      ...state,
      friends: payload,
    }),
    [SET_NEW_NOTIFICATION]: (state, { payload }) => ({
      ...state,
      new: payload,
    }),
    [SET_PAST_NOTIFICATIONS]: (state, { payload }) => ({
      ...state,
      past: payload,
    }),
  },
  initialState
);

export const activitySelector = (state) => state[namespace];
export const friendNotificationSelector = (state) => state[namespace].friends;

function* getFriendsNotificationRequest() {
  try {
    const {
      data: { data },
    } = yield call(resource.get, `/cabinet/friends/requests`);
    yield put(setFriendNotification(data));
  } catch (err) {
    console.log(err);
  }
}
function* getNewNotificationRequest() {
  try {
    const { data } = yield call(resource.get, `/cabinet/log?notification=new`);

    yield put(setNewNotification(data));
  } catch (err) {
    console.log(err);
  }
}
function* getNewNotificationPage() {
  while (true) {
    const { payload } = yield take(GET_PAGE);

    try {
      const { data } = yield call(
        resource.get,
        `/cabinet/log?notification=new&page=${payload}`
      );

      yield put(setNewNotification(data));
    } catch (err) {
      console.log(err);
    }
  }
}

function* acceptFriendRequest() {
  while (true) {
    const { payload } = yield take(ACCEPT_FRIEND);
    try {
      const response = yield call(
        resource.post,
        `/cabinet/friends/accept/${payload}`
      );
      if (response) {
        yield fork(getFriendsNotificationRequest);
        yield put(getUser());
      }
    } catch (err) {
      console.log(err);
    }
  }
}

function* getNotivicationRequest() {
  yield all([
    fork(getFriendsNotificationRequest),
    fork(getNewNotificationRequest),
  ]);
}

function* setNotificationToPast() {
  while (true) {
    const { payload } = yield take(SET_TO_PAST);

    try {
      const response = yield call(
        resource.post,
        `cabinet/log/click/${payload.id}`
      );
    } catch (err) {
      console.log(err);
    }
  }
}

function* requestPastNotifications({ payload }) {
  console.log(payload);
  try {
    const { data } = yield call(
      resource.get,
      `/cabinet/log?notification=past&page=${payload.page}`
    );

    yield put(setPastNotifications(data));
  } catch (err) {
    console.log(err);
  }
}

function* changeAcceptStatus({ payload }) {
  const { id } = payload;
  const { param } = payload;
  const { page } = payload;
  try {
    const { data } = yield call(
      resource.post,
      `cabinet/log/setAccept/${id}?notification=${param}&page=${page}`
    );
    return data;
  } catch (err) {
    console.log(err);
  }
}

function* acceptEventRequest({ payload }) {
  const { eventId } = payload;
  try {
    const response = yield call(
      resource.put,
      `/cabinet/event/${eventId}/accept`
    );
    if (response) {
      // yield put(getMembers(payload));
      yield put(successMessage("You joined this event"));
      return null;
    }
  } catch (err) {
    console.log(err);
  }
}
function* onEventAccept(payload) {
  const param = payload.payload.param;
  const response = yield all([
    changeAcceptStatus(payload),
    acceptEventRequest(payload),
  ]);
  if (param === "new") {
    yield put(setNewNotification(response[0]));
  }
  if (param === "past") {
    yield put(setPastNotifications(response[0]));
  }
}
export function* activities() {
  yield all([
    takeLatest(ACCEPT_EVENT, onEventAccept),
    acceptFriendRequest(),
    takeEvery(GET_NOTIFICATION, getNotivicationRequest),
    getNewNotificationPage(),
    setNotificationToPast(),
    takeEvery(GET_PAST_NOTIFICATIONS, requestPastNotifications),
    getFriendsNotificationRequest(),
  ]);
}
