import React, { useEffect, useRef, useState } from "react";

import styles from "./chip.module.sass";

const Chip = ({ count = null, children, isVisible = false, ...props }) => {
  const [style, setStyle] = useState();
  const ref = useRef();
  useEffect(() => {
    if (ref.current) {
      const width = ref.current.clientWidth;
      setStyle({
        height: width,
      });
    }
  }, []);
  return (
    <div className={styles.wrapper}>
      {isVisible && (
        <div className={styles.chip} ref={ref} style={style}>
          {count}
        </div>
      )}
      {React.cloneElement(children, { ...props })}
    </div>
  );
};

export default Chip;
