import React, { useState } from "react";
import Typography from "../../../../../components/ui/typography";
import styles from "./shared.module.sass";
import SearchInput from "../../../../../components/search-input/SearchInput";
import {
  friendsSelector,
  getFriends,
} from "../../../../../store/reducers/friends";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import FriendItem from "./FriendItem";
import {
  getShareDetails,
  shareDetailsSelector,
} from "../../../../../store/reducers/settings";

const SharedTimelines = () => {
  const [inputValue, setValue] = useState("");
  const dispatch = useDispatch();

  const handleSetInputValue = (value) => {
    setValue(value);
  };

  const shareDetails = useSelector(shareDetailsSelector);

  let arrOfTimelines = shareDetails?.map((detail) =>
    detail.categories.filter((category) => {
      return category.subscribed === true;
    })
  );

  let followedTimelinesArr = arrOfTimelines?.map((item) => item.length);

  let followedTimelinesQuantity = followedTimelinesArr?.reduce(
    (a, b) => a + b,
    0
  );

  const filteredFriendList = shareDetails?.filter((detail) => {
    return (detail?.firstName + detail?.lastName)
      .toLowerCase()
      .includes(inputValue.toLowerCase());
  });

  useEffect(() => {
    dispatch(getFriends());
    dispatch(getShareDetails());
  }, []);

  return (
    <div className={styles.sharedWrapper}>
      <Typography variant="title" component="h2">
        Shared Timelines
      </Typography>
      <Typography variant="title" component="h3">
        Shared with you {followedTimelinesQuantity} timelines
      </Typography>
      <SearchInput
        callback={handleSetInputValue}
        className={styles.searchInput}
      />
      <div className={styles.friendsWrapper}>
        {filteredFriendList?.map((detail) => {
          return <FriendItem detail={detail} key={detail.id} />;
        })}
      </div>
    </div>
  );
};
export default SharedTimelines;
