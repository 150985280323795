import img from './img.gif'

const styles = {
    position: 'absolute',
    transform: 'translate(-50%,-50%)',
    top: '50%',
    left: '50%'
}


const Loader = () => {
    return (
      <img style={styles} alt="" src={img} />
    )
}

export default Loader


