/* eslint-disable no-loop-func */
import loadScript from "load-script";
import { useCallback, useEffect, useState } from "react";
import styles from "../media-step.module.sass";
import classNames from "classnames";
import React from "react";
import { isGoogleReady, onApiLoad, doAuth, GOOGLE_SDK_URL } from "./GoogleAuth";
import FileGrid from "./FileGrid";
import { useParams } from "react-router";

const GooglePick = ({ children, field, helper, className }) => {
  let scriptLoadingStarted = false;
  const params = useParams();

  useEffect(() => {
    if (isGoogleReady()) {
      // google api is already exists
      // init immediately
      onApiLoad();
    } else if (!scriptLoadingStarted) {
      // load google api and the init
      scriptLoadingStarted = true;

      loadScript(GOOGLE_SDK_URL, onApiLoad);
    } else {
      // is loading
    }
  }, []);

  const [mediaItems, setMediaItems] = useState([]);

  const [selectedPhotos, setSelectedPhotos] = useState([]);

  const [chooserOpen, setChooserOpen] = useState(false);
  const [nextPageToken, setNextPageToken] = useState("");
  const [accessToken, setAccessToken] = useState("");
  const startChooser = () => {
    doAuth((response) => {
      if (response.access_token) {
        setAccessToken(response.access_token);
        createChooser(response.access_token);
      } else {
        alert("Smth is wrong");
      }
    });
  };

  const createChooser = async (access_token) => {
    const kek = fetch("https://photoslibrary.googleapis.com/v1/mediaItems", {
      method: "GET",
      headers: {
        Authorization: "Bearer " + access_token,
      },
    });

    kek
      .then((response) => {
        return response.json();
      })
      .then((data) => {
        console.log(data);
        setNextPageToken(data.nextPageToken);
        setMediaItems(data.mediaItems);
        setChooserOpen(true);
      });
  };

  const getNextPage = async () => {
    if (nextPageToken === "") {
      return;
    }
    const kek = fetch(
      `https://photoslibrary.googleapis.com/v1/mediaItems?pageToken=${nextPageToken}`,
      {
        method: "GET",
        headers: {
          Authorization: "Bearer " + accessToken,
        },
      }
    );

    kek
      .then((response) => {
        return response.json();
      })
      .then((data) => {
        console.log(data);
        setNextPageToken(data.nextPageToken ? data.nextPageToken : "");
        setMediaItems((prevState) => [...prevState, ...data.mediaItems]);
        setChooserOpen(true);
      });
  };

  const toggleSelectPhoto = (photoNew) => {
    const photoIndex = selectedPhotos.findIndex(
      (item) => item.id === photoNew.id
    );

    if (photoIndex > -1) {
      const newSelectedPhotos = selectedPhotos.filter(
        (photoItem) => photoItem.id !== photoNew.id
      );

      setSelectedPhotos(newSelectedPhotos);
    } else {
      const newSelectedPhotos = [...selectedPhotos, photoNew];

      setSelectedPhotos(newSelectedPhotos);
    }
  };

  const downloadPhotos = useCallback(async () => {
    let arr = [];
    let arr2 = [];
    for (let i = 0; i < selectedPhotos.length; i++) {
      const single_file = selectedPhotos[i];

      const response = await fetch(
        `${process.env.REACT_APP_BASE_URL}/getImage?url=${single_file.baseUrl}`,
        {
          method: "GET",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
        }
      );

      response.blob().then((myBlob) => {
        const file = new File([myBlob], single_file.filename);

        if (params.eventId) {
          arr.push(file);
          helper(arr);
        } else {
          const { size } = file;
          const mb = size / (1024 * 1024);
          let obj = {
            name: file.name,
            file: file,
            preview: URL.createObjectURL(file),
            size: mb.toFixed(2),
            cover: false,
          };

          arr.push(obj);
          arr2 = [...field.value, ...arr];
          field.value.map((item) => (item.cover = false));
          arr2[0].cover = true;
          helper.setValue(arr2);
        }
      });
    }
    setChooserOpen(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedPhotos]);

  return (
    <div className={className}>
      <button type="button" onClick={() => startChooser()}>
        {children}
      </button>
      {chooserOpen && (
        <FileGrid
          getNextPage={getNextPage}
          setChooserOpen={setChooserOpen}
          files={mediaItems}
          selectPhoto={toggleSelectPhoto}
          selectedPhotos={selectedPhotos}
          confirmSelections={downloadPhotos}
        />
      )}
    </div>
  );
};

export default GooglePick;
