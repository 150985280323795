import React from "react";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";
import styles from "./search-results.module.sass";
import Typography from "../../components/ui/typography";
// date={format(Date.parse(obj.start_date), "PP")}
const Event = ({ date, description, ownerId, eventId, title, avatar }) => {
  return (
    <div className={styles.event}>
      <Link
        to={{
          pathname: `/friend/${ownerId}/event/${eventId}`,
        }}
      >
        <div className={styles.event__header}>
          <img src={avatar} alt="event_icon" className={styles.event__image} />
          <ul className={styles.event__info}>
            <Typography
              component="li"
              variant="label"
              className={styles.event__title}
            >
              {title}
            </Typography>
            <Typography
              component="li"
              variant="smallText"
              className={styles.event__date}
            >
              {date}
            </Typography>
          </ul>
        </div>
        <Typography component="p" className={styles.event__description}>
          {description}
        </Typography>
      </Link>
    </div>
  );
};
Event.propTypes = {};
export default Event;
