import { useField } from "formik";

import Checkbox from "../checkbox";

const CheckboxControl = ({ name, className, timelineId, disabled = false }) => {
  const [field, meta, helpers] = useField({ name });
  const { value } = meta;

  const { setValue } = helpers;

  const handleChange = () => {
    setValue(!value);
  };

  return (
    <Checkbox
      name={name}
      checked={!!value}
      onChange={handleChange}
      className={className}
      id={timelineId}
      // error={!!meta.error}
      disabled={disabled}
    />
  );
};

export default CheckboxControl;
