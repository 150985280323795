import { isPast } from 'date-fns'
import { ErrorMessage, useField } from 'formik'
import React from 'react'
import styles from './inputControl.module.sass'

const ExpireInputControl = ({ label, name, ...props }) => {
  const [field, meta, helpers] = useField(name)
  const [value, setValue] = React.useState('xx/xx')
  const htmlFor = `text-${Math.random()}`
  const now = new Date()
  const month = now.getMonth()
  const year = now.getFullYear()
  // const dateNow = new Date(`${month}/1/${year}`);
  const handleChange = ({ target }) => {
    const { value } = target
    const prepareValue = value.replace(/\D/g, '').slice(0, 4)
    const arr = prepareValue.match(/.{1,2}/g)
    if (prepareValue[0] > 1 || (arr && arr[0] === '00')) {
      return helpers.setValue('')
    }
    if (arr?.length >= 2 && arr[1].length >= 2) {
      const inputDate = new Date(`${arr[0]}/1/20${arr[1]}`)
      if (isPast(inputDate)) {
        return helpers.setValue(prepareValue.slice(0, 3))
      }
      return helpers.setValue(prepareValue)
    }
    if (!arr || (arr?.length > 0 && arr[0] <= 12)) {
      return helpers.setValue(prepareValue)
    }
    //  helpers.setValue(prepareValue);
  }
  return (
    <div className={styles.expire__wrapper}>
      <div className={styles.row}>
        <label className={styles.label} htmlFor={htmlFor}>
          {label}
        </label>
        <div className={styles.error}>
          <ErrorMessage name={name} />
        </div>
      </div>
      <input
        className={styles.input}
        id={htmlFor}
        type="text"
        name={name}
        value={formatter(field.value)}
        onChange={handleChange}
        lol="dsdd"
        {...props}
      />
      {/* <span className={classNames(styles.expire, styles.mask)}>{value}</span> */}
    </div>
  )
}

export default ExpireInputControl

function formatter(value) {
  if (!value) {
    return
  }
  const arr = value.match(/.{1,2}/g)
  if (arr?.length > 1) {
    return arr.join(' / ')
  }
  return value
}
