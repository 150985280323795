import classNames from 'classnames'
import { ErrorMessage, useField } from 'formik'
import React, { useState } from 'react'
import { dangerMessage } from '../../../components/ui/alert/alertReducer'
// import GooglePicker from "react-google-picker";
import GooglePick from './GooglePicker/GooglePick'
import Typography from '../../../components/ui/typography'
import video from './video.svg'
import styles from './media-step.module.sass'
import { useDispatch } from 'react-redux'
const MediaStep = ({ name, error }) => {
  const [field, , helper] = useField(name)
  const ref = React.useRef(null)
  const dispatch = useDispatch()
  const [googleToken, setGoogleToken] = useState('')

  const handleChange = (e) => {
    const { target } = e

    let arr = []
    let arr2 = []

    for (let i = 0; i < target.files.length; i++) {
      const file = target.files

      const { size } = file[i]
      const mb = size / (1024 * 1024)

      if (mb > 10 && file[i].type.indexOf('image') === 0) {
        dispatch(
          dangerMessage(
            'This attachment has exceeded the maximum file size of 10MB. Please choose a smaller file size.',
          ),
        )
        return
      }
      if (mb > 4000 && file[i].type.indexOf('video') === 0) {
        dispatch(
          dangerMessage(
            'This attachment has exceeded the maximum file size of 4GB. Please choose a smaller file size.',
          ),
        )
        return
      }
      let obj = {
        name: file[i].name,
        file: file[i],
        preview: URL.createObjectURL(file[i]),
        isVideo: file[i].type.indexOf('video') === -1 ? false : true,
        size: mb.toFixed(2),
        cover: false,
      }

      arr.push(obj)
      arr2 = [...field.value, ...arr]
    }

    field.value.map((item) => (item.cover = false))
    if (arr2.length) {
      arr2[0].cover = true

      helper.setValue(arr2)
    }
    e.target.value = ''
  }
  return (
    <div className={styles.wrapper}>
      {error && (
        <div className={styles.errorTitle}>
          <ErrorMessage name={name} />{' '}
        </div>
      )}
      <div className={error ? styles.error : styles.media__container}>
        <div className={styles.media__header}>
          <div className={styles.media__headerDescription}>
            <div className={styles.media__icon}>
              <svg
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  fillRule="evenodd"
                  clipRule="evenodd"
                  d="M4 2H20C21.1046 2 22 2.89543 22 4V20C22 21.1046 21.1046 22 20 22H4C2.89543 22 2 21.1046 2 20V4C2 2.89543 2.89543 2 4 2ZM4 4V15.5858L8 11.5858L11.5 15.0858L18 8.58579L20 10.5858V4H4ZM4 20V18.4142L8 14.4142L13.5858 20H4ZM20 20H16.4142L12.9142 16.5L18 11.4142L20 13.4142V20ZM14 8C14 6.34315 12.6569 5 11 5C9.34315 5 8 6.34315 8 8C8 9.65685 9.34315 11 11 11C12.6569 11 14 9.65685 14 8ZM10 8C10 7.44772 10.4477 7 11 7C11.5523 7 12 7.44772 12 8C12 8.55228 11.5523 9 11 9C10.4477 9 10 8.55228 10 8Z"
                  fill="#97A3B7"
                />
              </svg>
            </div>
            <ul>
              <li>High resolution images</li>
              <li>PNG, JPG, GIF up to 10MB</li>
            </ul>
          </div>
        </div>
        <div className={styles.main}>
          {field.value.length > 0 &&
            field.value.map((e, i) => {
              return (
                <MediaImage
                  key={e.preview + i}
                  index={i}
                  file={e}
                  images={field.value}
                  setAction={helper.setValue}
                  cover={e.cover}
                />
              )
            })}
        </div>
        <div className={styles.media__actions}>
          <h1>Add media to entries from</h1>
          <div className={styles.buttonWrapper}>
            <div className={styles.picker}>
              <GooglePick
                field={field}
                helper={helper}
                className={styles.googlePick}
              >
                Upload from Google Photos
              </GooglePick>
            </div>
            <Typography
              className={styles.divider}
              variant="smallText"
              component="span"
            >
              Or
            </Typography>
            <label className={styles.uploadFile}>
              <input
                ref={ref}
                type="file"
                accept="video/*,image/*"
                multiple
                onChange={handleChange}
              />
              <svg
                width="17"
                height="16"
                viewBox="0 0 17 16"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M7.625 10.6128C7.625 11.0961 8.01675 11.4878 8.5 11.4878C8.98325 11.4878 9.375 11.0961 9.375 10.6128V4.34978L11.5937 6.56847C11.9354 6.91018 12.4894 6.91018 12.8311 6.56847C13.1728 6.22676 13.1728 5.67274 12.8311 5.33103L9.20721 1.70711C8.81668 1.31658 8.18352 1.31658 7.79299 1.70711L4.16907 5.33103C3.82736 5.67274 3.82736 6.22676 4.16907 6.56847C4.51078 6.91018 5.0648 6.91018 5.40651 6.56847L7.625 4.34998V10.6128Z"
                  fill="#fff"
                />
                <path
                  d="M1.5 10.625C1.5 10.1418 1.89175 9.75 2.375 9.75C2.85825 9.75 3.25 10.1418 3.25 10.625V13.25H13.75V10.625C13.75 10.1418 14.1418 9.75 14.625 9.75C15.1082 9.75 15.5 10.1418 15.5 10.625V13.25C15.5 14.2165 14.7165 15 13.75 15H3.25C2.2835 15 1.5 14.2165 1.5 13.25V10.625Z"
                  fill="#fff"
                />
              </svg>
              Upload
            </label>
          </div>
        </div>
      </div>
    </div>
  )
}

export default MediaStep

export const MediaImage = ({
  className,
  index,
  file,
  cover,
  images,
  setAction,
}) => {
  let isIsset = React.useRef(true)
  const [openOption, setOpenOption] = React.useState(false)

  const setOpen = (value) => {
    setOpenOption(true)
  }
  const setClose = () => {
    if (isIsset) {
      setOpenOption(false)
    }
  }

  const setCover = () => {
    const array = images.map((img) => {
      if (img.cover) {
        img.cover = false
      }
      return img
    })
    array[index].cover = true
    setOpenOption(false)
    setAction(array)
  }
  const handleDelete = () => {
    const isCover = images[index].cover
    const array = images.filter((e, i) => i !== index && e)
    if (isCover && array.length > 0) {
      array[0].cover = true
    }
    setOpenOption(false)
    setAction(array)
  }
  React.useEffect(() => {
    if (openOption) {
      window.addEventListener('click', setClose)
    } else {
      window.removeEventListener('click', setClose)
    }
    return () => {
      isIsset.current = false
      return window.removeEventListener('click', setClose)
    }
  }, [openOption])
  return (
    <div
      className={classNames(styles.image__wrapper, {
        [styles.image__wrapper_cover]: cover,
      })}
    >
      <h4
        className={classNames(styles.image__title, {
          [styles.image__title_cover]: !cover,
        })}
      >
        cover
      </h4>
      <div className={styles.image__container}>
        <div className={styles.insideWrapper}>
          <div className={styles.image__actions}>
            <button
              className={styles.image__openButton}
              type="button"
              onClick={setOpen}
            >
              <svg
                width="32"
                height="32"
                viewBox="0 0 32 32"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M18 10C18 11.1046 17.1046 12 16 12C14.8954 12 14 11.1046 14 10C14 8.89543 14.8954 8 16 8C17.1046 8 18 8.89543 18 10Z"
                  fill="white"
                />
                <path
                  d="M18 16C18 17.1046 17.1046 18 16 18C14.8954 18 14 17.1046 14 16C14 14.8954 14.8954 14 16 14C17.1046 14 18 14.8954 18 16Z"
                  fill="white"
                />
                <path
                  d="M16 24C17.1046 24 18 23.1046 18 22C18 20.8954 17.1046 20 16 20C14.8954 20 14 20.8954 14 22C14 23.1046 14.8954 24 16 24Z"
                  fill="white"
                />
              </svg>
            </button>
            <ul>
              <li>{file.name}</li>
              <li>{file.size}</li>
            </ul>

            {openOption && (
              <div className={styles.image__dropdown}>
                <button type="button" onClick={setCover}>
                  <svg
                    width="16"
                    height="16"
                    viewBox="0 0 16 16"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M5.20503 5.13807C5.46538 4.87772 5.88749 4.87772 6.14784 5.13807L10.8619 9.85212C11.1222 10.1125 11.1222 10.5346 10.8619 10.7949C10.6015 11.0553 10.1794 11.0553 9.91907 10.7949L5.20503 6.08088C4.94468 5.82053 4.94468 5.39842 5.20503 5.13807Z"
                      fill="#1D2A64"
                    />
                    <path
                      fillRule="evenodd"
                      clipRule="evenodd"
                      d="M14 4C14 2.89543 13.1046 2 12 2H4C2.89543 2 2 2.89543 2 4V12C2 13.1046 2.89543 14 4 14H12C13.1046 14 14 13.1046 14 12V4ZM12 3.33333H4C3.63181 3.33333 3.33333 3.63181 3.33333 4V12C3.33333 12.3682 3.63181 12.6667 4 12.6667H12C12.3682 12.6667 12.6667 12.3682 12.6667 12V4C12.6667 3.63181 12.3682 3.33333 12 3.33333Z"
                      fill="#1D2A64"
                    />
                  </svg>
                  Set as cover
                </button>
                <button type="button" onClick={handleDelete}>
                  <svg
                    width="16"
                    height="16"
                    viewBox="0 0 16 16"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M10.8923 6.2148C11.1616 5.96377 11.1765 5.54192 10.9254 5.27258C10.6744 5.00323 10.2526 4.98839 9.98322 5.23943L8.03246 7.05758L6.21431 5.10683C5.96328 4.83748 5.54143 4.82264 5.27209 5.07368C5.00274 5.32471 4.9879 5.74656 5.23894 6.0159L7.05709 7.96665L5.10634 9.78481C4.83699 10.0358 4.82215 10.4577 5.07319 10.727C5.32422 10.9964 5.74607 11.0112 6.01541 10.7602L7.96617 8.94203L9.78432 10.8928C10.0354 11.1621 10.4572 11.177 10.7265 10.9259C10.9959 10.6749 11.0107 10.253 10.7597 9.98371L8.94154 8.03295L10.8923 6.2148Z"
                      fill="#1D2A64"
                    />
                    <path
                      fillRule="evenodd"
                      clipRule="evenodd"
                      d="M0.666016 7.99984C0.666016 3.94975 3.94926 0.666504 7.99935 0.666504C12.0494 0.666504 15.3327 3.94975 15.3327 7.99984C15.3327 12.0499 12.0494 15.3332 7.99935 15.3332C3.94926 15.3332 0.666016 12.0499 0.666016 7.99984ZM7.99935 13.9998C4.68564 13.9998 1.99935 11.3135 1.99935 7.99984C1.99935 4.68613 4.68564 1.99984 7.99935 1.99984C11.3131 1.99984 13.9993 4.68613 13.9993 7.99984C13.9993 11.3135 11.3131 13.9998 7.99935 13.9998Z"
                      fill="#1D2A64"
                    />
                  </svg>
                  Delete
                </button>
              </div>
            )}
          </div>
          {!file.isVideo ? (
            <img
              src={!file.isVideo ? file.preview : video}
              alt="preview"
              className={classNames(styles.image, {
                [styles.video]: file.isVideo,
              })}
            />
          ) : (
            <video
              className={classNames(styles.image, {})}
              src={file.preview}
            ></video>
          )}
        </div>
      </div>
    </div>
  )
}
