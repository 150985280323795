import React from "react";
import Typography from "../../../../../../components/ui/typography";

import styles from "./cover-image.module.sass";

import { useDispatch, useSelector } from "react-redux";
import {
  addAvatar,
  avatarSelector,
  removeAvatar,
} from "../../../../../../store/reducers/user";

const CoverImage = () => {
  const avatar = useSelector(avatarSelector);
  const dispatch = useDispatch();

  const handleChange = ({ target }) => {
    const file = target.files[0];
    dispatch(addAvatar(file));
  };

  const handleDelete = () => {
    dispatch(removeAvatar());
  };
  return (
    <div className={styles.avatar__wrapper}>
      <Typography component="h4" variant="label" className={styles.label}>
        Cover image: <b>+20%</b>
      </Typography>
      <div className={styles.avatar__container}>
        <img src={avatar} alt="avatar" />
        <label className={styles.button__upload}>
          <input type="file" accept="image/*" onChange={handleChange} />
          Upload Photo
        </label>
        <button
          className={styles.avatar__delete}
          type="button"
          onClick={handleDelete}
        >
          <svg
            width="14"
            height="16"
            viewBox="0 0 14 16"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M5.37137 1.47822C5.38612 1.46306 5.40613 1.45455 5.42699 1.45455H8.57305C8.59391 1.45455 8.61392 1.46306 8.62867 1.47822C8.64342 1.49337 8.6517 1.51393 8.6517 1.53536V3.23237H5.34833V1.53536C5.34833 1.51393 5.35662 1.49337 5.37137 1.47822ZM3.9326 3.23237V1.53536C3.9326 1.12815 4.09005 0.737631 4.3703 0.449696C4.65055 0.16176 5.03065 0 5.42699 0H8.57305C8.96939 0 9.34949 0.16176 9.62974 0.449696C9.90999 0.737631 10.0674 1.12815 10.0674 1.53536V3.23237H12.49C12.499 3.2322 12.508 3.23219 12.517 3.23237H13.2921C13.6831 3.23237 14 3.55798 14 3.95965C14 4.36131 13.6831 4.68692 13.2921 4.68692H13.1556L12.4254 13.6896C12.4171 14.2992 12.1777 14.8819 11.7576 15.3136C11.3298 15.7531 10.7497 16 10.1447 16H3.85261C3.24768 16 2.66752 15.7531 2.23977 15.3136C1.81961 14.8819 1.58029 14.2992 1.57194 13.6896L0.841738 4.68692H0.707865C0.316922 4.68692 0 4.36131 0 3.95965C0 3.55798 0.316922 3.23237 0.707865 3.23237H1.48032C1.48937 3.23219 1.49839 3.2322 1.50737 3.23237H3.9326ZM2.985 13.5962L2.26238 4.68692H11.735L11.0124 13.5962C11.0107 13.6162 11.0099 13.6364 11.0099 13.6565C11.0099 13.8923 10.9188 14.1184 10.7565 14.2851C10.5943 14.4518 10.3742 14.5454 10.1447 14.5454H3.85261C3.62315 14.5454 3.4031 14.4518 3.24084 14.2851C3.07859 14.1184 2.98744 13.8923 2.98744 13.6565C2.98744 13.6364 2.98663 13.6162 2.985 13.5962ZM5.42698 6.46462C5.81792 6.46462 6.13485 6.79023 6.13485 7.1919V12.0404C6.13485 12.4421 5.81792 12.7677 5.42698 12.7677C5.03604 12.7677 4.71912 12.4421 4.71912 12.0404V7.1919C4.71912 6.79023 5.03604 6.46462 5.42698 6.46462ZM9.28292 7.19187C9.28292 6.79021 8.966 6.4646 8.57505 6.4646C8.18411 6.4646 7.86719 6.79021 7.86719 7.19187V12.0404C7.86719 12.442 8.18411 12.7676 8.57505 12.7676C8.966 12.7676 9.28292 12.442 9.28292 12.0404V7.19187Z"
              fill="#1D2A64"
            />
          </svg>
        </button>
      </div>
    </div>
  );
};

export default CoverImage;
