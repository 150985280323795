import React from "react";
import styles from "../media.module.sass";
import { useGlobalCloseSmiles } from "../../../hooks/useCloseMenu";
import { useDispatch } from "react-redux";
import { deleteImage } from "../../../store/reducers/timelines";
import { useParams } from "react-router";

const GalleryGridMore = ({ fill = "#fff", imageId }) => {
  const [openActions, setOpenActions] = React.useState(false);
  const ref = React.useRef(null);
  const dispatch = useDispatch();
  const { eventId } = useParams();

  const handleClose = () => {
    setOpenActions(false);
  };

  const handleOpen = (e) => {
    e.preventDefault();
    setOpenActions(!openActions);
  };

  const handleDelete = (e) => {
    e.preventDefault();
    e.stopPropagation();
    const payload = {
      eventId,
      imageId,
    };
    dispatch(deleteImage(payload));
    setOpenActions(false);
  };
  useGlobalCloseSmiles(ref, handleClose, openActions);

  return (
    <div className={styles.moreWrapper} onClick={handleOpen}>
      <svg
        width="4"
        height="16"
        viewBox="0 0 4 16"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M4 2C4 3.10457 3.10457 4 2 4C0.895431 4 0 3.10457 0 2C0 0.895431 0.895431 0 2 0C3.10457 0 4 0.895431 4 2Z"
          fill={fill}
        />
        <path
          d="M4 8C4 9.10457 3.10457 10 2 10C0.895431 10 0 9.10457 0 8C0 6.89543 0.895431 6 2 6C3.10457 6 4 6.89543 4 8Z"
          fill={fill}
        />
        <path
          d="M2 16C3.10457 16 4 15.1046 4 14C4 12.8954 3.10457 12 2 12C0.89543 12 0 12.8954 0 14C0 15.1046 0.89543 16 2 16Z"
          fill={fill}
        />
      </svg>

      {openActions && (
        <div className={styles.dropdown} ref={ref}>
          <button type="button" onClick={handleDelete}>
            <svg
              width="16"
              height="16"
              viewBox="0 0 16 16"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M10.8933 6.21431C11.1626 5.96328 11.1775 5.54143 10.9264 5.27209C10.6754 5.00274 10.2535 4.9879 9.98419 5.23894L8.03344 7.05709L6.21529 5.10634C5.96426 4.83699 5.54241 4.82215 5.27306 5.07319C5.00372 5.32422 4.98888 5.74607 5.23991 6.01541L7.05807 7.96617L5.10731 9.78432C4.83797 10.0354 4.82313 10.4572 5.07416 10.7265C5.3252 10.9959 5.74705 11.0107 6.01639 10.7597L7.96714 8.94154L9.78529 10.8923C10.0363 11.1616 10.4582 11.1765 10.7275 10.9254C10.9969 10.6744 11.0117 10.2526 10.7607 9.98322L8.94252 8.03247L10.8933 6.21431Z"
                fill="#1D2A64"
              />
              <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M0.666992 7.99935C0.666992 3.94926 3.95024 0.666016 8.00033 0.666016C12.0504 0.666016 15.3337 3.94926 15.3337 7.99935C15.3337 12.0494 12.0504 15.3327 8.00033 15.3327C3.95024 15.3327 0.666992 12.0494 0.666992 7.99935ZM8.00033 13.9993C4.68662 13.9993 2.00033 11.3131 2.00033 7.99935C2.00033 4.68564 4.68662 1.99935 8.00033 1.99935C11.314 1.99935 14.0003 4.68564 14.0003 7.99935C14.0003 11.3131 11.314 13.9993 8.00033 13.9993Z"
                fill="#1D2A64"
              />
            </svg>
            Delete
          </button>
        </div>
      )}
    </div>
  );
};

export default GalleryGridMore;
