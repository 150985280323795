import classNames from 'classnames'
import { ErrorMessage, useField } from 'formik'

import styles from './inputControl.module.sass'

const Textarea = ({
  name,
  className,
  disabled = false,
  label,
  labelClassName,
  placeholder,
  wrapperClassName,
}) => {
  const [field, meta, helpers] = useField(name)
  const htmlFor = new Date().toString()
  const onChange = (e) => {
    const target = e.target
    helpers.setValue(target.value)
    helpers.setTouched()
  }
  return (
    <div className={classNames(styles.textarea__container, wrapperClassName)}>
      {label && (
        <label
          className={classNames(styles.label, labelClassName)}
          htmlFor={htmlFor}
        >
          {label}
        </label>
      )}
      <div
        className={classNames(styles.textarea__wrapper, className, {
          [styles.textarea__disabled]: disabled,
          [styles.textarea__error]: meta.error && meta.touched,
        })}
      >
        <textarea
          id={htmlFor}
          className={classNames(styles.textarea)}
          name={name}
          value={field.value}
          onChange={onChange}
          disabled={disabled}
          placeholder={placeholder}
        />
      </div>
      <div className={styles.error}>
        <ErrorMessage name={name} />
      </div>
    </div>
  )
}

export default Textarea
