import React, { useState, useRef, useEffect } from 'react'
import { Redirect, useHistory, useLocation } from 'react-router'
import BackButton from '../../components/back-button'
import { Tab, TabContainer } from '../../components/tabs'
import TimelineSearchInput from '../../components/timeline-search-input/TimelineSearchInput'
import Typography from '../../components/ui/typography'
import styles from './search-results.module.sass'
import useQuery from '../../hooks/useQuery'
import Event from './EventContainer'
import UserContainer from './UserContainer'
import { useSelector } from 'react-redux'
import {
  changeName,
  combineSearchSelector,
  resetCombineSearch,
  setCombine,
} from '../../store/reducers/combine-search'
import { useDispatch } from 'react-redux'
import format from 'date-fns/format'
import { useGlobalCloseSmiles } from '../../hooks/useCloseMenu'
import CalendarWithRadio from '../../components/calendar-with-radio/CalendarWithRadio'
import Paginator from '../../components/paginator'

// import CalendarWithRadio from "../../components/calendar-with-radio/CalendarWithRadio";
const SearchResults = () => {
  const history = useHistory()
  const location = useLocation()
  const query = useQuery()
  const [inputValue, setValue] = useState('')
  const [calendarToggler, setToggler] = useState(false)
  const [total, setTotal] = useState(0)
  const ref = useRef()
  const search = useSelector(combineSearchSelector)

  useEffect(() => {
    return dispatch(resetCombineSearch())
  }, [])

  useEffect(() => {
    if (search?.events?.meta.total > 0) {
      setTotal(search?.events.meta.total)
    }
  }, [search])

  const bool = '1'
  const name = query.get('name').toString()
  const param = query.get('param')
  const dispatch = useDispatch()
  const handleChangeTab = (value) => {
    query.set('param', value)
    query.set('pageNumber', 1)
    query.set('page', 1)
    history.push({ pathname: location.pathname, search: query.toString() })
  }
  const handleInput = (value) => {
    console.log(value, 1927)
    dispatch(changeName(value))
    setValue(value)
  }
  const handleCalendar = () => {
    setToggler(false)
  }

  useGlobalCloseSmiles(ref, handleCalendar, calendarToggler)

  if (!location.search) {
    return (
      <Redirect
        to={{
          pathname: location.pathname,
          search: '?param=entries',
        }}
      />
    )
  }

  return (
    <div className={styles.wrapper}>
      <header className={styles.header}>
        <div>
          <BackButton onClick={history.goBack}>Back</BackButton>
        </div>
        <Typography component="h1" variant="pageTitle">
          Search results
        </Typography>
      </header>
      <TabContainer
        initialState={param || 'entries'}
        className={styles.tab__container}
        handleChange={handleChangeTab}
      >
        <Tab className={styles.tab} value="entries">
          Entries
        </Tab>
        <Tab className={styles.tab} value="people">
          People
        </Tab>
      </TabContainer>
      <div className={styles.actions}>
        <TimelineSearchInput
          value={inputValue}
          className={styles.search__input}
          onChange={handleInput}
        />
        {param === 'entries' && (
          <div className={styles.calendarContainer}>
            <div
              onClick={() => {
                setToggler(true)
              }}
              className={styles.calendarContainer__btn}
            >
              <Typography
                variant="smallText"
                className={styles.calendarContainer__staticSpan}
                component="span"
              >
                Sort by date:
              </Typography>
              <Typography variant="smallTextHeavy" component="span">
                All time
              </Typography>
            </div>
            {calendarToggler && (
              <CalendarWithRadio
                handleCalendar={handleCalendar}
                ref={ref}
                bool={true}
              />
            )}
          </div>
        )}
      </div>
      {search?.events && (
        <Typography
          className={styles.results}
          component="p"
          variant="smallText"
        >
          Found {total} matches with {name}
        </Typography>
      )}
      {param === 'entries' &&
        search?.events?.data.map((event) => {
          return (
            <Event
              date={format(Date.parse(event.start_date), 'PPP')}
              avatar={event.cover_img}
              title={event.title}
              key={event.id}
              ownerId={event.owner_id}
              description={event.description}
              eventId={event.id}
            ></Event>
          )
        })}
      {param === 'entries' && (
        <Paginator
          pageNumber={search?.events?.meta?.current_page}
          totalPages={search?.events?.meta?.last_page}
        />
      )}
      
      {param === 'people' &&
        search?.peoples?.data?.map((person) => {
          return (
            <UserContainer
              isFriend={person.isFriend}
              id={person.id}
              img={person.profile_photo_path}
              key={person.id}
              name={person.firstName}
            ></UserContainer>
          )
        })}{' '}
      {param === 'people' && (
        <Paginator
          pageNumber={search?.peoples?.meta?.current_page}
          totalPages={search?.peoples?.meta?.last_page}
        />
      )}
    </div>
  )
}

export default SearchResults
