import Typography from "../../../components/ui/typography";
import styles from "./about-me.module.sass";

function AboutMeItem({ title = "", children, icon: Icon }) {
  return (
    <div className={styles.aboutItem}>
      <Icon />
      <Typography component="span" className={styles.aboutText}>
        {title}
      </Typography>
      <Typography component="span" variant="label">
        {children}
      </Typography>
    </div>
  );
}

export default AboutMeItem;
