import React, { useEffect, useState } from "react";

import classNames from "classnames";
import styles from "../../../screens/settins/src/screens/categories/categories.module.sass";
import { useField } from "formik";
const ColorRadioButton = ({
  className,
  name,
  eventId,
  value,
  htmlFore,
  idx,
  id,
  isSelected,
}) => {
  const [field, meta, helpers] = useField(name);
  const { setValue } = helpers;
  const htmlFor = htmlFore;

  const onChange = (e) => {
    setValue(e.target.id);
  };

  return (
    <>
      <input
        id={id}
        type="radio"
        name={name}
        value={meta.value}
        checked={isSelected}
        onChange={(e) => onChange(e)}
      />
      <label
        htmlFor={htmlFor}
        className={classNames(styles.radio, className, {
          [styles.chosenOne]: isSelected,
        })}
        style={{ backgroundColor: value }}
      />
    </>
  );
};

export default ColorRadioButton;
