/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from 'react'
import styles from './comments.module.sass'
import { useSelector, useDispatch } from 'react-redux'
import { userSelector } from '../../store/reducers/user'
import { sendComment, sendMediaComment } from '../../store/reducers/comments'
import { useLocation, useParams } from 'react-router'
import { editComment, mediaEditComment } from '../../store/reducers/comments'
import Typography from '../ui/typography'
import Picker from 'emoji-picker-react'
import { useGlobalCloseSmiles } from '../../hooks/useCloseMenu'
import classNames from 'classnames'
import InputImage from './InputImage'
import sendIcon from '../assets/images/mobileSendBtn.svg'
import useMobile from '../../hooks/useMobile'

const CommentInput = ({
  editData,
  isFriendEvent,
  mediaId,
  closeEdit,
  clearIdData,
  setWarning,
  setSize,
  openGallery,
}) => {
  const user = useSelector(userSelector)
  const commentInput = React.useRef(null)

  const emojiRef = React.useRef(null)
  const [emojiState, setEmojiState] = useState(false)
  const [emojis, setChosenEmoji] = useState(null)
  const { eventId, id } = useParams()

  let isMobile = useMobile(428)

  const location = useLocation()

  const extraParams = useParams()
  const [inputValue, setValue] = useState('')
  const [message, setMessage] = useState('')
  const [emoji, setEmoji] = useState([])
  const dispatch = useDispatch()
  const [images, setImage] = useState([])
  let totalSize = images.reduce(
    (total, image) => parseInt(image.size) + total,
    0,
  )

  useEffect(() => {
    if (location.pathname.indexOf('media') === -1 && openGallery) {
      setSize(totalSize)
    }
  }, [totalSize])

  const handleImage = ({ target }) => {
    let arr = []
    let arr2 = []

    for (let i = 0; i < target.files.length; i++) {
      const file = target.files

      const { size } = file[i]
      const mb = size / (1024 * 1024)

      if (mb > 10) {
        return
      }

      let obj = {
        name: file[i].name,
        file: file[i],
        preview: URL.createObjectURL(file[i]),
        isVideo: file[i].type.indexOf('video') === -1 ? false : true,
        size: mb.toFixed(2),
        cover: false,
      }

      arr.push(obj)
      arr2 = [...images, ...arr]
    }

    images.map((item) => (item.cover = false))
    if (arr2.length) {
      setImage(arr2)
    }
  }

  const handleClose = () => {
    setEmojiState(false)
  }
  const evtId = !isFriendEvent ? eventId : extraParams.eventId

  useEffect(() => {
    emoji && setMessage(inputValue.concat(emoji))
  }, [emoji, inputValue])

  useEffect(() => {
    emojis && setEmoji((oldState) => [[...oldState, emojis.emoji].join('')])
  }, [emojis])

  const handleOpenSmiles = (e) => {
    e.preventDefault()
    setEmojiState(true)
  }

  useGlobalCloseSmiles(emojiRef, handleClose, emojiState)

  const handleEmojiClick = (e, emojiObject) => {
    setChosenEmoji(emojiObject)
  }
  const handleChange = (e) => {
    setValue(e.target.value)
    setEmoji([])
  }

  const cancelEditorMode = (e) => {
    closeEdit()
    setValue('')
  }

  useEffect(() => {
    editData?.comment && setValue(editData.comment)
    if (isMobile) return
    commentInput.current.focus()
  }, [editData?.comment])

  const handleSubmit = (e) => {
    e.preventDefault()

    if (mediaId && editData.commentId !== undefined) {
      const payload = {
        body: message,
        eventId: evtId,
        commentId: editData.commentId,
      }
      dispatch(mediaEditComment(payload))
      clearIdData()
      setValue('')
      setMessage('')
      return
    }

    if (editData.commentId !== undefined && !mediaId) {
      const payload = {
        body: message,
        eventId: evtId,
        commentId: editData.commentId,
      }
      dispatch(editComment(payload))
      clearIdData()
      setValue('')
      setMessage('')
      return
    }

    if (!mediaId) {
      const payload = { message: message, eventId: evtId, id, images: images }
      if (images.length < 16) {
        dispatch(sendComment(payload))
        setImage([])
      } else {
        setWarning(true)
      }
    }
    if (mediaId && editData.commentId === undefined) {
      const payload = {
        body: message,
        id: id,
        eventId: eventId,
        mediaId: mediaId,
      }
      dispatch(sendMediaComment(payload))
    }

    setValue('')
    setMessage('')
  }

  return (
    <div className={styles.commentInputWrapper}>
      <div
        className={classNames(styles.editionWrapper, {
          [styles.editorMode]: editData?.commentId,
        })}
      >
        <div className={styles.editionContainer}>
          <Typography
            variant="label"
            component="span"
            className={styles.editTitle}
          >
            Edit message
          </Typography>
          <Typography component="span" className={styles.editText}>
            {editData.comment}
          </Typography>
        </div>
        <div className={styles.cancelWrapper}>
          <button
            onClick={(e) => cancelEditorMode(e)}
            className={styles.cancelWButton}
          ></button>
        </div>
      </div>

      <div className={styles.SingleRowWrapper}>
        <img
          className={styles.myAvatar}
          src={user?.profile_photo_path}
          alt="user avatar"
        />
        <form
          id="comment"
          onSubmit={(e) => handleSubmit(e)}
          action="send_comment"
        >
          <div className={styles.customizationWrapper}>
            {images.length > 0 && (
              <div className={styles.inputPreviewWrapper}>
                {images.map((image, idx) => {
                  return (
                    <InputImage
                      images={images}
                      index={idx}
                      setImage={setImage}
                      key={image.preview}
                      url={image.preview}
                    />
                  )
                })}
              </div>
            )}
            <div className={styles.textArea}>
              <input
                ref={commentInput}
                className={styles.textInput}
                type="text"
                onChange={(e) => handleChange(e)}
                value={message}
                placeholder="Write your comment..."
              ></input>
              <label
                htmlFor="uploadImage"
                className={classNames(styles.fileLabel, {
                  [styles.noUpload]: mediaId,
                })}
              ></label>
              <input
                onChange={(e) => handleImage(e)}
                className={styles.imageUpload}
                type="file"
                accept="video/*,image/*"
                multiple
                id="uploadImage"
              ></input>
              <button
                type="button"
                onClick={(e) => handleOpenSmiles(e)}
                className={styles.smileBtn}
              ></button>
              <div
                ref={emojiRef}
                className={classNames(styles.emojiWrapper, {
                  [styles.noEmoji]: !emojiState,
                  [styles.widthExpander]: mediaId,
                })}
              >
                <Picker
                  pickerStyle={{ width: '100%' }}
                  onEmojiClick={handleEmojiClick}
                ></Picker>
              </div>
            </div>
          </div>
          <button
            className={classNames(styles.sendBtn, {
              [styles.smallerBtn]: mediaId,
            })}
            value="Send"
            type="submit"
          >
            {isMobile ? <img src={sendIcon} alt="mobileSendIcon" /> : 'Send'}
          </button>
        </form>
      </div>
    </div>
  )
}

export default CommentInput
