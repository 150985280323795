import classNames from 'classnames'
import { useEffect, useMemo, useRef, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import Loader from '../../components/loader'
import Typography from '../../components/ui/typography'
import {
  getFriends,
  resetFriends,
  searchFriendsSelector,
} from '../../store/reducers/search'
import {
  getMembers,
  inviteUser,
  timelinesLoadSelector,
  timelinesMembersSelector,
  timelinesPermissionsSelector,
} from '../../store/reducers/timelines'
import useMobile from '../../hooks/useMobile'

import styles from './mannage.module.sass'

const MannageMembers = ({ closeModal, eventId }) => {
  const [searchUser, setSearchUser] = useState(null)
  const [searchPermission, setSearchPermission] = useState('')
  const dispatch = useDispatch()
  const friends = useSelector(searchFriendsSelector)
  console.log('MannageMembers ~ friends', friends)
  const members = useSelector(timelinesMembersSelector)
  console.log('MannageMembers ~ members', members)

  const userName = useMemo(() => {
    if (searchUser) {
      return searchUser.email
    }
    return ''
  }, [searchUser])
  useEffect(() => {
    dispatch(getMembers(eventId))
  }, [dispatch, eventId])

  if (!eventId) {
    return null
  }
  const handleSearch = (value) => {
    dispatch(getFriends(value))
  }
  const handleResetSearch = () => {
    if (friends?.length > 0) {
      dispatch(resetFriends())
    }
  }
  const handleSelectUser = (user) => {
    if (friends?.length) {
      setSearchUser(user)
    }
  }

  const handleClickPermission = (e) => {
    e.preventDefault()
    e.stopPropagation()
    const { value } = e.target.dataset
    setSearchPermission(value)
  }

  const handleSubmit = () => {
    if (searchUser) {
      dispatch(
        inviteUser({
          eventId,
          userId: searchUser.id,
          permissions: {
            permission: searchPermission ? searchPermission : '0000',
          },
        }),
      )
      setSearchPermission('')
      setSearchUser(null)
    }
  }

  return (
    <div className={styles.wrapper}>
      <SearchMembers
        onChange={handleSearch}
        clear={handleResetSearch}
        userName={userName}
        handleSubmit={handleSubmit}
        onPermissionClick={handleClickPermission}
        selected={searchPermission}
      />
      <MemberList
        search={friends}
        members={members}
        selectUser={handleSelectUser}
        eventId={eventId}
        showActions={!friends?.length}
      />
    </div>
  )
}

export default MannageMembers

const SearchMembers = ({
  onChange,
  clear,
  userName,
  handleSubmit,
  onPermissionClick,
  selected,
}) => {
  const [searchValue, setSearchValue] = useState(userName)
  const isMobile = useMobile(428)
  useEffect(() => {
    if (userName) {
      setSearchValue(userName)
      clear()
    }
  }, [userName, clear])

  const handleChange = (e) => {
    const { value } = e.target
    setSearchValue(value)
    if (value.length >= 3) {
      onChange(value)
    }
    if (value.length < 3) {
      clear()
    }
  }

  return (
    <div className={styles.container}>
      <div className={styles.search__wrapper}>
        <Input
          placeholder="Invite someone..."
          onChange={handleChange}
          value={searchValue}
        />
        <Dropdown onClick={onPermissionClick} selected={selected} />
      </div>
      <button className={styles.invite} type="button" onClick={handleSubmit}>
      {isMobile ? "+":"Send invite"}
      </button>
    </div>
  )
}

const Input = (props) => {
  return <input className={styles.input} {...props} />
}

const Dropdown = ({ className, onClick, selected }) => {
  const [open, setOpen] = useState(false)
  const permissions = useSelector(timelinesPermissionsSelector)
  const ref = useRef(null)

  useEffect(() => {
    if (open && ref.current) {
      window.addEventListener('click', close)
    } else {
      window.removeEventListener('click', close)
    }
    return () => window.removeEventListener('click', close)
  }, [open])

  const label = useMemo(() => {
    if (selected && permissions?.length) {
      const permission = permissions.find(
        (e) => e.value.toString() === selected,
      )
      return permission.label
    }
    if (!selected && permissions?.length) return permissions[0].label
  }, [permissions, selected])

  const close = () => {
    setOpen(false)
  }
  const handleClick = (e) => {
    onClick(e)
    setOpen(false)
  }
  return (
    <div className={classNames(styles.dropdown, className)}>
      <div
        className={styles.label__wrapper}
        onClick={() => {
          setOpen(!open)
        }}
      >
        <div className={styles.label}>{label}</div>
        <svg
          width="10"
          height="6"
          viewBox="0 0 10 6"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M1 1L5 5L9 1"
            stroke="#1D2A64"
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </svg>
      </div>

      {open && (
        <ul className={styles.dropdown__list} ref={ref}>
          {permissions?.map(({ label, value }) => (
            <li
              data-value={value}
              key={value}
              className={styles.dropdown__listItem}
              onClick={handleClick}
            >
              {label}
            </li>
          ))}
        </ul>
      )}
    </div>
  )
}

const MemberList = ({ search, members, selectUser, eventId, showActions }) => {
  const isLoad = useSelector(timelinesLoadSelector)
  let users = members
  if (search?.length > 0) {
    users = [...search]
  }
  return (
    <div className={styles.members__wrapper}>
      {isLoad && <Loader />}
      <Typography variant="label" component="h3">
        Members
      </Typography>
      <div className={styles.members}>
        {users?.map((u) => (
          <MemberItem
            user={u}
            key={u.id}
            selectUser={selectUser}
            permission={!!members}
            eventId={eventId}
            showActions={showActions}
          />
        ))}
      </div>
    </div>
  )
}

const MemberItem = ({ user, selectUser, permission, eventId, showActions }) => {
  const dispatch = useDispatch()
  const isMobile = useMobile(428)
  const handleClick = () => {
    selectUser(user)
  }
  const changePermission = (e) => {
    const { value } = e.target.dataset
    dispatch(
      inviteUser({
        eventId,
        userId: user.id,
        permissions: {
          permission: value,
        },
      }),
    )
  }
  if (!user) {
    return null
  }
  return (
    <>
      <div className={styles.members__item} onClick={handleClick}>
        <div className={styles.members__info}>
          <img
            className={styles.members__image}
            src={user.profile_photo_path}
            alt="avatar"
          />
          <Typography component="h4">{user.email}</Typography>
          {permission && showActions && (
            <Dropdown
              className={styles.members__dropdown}
              selected={user.permissions}
              onClick={changePermission}
            />
          )}
          {!isMobile && permission && showActions && (
            <div className={styles.members__hasInvited}>invited</div>
          )}
        </div>
      </div>
      {isMobile && permission && showActions && (
        <div className={styles.members__hasInvited}>invited</div>
      )}
    </>
  )
}
