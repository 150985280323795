import { push } from 'connected-react-router'
import format from 'date-fns/format'
import React, { useCallback, useRef, useState } from 'react'
import hexToRgba from 'hex-to-rgba'
import { useDispatch, useSelector } from 'react-redux'
import EventList from '../../components/event-list'
import { EventListSortDate } from '../../components/event-list/EventList'
import Typography from '../../components/ui/typography'
import useQuery from '../../hooks/useQuery'
import useMobile from '../../hooks/useMobile'

import {
  getCategories,
  getEvents,
  getFriendEvents,
  timelinesSelector,
  eventId,
  deleteState,
  deleteEvent,
  confirmDeleteEvent,
} from '../../store/reducers/timelines'
import { useHistory, useParams } from 'react-router'
import styles from './timeline.module.sass'
import TimelineSearchInput from '../../components/timeline-search-input/TimelineSearchInput'
import debounce from 'lodash.debounce'
import {
  changeName,
  combineSearchSelector,
} from '../../store/reducers/combine-search'
import SearchDropDownWrapper from '../../components/search-dropdown'
import { Link } from 'react-router-dom'
import classNames from 'classnames'
import { Form, Formik } from 'formik'
import RangePicker from '../../components/ui/range-picker/RangePicker'
import ClassicButton from '../../components/ui/buttons/ClassicButtonWithText'
import TimelineDropdownFilter from './timeline-filter-dropdown/TimelineDropdownFilter'
import { userSelector } from '../../store/reducers/user'
import TimelineModal from '../../components/event/TimelineModal'
import { useGlobalCloseSmiles } from '../../hooks/useCloseMenu'

const Timeline = (props) => {
  const [nameValue, setValue] = useState('')
  const { events } = useSelector(timelinesSelector)
  // const { event } = useSelector(timelinesSelector);

  let searchParams = new URLSearchParams(window.location.search);
 
  const [filterState, setFilter] = useState(false)
  const query = useQuery()
  const dispatch = useDispatch()
  const location = useSelector(({ router: { location } }) => location)
  const deleteConfirmation = useSelector(deleteState)
  const ref = useRef(null)
  const params = useParams()
  const isMobile = useMobile(428)

  const eventID = useSelector(eventId)
  const timelineId = query.get('timeline_id')
  const shared = query.get('shared')
  const searchFriend = query.get('friend')
  const date = query.get('from')
  const user = useSelector(userSelector)
  const history = useHistory()
  const combineData = useSelector(combineSearchSelector)
  const { combine } = combineData
  const [calendarState, setCalendarState] = useState(true)

  const handleDelete = () => {
    dispatch(deleteEvent({ eventId: eventID }))
    params.eventId && history.push('/')
  }
  React.useEffect(() => {
    if (searchFriend !== null) {
      dispatch(
        getFriendEvents({
          user_id: searchFriend,
          timeline_id: timelineId,
        }),
      )
    } else {
      dispatch(getEvents())
    }
  }, [date, dispatch])

  // React.useEffect(() => {
  //   if (searchFriend !== null) {
  //     dispatch(
  //       getFriendEvents({
  //         timeline_id: timelineId,
  //         shared: shared,
  //       }),
  //     )
  //   } else {
  //     dispatch(getEvents({ timeline_id: timelineId, shared: shared }))
  //   }
  // }, [timelineId, shared])

  React.useEffect(() => {
    dispatch(getCategories())
  }, [])

  const handleDataPickerChange = (value) => {
    const { calendar } = value

    const payload = {
      date: {
        from: format(calendar.startDate, 'yyyy-MM-dd'),
        till: format(calendar.endDate, 'yyyy-MM-dd'),
      },
    }
    dispatch(getEvents(payload))
  }
  const handleCloseModal = () => {
    dispatch(confirmDeleteEvent(false))
  }

  useGlobalCloseSmiles(ref, handleCloseModal, deleteConfirmation)

  const handleInput = (value) => {
    query.set('param', 'combine')
    setValue(value)
    history.push({ pathname: location.pathname, search: query.toString() })
    dispatch(changeName(value))
  }

  const debounceChangeHandler = useCallback(debounce(handleInput, 500), [])
  if (!events) {
    return null
  }
  return (
    <>
      {deleteConfirmation && (
        <TimelineModal
          ref={ref}
          btnText="Confirm"
          onClick={handleDelete}
          message="Please confirm to delete entry"
        />
      )}
      <header className={styles.header}>
        <Typography component="h1" variant="pageTitle">
          Timeline
        </Typography>
        <div className={styles.userCaseWrapper}>
          <div className={styles.userCase}>
            <div className={styles.userCase_img}></div>
          </div>
          <div className={styles.search__wrapper}>
            <TimelineSearchInput onChange={debounceChangeHandler} />
          </div>
          {(combine?.events.data.length > 0 || combine?.users.data) &&
            nameValue && <SearchDropDownWrapper />}

          {isMobile &&
            (searchFriend === `${user?.id}` || searchFriend === null) && (
              <button className={classNames(styles.addButton)}>
                <Link
                  className={classNames(styles.addButtonText)}
                  // onClick={() => setModal(!modal)}
                  to="/new-entry"
                >
                  +
                </Link>
              </button>
            )}
        </div>
      </header>
      <main className={styles.main}>
        <div className={styles.actions}>
          <TimelineDropdownFilter />
          <Formik
            initialValues={{
              calendar: {
                startDate: '',
                endDate: '',
              },
            }}
            onSubmit={(values) => {
              return handleDataPickerChange(values)
            }}
          >
            {({ values }) => {
              return (
                <Form className={styles.rangepickerForm}>
                  {calendarState && (
                    <RangePicker
                      noText={true}
                      name="calendar"
                      className={styles.rangepicker}
                    />
                  )}

                  <ClassicButton
                    className={styles.timelineFilter}
                    type="submit"
                  >
                    {!isMobile ? (
                      'Apply'
                    ) : (
                      <svg
                        width="24"
                        height="24"
                        viewBox="0 0 24 24"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          fillRule="evenodd"
                          clipRule="evenodd"
                          d="M16.6143 3.23077C16.6143 2.82718 16.2881 2.5 15.8857 2.5C15.4833 2.5 15.1571 2.82718 15.1571 3.23077V4.44872H8.84286V3.23077C8.84286 2.82718 8.51666 2.5 8.11429 2.5C7.71191 2.5 7.38571 2.82718 7.38571 3.23077V4.44872H6.17143C4.69604 4.44872 3.5 5.64837 3.5 7.12821V11.0256V18.8205C3.5 20.3004 4.69604 21.5 6.17143 21.5H17.8286C19.304 21.5 20.5 20.3004 20.5 18.8205V11.0256V7.12821C20.5 5.64837 19.304 4.44872 17.8286 4.44872H16.6143V3.23077ZM19.0429 10.2949V7.12821C19.0429 6.45555 18.4992 5.91026 17.8286 5.91026H16.6143V7.12821C16.6143 7.5318 16.2881 7.85897 15.8857 7.85897C15.4833 7.85897 15.1571 7.5318 15.1571 7.12821V5.91026H8.84286V7.12821C8.84286 7.5318 8.51666 7.85897 8.11429 7.85897C7.71191 7.85897 7.38571 7.5318 7.38571 7.12821V5.91026H6.17143C5.5008 5.91026 4.95714 6.45555 4.95714 7.12821V10.2949H19.0429ZM4.95714 11.7564H19.0429V18.8205C19.0429 19.4932 18.4992 20.0385 17.8286 20.0385H6.17143C5.5008 20.0385 4.95714 19.4932 4.95714 18.8205V11.7564ZM10.3 14.9231C10.3 14.5195 10.6262 14.1923 11.0286 14.1923H12C12.0503 14.1923 12.0994 14.1974 12.1468 14.2072C12.4788 14.2753 12.7286 14.5699 12.7286 14.9231L12.7286 17.8462C12.7286 18.2497 12.4024 18.5769 12 18.5769C11.5976 18.5769 11.2714 18.2497 11.2714 17.8462V15.6538H11.0286C10.6262 15.6538 10.3 15.3267 10.3 14.9231Z"
                          fill="white"
                        />
                      </svg>
                    )}
                  </ClassicButton>

                  {!isMobile && (
                    <ClassicButton
                      onClick={(e) => {
                        //dispatch(getEvents())
                        history.push('/')
                      }}
                      className={styles.timelineFilter}
                      type="button"
                    >
                      Clear Filter
                    </ClassicButton>
                  )}
                </Form>
              )
            }}
          </Formik>
          {!isMobile &&
            (searchFriend === `${user?.id}` || searchFriend === null) && (
              <button className={classNames(styles.addButton)}>
                <Link
                  className={classNames(styles.addButtonText)}
                  // onClick={() => setModal(!modal)}
                  to="/new-entry"
                >
                  + Add entry
                </Link>
              </button>
            )}
        </div>
        <MyTimelines />
        <EventList setCalendarState={setCalendarState}>
          {Object.keys(events).map((e) => {
            return <EventListSortDate key={e} event={events[e]} title={e} />
          })}
        </EventList>
      </main>
    </>
  )
}

export default Timeline

const MyTimelines = () => {
  const { categories } = useSelector(timelinesSelector)

  const {
    location: { pathname },
  } = useSelector((state) => state.router)

  const dispatch = useDispatch()
  const query = useQuery()
  const timelineId = query.get('timeline_id')
  const shared = query.get('shared')

  if (!categories?.default.length) {
    return null
  }
  const handleClick = (e) => {
    const { target } = e
    const { value } = target.dataset

    if (!value && !shared) {
      query.delete('timeline_id')
    } else if (Number.isInteger(parseInt(value))) {
      query.set('timeline_id', value)
      query.delete('shared')
    } else if (value === 'shared') {
      query.set('shared', '1')
      query.delete('timeline_id')
    } else {
      query.delete('shared')
    }

    dispatch(
      push({
        pathname,
        search: query.toString(),
      }),
    )
  }

  return (
    <>
      <ul className={styles.timelenes__list}>
        <li>
          <LineButton
            value={null}
            onClick={handleClick}
            active={!timelineId && !shared}
            bgc={'#131F55'}
          >
            All
          </LineButton>
          <LineButton
            active={shared && !timelineId}
            bgc={'#131D55'}
            value="shared"
            onClick={handleClick}
          >
            Shared
          </LineButton>
          {categories.default.map((e) => {
            return (
              e.enabled && (
                <LineButton
                  key={e.id}
                  value={e.id}
                  bgc={e.color}
                  onClick={handleClick}
                  active={timelineId === `${e.id}`}
                >
                  {e.name}
                </LineButton>
              )
            )
          })}
          {categories.custom.map((e) => {
            return (
              e.enabled && (
                <LineButton
                  key={e.id}
                  value={e.id}
                  bgc={e.color}
                  onClick={handleClick}
                  active={timelineId === `${e.id}`}
                >
                  {e.name}
                </LineButton>
              )
            )
          })}
        </li>
      </ul>
    </>
  )
}

const LineButton = ({ children, value, bgc, onClick, active }) => {
  const halfTone = hexToRgba(bgc + '', 0.1)

  return (
    <button
      className={styles.lineButton}
      data-value={value}
      data-color={bgc}
      style={{
        backgroundColor: active ? bgc : halfTone,
        color: active ? '#fff' : bgc,
      }}
      onClick={onClick}
    >
      {children}
    </button>
  )
}
