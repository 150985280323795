import React, { useState, useCallback, useMemo } from "react";
import ModalContent from "./Modal";
import { ModalContext, ModalHook } from "./context";
import { useParams } from "react-router";

const Modal = ({
  isOpen,
  children,
  onClose,
  submit,
  setCalendarState,
  className,
}) => {
  const [closingAnimate, setClosingAnimate] = useState(false);
  const params = useParams();

  const close = useCallback(() => {
    if (!params.eventId) {
      setCalendarState(true);
    }

    setClosingAnimate(true);
    setTimeout(() => {
      setClosingAnimate(false);
      if (onClose) {
        onClose();
      }
    }, 600); // must less  than the transition time in css 0.5s
  }, [onClose, setCalendarState, setClosingAnimate]);

  const value = useMemo(
    () => ({
      close,
      isOpen,
    }),
    [close, isOpen]
  );

  return (
    <ModalContext.Provider value={value}>
      <ModalContent
        closingAnimate={closingAnimate}
        submit={submit}
        className={className}
      >
        {children}
      </ModalContent>
    </ModalContext.Provider>
  );
};

export const useModal = () => ModalHook();

export default Modal;
