import React from "react";
import PropTypes from "prop-types";
import { useLocation } from "react-router";
import styles from "./search-results.module.sass";
import Typography from "../../components/ui/typography";
import { Link } from "react-router-dom";
import { InviteButton } from "../friend-profile/FriendProfile";
import { inviteFriend } from "../../store/reducers/friends";
import { useDispatch } from "react-redux";

function UserItem({ img, id, name, isFriend }) {
  const location = useLocation();
  const dispatch = useDispatch();
  const stringedId = `${id}`;
  const inviteNewFriend = () => {
    dispatch(inviteFriend(stringedId));
  };

  return (
    <div className={styles.friend__wrapper}>
      <Link
        to={{
          pathname: `/friend/${id}`,
          state: {
            from: location.pathname,
          },
        }}
      >
        <div className={styles.friend__wrapper_info}>
          <img src={img} className={styles.friend__avatar} alt="" />
          <Typography component="h3" variant="titleHeavy">
            {name}
          </Typography>
        </div>
      </Link>
      {!isFriend && <InviteButton onClick={inviteNewFriend} />}
    </div>
  );
}
UserItem.propTypes = {
  img: PropTypes.string,
};
export default UserItem;
