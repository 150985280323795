import logo from "../../components/assets/images/Logo.svg";
import first from "./first.png";
import second from "./second.png";
import third from "./third.png";

import Slick from "react-slick";

import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import "./onboarding.sass";
import { Link } from "react-router-dom";

const Onboarding = (props) => {
  const settings = {
    dots: true,
    infinite: true,
    autoplay: true,
    speed: 500,
    autoplaySpeed: 4000,
    cssEase: "linear",
    slidesToShow: 1,
    slidesToScroll: 1,
    arrows: false,
    pauseOnHover: false, //TODO:
  };
  return (
    <div className="onboarding__wrapper">
      <header className="header">
        <Link to="/">
          <img src={logo} alt="" />
        </Link>
      </header>
      <section className="onboarding__container">
        <div className="onboarding__slider">
          <Slick className="slick-onboarding" {...settings}>
            <div className="onboarding__slide">
              <img src={first} alt="" />
              <h1 className="title">Build a life map with Storyline</h1>
              <p className="text">
                Create different Timelines for relevant categories <br />
                and event in your lifetime
              </p>
            </div>
            <div className="onboarding__slide">
              <img src={second} alt="" />
              <h1 className="title">
                Add photos, videos and media to any event
              </h1>
              <p className="text">
                Add entries to each specific timeline and include photos,
                videos,
                <br /> descriptions, and even attach personal narration.
              </p>
            </div>
            <div className="onboarding__slide">
              <img src={third} alt="" />
              <h1 className="title">Share your impressions with friends</h1>
              <p className="text">
                Share your entries with your friends and family.
              </p>
            </div>
          </Slick>
        </div>
        <button className="submit__button">Get started</button>
      </section>
    </div>
  );
};

export default Onboarding;
