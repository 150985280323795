import React from "react";
import ClassNames from "classnames";
import Base from "../base";
import styles from "./typography.module.sass";

const Typography = React.forwardRef(
  (
    {
      component,
      className,
      children,
      variant = "root",
      style,
      onClick,
      onChange,
      ...props
    },
    ref
  ) => {
    const classes = ClassNames(
      className,
      { [styles.root]: variant === "root" },
      { [styles.title]: variant === "title" },
      { [styles.smallText]: variant === "smallText" },
      { [styles.smallTextHeavy]: variant === "smallTextHeavy" },
      { [styles.titleHeavy]: variant === "titleHeavy" },
      { [styles.pageTitle]: variant === "pageTitle" },
      { [styles.label]: variant === "label" },
      { [styles.titleHeavySecond]: variant === "titleHeavySecond" }
    );

    return (
      <Base
        ref={ref}
        component={component}
        className={classes}
        style={style}
        onClick={onClick}
        onChange={onChange}
        {...props}
      >
        {children}
      </Base>
    );
  }
);

export default Typography;
