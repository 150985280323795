import RadioButton from "../ui/radio-button/RadioButton";
import styles from "./calendar.module.sass";
import React, { useState, forwardRef } from "react";
import { Formik, Form } from "formik";
import RangeCalendar from "../ui/range-calendar/RangeCalendar";
import Typography from "../ui/typography";
import subDays from "date-fns/subDays";
import {} from "react-redux";
import { useEffect } from "react";
import { useDispatch } from "react-redux";
import { getDates } from "../../store/reducers/combine-search";
import useQuery from "../../hooks/useQuery";
import format from "date-fns/format";

const CalendarWithRadio = forwardRef(({ bool, handleCalendar }, ref) => {
  const [id, setId] = useState("1");

  const [dates, setDates] = useState({
    from: null,
    till: subDays(new Date(), 0),
  });
  let payload;
  const query = useQuery();
  const dispatch = useDispatch();
  const name = query.get("name").toString();
  const handleId = ({ target }) => {
    setId(target.id);
  };

  const handleDates = ({ target }) => {
    if (target.id === "1") {
      setDates({
        from: null,
        till: null,
      });
    } else {
      setDates({
        from: new Date(target.getAttribute("date")),
        till: subDays(new Date(), 0),
      });
    }
  };

  const createPayload = () => {
    if (dates.till === null && dates.from === null) {
      payload = { name: name };
    }
    if (dates.till === "" && dates.from !== "") {
      payload = { name: name, date: format(dates.from, "yyyy-MM-dd") };
    }
    if (dates.till !== "" && dates.from !== null) {
      payload = {
        name: name,
        date: {
          from: format(dates.from, "yyyy-MM-dd"),
          till: format(dates.till, "yyyy-MM-dd"),
        },
      };
    }

    return payload;
  };
  const handlePressed = ({ target }) => {
    target && setId("6");
  };
  useEffect(() => {
    createPayload();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dates]);

  const handleWithdrawDates = (start, end) => {
    setDates({
      from: start,
      till: end,
    });
  };
  const filterByDates = (e) => {
    e.preventDefault();
    dispatch(getDates(payload));
  };
  const onSubmitHandler = (e) => {
    console.log(e);
    filterByDates(e);
    handleCalendar();
  };
  return (
    <div ref={ref} className={styles.calendarContainer__calendar}>
      <form
        onSubmit={(e) => onSubmitHandler(e)}
        className={styles.calendarContainer__calendar_radioForm}
        action="#"
      >
        <RadioButton
          onChange={handleId}
          checked={id === "1"}
          id={"1"}
          type={"All time"}
          date={null}
          handleDates={handleDates}
        />
        <RadioButton
          onChange={handleId}
          checked={id === "2"}
          id={"2"}
          type={"Last 7 days"}
          date={subDays(new Date(), 7)}
          handleDates={handleDates}
        />
        <RadioButton
          onChange={handleId}
          checked={id === "3"}
          id={"3"}
          handleDates={handleDates}
          type={"Last 30 days"}
          date={subDays(new Date(), 30)}
        />
        <RadioButton
          onChange={handleId}
          checked={id === "4"}
          handleDates={handleDates}
          id={"4"}
          type={"Last 6 months"}
          date={subDays(new Date(), 182.5)}
        />
        <RadioButton
          onChange={handleId}
          id={"5"}
          checked={id === "5"}
          handleDates={handleDates}
          type={"Last year"}
          date={subDays(new Date(), 365)}
        />
        <RadioButton
          onChange={handleId}
          checked={id === "6"}
          id={"6"}
          type={"Custom"}
          handleDates={handleDates}
          date={null}
        />
        <Typography
          variant={"label"}
          component="button"
          className={styles.filterButton}
        >
          Apply
        </Typography>
      </form>

      <Formik
        initialValues={{
          timeline: "",
          // name: "",
          calendar: {
            startDate: "",
            endDate: "",
          },
          eventTitle: "",
        }}
      >
        <Form>
          <RangeCalendar
            handleCustom={handlePressed}
            dates={dates}
            setDates={handleWithdrawDates}
            fromSearch={bool}
            name="calendar"
          />
        </Form>
      </Formik>
    </div>
  );
});
export default CalendarWithRadio;
