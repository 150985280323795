import { createAction, handleActions } from "redux-actions";
import { all, call, put, take, takeEvery } from "redux-saga/effects";
import { resource } from "../../api";

const namespace = "user";

const GET_USER = `${namespace}/GET_USER`;
const SET_USER = `${namespace}/SET_USER`;
const SUBMIT_ABOUT = `${namespace}/SUBMIT_ABOUT`;
const SUBMIT_PROFILE = `${namespace}/SUBMIT_PROFILE`;
const SUBMIT_ADDITIONAL = `${namespace}/SUBMIT_ADDITIONAL`;
const SUBMIT_PREFERENCES = `${namespace}/SUBMIT_PREFERENCES`;
const SET_PROFILE = `${namespace}/SET_PROFILE`;
const MEMORY_SETTING = `${namespace}/MEMORY_SETTING`;

const ADD_AVATAR = `${namespace}/ADD_AVATAR`;
const REMOVE_AVATAR = `${namespace}/REMOVE_AVATAR`;

export const submitAbout = createAction(SUBMIT_ABOUT);
export const submitProfile = createAction(SUBMIT_PROFILE);
export const submitAdditional = createAction(SUBMIT_ADDITIONAL);
export const submitPreferences = createAction(SUBMIT_PREFERENCES);
export const getUser = createAction(GET_USER);
export const setUser = createAction(SET_USER);
export const setProfile = createAction(SET_PROFILE);
export const updateMemory = createAction(MEMORY_SETTING);

export const addAvatar = createAction(ADD_AVATAR);
export const removeAvatar = createAction(REMOVE_AVATAR);

const initialState = {
  user: null,
  profile: null,
};

export default handleActions(
  {
    [SET_USER]: (state, { payload }) => ({ ...state, user: payload }),
    [SET_PROFILE]: (state, { payload }) => ({ ...state, profile: payload }),
  },
  initialState
);

export const userSelector = (state) => state[namespace].user;
export const profileSelector = (state) => state[namespace].profile;
export const avatarSelector = (state) => {
  const user = userSelector(state);
  return user?.profile_photo_path || null;
};

function* getUserRequest() {
  while (true) {
    yield take(GET_USER);
    try {
      const {
        data: { data },
      } = yield call(resource.get, `/cabinet/profile`);
      const { profile, ...props } = data;

      yield put(setUser(props));

      yield put(setProfile(profile));
    } catch (err) {
      console.log(err);
    }
  }
}

function* submitAboutRequest() {
  while (true) {
    const { payload } = yield take(SUBMIT_ABOUT);
    try {
      const {
        data: { data },
      } = yield call(resource.post, `/cabinet/profile/about`, payload);
      const { profile, ...user } = data;
      yield put(setUser(user));
    } catch (err) {
      console.log(err);
    }
  }
}

function* submitProfileRequest() {
  while (true) {
    const { payload } = yield take(SUBMIT_PROFILE);
    try {
      const {
        data: { data },
      } = yield call(resource.post, `/cabinet/profile/info`, payload);
      const { profile, ...user } = data;
      yield put(setUser(user));
    } catch (err) {
      console.log(err);
    }
  }
}

function* submitAdditionalRequest() {
  while (true) {
    const { payload } = yield take(SUBMIT_ADDITIONAL);
    try {
      const {
        data: { data },
      } = yield call(resource.post, `/cabinet/profile/additional`, payload);
      const { profile } = data;
      yield put(setProfile(profile));
      yield put(setUser(data));
    } catch (err) {
      console.log(err);
    }
  }
}

function* submitPreferencesRequest() {
  while (true) {
    const { payload } = yield take(SUBMIT_PREFERENCES);
    try {
      const {
        data: { data },
      } = yield call(resource.post, `/cabinet/profile/preferences`, payload);
      const { profile } = data;
      yield put(setProfile(profile));
      yield put(setUser(data))
    } catch (err) {
      console.log(err);
    }
  }
}

function* addAvatarRequest() {
  while (true) {
    const { payload: file } = yield take(ADD_AVATAR);
    const formData = new FormData();
    formData.append("image", file);

    try {
      const {
        data: { data },
      } = yield call(resource.post, `/cabinet/profile/avatar`, formData);
      const { profile, ...user } = data;
      yield put(setUser(user));
    } catch (err) {
      console.log(err);
    }
  }
}

function* removeAvatarRequest() {
  while (true) {
    yield take(REMOVE_AVATAR);
    try {
      const {
        data: { data },
      } = yield call(resource.delete, `/cabinet/profile/avatar`);
      const { profile } = data;
      yield put(setProfile(profile));
    } catch (err) {
      console.log(err);
    }
  }
}

function* updateMemorySettings() {
  while (true) {
    const { payload } = yield take(MEMORY_SETTING);
    console.log(payload);
    try {
      const {
        data: { data },
      } = yield call(resource.post, `/cabinet/profile/memorySettings`, payload);
      const { profile, ...user } = data;
      yield put(setUser(user));
      yield put(setProfile(profile));
    } catch (err) {
      console.log(err);
    }
  }
}

export function* userSaga() {
  return yield all([
    submitAboutRequest(),
    //takeEvery(MEMORY_SETTING, updateMemorySettings),
    updateMemorySettings(),
    submitPreferencesRequest(),
    submitAdditionalRequest(),
    submitProfileRequest(),
    getUserRequest(),
    addAvatarRequest(),
    removeAvatarRequest(),
  ]);
}
