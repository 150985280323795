import Typography from "../../../components/ui/typography";
import ClassicButton from "../../../components/ui/buttons/ClassicButtonWithText";
import styles from "./approve.module.sass";
import React from "react";
import classNames from "classnames";
import {
  setReject,
  setApprove,
  getComments,
} from "../../../store/reducers/comments";
import { useDispatch } from "react-redux";
import { useParams } from "react-router";
const ApprovalItem = ({ approve }) => {
  const dispatch = useDispatch();

  const params = useParams();
  const { eventId } = params;

  const handleApprove = () => {
    dispatch(setApprove({ eventId: eventId, commentId: approve?.id }));
    dispatch(getComments(eventId));
  };

  const handleReject = () => {
    dispatch(setReject({ eventId: eventId, commentId: approve?.id }));
  };

  return (
    <div
      className={classNames(styles.approveWrapper, {
        [styles.approveCol]: approve?.media.length > 1,
      })}
    >
      <div
        className={classNames(styles.imagesWrapper, {
          [styles.approveRow]: approve?.media.length > 1,
        })}
      >
        {approve?.media.map((item) => {
          return (
            <div
              key={item.id}
              className={classNames(styles.singleImgWrapper, {
                [styles.multipleImage]: approve?.media.length > 1,
              })}
            >
              <img src={item.cover_img} alt="coverPic" />
            </div>
          );
        })}
      </div>
      <div className={styles.organizer}>
        <div className={styles.contentWrapper}>
          <div className={styles.approveWrapper__upperPart}>
            <div className={styles.imgWrapper}>
              <img src={approve?.profile_photo_path} alt="Profile pic" />
            </div>
            <Typography
              className={styles.userName}
              variant="title"
              component="h3"
            >
              {approve.author.firstName} {approve?.author.lastName}
            </Typography>
          </div>
          <Typography className={styles.userComment} component="p">
            {approve?.body}
          </Typography>
        </div>
        <div className={styles.btnWrapper}>
          <ClassicButton
            onClick={handleReject}
            className={styles.rejBtn}
            variant="gray"
          >
            Reject
          </ClassicButton>
          <ClassicButton onClick={handleApprove}>Approve</ClassicButton>
        </div>
      </div>
    </div>
  );
};

export default ApprovalItem;
