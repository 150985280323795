import classNames from "classnames";
import React, { useEffect } from "react";
import { useHistory, useLocation } from "react-router";
import useQuery from "../../hooks/useQuery";
import { ArrowIcon } from "../ui/icon/Icon";
import { getPage } from "../../store/reducers/activities";
import styles from "./paginator.module.sass";
import { useDispatch } from "react-redux";

const Button = ({ children, className, onClick, value, disabled }) => {
  return (
    <button
      value={value}
      className={classNames(styles.button, className)}
      onClick={onClick}
      disabled={disabled}
    >
      {children}
    </button>
  );
};

const ArrowLeft = ({ onClick, value }) => {
  return (
    <Button className={styles.arrow__wrapper} onClick={onClick} value={value}>
      {/* <div className={classNames(styles.arrow, styles.arrow__left)} /> */}

      <ArrowIcon />
    </Button>
  );
};
const ArrowRight = ({ onClick, value, disabled }) => {
  return (
    <Button
      className={styles.arrow__wrapper}
      onClick={onClick}
      value={value}
      disabled={disabled}
    >
      {/* <div className={classNames(styles.arrow, styles.arrow__right)} /> */}
      <ArrowIcon style={{ transform: "rotate(180deg)" }} />
    </Button>
  );
};

const Paginator = ({
  pageNumber,
  totalPages = 8,
  size = 3,
  rightOffset = 2,
  className,
}) => {
  const query = useQuery();
  const history = useHistory();
  const location = useLocation();
  const { pathname } = location;
  const dispatch = useDispatch();
  const currentPage = query.get("pageNumber");

  React.useEffect(() => {
    window.scrollTo(0, 0);
  }, [location]);

  useEffect(() => {
    dispatch(getPage(currentPage));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentPage]);

  const clickHandler = (e) => {
    query.set("pageNumber", e.target.value);
    query.set("page", e.target.value);
    history.push(`${pathname}?${query.toString()}`);
  };

  const arrowClickHandler = (e) => {
    const { value } = e.currentTarget;
    const page = parseInt(pageNumber) + parseInt(value);
    query.set("pageNumber", page);
    query.set("page", page);
    history.push(`${pathname}?${query.toString()}`);
  };

  const show = (value) => {
    const start = pageNumber <= value ? 1 : pageNumber - value;
    const limit =
      pageNumber + value >= totalPages ? totalPages : pageNumber + rightOffset;

    const paginationArr = [];
    for (let i = start; i <= limit; i++) {
      const page = (
        <Button
          key={i}
          className={classNames({ [styles.button__active]: i === +pageNumber })}
          onClick={clickHandler}
          value={i}
        >
          {i}
        </Button>
      );

      paginationArr.push(page);
    }
    return paginationArr;
  };

  return (
    <div className={classNames(styles.container, className)}>
      <div className={styles.wrapper}>
        {pageNumber > 1 && <ArrowLeft value={-1} onClick={arrowClickHandler} />}
        {show(size)}
        {+pageNumber + rightOffset + 1 < totalPages ? (
          <>
            <div className={styles.button}>...</div>
            <Button
              className={styles.button}
              onClick={clickHandler}
              value={totalPages}
            >
              {totalPages}
            </Button>
          </>
        ) : null}
        {pageNumber !== totalPages && totalPages > 0 && (
          <ArrowRight disabled={false} value={1} onClick={arrowClickHandler} />
        )}
      </div>
    </div>
  );
};

export default Paginator;

// pageNumber = 1,
//   totalPages = 0,
//   size = 3,
//   rightOffset = 2,
//   className,
