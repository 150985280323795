import React, { useRef } from "react";
import Typography from "../../../components/ui/typography";
import styles from "./limit.module.sass";
import ClassicButton from "../../../components/ui/buttons/ClassicButtonWithText";
import Preloader from "./Preloader.svg";
import { useGlobalCloseSmiles } from "../../../hooks/useCloseMenu";
const ImageLimitation = ({ handleCloseWarning, openWarning }) => {
  const ref = useRef({});
  useGlobalCloseSmiles(ref, handleCloseWarning, openWarning);
  return (
    <div className={styles.warningOverlay}>
      <div ref={ref} className={styles.warningOverlay__modal}>
        <button
          onClick={() => {
            handleCloseWarning();
          }}
          className={styles.closeBtn}
        >
          <svg
            width="10"
            height="10"
            viewBox="0 0 10 10"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M9.67711 0.322887C9.2466 -0.107629 8.54859 -0.107629 8.11808 0.322887L5 3.44096L1.88192 0.322887C1.45141 -0.107629 0.753404 -0.107629 0.322887 0.322887C-0.107629 0.753404 -0.107629 1.45141 0.322887 1.88192L3.44096 5L0.322887 8.11807C-0.107629 8.54859 -0.107629 9.2466 0.322887 9.67711C0.753404 10.1076 1.45141 10.1076 1.88192 9.67711L5 6.55904L8.11807 9.67711C8.54859 10.1076 9.2466 10.1076 9.67711 9.67711C10.1076 9.2466 10.1076 8.54859 9.67711 8.11807L6.55904 5L9.67711 1.88192C10.1076 1.45141 10.1076 0.753404 9.67711 0.322887Z"
              fill="#1D2A64"
            />
          </svg>
        </button>
        <div className={styles.imageWrapper}>
          <img src={Preloader} alt="" />
        </div>
        <Typography
          className={styles.warningTitle}
          variant="title"
          component="h2"
        >
          Unable to load media files
        </Typography>
        <Typography className={styles.warningDescription} component="span">
          You’ve reached the limit of 500 MB of total storage for this entry, or
          tried to upload more than 15 images. Please, crop the image or upload
          less images.
        </Typography>
        <ClassicButton
          onClick={handleCloseWarning}
          className={styles.modalCLSBTN}
        >
          Close
        </ClassicButton>
      </div>
    </div>
  );
};
export default ImageLimitation;
