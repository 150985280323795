// import { Form, Formik, useField } from "formik";

import CustomFields from '../../../../../components/ui/custom-fields'
import styles from './categories.module.sass'
import Typography from '../../../../../components/ui/typography'
import React, { useEffect, useRef, useState } from 'react'
import burger from '../../components/images/burger.svg'
import classNames from 'classnames'
import closeIcon from './closeIcon.svg'
import { Switcher } from '../../../../../components/ui/formik'
import img from './Image.png'
import {
  disableCategoriesSwitchers,
  openCategoryIdSelector,
  setOpenCategoryId,
} from '../../../../../store/reducers/settings'
import ColorRadioButton from './RadioButton'
import { useDispatch, useSelector } from 'react-redux'
import colors from './colorsArr'
import { useGlobalCloseSmiles } from '../../../../../hooks/useCloseMenu.jsx'
import CheckboxControl from './CheckboxControl'
import { userSelector } from '../../../../../store/reducers/user'
const Category = ({
  name,
  category,
  categories,
  categoryId,
  src,
  color,
  switcherState,
}) => {
  useEffect(() => {
    dispatch(setOpenCategoryId(false))
  }, [])

  const openCategoryId = useSelector(openCategoryIdSelector)
  const [openCategory, setOpenCategory] = useState(
    openCategoryId == categoryId ? true : false,
  )
  const dispatch = useDispatch()
  const ref = useRef({})
  const handleClose = () => {
    setOpenCategory(false)
    //dispatch(setOpenCategoryId(false))
  }

  useGlobalCloseSmiles(ref, handleClose, openCategory)
  const switcherHandler = (e) => {
    setOpenCategory(false)
    dispatch(setOpenCategoryId(false))

    dispatch(disableCategoriesSwitchers(e.target.id))
  }
  const categoryOpener = () => {
    setOpenCategory(!openCategory)
    dispatch(setOpenCategoryId(categoryId))
  }
  return (
    <div className={styles.category}>
      <img className={styles.burger} src={burger} alt="icon" />
      <div ref={ref} className={styles.dropdown__wrapper}>
        <div
          className={styles.category__container}
          onClick={(e) => {
            categoryOpener(e)
          }}
        >
          {src.length > 0 ? (
            <img
              className={styles.category__image}
              src={src ? src : img}
              alt="default-category"
            />
          ) : (
            <div
              style={{ backgroundColor: color }}
              className={classNames(
                styles.category__image,
                styles.borderRadius,
              )}
            ></div>
          )}

          <Typography component="h3" variant="label">
            {name ? name : 'loading...'}
          </Typography>
          <Switcher
            id={categoryId}
            switcherState={switcherState}
            className={styles.switcher}
            onChange={switcherHandler}
          />
          <img
            className={classNames(styles.close_icon, {
              [styles.close_icon_transform]: openCategory,
            })}
            src={closeIcon}
            alt="close-icon"
          />
        </div>
        <div
          className={classNames(styles.dropdown__container, {
            [styles.dropdown__container_open]: openCategory,
          })}
        >
          <div className={styles.down}>
            <h4 className={styles.down__title}>General settings</h4>
            <h5 className={styles.down__subtitle}>
              This timeline include such fields as:
            </h5>
            <div className={styles.includeFields__wrapper}>
              <label className={styles.includeFields__container}>
                <CheckboxControl
                  // category={category}
                  value={category?.has_location}
                  name={'has_location'}
                  className={styles.includeFields__checkbox}
                  timelineId={category.id}
                />
                <Typography component="span">Location field</Typography>
              </label>
              <label className={styles.includeFields__container}>
                <CheckboxControl
                  name="has_image"
                  value={category?.has_image}
                  timelineId={category.id}
                  className={styles.includeFields__checkbox}
                />
                <Typography component="span">Add image</Typography>
              </label>
              <label className={styles.includeFields__container}>
                <CheckboxControl
                  name={'has_media'}
                  className={styles.includeFields__checkbox}
                  value={category?.has_media}
                  timelineId={category.id}
                />
                <Typography component="span">Add media</Typography>
              </label>
            </div>
            <h5 className={styles.down__subtitle}>Color Timeline</h5>
            <div className={styles.color__wrapper}>
              {colors.map((color, idx) => {
                return (
                  <ColorRadioButton
                    categories={categories?.default}
                    className={styles.color__item}
                    idx={idx}
                    htmlFore={categoryId + color}
                    id={categoryId + color}
                    name="color"
                    eventId={categoryId}
                    key={color}
                    value={`${color}`}
                    isSelected={color === category.color}
                  />
                )
              })}
            </div>
            {/* <CustomFields /> */}
            <h4 className={styles.down__title}>Comments settings</h4>
            <div className={styles.flex}>
              <label className={styles.includeFields__container}>
                <CheckboxControl
                  name="allow_comments"
                  className={styles.includeFields__checkbox}
                  value={category.allow_comments}
                  timelineId={category.id}
                />

                <Typography component="span">Allow comments</Typography>
              </label>
              <label className={styles.includeFields__container}>
                <CheckboxControl
                  name={'moderate_comments'}
                  className={styles.includeFields__checkbox}
                  value={category?.moderate_comments}
                  timelineId={category.id}
                />
                <Typography component="span">Auto-Approve Comments</Typography>
              </label>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
export default Category
