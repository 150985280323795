import { useHistory } from "react-router";
import styles from "./index.module.sass";

export default function BackButton({ children }) {
  const history = useHistory();
  return (
    <button
      onClick={() => {
        history.goBack();
      }}
      className={styles.button_back}
      type="button"
    >
      <div className={styles.arrow} />
      {children}
    </button>
  );
}
