import { useSelector } from "react-redux";

export default function usePathname(condition = null) {
  const pathname = useSelector(
    ({
      router: {
        location: { pathname },
      },
    }) => pathname
  );
  const result =
    pathname === "/onboarding" ||
    condition?.some?.((e) => pathname.includes(e));
  return result;
}
