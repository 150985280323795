import { useLocation } from "react-router";
import Typography from "../../../components/ui/typography";
import userProfileBG from "./userInterfaceBG.png";
import styles from "./userProfile.module.sass";
import classNames from "classnames";
import Linkify from "linkify-react";

const UserHeader = ({ src, userName, about, isFriend, removeFriend }) => {
  const location = useLocation();
  const options = {
    /*...*/
  };
  const content = about;

  return (
    <div className={styles.headerWrapper}>
      <div
        style={{ backgroundImage: `url(${userProfileBG})` }}
        className={styles.graphicWrapper}
      >
        <img className={styles.userAvatar} src={src} alt="User avatar" />
      </div>
      <div className={styles.header__title}>
        <Typography
          component="h1"
          className={styles.userName}
          variant="pageTitle"
        >
          {userName}
        </Typography>
        {isFriend && location.pathname.indexOf("home") !== 1 && (
          <DeleteButton onClick={removeFriend}>Unfriend</DeleteButton>
        )}
      </div>

      {about && (
        <Linkify tagName="p" options={options} className={styles.userAbout}>
          {content}
        </Linkify>
      )}
    </div>
  );
};

export const DeleteButton = ({ onClick, className, children }) => {
  return (
    <>
      <button
        className={classNames(styles.button__remove, className)}
        type="button"
        onClick={onClick}
      >
        {children}
      </button>
    </>
  );
};
export default UserHeader;
