import classNames from "classnames";
import PropTypes from "prop-types";
import React from "react";

import styles from "./tabs.module.sass";

export const TabContainer = ({
  children,
  handleChange,
  initialState,
  className,
}) => {
  const [active, setActive] = React.useState(initialState);
  const childrenArr = React.Children.toArray(children);

  const onChange = (value) => {
    setActive(value);
    handleChange && handleChange(value);
  };

  return (
    <div className={classNames(styles.tab__container, className)}>
      {childrenArr.map((e) =>
        React.cloneElement(e, { activeTab: active, handleChange: onChange })
      )}
    </div>
  );
};

export const Tab = ({
  activeTab,
  value,
  handleChange,
  children,
  className,
}) => {
  const handleClick = () => {
    handleChange && handleChange(value || "");
  };
  return (
    <div
      className={classNames(styles.tab, className, {
        [styles.tab_active]: activeTab === value,
      })}
      onClick={handleClick}
    >
      {children}
    </div>
  );
};

Tab.propTypes = {
  activeTab: PropTypes.string,
  value: PropTypes.string,
  handleChange: PropTypes.func,
  children: PropTypes.node,
  className: PropTypes.string,
};
Tab.defaultProps = {
  activeTab: "",
  value: "",
  handleChange: () => {},
  children: null,
  className: "",
};
