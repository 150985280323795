import React, { useEffect, useRef, useState } from "react";
import { useSelector } from "react-redux";
import Typography from "../ui/typography";
import People from "./People";
import styles from "./search-dropdown.module.sass";
import { Link } from "react-router-dom";
import { combineSearchSelector } from "../../store/reducers/combine-search";
import Entry from "./Entry";
import format from "date-fns/format";
import img from "./noImg.jpeg";
import useQuery from "../../hooks/useQuery";
import { useGlobalCloseSmiles } from "../../hooks/useCloseMenu";

export const SearchDropDownWrapper = () => {
  const combineData = useSelector(combineSearchSelector);
  const { combine } = combineData;
  const [isOpen, setIsOpen] = useState(false);
  const ref = useRef(null);

  useEffect(() => {
    combine.events && setIsOpen(true);
  }, [combine]);
  const handleClose = () => {
    setIsOpen(false);
  };
  useGlobalCloseSmiles(ref, handleClose, isOpen);
  const query = useQuery();

  return (
    <>
      {isOpen && (
        <div ref={ref} className={styles.dropDownWrapper}>
          <Typography component="h3" variant="label">
            Entries
          </Typography>
          <div className={styles.dropdownWrapper__entries}>
            {combine?.events.data.length > 0 ? (
              combine.events.data.map((obj) => {
                return (
                  <Entry
                    img={obj.cover_img ? obj.cover_img : img}
                    key={obj.id}
                    friendId={obj.owner_id}
                    entryId={obj.id}
                    date={format(Date.parse(obj.start_date), "PP")}
                    description={obj.description}
                    eventName={obj.title}
                    className={styles.entry}
                  />
                );
              })
            ) : (
              <Typography component="span">No entries found</Typography>
            )}
          </div>

          <Typography component="h3" variant="label">
            People
          </Typography>
          <div className={styles.people}>
            {combine?.users.data.length > 0 ? (
              combine?.users.data.map((user) => {
                return (
                  <People
                    isFriend={user.isFriend}
                    friendId={user.id}
                    name={user.firstName}
                    img={user.profile_photo_path}
                    key={user.id}
                  ></People>
                );
              })
            ) : (
              <Typography component="span">No users found</Typography>
            )}
          </div>
          <Link
            to={{
              pathname: "/timelines/search",
              search: `?param=entries&name=${query.get("name")}`,
            }}
          >
            See All Results
          </Link>
        </div>
      )}
    </>
  );
};
