import { push } from 'connected-react-router'
import { createAction, handleActions } from 'redux-actions'
import { all, call, put, take } from 'redux-saga/effects'
import { resource } from '../../api'
import { dangerMessage } from '../../components/ui/alert/alertReducer'
import { setCategories } from './timelines'
import { setUser } from './user'
const namespace = 'auth'

const initialState = {
  auth: false,
  token: null,
}

const SET_AUTH = `${namespace}/SET_AUTH`
const REGISTER = `${namespace}/REGISTER`
const LOGIN = `${namespace}/LOGIN`
const LOGIN_GOOGLE = `${namespace}/LOGIN_GOOGLE`
const SET_TOKEN = `${namespace}/SET_TOKEN`
const LOGOUT = `${namespace}/LOGOUT`
const RESET_PASSWORD_EMAIL = `${namespace}/RESET_PASSWORD`
const SET_PASSWORD = `${namespace}/SET_PASSWORD`

export const setAuth = createAction(SET_AUTH)
export const register = createAction(REGISTER)
export const login = createAction(LOGIN)
export const loginGoogle = createAction(LOGIN_GOOGLE)
export const setToken = createAction(SET_TOKEN)
export const logoutAction = createAction(LOGOUT)
export const resetPasswordEmail = createAction(RESET_PASSWORD_EMAIL)
export const setPassword = createAction(SET_PASSWORD)

export default handleActions(
  {
    [SET_AUTH]: (state, { payload }) => ({ ...state, auth: payload }),
    [SET_TOKEN]: (state, { payload }) => ({ ...state, token: payload }),
  },
  initialState,
)

export const authSelector = (state) => state[namespace].auth

function* newPasswordRequest() {
  while (true) {
    const { payload } = yield take(SET_PASSWORD)
    try {
      const res = yield call(
        resource.post,
        `/password/reset/${payload.token}`,
        payload,
      )
      if (res.status == 200) {
        yield put(push('/'))
      } else {
        payload.setSubmitting()
      }
    } catch (err) {
      const message = err?.response?.data?.message
      if (message) {
        yield put(dangerMessage(message))
        payload.setSubmitting()
      }

      console.log(err)
    }
  }
}
function* resetPasswordEmailRequest() {
  while (true) {
    const { payload } = yield take(RESET_PASSWORD_EMAIL)
    try {
      const res = yield call(resource.post, `/password/email`, payload)

      if (res.status == 200) {
        yield put(push('/'))
        yield put(dangerMessage(res.data.data))
      } else {
        payload.setSubmitting()
      }
    } catch (err) {
      const message = err?.response?.data?.message
      if (message) {
        yield put(dangerMessage(message))
        payload.setSubmitting()
      }

      console.log(err)
    }
  }
}
function* registerRequest() {
  while (true) {
    const { payload } = yield take(REGISTER)
    try {
      const {
        data: { data },
      } = yield call(resource.post, `/auth/register`, payload)
      if (data.token) {
        localStorage.setItem('token', data.token)
        yield put(setToken(data.token))
        yield put(setAuth(!!data.token))
        yield put(push('/'))
      }
    } catch (err) {
      const message = err?.response?.data?.message
      const errorMesssage = Object.keys(err?.response?.data?.errors)[0]
        ? err?.response?.data?.errors[
            Object.keys(err?.response?.data?.errors)[0]
          ]
        : 0

      if (errorMesssage) {
        yield put(dangerMessage(errorMesssage))
      }

      console.log(err)
    }
  }
}
function* loginRequest() {
  while (true) {
    const { payload } = yield take(LOGIN)
    try {
      const {
        data: { data },
      } = yield call(resource.post, `/auth/login`, payload)
      console.log(data)
      if (data.token) {
        localStorage.setItem('token', data.token)
        yield put(setToken(data.token))
        yield put(setAuth(!!data.token))
        payload.setSubmitting(false)
        yield put(push('/'))
      }
    } catch (err) {
      const message = err?.response?.data?.message
      if (message) {
        yield put(dangerMessage(message))
      }
      payload.setSubmitting(false)
      console.log(err)
    }
  }
}
function* loginGoogleRequest() {
  while (true) {
    const { payload } = yield take(LOGIN_GOOGLE)
    try {
      const {
        data: { data },
      } = yield call(resource.post, `/auth/login-google`, payload, {
        headers: {
          'Access-Control-Allow-Origin': '*',
        },
      })
      console.log(data)
      if (data.token) {
        localStorage.setItem('token', data.token)
        yield put(setToken(data.token))
        yield put(setAuth(!!data.token))
        yield put(push('/'))
      }
    } catch (err) {
      const message = err?.response?.data?.message
      if (message) {
        yield put(dangerMessage(message))
      }
      console.log(err)
    }
  }
}
function* init() {
  const token = localStorage.getItem('token')
  if (token) {
    yield put(setToken(token))
    yield put(setAuth(!!token))
    // yield put(getUser());
  }
}
export function* logout() {
  while (true) {
    yield take(LOGOUT)
    yield put(setToken(null))
    yield put(setAuth(false))
    yield put(setUser(null))
    yield put(setCategories(null))
    localStorage.removeItem('token')
  }
}
export function* authSaga() {
  yield all([
    init(),
    registerRequest(),
    newPasswordRequest(),
    resetPasswordEmailRequest(),
    loginRequest(),
    loginGoogleRequest(),
    logout(),
  ])
}
