//DATE-PIKER variant
import classNames from 'classnames'
import React, { useEffect } from 'react'

import styles from './general.module.sass'

import { Field, Form, Formik } from 'formik'
import * as Yup from 'yup'
import gender from './icons/gender.svg'
import birthday from './icons/birthday.svg'
import bornIn from './icons/bornIn.svg'
import liveIn from './icons/liveIn.svg'
import studiedAt from './icons/studiedAt.svg'
import worksIn from './icons/worksIn.svg'
import marriedTo from './icons/marriedTo.svg'
import children from './icons/children.svg'
import fullName from './icons/fullName.svg'
import email from './icons/email.svg'
import country from './icons/country.svg'
import phone from './icons/phone.svg'
import { useDispatch, useSelector } from 'react-redux'
import Typography from '../../../../../components/ui/typography'
import {
  getUser,
  profileSelector,
  submitAbout,
  submitAdditional,
  submitPreferences,
  submitProfile,
  userSelector,
} from '../../../../../store/reducers/user'
import {
  CheckboxControl,
  InputControl,
  Textarea,
} from '../../../../../components/ui/formik'
import CoverImage from './cover-image/CoverImage'
import SelectControl from '../../../../../components/ui/formik/select/SelectControl'
import DateFormatControl from '../../../../../components/ui/formik/date-format'

const General = () => {
  const dispatch = useDispatch()
  const user = useSelector(userSelector)
  const profile = useSelector(profileSelector)

  useEffect(() => {
    if (!user) {
      dispatch(getUser())
    }
  }, [user, dispatch])
  if (!user || !profile) {
    return null
  }
  const aboutSubmit = (values) => {
    dispatch(submitAbout(values))
  }
  const profileSubmit = (values) => {
    dispatch(submitProfile(values))
  }
  const additionalSubmit = (values) => {
    dispatch(submitAdditional(values))
  }
  const preferencesSubmit = (values) => {
    dispatch(submitPreferences(values))
  }
  const handleCancel = (setEdit) => {
    setEdit(false)
  }

  return (
    <div className={styles.wrapper}>
      <Progress progress={user.fulfil_percentage} />
      <div className={styles.separator} />
      <CoverImage />
      <div className={styles.separator} />
      <ProfileForm>
        <ProfileFormStep
          initialValues={{
            firstName: user.firstName || '',
            lastName: user.lastName || '',
            email: user.email || '',
            country: user.country || '',
            city: user.city || '',
            phone: user.phone || '',
          }}
          onSubmit={profileSubmit}
          user={user}
          profile={profile}
          validation={Yup.object().shape({
             email: Yup.string().email('Please enter a valid email').required(),
             phone: Yup.string().matches(/^\d+$/,"only numbers").min(8)
          })}
        >
          {({ edit, setEdit, values, resetForm }) => (
            <>
              <div className={styles.edit__wrapper}>
                <Typography
                  component="h4"
                  variant="label"
                  className={styles.label}
                >
                  Account information: <b>+20%</b>
                </Typography>
                <button
                  type="button"
                  onClick={() => {
                    setEdit(!edit)
                  }}
                >
                  <svg
                    width="16"
                    height="16"
                    viewBox="0 0 16 16"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      fillRule="evenodd"
                      clipRule="evenodd"
                      d="M11 1C11.5288 1 11.9869 1.20949 12.3544 1.57991L14.4217 3.64718C14.7924 4.01786 15 4.47274 15 5C15 5.52385 14.794 6.06728 14.4217 6.43959L6.89021 13.9675C6.36661 14.5715 5.62438 14.9426 4.7564 15.0016H2H1V14.0016L1.00325 11.1647C1.06698 10.3758 1.43373 9.64229 1.98196 9.15991L9.56093 1.58101C9.93285 1.20718 10.4754 1 11 1ZM3.35157 10.6161C3.14601 10.7979 3.01885 11.0522 3 11.2452V13.0025L4.68578 13.004C4.95369 12.9851 5.20307 12.8604 5.42749 12.6054L9.80933 8.22354L7.77751 6.19172L3.35157 10.6161ZM9.19197 4.77776L11.2235 6.80933L12.9895 5.04339L10.9582 3.01212L9.19197 4.77776Z"
                      fill="#1D2A64"
                    />
                  </svg>
                </button>
              </div>
              <AccountInformation
                edit={edit}
                values={values}
                cancel={() => {
                  handleCancel(setEdit)
                }}
              />

              <div className={styles.separator} />
            </>
          )}
        </ProfileFormStep>
        <ProfileFormStep
          initialValues={{
            about: user.about || '',
          }}
          onSubmit={aboutSubmit}
          user={user}
          profile={profile}
          validation={Yup.object().shape({
              about: Yup.string().required("This field cannot be empty"),
            })}
          
        >
          {({ edit, setEdit }) => (
            <>
              <div className={styles.edit__wrapper}>
                <Typography
                  component="h4"
                  variant="label"
                  className={styles.label}
                >
                  About me: <b>+20%</b>
                </Typography>
                <button
                  type="button"
                  onClick={() => {
                    setEdit(!edit)
                  }}
                >
                  <svg
                    width="16"
                    height="16"
                    viewBox="0 0 16 16"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      fillRule="evenodd"
                      clipRule="evenodd"
                      d="M11 1C11.5288 1 11.9869 1.20949 12.3544 1.57991L14.4217 3.64718C14.7924 4.01786 15 4.47274 15 5C15 5.52385 14.794 6.06728 14.4217 6.43959L6.89021 13.9675C6.36661 14.5715 5.62438 14.9426 4.7564 15.0016H2H1V14.0016L1.00325 11.1647C1.06698 10.3758 1.43373 9.64229 1.98196 9.15991L9.56093 1.58101C9.93285 1.20718 10.4754 1 11 1ZM3.35157 10.6161C3.14601 10.7979 3.01885 11.0522 3 11.2452V13.0025L4.68578 13.004C4.95369 12.9851 5.20307 12.8604 5.42749 12.6054L9.80933 8.22354L7.77751 6.19172L3.35157 10.6161ZM9.19197 4.77776L11.2235 6.80933L12.9895 5.04339L10.9582 3.01212L9.19197 4.77776Z"
                      fill="#1D2A64"
                    />
                  </svg>
                </button>
              </div>

              <section className={styles.form__section}>
                <Form>
                  <div className={styles.textarea__container}>
                    <Textarea
                      name="about"
                      disabled={!edit}
                      label="Write a brief description about yourself."
                      className={styles.textarea}
                    ></Textarea>
                  </div>

                  {edit && (
                    <div className={styles.buttons}>
                      <button
                        type="button"
                        onClick={() => {
                          handleCancel(setEdit)
                        }}
                      >
                        Cancel
                      </button>
                      <button type="submit">Save changes</button>
                    </div>
                  )}
                </Form>
              </section>
              <div className={styles.separator} />
            </>
          )}
        </ProfileFormStep>
        <ProfileFormStep
          initialValues={{
            gender: profile.gender || '',
            birthday: profile.info.birthday || '',
            bornIn: profile.info.bornIn || '',
            liveIn: profile.info.liveIn || '',
            liveInFrom: profile.info.liveInFrom || '',
            studiedAt: profile.info.studiedAt || '',
            studiedAtFrom: profile.info.studiedAtFrom || '',
            studiedAtTo: profile.info.studiedAtTo || '',
            worksIn: profile.info.worksIn || '',
            worksInFrom: profile.info.worksInFrom || '',
            worksInTo: profile.info.worksInTo || '',
            marriedTo: profile.info.marriedTo || '',
            marriedToFrom: profile.info.marriedToFrom || '',
            // children: profile.children || "",
          }}
          onSubmit={additionalSubmit}
          user={user}
          profile={profile}
          validation={Yup.object().shape({
            birthday: Yup.date().nullable()
              .max(new Date(), 'Unavailable date')
              .min(new Date('02-01-1900'.replace(/-/g, "/")), 'Unavailable date'),
            liveInFrom: Yup.date().nullable()
              .max(new Date(), 'Unavailable date')
              .min(new Date('02-01-1900'.replace(/-/g, "/")), 'Unavailable date'),
            studiedAtFrom: Yup.date().nullable()
              .max(new Date(), 'Unavailable date')
              .min(new Date('02-01-1900'.replace(/-/g, "/")), 'Unavailable date'),
            studiedAtTo: Yup.date().nullable()
              .max(new Date(), 'Unavailable date')
              .min(new Date('02-01-1900'.replace(/-/g, "/")), 'Unavailable date'),
            worksInFrom: Yup.date().nullable()
              .max(new Date(), 'Unavailable date')
              .min(new Date('02-01-1900'.replace(/-/g, "/")), 'Unavailable date'),
            worksInTo: Yup.date().nullable()
              .max(new Date(), 'Unavailable date')
              .min(new Date('02-01-1900'.replace(/-/g, "/")), 'Unavailable date'),
            marriedToFrom: Yup.date().nullable()
              .max(new Date(), 'Unavailable date')
              .min(new Date('02-01-1900'.replace(/-/g, "/")), 'Unavailable date'),
          })}
        >
          {({ edit, setEdit, values }) => {
            return (
              <>
                <div className={styles.edit__wrapper}>
                  <Typography
                    component="h4"
                    variant="label"
                    className={styles.label}
                  >
                    Additional information: <b>+20%</b>
                  </Typography>
                  <button
                    type="button"
                    onClick={() => {
                      setEdit(!edit)
                    }}
                  >
                    <svg
                      width="16"
                      height="16"
                      viewBox="0 0 16 16"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        fillRule="evenodd"
                        clipRule="evenodd"
                        d="M11 1C11.5288 1 11.9869 1.20949 12.3544 1.57991L14.4217 3.64718C14.7924 4.01786 15 4.47274 15 5C15 5.52385 14.794 6.06728 14.4217 6.43959L6.89021 13.9675C6.36661 14.5715 5.62438 14.9426 4.7564 15.0016H2H1V14.0016L1.00325 11.1647C1.06698 10.3758 1.43373 9.64229 1.98196 9.15991L9.56093 1.58101C9.93285 1.20718 10.4754 1 11 1ZM3.35157 10.6161C3.14601 10.7979 3.01885 11.0522 3 11.2452V13.0025L4.68578 13.004C4.95369 12.9851 5.20307 12.8604 5.42749 12.6054L9.80933 8.22354L7.77751 6.19172L3.35157 10.6161ZM9.19197 4.77776L11.2235 6.80933L12.9895 5.04339L10.9582 3.01212L9.19197 4.77776Z"
                        fill="#1D2A64"
                      />
                    </svg>
                  </button>
                </div>

                <section className={styles.form__section}>
                  <AdditionalInformation
                    values={values}
                    edit={edit}
                    setEdit={setEdit}
                    profile={profile}
                  />
                </section>
                <div className={styles.separator} />
              </>
            )
          }}
        </ProfileFormStep>
        <ProfileFormStep
          initialValues={{
            showFriends: profile.showFriends || false,
            showBirthday: profile.showBirthday || false,
            showBorn: profile.showBorn || false,
            showLive: profile.showLive || false,
            showStudied: profile.showStudied || false,
            showWorks: profile.showWorks || false,
            showMarried: profile.showMarried || false,
            showChildren: profile.showChildren || false,
          }}
          onSubmit={preferencesSubmit}
          user={user}
          profile={profile}
        >
          {({ edit, setEdit }) => (
            <>
              <div
                className={classNames(
                  styles.edit__wrapper,
                  styles.edit__wrapper_preferences,
                )}
              >
                <div>
                  <Typography
                    component="h4"
                    variant="label"
                    className={classNames(
                      styles.label,
                      styles.label__description_title,
                    )}
                  >
                    Preferences:<b>+20%</b>
                  </Typography>
                  <Typography
                    component="p"
                    variant="smallText"
                    className={styles.label__description}
                  >
                    Customize the look of your profile page
                  </Typography>
                </div>

                <button
                  type="button"
                  onClick={() => {
                    setEdit(!edit)
                  }}
                >
                  <svg
                    width="16"
                    height="16"
                    viewBox="0 0 16 16"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      fillRule="evenodd"
                      clipRule="evenodd"
                      d="M11 1C11.5288 1 11.9869 1.20949 12.3544 1.57991L14.4217 3.64718C14.7924 4.01786 15 4.47274 15 5C15 5.52385 14.794 6.06728 14.4217 6.43959L6.89021 13.9675C6.36661 14.5715 5.62438 14.9426 4.7564 15.0016H2H1V14.0016L1.00325 11.1647C1.06698 10.3758 1.43373 9.64229 1.98196 9.15991L9.56093 1.58101C9.93285 1.20718 10.4754 1 11 1ZM3.35157 10.6161C3.14601 10.7979 3.01885 11.0522 3 11.2452V13.0025L4.68578 13.004C4.95369 12.9851 5.20307 12.8604 5.42749 12.6054L9.80933 8.22354L7.77751 6.19172L3.35157 10.6161ZM9.19197 4.77776L11.2235 6.80933L12.9895 5.04339L10.9582 3.01212L9.19197 4.77776Z"
                      fill="#1D2A64"
                    />
                  </svg>
                </button>
              </div>

              <section className={styles.form__section}>
                <Form>
                  <label className={styles.preference__container}>
                    <CheckboxControl
                      name="showFriends"
                      className={styles.preference__checkbox}
                      disabled={!edit}
                    />
                    <Typography component="span">
                      Show my friends list
                    </Typography>
                  </label>
                  <label className={styles.preference__container}>
                    <CheckboxControl
                      name="showBirthday"
                      className={styles.preference__checkbox}
                      disabled={!edit}
                    />
                    <Typography component="span">
                      Show my Date of Birth
                    </Typography>
                  </label>
                  <label className={styles.preference__container}>
                    <CheckboxControl
                      name="showBorn"
                      className={styles.preference__checkbox}
                      disabled={!edit}
                    />
                    <Typography component="span">
                      Show where I was born
                    </Typography>
                  </label>
                  <label className={styles.preference__container}>
                    <CheckboxControl
                      name="showLive"
                      className={styles.preference__checkbox}
                      disabled={!edit}
                    />
                    <Typography component="span">Show where I live</Typography>
                  </label>
                  <label className={styles.preference__container}>
                    <CheckboxControl
                      name="showStudied"
                      className={styles.preference__checkbox}
                      disabled={!edit}
                    />
                    <Typography component="span">
                      Show where I studied
                    </Typography>
                  </label>
                  <label className={styles.preference__container}>
                    <CheckboxControl
                      name="showWorks"
                      className={styles.preference__checkbox}
                      disabled={!edit}
                    />
                    <Typography component="span">Show where I work</Typography>
                  </label>
                  <label className={styles.preference__container}>
                    <CheckboxControl
                      name="showMarried"
                      className={styles.preference__checkbox}
                      disabled={!edit}
                    />
                    <Typography component="span">
                      Show married status
                    </Typography>
                  </label>
                  <label className={styles.preference__container}>
                    <CheckboxControl
                      name="showChildren"
                      className={styles.preference__checkbox}
                      disabled={!edit}
                    />
                    <Typography component="span">Show children</Typography>
                  </label>

                  {edit && (
                    <div className={styles.buttons}>
                      <button
                        type="button"
                        onClick={() => {
                          setEdit(false)
                        }}
                      >
                        Cancel
                      </button>
                      <button type="submit">Save changes</button>
                    </div>
                  )}
                </Form>
              </section>
            </>
          )}
        </ProfileFormStep>
      </ProfileForm>
    </div>
  )
}

export default General

const Progress = ({ progress }) => {
  return (
    <div className={styles.progress}>
      <div className={styles.progress__header}>
        <h2>General account settings</h2>
        <span>{progress}%</span>
      </div>
      <p className={styles.progress__text}>
        Fill in your information to verify your profile
      </p>
      <div className={styles.bar__wrapper}>
        <div className={styles.bar} style={{ width: progress + '%' }} />
      </div>
    </div>
  )
}

const ProfileFormStep = ({
  children,
  initialValues,
  onSubmit,
  values,
  resetForm,
  user,
  profile,
}) => {
  const [edit, setEdit] = React.useState(false)
  useEffect(() => {
    setEdit(false)
  }, [user, profile])

  useEffect(() => {
    if (!edit) {
      resetForm({ values: initialValues })
    }
  }, [edit, initialValues, resetForm])
  return children({ edit, setEdit, values, resetForm })
}
const ProfileForm = (props) => {
  const form = React.Children.toArray(props.children)

  console.log(777,form);
  return (
    form?.length > 0 &&
    form.map((e) => (
      <Formik
        initialValues={{ ...e.props.initialValues }}
        onSubmit={e.props.onSubmit}
        key={e.key}
        validationSchema={e.props.validation?? null}
      >
        {({ values, resetForm }) =>
          React.cloneElement(e, { values, resetForm })
        }
      </Formik>
    ))
  )
}

const AccountInformation = ({ edit, values, cancel, form }) => {
  return (
    <section className={styles.form__section}>
      {edit ? (
        <Form>
          <div className={styles.input__container}>
            <InputControl
              className={styles.input}
              name="firstName"
              label="First name"
              disabled={!edit}
            />
            <InputControl
              className={styles.input}
              name="lastName"
              label="Last Name"
              disabled={!edit}
            />
            <InputControl
              className={styles.input}
              name="email"
              label="Email"
              disabled={!edit}
            />
            <InputControl
              className={styles.input}
              name="country"
              label="Country"
              disabled={!edit}
            />
            <InputControl
              className={styles.input}
              name="city"
              label="City"
              disabled={!edit}
            />
            <InputControl
              className={styles.input}
              name="phone"
              label="Phone"
              disabled={!edit}
              type="text"
            />
          </div>

          {edit && (
            <div className={styles.buttons}>
              <button
                type="button"
                onClick={() => {
                  cancel()
                }}
              >
                Cancel
              </button>
              <button type="submit">Save changes</button>
            </div>
          )}
        </Form>
      ) : (
        <>
          <div className={styles.content__container}>
            <div className={styles.content__value}>
              <img className={styles.content__icon} src={fullName} alt="icon" />
              <span className={styles.content__label}>Full name</span>
            </div>
            <div
              className={styles.content__value}
            >{`${values.firstName} ${values.lastName}`}</div>
          </div>
          <div className={styles.content__container}>
            <div className={styles.content__value}>
              <img className={styles.content__icon} src={email} alt="icon" />
              <span className={styles.content__label}>Email</span>
            </div>
            <div className={styles.content__value}>{`${values.email}`}</div>
          </div>
          <div className={styles.content__container}>
            <div className={styles.content__value}>
              <img className={styles.content__icon} src={country} alt="icon" />
              <span className={styles.content__label}>Country</span>
            </div>
            <div className={styles.content__value}>{`${values.country}`}</div>
          </div>
          <div className={styles.content__container}>
            <div className={styles.content__value}>
              <img className={styles.content__icon} src={phone} alt="icon" />
              <span className={styles.content__label}>Phone</span>
            </div>
            <div className={styles.content__value}>{`${values.phone}`}</div>
          </div>
        </>
      )}
    </section>
  )
}

const AdditionalInformation = ({ edit, values, setEdit, profile }) => {
  // console.log("AdditionalInformation ~ profile", profile);

  const options = [
    {
      label: 'Male',
      value: 'Male',
    },
    {
      label: 'Female',
      value: 'Female',
    },
  ]
  return (
    <section className={styles.form__section}>
      {edit ? (
        <Form>
          <div className={styles.additional__container}>
            <div className={styles.additional__row}>
              <div className={styles.additional__col}>
                <SelectControl
                  options={options}
                  className={styles.additional__input}
                  name="gender"
                  label="Gender"
                  disabled={!edit}
                />
              </div>
            </div>
            <div className={styles.additional__row}>
              <div className={styles.additional__col}>
                <DateFormatControl
                  name="birthday"
                  label="Date of Birth"
                  placeholder={'mm/dd/yy'}
                  format="mm/dd/yy"
                  // disabled={!edit}
                />
              </div>
              <div className={styles.additional__col}>
                <InputControl
                  className={styles.additional__input}
                  name="bornIn"
                  label="Born in"
                  disabled={!edit}
                  value={values.bornIn}
                />
              </div>
            </div>
            <div className={styles.additional__row}></div>
            <div className={styles.additional__row}>
              <div className={styles.additional__col}>
                <InputControl
                  className={styles.additional__input}
                  name="liveIn"
                  label="Live in"
                  // disabled={!edit}
                />
              </div>
              <div
                className={classNames(
                  styles.additional__col,
                  styles.additional__col_picker,
                )}
              >
                <DateFormatControl
                  name="liveInFrom"
                  label="From"
                  placeholder={'mm/dd/yy'}
                  format="mm/dd/yy"
                  disabled={!edit}
                />
              </div>
            </div>
            <div className={styles.additional__row}>
              <div className={styles.additional__col}>
                <InputControl
                  className={styles.additional__input}
                  name="studiedAt"
                  label="Studied at"
                  disabled={!edit}
                />
              </div>
              <div
                className={classNames(
                  styles.additional__col,
                  styles.additional__col_picker,
                )}
              >
                <DateFormatControl
                  name="studiedAtFrom"
                  label="From"
                  placeholder={'mm/dd/yy'}
                  format="mm/dd/yy"
                  // disabled={!edit}
                />
              </div>
              <div
                className={classNames(
                  styles.additional__col,
                  styles.additional__col_picker,
                )}
              >
                <DateFormatControl
                  name="studiedAtTo"
                  label="To"
                  placeholder={'mm/dd/yy'}
                  format="mm/dd/yy"
                  // disabled={!edit}
                />
              </div>
            </div>
            <div className={styles.additional__row}>
              <div className={styles.additional__col}>
                <InputControl
                  className={styles.additional__input}
                  name="worksIn"
                  label="Works in"
                  disabled={!edit}
                />
              </div>
              <div
                className={classNames(
                  styles.additional__col,
                  styles.additional__col_picker,
                )}
              >
                <DateFormatControl
                  name="worksInFrom"
                  label="From"
                  placeholder={'mm/dd/yy'}
                  format="mm/dd/yy"
                  // disabled={!edit}
                />
              </div>
              <div
                className={classNames(
                  styles.additional__col,
                  styles.additional__col_picker,
                )}
              >
                <DateFormatControl
                  name="worksInTo"
                  label="To"
                  placeholder={'mm/dd/yy'}
                  format="mm/dd/yy"
                  // disabled={!edit}
                />
              </div>
            </div>
            <div className={styles.additional__row}>
              <div className={styles.additional__col}>
                <InputControl
                  className={styles.additional__input}
                  name="marriedTo"
                  label="Married to"
                  // disabled={!edit}
                />
              </div>
              <div
                className={classNames(
                  styles.additional__col,
                  styles.additional__col_picker,
                )}
              >
                <DateFormatControl
                  name="marriedToFrom"
                  label="From"
                  format="mm/dd/yy"
                  disabled={!edit}
                />
              </div>
            </div>
          </div>
          {edit && (
            <div className={styles.buttons}>
              <button
                type="button"
                onClick={() => {
                  setEdit(false)
                }}
              >
                Cancel
              </button>
              <button type="submit">Save changes</button>
            </div>
          )}
        </Form>
      ) : (
        <>
          <div className={styles.content__container}>
            <div className={styles.content__value}>
              <img className={styles.content__icon} src={gender} alt="icon" />
              <span className={styles.content__label}>Gender</span>
            </div>
            <div className={styles.content__value}>{`${values.gender}`}</div>
          </div>
          <div className={styles.content__container}>
            <div className={styles.content__value}>
              <img className={styles.content__icon} src={birthday} alt="icon" />
              <span className={styles.content__label}>Date of Birth</span>
            </div>
            <div
              className={styles.content__value}
            >{`${profile.info.birthday}`}</div>
          </div>
          <div className={styles.content__container}>
            <div className={styles.content__value}>
              <img className={styles.content__icon} src={bornIn} alt="icon" />
              <span className={styles.content__label}>Born in</span>
            </div>
            <div
              className={styles.content__value}
            >{`${profile.info.bornIn}`}</div>
          </div>
          <div className={styles.content__container}>
            <div className={styles.content__value}>
              <img className={styles.content__icon} src={liveIn} alt="icon" />
              <span className={styles.content__label}>Live in</span>
            </div>
            <div className={styles.content__value}>
              <span
                className={styles.valueSpan}
              >{`${profile.info.liveIn}`}</span>
              <span className={styles.dateSpan}>
                from {profile.info.liveInFrom}
              </span>
            </div>
          </div>
          <div className={styles.content__container}>
            <div className={styles.content__value}>
              <img
                className={styles.content__icon}
                src={studiedAt}
                alt="icon"
              />
              <span className={styles.content__label}>Studied at</span>
            </div>
            <div className={styles.content__value}>
              <p className={styles.valueSpan}>{`${profile.info.studiedAt}`}</p>
              <p className={styles.dateSpan}>
                {`${profile.info.studiedAtFrom}`} -{' '}
                {profile.info.studiedAtFromTo === ''
                  ? ' '
                  : `${profile.info.studiedAtFrom}`}
              </p>
              <p className={styles.dateSpan}></p>
            </div>
          </div>
          <div className={styles.content__container}>
            <div className={styles.content__value}>
              <img className={styles.content__icon} src={worksIn} alt="icon" />
              <span className={styles.content__label}>Works in</span>
            </div>
            <div className={styles.content__value}>
              <p className={styles.valueSpan}>{`${profile.info.worksIn}`}</p>
              <p
                className={styles.dateSpan}
              >{`${profile.info.worksInFrom}`}</p>{' '}
              - <p className={styles.dateSpan}>{`${profile.info.worksInTo}`}</p>
            </div>
          </div>
          <div className={styles.content__container}>
            <div className={styles.content__value}>
              <img
                className={styles.content__icon}
                src={marriedTo}
                alt="icon"
              />
              <span className={styles.content__label}>Married to</span>
            </div>
            <div className={styles.content__value}>
              <p className={styles.valueSpan}>{`${profile.info.marriedTo}`}</p>
              <p
                className={styles.dateSpan}
              >{`${profile.info.marriedToFrom}`}</p>
            </div>
          </div>
        </>
      )}
    </section>
  )
}

//DROPDOWN variant of section - change settings

// const AdditionalInformation = ({
//   edit,
//   values,
//   setEdit,
//   profile,
//   inputValue,
// }) => {
//   console.log("inputValue", inputValue);
//   console.log("AdditionalInformation ~ values", profile);
//   const liveInFrom = new Date(
//     Date.parse(profile.info.liveInFrom)
//   ).getFullYear();
//   const studiedAtFrom = new Date(
//     Date.parse(profile.info.studiedAtFrom)
//   ).getFullYear();
//   const studiedAtTo = new Date(
//     Date.parse(profile.info.studiedAtTo)
//   ).getFullYear();
//   const worksInFrom = new Date(
//     Date.parse(profile.info.worksInFrom)
//   ).getFullYear();
//   const worksInTo = new Date(Date.parse(profile.info.worksInTo)).getFullYear();

//   const options = [
//     {
//       label: "Male",
//       value: "Male",
//     },
//     {
//       label: "Female",
//       value: "Female",
//     },
//   ];
//   const day = [
//     { label: 1, value: "01" },
//     { label: 2, value: "02" },
//     { label: 3, value: "03" },
//     { label: 4, value: "04" },
//     { label: 5, value: "05" },
//     { label: 6, value: "06" },
//     { label: 7, value: "07" },
//     { label: 8, value: "08" },
//     { label: 9, value: "09" },
//     { label: 10, value: "10" },
//     { label: 11, value: "11" },
//     { label: 12, value: "12" },
//     { label: 13, value: "13" },
//     { label: 14, value: "14" },
//     { label: 15, value: "15" },
//     { label: 16, value: "16" },
//     { label: 17, value: "17" },
//     { label: 18, value: "18" },
//     { label: 19, value: "19" },
//     { label: 20, value: "20" },
//     { label: 21, value: "21" },
//     { label: 22, value: "22" },
//     { label: 23, value: "23" },
//     { label: 24, value: "24" },
//     { label: 25, value: "25" },
//     { label: 26, value: "26" },
//     { label: 27, value: "27" },
//     { label: 28, value: "28" },
//     { label: 29, value: "29" },
//     { label: 30, value: "30" },
//     { label: 31, value: "31" },
//   ];
//   const month = [
//     { label: 1, value: "01" },
//     { label: 2, value: "02" },
//     { label: 3, value: "03" },
//     { label: 4, value: "04" },
//     { label: 5, value: "05" },
//     { label: 6, value: "06" },
//     { label: 7, value: "07" },
//     { label: 8, value: "08" },
//     { label: 9, value: "09" },
//     { label: 10, value: "10" },
//     { label: 11, value: "11" },
//     { label: 12, value: "12" },
//   ];
//   const year = [
//     { label: 2021, value: "01/01/2021" },
//     { label: 2020, value: "01/01/2020" },
//     { label: 2019, value: "01/01/2019" },
//     { label: 2018, value: "01/01/2018" },
//     { label: 2017, value: "01/01/2017" },
//     { label: 2016, value: "01/01/2016" },
//     { label: 2015, value: "01/01/2015" },
//     { label: 2014, value: "01/01/2014" },
//     { label: 2013, value: "01/01/2013" },
//     { label: 2012, value: "01/01/2012" },
//     { label: 2011, value: "01/01/2011" },
//     { label: 2010, value: "01/01/2010" },
//     { label: 2009, value: "01/01/2009" },
//     { label: 2008, value: "01/01/2008" },
//     { label: 2007, value: "01/01/2007" },
//     { label: 2006, value: "01/01/2006" },
//     { label: 2005, value: "01/01/2005" },
//     { label: 2004, value: "01/01/2004" },
//     { label: 2003, value: "01/01/2003" },
//     { label: 2002, value: "01/01/2002" },
//     { label: 2001, value: "01/01/2001" },
//     { label: 2000, value: "01/01/2000" },
//     { label: 1999, value: "01/01/1999" },
//     { label: 1998, value: "01/01/1998" },
//     { label: 1997, value: "01/01/1997" },
//     { label: 1996, value: "01/01/1996" },
//     { label: 1995, value: "01/01/1995" },
//     { label: 1994, value: "01/01/1994" },
//     { label: 1993, value: "01/01/1993" },
//     { label: 1992, value: "01/01/1992" },
//     { label: 1991, value: "01/01/1991" },
//     { label: 1990, value: "01/01/1990" },
//     { label: 1989, value: "01/01/1989" },
//     { label: 1988, value: "01/01/1988" },
//     { label: 1987, value: "01/01/1987" },
//     { label: 1986, value: "01/01/1986" },
//     { label: 1985, value: "01/01/1985" },
//     { label: 1984, value: "01/01/1984" },
//     { label: 1983, value: "01/01/1983" },
//     { label: 1982, value: "01/01/1982" },
//     { label: 1981, value: "01/01/1981" },
//     { label: 1980, value: "01/01/1980" },
//     { label: 1979, value: "01/01/1979" },
//     { label: 1978, value: "01/01/1978" },
//     { label: 1977, value: "01/01/1977" },
//     { label: 1976, value: "01/01/1976" },
//     { label: 1975, value: "01/01/1975" },
//     { label: 1974, value: "01/01/1974" },
//     { label: 1973, value: "01/01/1973" },
//     { label: 1972, value: "01/01/1972" },
//     { label: 1971, value: "01/01/1971" },
//     { label: 1970, value: "01/01/1970" },
//     { label: 1969, value: "01/01/1969" },
//     { label: 1968, value: "01/01/1968" },
//     { label: 1967, value: "01/01/1967" },
//     { label: 1966, value: "01/01/1966" },
//     { label: 1965, value: "01/01/1965" },
//     { label: 1964, value: "01/01/1964" },
//     { label: 1963, value: "01/01/1963" },
//     { label: 1962, value: "01/01/1962" },
//     { label: 1961, value: "01/01/1961" },
//     { label: 1960, value: "01/01/1960" },
//     { label: 1959, value: "01/01/1959" },
//     { label: 1958, value: "01/01/1958" },
//     { label: 1957, value: "01/01/1957" },
//     { label: 1956, value: "01/01/1956" },
//     { label: 1955, value: "01/01/1955" },
//     { label: 1954, value: "01/01/1954" },
//     { label: 1953, value: "01/01/1953" },
//     { label: 1952, value: "01/01/1952" },
//     { label: 1951, value: "01/01/1951" },
//     { label: 1950, value: "01/01/1950" },
//     { label: 1949, value: "01/01/1949" },
//     { label: 1948, value: "01/01/1948" },
//     { label: 1947, value: "01/01/1947" },
//     { label: 1946, value: "01/01/1946" },
//     { label: 1945, value: "01/01/1945" },
//     { label: 1944, value: "01/01/1944" },
//     { label: 1943, value: "01/01/1943" },
//     { label: 1942, value: "01/01/1942" },
//     { label: 1941, value: "01/01/1941" },
//     { label: 1940, value: "01/01/1940" },
//     { label: 1939, value: "01/01/1939" },
//     { label: 1938, value: "01/01/1938" },
//     { label: 1937, value: "01/01/1937" },
//     { label: 1936, value: "01/01/1936" },
//     { label: 1935, value: "01/01/1935" },
//     { label: 1934, value: "01/01/1934" },
//     { label: 1933, value: "01/01/1933" },
//     { label: 1932, value: "01/01/1932" },
//     { label: 1931, value: "01/01/1931" },
//     { label: 1930, value: "01/01/1930" },
//     { label: 1929, value: "01/01/1929" },
//     { label: 1928, value: "01/01/1928" },
//     { label: 1927, value: "01/01/1927" },
//     { label: 1926, value: "01/01/1926" },
//     { label: 1925, value: "01/01/1925" },
//     { label: 1924, value: "01/01/1924" },
//     { label: 1923, value: "01/01/1923" },
//     { label: 1922, value: "01/01/1922" },
//     { label: 1921, value: "01/01/1921" },
//     { label: 1920, value: "01/01/1920" },
//     { label: 1919, value: "01/01/1919" },
//     { label: 1918, value: "01/01/1918" },
//     { label: 1917, value: "01/01/1917" },
//     { label: 1916, value: "01/01/1916" },
//     { label: 1915, value: "01/01/1915" },
//     { label: 1914, value: "01/01/1914" },
//     { label: 1913, value: "01/01/1913" },
//     { label: 1912, value: "01/01/1912" },
//     { label: 1911, value: "01/01/1911" },
//     { label: 1910, value: "01/01/1910" },
//     { label: 1909, value: "01/01/1909" },
//     { label: 1908, value: "01/01/1908" },
//     { label: 1907, value: "01/01/1907" },
//     { label: 1906, value: "01/01/1906" },
//     { label: 1905, value: "01/01/1905" },
//     { label: 1904, value: "01/01/1904" },
//     { label: 1903, value: "01/01/1903" },
//     { label: 1902, value: "01/01/1902" },
//     { label: 1901, value: "01/01/1901" },
//     { label: 1900, value: "01/01/1900" },
//   ];
//   return (
//     <section className={styles.form__section}>
//       {edit ? (
//         <Form>
//           <div className={styles.additional__container}>
//             <div className={styles.additional__row}>
//               <SelectControl
//                 options={options}
//                 className={styles.additional__input}
//                 name="gender"
//                 label="Gender"
//                 disabled={!edit}
//               />
//               <div className={styles.additional__doubleBox}>
//                 <SelectControl
//                   name="day"
//                   label="Day"
//                   placeholder={"day"}
//                   disabled={!edit}
//                   options={day}
//                   className={styles.additional__input3}
//                 />
//                 <SelectControl
//                   name="month"
//                   label="Month"
//                   placeholder={"month"}
//                   disabled={!edit}
//                   options={month}
//                   className={styles.additional__input3}
//                 />
//                 <SelectControl
//                   name="year"
//                   label="Year"
//                   placeholder={"yaer"}
//                   disabled={!edit}
//                   options={year}
//                   className={styles.additional__input3}
//                 />
//               </div>
//             </div>
//             <div className={styles.additional__row}>
//               <InputControl
//                 className={styles.additional__input}
//                 name="bornIn"
//                 label="Born in"
//                 disabled={!edit}
//               />
//               <div className={styles.additional__doubleBox}>
//                 <InputControl
//                   className={styles.additional__input}
//                   name="liveIn"
//                   label="Live in"
//                   // disabled={!edit}
//                 />
//                 <SelectControl
//                   name="liveInFrom"
//                   label="Live in from"
//                   placeholder={"yaer"}
//                   // format="mm/dd/yy"
//                   disabled={!edit}
//                   options={year}
//                   className={styles.additional__input}
//                 />
//               </div>
//             </div>
//             <div className={styles.additional__row}>
//               <InputControl
//                 className={styles.additional__input}
//                 name="studiedAt"
//                 label="Studied at"
//                 disabled={!edit}
//               />

//               <div className={styles.additional__doubleBox}>
//                 <SelectControl
//                   name="studiedAtFrom"
//                   label="Studied at from"
//                   placeholder={"yaer"}
//                   options={year}
//                   className={styles.additional__input}
//                 />
//                 <SelectControl
//                   name="studiedAtTo"
//                   label="Studied at to"
//                   placeholder={"yaer"}
//                   options={year}
//                   className={styles.additional__input}
//                 />
//               </div>
//             </div>
//             <div className={styles.additional__row}>
//               <InputControl
//                 className={styles.additional__input}
//                 name="worksIn"
//                 label="Works in"
//                 disabled={!edit}
//               />

//               <div className={styles.additional__doubleBox}>
//                 <SelectControl
//                   name="worksInFrom"
//                   label="Works in from"
//                   placeholder={"yaer"}
//                   options={year}
//                   className={styles.additional__input}
//                 />

//                 <SelectControl
//                   name="worksInTo"
//                   label="Works in from to"
//                   placeholder={"yaer"}
//                   options={year}
//                   className={styles.additional__input}
//                 />
//               </div>
//             </div>
//             <div className={styles.additional__row}>
//               <InputControl
//                 className={styles.additional__input}
//                 name="marriedTo"
//                 label="Married to"
//                 // disabled={!edit}
//               />
//               <DateFormatControl
//                 className={styles.additional__input}
//                 name="marriedToFrom"
//                 label="Married to from"
//                 format="mm/dd/yy"
//                 disabled={!edit}
//               />
//             </div>
//           </div>
//           {edit && (
//             <div className={styles.buttons}>
//               <button
//                 type="button"
//                 onClick={() => {
//                   setEdit(false);
//                 }}
//               >
//                 cancel
//               </button>
//               <button type="submit">Save changes</button>
//             </div>
//           )}
//         </Form>
//       ) : (
//         <>
//           <div className={styles.content__container}>
//             <div className={styles.content__value}>
//               <img className={styles.content__icon} src={gender} alt="icon" />
//               <span className={styles.content__label}>Gender</span>
//             </div>
//             <div className={styles.content__value}>{`${values.gender}`}</div>
//           </div>
//           <div className={styles.content__container}>
//             <div className={styles.content__value}>
//               <img className={styles.content__icon} src={birthday} alt="icon" />
//               <span className={styles.content__label}>Date of Birth</span>
//             </div>
//             <div
//               className={styles.content__value}
//             >{`${profile.info.birthday}`}</div>
//           </div>
//           <div className={styles.content__container}>
//             <div className={styles.content__value}>
//               <img className={styles.content__icon} src={bornIn} alt="icon" />
//               <span className={styles.content__label}>Born in</span>
//             </div>
//             <div
//               className={styles.content__value}
//             >{`${profile.info.bornIn}`}</div>
//           </div>
//           <div className={styles.content__container}>
//             <div className={styles.content__value}>
//               <img className={styles.content__icon} src={liveIn} alt="icon" />
//               <span className={styles.content__label}>Live in</span>
//             </div>
//             <div className={styles.content__value}>
//               <span
//                 className={styles.valueSpan}
//               >{`${profile.info.liveIn}`}</span>
//               <span className={styles.dateSpan}>from {liveInFrom}</span>
//             </div>
//           </div>
//           <div className={styles.content__container}>
//             <div className={styles.content__value}>
//               <img
//                 className={styles.content__icon}
//                 src={studiedAt}
//                 alt="icon"
//               />
//               <span className={styles.content__label}>Studied at</span>
//             </div>
//             <div className={styles.content__value}>
//               <p className={styles.valueSpan}>{`${profile.info.studiedAt}`}</p>
//               <p className={styles.dateSpan}>
//                 {`${studiedAtFrom}`} -{" "}
//                 {studiedAtTo === "" ? " " : `${studiedAtTo}`}
//               </p>
//               <p className={styles.dateSpan}></p>
//             </div>
//           </div>
//           <div className={styles.content__container}>
//             <div className={styles.content__value}>
//               <img className={styles.content__icon} src={worksIn} alt="icon" />
//               <span className={styles.content__label}>Works in</span>
//             </div>
//             <div className={styles.content__value}>
//               <p className={styles.valueSpan}>{`${profile.info.worksIn}`}</p>
//               <p className={styles.dateSpan}>{`${worksInFrom}`}</p> -{" "}
//               <p className={styles.dateSpan}>{`${worksInTo}`}</p>
//             </div>
//           </div>
//           <div className={styles.content__container}>
//             <div className={styles.content__value}>
//               <img
//                 className={styles.content__icon}
//                 src={marriedTo}
//                 alt="icon"
//               />
//               <span className={styles.content__label}>Married to</span>
//             </div>
//             <div className={styles.content__value}>
//               <p className={styles.valueSpan}>{`${profile.info.marriedTo}`}</p>
//               <p
//                 className={styles.dateSpan}
//               >{`${profile.info.marriedToFrom}`}</p>
//             </div>
//           </div>
//         </>
//       )}
//     </section>
//   );
// };
