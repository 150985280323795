import React from "react";
import styles from "./friend-list.module.sass";
import { Link } from "react-router-dom";
import Typography from "../../../components/ui/typography";

function FriendList({ friends = [] }) {
  if (!friends.length) {
    return null;
  }
  return (
    <div className={styles.friendListWrapper}>
      {friends.map((data) => {
        return (
          <Link
            className={styles.userWrapper}
            to={{ pathname: `/friend/${data.id}` }}
            key={data.id}
          >
            <img
              src={data.profile_photo_path}
              className={styles.userAvatar}
              alt="User avatar"
            />
            <Typography
              className={styles.userName}
              variant="smallText"
              component="span"
            >
              {data.firstName}
            </Typography>
          </Link>
        );
      })}
    </div>
  );
}

export default FriendList;
