import classNames from 'classnames'
import { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory, Link, useLocation, useParams } from 'react-router-dom'
import SearchInput from '../../components/search-input'
import Typography from '../../components/ui/typography'
import {
  friendsSelector,
  getFriends,
  removeFriendFromList,
} from '../../store/reducers/friends'
import {
  getUsers as getSearchFriends,
  searchUsersSelector,
} from '../../store/reducers/search'
import { DeleteButton } from '../friend-profile/header/UserHeader'

import styles from './friends.module.sass'

const Friends = () => {
  const history = useHistory()
  const [inputValue, setValue] = useState('')

  const dispatch = useDispatch()
  const params = useParams()
  const searchFriends = useSelector(searchUsersSelector)
  const friends = useSelector(friendsSelector)

  // const handleSearch = (value) => {
  //   dispatch(getSearchFriends(value));
  // };
  const arrOfFriends = friends?.filter((friend) => {
    if (inputValue === '') {
      return friend
    } else if (
      friend.firstName.toLowerCase().includes(inputValue.toLowerCase())
    ) {
      return friend
    }
  })

  useEffect(() => {
    if (params.alias) {
      dispatch(getFriends())
    }
  }, [params.alias, dispatch])
  let data = []
  if (params.alias) {
    data = friends
  } else {
    data = searchFriends
  }

  return (
    <div className={styles.wrapper}>
      <div className={styles.header}>
        <div className={styles.header__top}>
          <BackButton
            onClick={() => {
              history.goBack()
            }}
          >
            Back
          </BackButton>
        </div>
        <div className={styles.header__content}>
          <Title>{friends?.length > 0 && friends.length}</Title>
          <SearchInput
            className={styles.searchInput}
            callback={setValue}
            size={3}
          />
        </div>
      </div>
      <FriendList list={arrOfFriends} />
    </div>
  )
}

export default Friends

function BackButton({ children, onClick }) {
  return (
    <button className={styles.button_back} type="button" onClick={onClick}>
      <div className={styles.arrow} />
      {children}
    </button>
  )
}

const Title = ({ children }) => {
  return (
    <div className={styles.title}>
      <Typography component="h1" variant="pageTitle">
        Connections
      </Typography>
      {children ? (
        <Typography component="p" variant="smallText">
          you have <b>{children}</b> Connections
        </Typography>
      ) : null}
    </div>
  )
}

function FriendList({ list }) {
  if (!list || !list.length) {
    return null
  }
  return (
    <div>
      {list.map((e) => (
        <FriendListItem user={e} key={e.id} />
      ))}
    </div>
  )
}
function FriendListItem({ user }) {
  const dispatch = useDispatch()
  const handleRemove = (id, name, lastName) => {
    dispatch(removeFriendFromList({ id: id, name: name, lastName: lastName }))
  }
  const location = useLocation()
  return (
    <div className={styles.itemWrapper}>
      <Link
        to={{
          pathname: `/friend/${user.id}`,
          state: {
            from: location.pathname,
          },
        }}
        className={styles.friend__wrapper}
      >
        <img
          src={user?.profile_photo_path}
          className={styles.friend__avatar}
          alt=""
        />
        <Typography component="h3" variant="titleHeavy">
          {user?.firstName}
        </Typography>
      </Link>
      <DeleteButton
        onClick={() =>
          handleRemove(user.id, user.firstName ?? "", user.lastName ?? "")
        }
        className={styles.delBtn}
      >
        Unfriend
      </DeleteButton>
    </div>
  )
}
