/* eslint-disable jsx-a11y/img-redundant-alt */

import React, { useState, useRef, useEffect, FC, useMemo } from 'react'
import styles from './categories.module.sass'
import ClassicButtonWithText from '../../../../../components/ui/buttons/ClassicButtonWithText'
import { useGlobalCloseSmiles } from '../../../../../hooks/useCloseMenu'
import Category from './Category.jsx'
import { useDispatch } from 'react-redux'
import {
  getCategories,
  setCategoriesCustom,
  setCategoriesDefault,
  setCategoriesNumbers,
  timelinesSelector,
} from '../../../../../store/reducers/timelines'
import { useSelector } from 'react-redux'
import Typography from '../../../../../components/ui/typography'
import CustomTimeline from './CustomTimeline'
import useMobile from '../../../../../hooks/useMobile'
import { ReactSortable } from 'react-sortablejs'
import { delay } from 'redux-saga/effects'
import { userSelector } from '../../../../../store/reducers/user'
import { openCategoryIdSelector } from '../../../../../store/reducers/settings'
import { dangerMessage } from '../../../../../components/ui/alert/alertReducer'

const Categories = () => {
  const dispatch = useDispatch()
  const [modal, setModal] = useState(false)
  const { categories } = useSelector(timelinesSelector)
console.log(444,categories?.canCreateTimeline);
  const isMobile = useMobile(428)

  const modalRef = useRef({})
  useEffect(() => {
    dispatch(getCategories())
  }, [])

  // useEffect(() => {
  //   dispatch(getCategories())
  // }, [dispatch])

  const openModal = () => {
    setModal(true)
  }
  const closeModal = () => {
    setModal(false)
  }

  const DefaultCategoriesMap = (props) => {
    const setDefaultCategories = (e) => {
      const temp = e.find((element, index) => {
        return categories.default[index].id !== element.id
      })
      if (temp) {
        dispatch(setCategoriesDefault(e))
      }
    }
    const [state, setState] = useState(categories.default)

    return (
      state.length && (
        <ReactSortable
          list={state}
          setList={(e) => {
            setDefaultCategories(e)
          }}
          //onUpdate={setDefaultCategories}
        >
          {state.map((item) => (
            <Category
              categories={categories}
              key={item.id}
              categoryId={item.id}
              src={item.image}
              name={item.name}
              category={item}
              switcherState={item.enabled}
            />
          ))}
        </ReactSortable>
      )
    )
  }
  const CustomCategoriesMap = (props) => {
    const [state, setState] = useState(categories.custom)

    const setCustomCategories = (e) => {
      const temp = e.find((element, index) => {
        return categories.custom[index].id !== element.id
      })

      if (temp) dispatch(setCategoriesCustom(e))
    }

    useEffect(() => {
      if (!state.length) return
    }, [state])

    return (
      state.length && (
        <ReactSortable
          list={state}
          setList={(e) => {
            setCustomCategories(e)
          }}
          //onUpdate={() => setCustomCategories(state)}
        >
          {state.map((item) => (
            <Category
              categories={categories}
              key={item.id}
              categoryId={item.id}
              src={item.image}
              name={item.name}
              category={item}
              switcherState={item.enabled}
              color={item.color}
            />
          ))}
        </ReactSortable>
      )
    )
  }

  return (
    <div className={styles.wrapper}>
      {modal && (
        <CustomTimeline
          closeModal={() => {
            closeModal()
          }}
          modalState={modal}
          ref={modalRef}
        />
      )}

      <div className={styles.header}>
        <Typography variant="title" component="h1">
          {isMobile ? 'Categories' : 'Timeline settings'}
        </Typography>
        <ClassicButtonWithText
          onClick={
            categories?.canCreateTimeline
              ? () => openModal()
              : () =>
                  dispatch(
                    dangerMessage(
                      'You have to update your plan for add cutom timelines more',
                    ),
                  )
          }
          variant="addButton"
          className={styles.addTimeLineButton}
        >
          Add Timeline
        </ClassicButtonWithText>
      </div>
      <Typography className={styles.subTitle} variant="title" component="h2">
        Default timelines
      </Typography>
      {categories?.default?.length && <DefaultCategoriesMap />}

      <Typography className={styles.subTitle} variant="title" component="h2">
        Custom timelines
      </Typography>
      {categories?.custom?.length && <CustomCategoriesMap />}
    </div>
  )
}

export default Categories
