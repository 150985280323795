import Base from "../base";
import styles from "./container.module.sass";

const AppContainer = ({ children }) => (
  <Base component="section" className={styles.AppContainer}>
    {children}
  </Base>
);

export default AppContainer;
