import React from "react";
import styles from "./comments.module.sass";

const InputImage = ({ url, setImage, index, images }) => {
  const handleDelete = () => {
    const isCover = images[index].cover;
    const array = images.filter((e, i) => i !== index && e);
    if (isCover && array.length > 0) {
      array[0].cover = true;
    }

    setImage(array);
  };
  return (
    <div className={styles.smallImgWrapper}>
      <button
        onClick={() => handleDelete()}
        className={styles.smallImgWrapper__delBtn}
      ></button>
      <img
        className={styles.smallImgWrapper__img}
        src={url}
        alt="small preview"
      />
    </div>
  );
};
export default InputImage;
