import React from "react";
import ClassNames from "classnames";
import { useField, ErrorMessage } from "formik";
import styles from "./checkbox.module.sass";

const Checkbox = ({
  className,
  onChange,
  checked,
  name,
  // error,
  disabled,
  ...props
}) => {
  return (
    <label className={ClassNames(styles.wrapper, className)}>
      <input
        type="checkbox"
        className={styles.input}
        onChange={onChange}
        name={name}
        checked={checked}
        disabled={disabled}
        {...props}
      />
      {/* <div className={ClassNames(styles.visual, { [styles.error]: error })}> */}
      <div className={ClassNames(styles.visual)}>
        <svg
          width="8"
          height="6"
          viewBox="0 0 8 6"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M1 3L3 5L7 1"
            stroke="#131F55"
            strokeWidth="1.5"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </svg>
      </div>
    </label>
  );
};

export default Checkbox;

Checkbox.defaultProps = {
  disabled: false,
};
