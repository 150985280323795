import classNames from "classnames";
import React from "react";

import styles from "./image-composition.module.sass";

export const ImageComposition = ({
  cover,
  file,
  eventId,
  setCoverImage,
  deleteImage,
}) => {
  let ref = React.useRef(null);

  const [openOption, setOpenOption] = React.useState(false);

  const setOpen = (value) => {
    setOpenOption(true);
  };
  const setClose = () => {
    if (ref.current) {
      setOpenOption(false);
    }
  };

  const setCover = (e) => {
    e.stopPropagation();
    setCoverImage(eventId, file.id);
    setOpenOption(false);
  };

  const handleDelete = (e) => {
    e.stopPropagation();
    deleteImage(eventId, file.id);
    setOpenOption(false);
  };

  React.useEffect(() => {
    if (openOption) {
      window.addEventListener("click", setClose);
    } else {
      window.removeEventListener("click", setClose);
    }
    return () => {
      return window.removeEventListener("click", setClose);
    };
  }, [openOption]);
  return (
    <div
      className={classNames(styles.image__wrapper, {
        [styles.image__wrapper_cover]: cover,
      })}
    >
      <h4
        className={classNames(styles.image__title, {
          [styles.image__title_cover]: !cover,
        })}
      >
        cover
      </h4>
      <div className={styles.image__container}>
        <div className={styles.image__actions}>
          <button
            className={styles.image__openButton}
            type="button"
            onClick={setOpen}
          >
            <svg
              width="32"
              height="32"
              viewBox="0 0 32 32"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M18 10C18 11.1046 17.1046 12 16 12C14.8954 12 14 11.1046 14 10C14 8.89543 14.8954 8 16 8C17.1046 8 18 8.89543 18 10Z"
                fill="white"
              />
              <path
                d="M18 16C18 17.1046 17.1046 18 16 18C14.8954 18 14 17.1046 14 16C14 14.8954 14.8954 14 16 14C17.1046 14 18 14.8954 18 16Z"
                fill="white"
              />
              <path
                d="M16 24C17.1046 24 18 23.1046 18 22C18 20.8954 17.1046 20 16 20C14.8954 20 14 20.8954 14 22C14 23.1046 14.8954 24 16 24Z"
                fill="white"
              />
            </svg>
          </button>
          <ul>
            <li>{file.name}</li>
            <li>{file.size}</li>
          </ul>

          {openOption && (
            <div className={styles.image__dropdown} ref={ref}>
              <button type="button" onClick={setCover}>
                <svg
                  width="16"
                  height="16"
                  viewBox="0 0 16 16"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M5.20503 5.13807C5.46538 4.87772 5.88749 4.87772 6.14784 5.13807L10.8619 9.85212C11.1222 10.1125 11.1222 10.5346 10.8619 10.7949C10.6015 11.0553 10.1794 11.0553 9.91907 10.7949L5.20503 6.08088C4.94468 5.82053 4.94468 5.39842 5.20503 5.13807Z"
                    fill="#1D2A64"
                  />
                  <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M14 4C14 2.89543 13.1046 2 12 2H4C2.89543 2 2 2.89543 2 4V12C2 13.1046 2.89543 14 4 14H12C13.1046 14 14 13.1046 14 12V4ZM12 3.33333H4C3.63181 3.33333 3.33333 3.63181 3.33333 4V12C3.33333 12.3682 3.63181 12.6667 4 12.6667H12C12.3682 12.6667 12.6667 12.3682 12.6667 12V4C12.6667 3.63181 12.3682 3.33333 12 3.33333Z"
                    fill="#1D2A64"
                  />
                </svg>
                Set as cover
              </button>
              <button type="button" onClick={handleDelete}>
                <svg
                  width="16"
                  height="16"
                  viewBox="0 0 16 16"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M10.8923 6.2148C11.1616 5.96377 11.1765 5.54192 10.9254 5.27258C10.6744 5.00323 10.2526 4.98839 9.98322 5.23943L8.03246 7.05758L6.21431 5.10683C5.96328 4.83748 5.54143 4.82264 5.27209 5.07368C5.00274 5.32471 4.9879 5.74656 5.23894 6.0159L7.05709 7.96665L5.10634 9.78481C4.83699 10.0358 4.82215 10.4577 5.07319 10.727C5.32422 10.9964 5.74607 11.0112 6.01541 10.7602L7.96617 8.94203L9.78432 10.8928C10.0354 11.1621 10.4572 11.177 10.7265 10.9259C10.9959 10.6749 11.0107 10.253 10.7597 9.98371L8.94154 8.03295L10.8923 6.2148Z"
                    fill="#1D2A64"
                  />
                  <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M0.666016 7.99984C0.666016 3.94975 3.94926 0.666504 7.99935 0.666504C12.0494 0.666504 15.3327 3.94975 15.3327 7.99984C15.3327 12.0499 12.0494 15.3332 7.99935 15.3332C3.94926 15.3332 0.666016 12.0499 0.666016 7.99984ZM7.99935 13.9998C4.68564 13.9998 1.99935 11.3135 1.99935 7.99984C1.99935 4.68613 4.68564 1.99984 7.99935 1.99984C11.3131 1.99984 13.9993 4.68613 13.9993 7.99984C13.9993 11.3135 11.3131 13.9998 7.99935 13.9998Z"
                    fill="#1D2A64"
                  />
                </svg>
                Delete
              </button>
            </div>
          )}
        </div>
        <div className={styles.coverImgWrapper}>
          {file?.type.indexOf("video") !== -1 && (
            <div className={styles.playIcon}>
              <svg
                width="23"
                height="32"
                viewBox="0 0 23 32"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path d="M23 16L0 32V0L23 16Z" fill="white" />
              </svg>
            </div>
          )}
          <img
            src={file?.cover_img}
            alt="the_file_is_loading"
            className={styles.image}
          />
        </div>
      </div>
    </div>
  );
};

export default ImageComposition;
