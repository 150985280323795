import React, { useEffect, useState } from "react";
import styles from "./create-timeline.module.sass";
import classNames from "classnames";
import { useField } from "formik";
import Typography from "../../components/ui/typography";
const Radio = ({
  name,
  className,
  label = "Residence",
  children,
  value,
  src,
  checkedProps,
  onClick
}) => {
  const [f, , helpers] = useField(name);
  const htmmlFor = `radio-${Math.random()}`;
  const [checked, setChecked] = useState(false);

  // useEffect(() => {
  //   helpers.setValue(arrOfIds);
  // }, [arrOfIds]);

  const handleChange = (e) => {
    console.log(e.target.value);
    setChecked(!checked);
    helpers.setValue(e.target.value);
  };
  
  return (
    <div className={classNames(styles.radio, className)}>
      <input
        name={name}
        id={htmmlFor}
        className={styles.radio}
        type="radio"
        checked={checkedProps}
        onChange={(e) => handleChange(e)}
        value={value}
        onClick={onClick}
      />
      <label htmlFor={htmmlFor} className={styles.radio__label}>
        <div className={styles.radio__label__wrapper}>
          <img src={src} alt="" />
          <div className={styles.radio__content}>
            <Typography variant="root" component="span">
              {label}
            </Typography>
          </div>
        </div>

        {children && (
          <Typography variant="smallText" component="p">
            {children}
          </Typography>
        )}
      </label>
    </div>
  );
};
export default Radio;
