import { object } from "prop-types";
import { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router";
import {
  friendsLoad,
  friendProfileSelector,
  getFriendProfile,
  inviteFriend,
  removeFriend,
} from "../../store/reducers/friends";
import AboutMeWrapper from "./about-me/AboutMe";
import FriendEventsWrapper from "./friend-events/FriendEventsWrapper";
import FriendListSection from "./friend-list/FriendListSection";

import styles from "./friend-profile.module.sass";
import UserHeader from "./header/UserHeader";
import Linkify from "linkify-react";

const FriendProfile = () => {
  const { id } = useParams();
  const friendProfile = useSelector(friendProfileSelector);
  const load = useSelector(friendsLoad);
  const [displayAbout, setDisplayAbout] = useState(false);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getFriendProfile(id));
  }, [id, dispatch]);

  if (!friendProfile) {
    return null;
  }
  const { isFriend, userFriendsCount, userFriends } = friendProfile;

  const inviteNewFriend = () => {
    dispatch(inviteFriend(id));
  };

  const handleRemove = () => {
    dispatch(removeFriend());
  };
  if (load) {
    return <div>LOAD...</div>;
  }

  return (
    <>
      <UserHeader
        userName={friendProfile.firstName}
        about={friendProfile.about}
        src={friendProfile.profile_photo_path}
        isFriend={isFriend}
        removeFriend={handleRemove}
      />
      <div className={styles.friendProfileMain}>
        {isFriend ? (
          <>
            <div className={styles.aboutAndFriends}>
              {!Array.isArray(friendProfile?.profile.info) && (
                <AboutMeWrapper profile={friendProfile.profile} />
              )}
              {friendProfile.userFriends.length > 0 && (
                <FriendListSection
                  count={userFriendsCount}
                  friends={userFriends}
                />
              )}
            </div>
            <FriendEventsWrapper events={friendProfile.events} />
          </>
        ) : (
          <InviteButton onClick={inviteNewFriend} />
        )}
      </div>
    </>
  );
};
export default FriendProfile;

export const InviteButton = ({ onClick }) => (
  <button type="button" className={styles.button__invite} onClick={onClick}>
    <svg
      width="17"
      height="16"
      viewBox="0 0 17 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M8.5 2C9.05228 2 9.5 2.44772 9.5 3V7H13.5C14.0523 7 14.5 7.44772 14.5 8C14.5 8.55228 14.0523 9 13.5 9H9.5V13C9.5 13.5523 9.05228 14 8.5 14C7.94772 14 7.5 13.5523 7.5 13V9H3.5C2.94772 9 2.5 8.55228 2.5 8C2.5 7.44772 2.94771 7 3.5 7H7.5V3C7.5 2.44772 7.94772 2 8.5 2Z"
        fill="white"
      />
    </svg>
    Add Friend
  </button>
);
