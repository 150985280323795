import { useRef, useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'
import useQuery from '../../hooks/useQuery'
import useMobile from '../../hooks/useMobile'

import styles from './search.module.sass'

import searchIcon from './search.svg'
import closeIcon from './close.svg'

const TimelineSearchInput = ({ className, onChange }) => {
  const [InputValue, setValue] = useState('')
  const ref = useRef()
  const query = useQuery()
  const isMobile = useMobile(428)
  const name = query.get('name')

  const handleClick = (e) => {
    e.preventDefault()
    ref.current.focus()
  }
  useEffect(() => {
    name && setValue(name)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])
  const handleInput = ({ target }) => {
    setValue(target.value)
    onChange(target.value)
  }
  const handleClear = (e) => {
    setValue('')
  }
  return isMobile ? (
    <>
      <img
        className={classNames(styles.icon, styles.search)}
        src={searchIcon}
        alt="icon"
      />
      <input
        ref={ref}
        className={styles.input}
        value={InputValue}
        onChange={handleInput}
      />
    </>
  ) : (
    <div
      className={classNames(styles.wrapper, className)}
      onClick={handleClick}
    >
      <img
        className={classNames(styles.icon, styles.search)}
        src={searchIcon}
        alt="icon"
      />
      <input
        ref={ref}
        className={styles.input}
        value={InputValue}
        onChange={handleInput}
      />
      <img
        className={classNames(styles.icon, styles.close)}
        src={closeIcon}
        alt="icon"
        onClick={handleClear}
      />
    </div>
  )
}

export default TimelineSearchInput

TimelineSearchInput.propTypes = {
  className: PropTypes.string,
}
