import Sidebar from "../Sidebar";
import Typography from "../../../../../../components/ui/typography";

const MobileSidebar = () => {
  const styles = {
    padding: "20px 27px",
  };
  return (
    <>
      <header>
        <Typography component="h1" variant="pageTitle" style={styles}>
          Settings
        </Typography>
      </header>
      <Sidebar />
    </>
  );
};

export default MobileSidebar;
