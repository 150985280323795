const colors = [
  "#423323",
  "#9d7416",
  "#2facb3",
  "#6d74b2",
  "#7b75ca",
  "#ea48f0",
  "#db08bb",
  "#6260a9",
  "#4cabdb",
];
export default colors;
