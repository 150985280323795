import { createAction, handleActions } from 'redux-actions'

const namespace = 'home'



const initialState = {
    timelineLink: null
}
const SET_TIMELINE_LINK = `${namespace}/SET_TIMELINE_LINK`
export const setTimeLineLink = createAction(SET_TIMELINE_LINK)
export default handleActions({
    [SET_TIMELINE_LINK]: (state, { payload }) => ({ ...state, timelineLink: payload })
}, initialState)


export const LinkSelector = (state) => state[namespace].timelineLink
