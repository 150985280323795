import React from "react";
import styles from "./video.module.sass";

const VideoPlayer = ({ url }) => {
  return (
    <div className={styles.wrapper}>
      <video width="320" height="240" controls>
        <source src={url ? url : null} type="video/mp4" />
        Your browser does not support the video tag.
      </video>
    </div>
  );
};
export default VideoPlayer;
