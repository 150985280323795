import React, { useEffect, useState } from "react";
import Slider from "react-slick";
import { useParams } from "react-router";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import styles from "./gallery.module.sass";
import "./slick.sass";
import classNames from "classnames";
import video from "../../screens/create-timeline/media-step/video.svg";
import Typography from "../ui/typography";
import { Link } from "react-router-dom";
import { useSelector } from "react-redux";
import { userSelector } from "../../store/reducers/user";
import { useDispatch } from "react-redux";
import { setItem } from "../../store/reducers/comments";

const EventGallery = ({ handleGetItem, media, slidesToShow = 1 }) => {
  const [current, setCurrent] = useState(0);
  const user = useSelector(userSelector);

  const dispatch = useDispatch();

  const { id, eventId } = useParams();
  let dragging = false;

  if (!media?.length) {
    return null;
  }

  const getItemHandler = (item) => {
    dispatch(setItem(item));
  };

  const settings = {
    dots: false,
    infinite: false,
    speed: 500,
    slidesToShow: slidesToShow,
    slidesToScroll: 2,
    initialSlide: 0,
    afterChange: (currentSlide) => {
      dragging = false;
      setCurrent(currentSlide);
    },
    beforeChange: () => (dragging = true),
    nextArrow: <NextArrow />,
    prevArrow: <PrevArrow />,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 3,
          infinite: false,
          dots: true,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
          initialSlide: 2,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };

  return (
    <div className={styles.galleryWrapper}>
      <div className={styles.mediaInfoBox}>
        <Typography
          className={styles.descriptionTitle}
          component="h3"
          variant="smallTextHeavy"
        >
          Media
        </Typography>
        <Link
          to={`/friend/${id}/event/${eventId}/media`}
          className={styles.mediaLink}
        >
          <span className={styles.wordWrapper}>See all</span>

          <svg
            width="6"
            height="10"
            viewBox="0 0 6 10"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M0.292893 9.70711C-0.0976311 9.31658 -0.0976311 8.68342 0.292893 8.29289L3.58579 5L0.292893 1.70711C-0.0976311 1.31658 -0.0976311 0.683418 0.292893 0.292894C0.683417 -0.0976312 1.31658 -0.0976312 1.70711 0.292894L5.70711 4.29289C6.09763 4.68342 6.09763 5.31658 5.70711 5.70711L1.70711 9.70711C1.31658 10.0976 0.683417 10.0976 0.292893 9.70711Z"
              fill="#1D2A64"
            />
          </svg>
        </Link>
      </div>

      <Slider {...settings}>
        {media?.map((e, i) => {
          return (
            <div
              onClick={() => {
                return !dragging && getItemHandler(e);
              }}
              key={e.id}
              className={classNames(
                styles.slide__wrapper,
                { [styles.current]: i === current },
                { [styles.last]: i === media.length - 1 }
              )}
            >
              {user?.id !== +id && (
                <div className={styles.imgPrev}>
                  <img
                    src={e?.uploaded_by?.profile_photo_path}
                    alt="avatarPreview"
                  />
                </div>
              )}

              {e.type.indexOf("video") !== -1 && (
                <div className={styles.playIcon}>
                  <svg
                    width="23"
                    height="32"
                    viewBox="0 0 23 32"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path d="M23 16L0 32V0L23 16Z" fill="white" />
                  </svg>
                </div>
              )}
              <img
                className={classNames(styles.galleryItem, {
                  [styles.video]: e.type.indexOf("video") !== -1,
                })}
                src={e.cover_img}
                alt=""
              />
              <div className={styles.infoWrapper}>
                <div className={styles.infoWrapper__counter}>
                  <svg
                    width="20"
                    height="20"
                    viewBox="0 0 20 20"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M12.9157 15.2451C12.0322 15.7372 11.0347 16 10 16C6.68629 16 4 13.3137 4 10C4 6.68629 6.68629 4 10 4C13.3137 4 16 6.68629 16 10C16 11.0347 15.7372 12.0322 15.2451 12.9157L15.4568 15.4568L12.9157 15.2451Z"
                      stroke="white"
                      strokeWidth="1.5"
                    />
                    <rect
                      x="7"
                      y="8"
                      width="6"
                      height="1.5"
                      rx="0.75"
                      fill="white"
                    />
                    <rect
                      x="7"
                      y="10.5"
                      width="4"
                      height="1.5"
                      rx="0.75"
                      fill="white"
                    />
                  </svg>
                  <span> {e?.comments_count}</span>
                </div>
              </div>
            </div>
          );
        })}
      </Slider>
    </div>
  );
};
export default EventGallery;

const NextArrow = ({ onClick, className }) => {
  return (
    <button
      className={classNames(styles.arrow, className)}
      onClick={onClick}
      type="button"
    >
      <svg
        width="24"
        height="24"
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <rect width="24" height="24" rx="8" fill="white" />
        <rect
          x="0.5"
          y="0.5"
          width="23"
          height="23"
          rx="7.5"
          stroke="#D5D5D5"
          strokeOpacity="0.5"
        />
        <path
          d="M10 17L15 12L10 7"
          stroke="#9CA2BF"
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </svg>
    </button>
  );
};
const PrevArrow = ({ onClick, className }) => {
  return (
    <button
      className={classNames(styles.arrow, styles.arrow__prev, className)}
      onClick={onClick}
      type="button"
    >
      <svg
        width="24"
        height="24"
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <rect width="24" height="24" rx="8" fill="white" />
        <rect
          x="0.5"
          y="0.5"
          width="23"
          height="23"
          rx="7.5"
          stroke="#D5D5D5"
          strokeOpacity="0.5"
        />
        <path
          d="M10 17L15 12L10 7"
          stroke="#9CA2BF"
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </svg>
    </button>
  );
};
