import { createAction, handleActions } from "redux-actions";

const namespace = "alert";

const SET_ITEM = `${namespace}/SET_ITEM`;
const RESET_ITEM = `${namespace}/RESET_ITEM`;

export const dangerMessage = createAction(SET_ITEM, (message) => ({
  id: Date.now().toString(),
  message,
  type: "danger",
}));
export const infoMessage = createAction(SET_ITEM, (message) => ({
  id: Date.now().toString(),
  message,
  type: "info",
}));
export const successMessage = createAction(SET_ITEM, (message) => ({
  id: Date.now().toString(),
  message,
  type: "success",
}));

export const resetItem = createAction(RESET_ITEM)

const initialState = [];

export default handleActions(
  {
    // [SET_ITEM]: (state, { payload }) => [...state, payload],         ------->>>>>>>>>> flood window of alerts 
    [SET_ITEM]: (state, { payload }) => [payload],
    [RESET_ITEM]: (state, { payload }) => state.filter((e) => e.id !== payload),
  },
  initialState
);

export const alertSelector = (state) => state[namespace];
