import React from "react";
import { useDispatch } from "react-redux";
import { categoryUpdate } from "../../../../../store/reducers/settings";
import classNames from "classnames";
import styles from "./categories.module.sass";

const ColorRadioButton = ({
  className,
  name,
  eventId,
  value,
  htmlFore,
  idx,
  id,
  isSelected,
}) => {
  // const [field] = useField(name);
  const htmlFor = htmlFore;

  const dispatch = useDispatch();

  const onChange = (e) => {
    dispatch(categoryUpdate([{ [name]: value }, { id: eventId }]));
  };

  return (
    <>
      <input
        id={id}
        type="radio"
        name={name}
        value={value}
        checked={isSelected}
        onChange={(e) => onChange(e)}
      />
      <label
        htmlFor={htmlFor}
        className={classNames(styles.radio, className, {
          [styles.chosenOne]: isSelected,
        })}
        style={{ backgroundColor: value }}
      />
    </>
  );
};

export default ColorRadioButton;
