import * as yup from "yup";
import classNames from "classnames";
import { Formik, Form, useField } from "formik";
import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  CheckboxControl,
  InputControl,
  Textarea,
} from "../../components/ui/formik";
// import Modal from "../../components/ui/modal";
import RangeCalendar from "../../components/ui/range-calendar";
import Typography from "../../components/ui/typography";
import {
  createEvent,
  getCategories,
  timelinesLoadSelector,
  timelinesSelector,
} from "../../store/reducers/timelines";
import BackButton from "../../components/back-button";
import styles from "./create-timeline.module.sass";
import publi from "./public.svg";
import priva from "./private.svg";
import MediaStep from "./media-step";
import { dangerMessage } from "../../components/ui/alert/alertReducer";
// import { titles } from "./utils";
import { useHistory } from "react-router";
import { set } from "date-fns";
import Radio from "./Radio.jsx";
import { useGlobalCloseSmiles } from "../../hooks/useCloseMenu";
import Preloader from "../../components/ui/preloader/Preloader";
import useMobile from "../../hooks/useMobile";

const CreateTimeline = ({ buttonClassName }) => {
  // const [modal, setModal] = React.useState(true);
  const [step, setStep] = React.useState(0);
  const { categories } = useSelector(timelinesSelector);
  const allCategories = categories?.default.length > 0 && [
    ...categories.default,
    ...categories.custom,
  ];
  const load = useSelector(timelinesLoadSelector);
  const dispatch = useDispatch();
  const history = useHistory();
  const calendarRef = useRef({});
  const [arrOfIds, setArr] = useState([]);
  const [fromTimeline, setIsOpen] = useState(false);
  const [checkedPublic, setPublic] = useState(false);
  const [checkedPrivate, setPrivate] = useState(false);
  const isMobile = useMobile(428);

  const handlerForRadioBtn = (e) => {
    if(e == "public"){
      setPrivate(false)
      setPublic(!checkedPublic)
    }
    else{
      setPublic(false)
      setPrivate(!checkedPrivate)
    }
    console.log(e);
  };

  const openCalendar = () => {
    setIsOpen(true);
  };

  const initialValues = {
    timeline: [],
    calendar: {
      startDate: "",
      endDate: "",
    },
    eventTitle: "",
    location: "",
    description: "",
    allowMedia: false,
    highlight: false,
    allowFriends: false,
    preferences: "",
    images: [],
  };

  const singleValidationScheme = yup.object().shape({
    eventTitle: yup
      .string()
      .min(2, "Enter the title")
      .required("Please, name this entry"),
    calendar: yup.object().shape({
      startDate: yup.date().required("Please choose a date for this Entry."),
      endDate: yup.date(),
    }),
    timeline: yup
      .array()
      .min(1, "Please choose at least one Timeline Category.")
      .required("Please choose at least one Timeline Category."),
      preferences: yup
      .string()
      .min(1, "Please choose preference")
      .required("Please choose preference"),
    allowMedia: yup.boolean(),
    highlight: yup.boolean(),
    allowFriends: yup.boolean(),
    images: yup.array().min(1,"PLEASE CHOOSE A IMAGE OR VIDEO"),
  });

  const handleCalendarClose = () => {
    setIsOpen(false);
  };

  const [counter, setCounter] = useState(0);

  useGlobalCloseSmiles(calendarRef, handleCalendarClose, fromTimeline);

  const setValueHandler = (value) => {
    if (arrOfIds.length < 2) {
      setArr((prevState) => [...prevState, value]);
    }
  };
  React.useEffect(() => {
    dispatch(getCategories());
    return () => setStep(0);
  }, [dispatch]);
  // const closeModal = () => {
  //   setModal(false);
  //   setStep(0);
  // };
  // const goBack = () => {
  //   history.goBack();
  // };
  const push = (friendId, eventId) => {
    history.push(`/friend/${friendId}/event/${eventId}`);
  };

  const onSubmitForm = (values) => {
    // if (step < childrenArr.length - 1) {
    //   setStep((s) => s + 1);
    // }
    // if (step === childrenArr.length - 1) {

    // }

    let totalSize = 0;
    for (let i = 0; i < values.images.length; i++) {
      totalSize += parseInt(values.images[i].size);
    }
    if (totalSize > 500) {
      dispatch(
        dangerMessage(
          "Total size of uploaded content has to be less then 500 mb. "
        )
      );
      return;
    }
    dispatch(createEvent({ values, push }));
  };

  return (
    <>
      {/* {load && <Preloader />} */}
      {/* <button
        className={classNames(styles.addButton, buttonClassName)}
        onClick={() => setModal(!modal)}
      >
        + Add entry
      </button> */}
      {/* <Modal
        isOpen={modal}
        onClose={closeModal}
        submit={() => {}}
        className={styles.modal__wrapper}
      >
        {(close, disabled) => ( */}
      <div className={styles.wrapper}>
        <div className={styles.modal__header}>
          <BackButton onClick={() => history.goBack()}>Back</BackButton>
          <Typography variant="pageTitle" component="h1">
            New Entry
          </Typography>
          {/* <button
            className={styles.closeButton}
            type="button"
            onClick={() => close()}
          >
            <svg
              width="24"
              height="24"
              viewBox="0 0 24 24"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M16.6771 7.32289C16.2466 6.89237 15.5486 6.89237 15.1181 7.32289L12 10.441L8.88192 7.32289C8.45141 6.89237 7.7534 6.89237 7.32289 7.32289C6.89237 7.7534 6.89237 8.45141 7.32289 8.88192L10.441 12L7.32289 15.1181C6.89237 15.5486 6.89237 16.2466 7.32289 16.6771C7.7534 17.1076 8.45141 17.1076 8.88192 16.6771L12 13.559L15.1181 16.6771C15.5486 17.1076 16.2466 17.1076 16.6771 16.6771C17.1076 16.2466 17.1076 15.5486 16.6771 15.1181L13.559 12L16.6771 8.88192C17.1076 8.45141 17.1076 7.7534 16.6771 7.32289Z"
                fill="#1D2A64"
              />
            </svg>
          </button> */}
        </div>
        {/* <Progress step={step} /> */}

        <Formik
          setStep={setStep}
          historyPush={push}
          initialValues={initialValues}
          step={step}
          onSubmit={onSubmitForm}
          validationSchema={singleValidationScheme}
        >
          {({ errors, touched, values }) => {
            return (
              <Form className={styles.form}>
                <div className={styles.form__content}>
                  <div className={styles.settingsWrapper}>
                    <Step>
                      <div className={styles.container}>
                        <div className={styles.titles}></div>
                        <InputControl
                          placeholder={"Enter entry title"}
                          name="eventTitle"
                          label="Entry title"
                          className={styles.input}
                          labelClassName={styles.input__label}
                        />
                        <Textarea
                          name="description"
                          label="description"
                          placeholder={"Enter entry description"}
                          className={styles.input}
                          labelClassName={styles.input__label}
                        />
                        <InputControl
                          name="location"
                          label="location"
                          placeholder={"Enter entry location"}
                          className={styles.input}
                          labelClassName={styles.input__label}
                        />
                      </div>
                    </Step>
                    <Step>
                      {errors.calendar && touched.calendar ? (
                        <div className={styles.error}>
                          {errors.calendar.startDate}
                        </div>
                      ) : null}
                      <div className={styles.container}>
                        <RangeCalendar
                          ref={calendarRef}
                          fromTimeline={fromTimeline}
                          handleOpenCalendar={openCalendar}
                          handleCalendarClose={handleCalendarClose}
                          name="calendar"
                          timeLinePreSettings={true}
                        />
                      </div>
                    </Step>
                    <Step>
                      <div className={styles.titles}>
                        <Typography
                          className={styles.title}
                          component="h1"
                          variant="pageTitle"
                        >
                          Select Timeline
                        </Typography>
                      </div>
                      {errors.timeline && touched.timeline ? (
                        <div className={styles.error}>{errors.timeline}</div>
                      ) : null}
                      <div className={styles.timelines__container}>
                        {allCategories.length > 0 &&
                          allCategories.map((e, idx) => (
                            <Checkbox
                              setValueHandler={setValueHandler}
                              setCounter={setCounter}
                              counter={counter}
                              key={e.id}
                              name="timeline"
                              value={e.id}
                              arrOfIds={arrOfIds}
                              setArr={setArr}
                              label={e.name}
                              src={e.image}
                              category={e}
                              color={e.color}
                              idx={idx}
                            />
                          ))}
                      </div>
                      {/* <div className={styles.separator} />
                                <InputControl
                                    name="name"
                                    label="Name timeline"
                                    className={styles.input}
                                    labelClassName={styles.input__label}
                                    placeholder="Enter name timeline"
                                /> */}
                    </Step>
                    <Step>
                      <div className={styles.container__preferences}>
                        <div className={styles.titles}>
                          <Typography
                            className={styles.title}
                            component="h1"
                            variant="pageTitle"
                          >
                            Select preferences
                          </Typography>
                          {errors.preferences && touched.preferences ? (
                            <div className={styles.error}>{errors.preferences}</div>
                          ) : null}
                        </div>
                        <div className={styles.preferences__row}>
                          <div className={styles.preferences__radioContainer}>
                            <Radio
                              name="preferences"
                              className={styles.preferences}
                              value={0}
                              src={publi}
                              type="radio"
                              label="Public Event"
                              checkedProps={checkedPublic}
                              onClick={()=>handlerForRadioBtn("public")}
                            >
                              Anyone can see this event.
                            </Radio>
                            <Radio
                              src={priva}
                              name="preferences"
                              className={styles.preferences}
                              value={1}
                              label="Private Event"
                              checkedProps={checkedPrivate}
                              onClick={()=>handlerForRadioBtn("private")}
                            >
                              This event will be only visible to you and the
                              friends you invite to view it.
                            </Radio>
                          </div>
                          <div className={styles.preferences__options}>
                            <h3>Additional settings</h3>
                            <div className={styles.preferences__checkboxes}>
                              <label
                                className={styles.includeFields__container}
                              >
                                <CheckboxControl
                                  name="allowMedia"
                                  className={styles.includeFields__checkbox}
                                />
                                <Typography component="span">
                                  Allow media
                                </Typography>
                              </label>
                              <label
                                className={styles.includeFields__container}
                              >
                                <CheckboxControl
                                  name="highlight"
                                  className={styles.includeFields__checkbox}
                                />
                                <Typography component="span">
                                  Highlight
                                </Typography>
                              </label>
                              <label
                                className={styles.includeFields__container}
                              >
                                <CheckboxControl
                                  name="allowFriends"
                                  className={styles.includeFields__checkbox}
                                />
                                <Typography component="span">
                                  Allow friends to add media
                                </Typography>
                              </label>
                            </div>
                          </div>
                        </div>
                      </div>
                    </Step>
                  </div>
                  <div className={styles.lineContainer}>
                    <div className={styles.lineContainer__line}></div>
                  </div>
                  <Step
                    // validationSchema={yup.object().shape({
                    //   images: yup.array().min(0),
                    // })}
                  >
                    <MediaStep name="images" error={errors.images && touched.images } />
                  </Step>
                </div>

                <div className={styles.buttons}>
                  {!isMobile && (
                    <button
                      className={styles.button__back}
                      type="button"
                      onClick={() => history.goBack()}
                    >
                      Cancel
                    </button>
                  )}

                  <button className={styles.submitButton} type="submit">
                    Next
                  </button>
                </div>
              </Form>
            );
          }}
        </Formik>
      </div>
      {/* )}
      </Modal> */}
    </>
  );
};

export default CreateTimeline;

// const Progress = ({ step = 0 }) => {
//   return (
//     <div className={styles.progress__wrapper}>
//       <ProgressItem
//         active={step >= 0}
//         isPast={step > 0}
//         step={1}
//         title="Select timeline"
//       />
//       <div className={styles.line} />
//       <ProgressItem
//         active={step >= 1}
//         isPast={step > 1}
//         step={2}
//         title="Select date"
//       />
//       <div className={styles.line} />
//       <ProgressItem
//         active={step >= 2}
//         isPast={step > 2}
//         step={3}
//         title="Main info"
//       />
//       <div className={styles.line} />
//       <ProgressItem
//         active={step >= 3}
//         isPast={step > 3}
//         step={4}
//         title="Preferences"
//       />
//       <div className={styles.line} />
//       <ProgressItem
//         active={step >= 4}
//         isPast={step > 4}
//         step={5}
//         title="Media"
//       />
//     </div>
//   );
// };

const ProgressItem = ({ step, title, active, isPast }) => {
  return (
    <div className={styles.step__container}>
      <span
        className={classNames(styles.step__title, styles.step, {
          [styles.progress__active]: active,
        })}
      >
        {!isPast ? (
          step
        ) : (
          <svg
            width="9"
            height="8"
            viewBox="0 0 9 8"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M3.02047 5.24459C3.10378 5.3298 3.24088 5.32983 3.32422 5.24465L7.44135 1.03637C7.72957 0.741777 8.20342 0.74102 8.49257 1.03469C8.77429 1.32081 8.7749 1.77987 8.49393 2.06673L3.50901 7.15624C3.32419 7.34494 3.02041 7.34497 2.83554 7.15631L0.506137 4.77905C0.22515 4.49229 0.225705 4.03328 0.507385 3.7472C0.796529 3.45354 1.2704 3.45439 1.55849 3.74908L3.02047 5.24459Z"
              fill="white"
              stroke="white"
              strokeWidth="0.5"
            />
          </svg>
        )}
      </span>
      <span className={classNames(styles.step__title)}>{title}</span>
    </div>
  );
};

const Step = ({ children }) => {
  return <>{children}</>;
};

// const TimelineForm = ({
//   children,
//   step,
//   setStep,
//   initialValues,
//   historyPush,
//   singleValidationScheme,
// }) => {
//   const dispatch = useDispatch();
//   const childrenArr = React.Children.toArray(children);

//   const history = useHistory();

//   const onSubmitForm = (values) => {
//     // if (step < childrenArr.length - 1) {
//     //   setStep((s) => s + 1);
//     // }
//     // if (step === childrenArr.length - 1) {

//     // }
//     let totalSize = 0;
//     for (let i = 0; i < values.images.length; i++) {
//       totalSize += parseInt(values.images[i].size);
//     }
//     if (totalSize > 500) {
//       dispatch(
//         dangerMessage(
//           "Total size of uploaded content has to be less then 500 mb. "
//         )
//       );
//       return;
//     }
//     dispatch(createEvent({ values, historyPush }));
//   };

//   // const handleBack = () => {
//   //   if (step !== 0) {
//   //     setStep((s) => s - 1);
//   //   }
//   // };
//   return (
//     <Formik
//       initialValues={initialValues}
//       onSubmit={onSubmitForm}
//       validationSchema={singleValidationScheme}
//     >
//       {({ errors, touched, values }) => {
//         return (
//           <Form className={styles.form}>
//             <div className={styles.form__content}>{childrenArr}</div>

//             <div className={styles.buttons}>
//               <button
//                 className={styles.button__back}
//                 type="button"
//                 onClick={() => history.goBack()}
//               >
//                 Cancel
//               </button>
//               <button className={styles.submitButton} type="submit">
//                 Next
//               </button>
//             </div>
//           </Form>
//         );
//       }}
//     </Formik>
//   );
// };

const Checkbox = ({
  name,
  className,
  label = "Residence",
  children,
  value,
  counter,
  setCounter,
  setValueHandler,
  arrOfIds,
  src,
  color,
  category,
}) => {
  const [f, , helpers] = useField(name);
  const htmmlFor = `radio-${Math.random()}`;
  const [checked, setChecked] = useState(false);

  useEffect(() => {
    helpers.setValue(arrOfIds);
  }, [arrOfIds]);

  const handleChange = (e) => {
    if (counter < 2 && !checked) {
      setCounter(counter + 1);
      setChecked(true);
      setValueHandler(value);
    }
    if (checked) {
      setCounter(counter - 1);
      setChecked(false);

      let idx = arrOfIds.indexOf(parseInt(e.target.value));

      arrOfIds.splice(idx, 1);
    }
  };

  return (
    <div className={classNames(styles.radio, className)}>
      <input
        name={name}
        id={htmmlFor}
        className={styles.radio}
        type="checkbox"
        checked={checked}
        onChange={(e) => handleChange(e)}
        value={value}
      />
      <label htmlFor={htmmlFor} className={styles.radio__label}>
        <div className={styles.radio__label__wrapper}>
          {category.default === 1 ? (
            <img src={src} alt="" />
          ) : (
            <div
              className={styles.bgTheme}
              style={{ backgroundColor: color }}
            ></div>
          )}

          <div className={styles.radio__content}>
            <Typography variant="root" component="span">
              {label}
            </Typography>
          </div>
        </div>

        {children && (
          <Typography variant="smallText" component="p">
            {children}
          </Typography>
        )}
      </label>
    </div>
  );
};
