import React, { useEffect, useState } from 'react'
import styles from './subscriptions.module.sass'
import Typography from '../../../../../components/ui/typography'
import ClassNames from 'classnames'
import add from './icons/add.svg'
import plans from './plans.json'
import Plan from './Plan.jsx'
import { resource } from '../../../../../api'
import { useHistory } from 'react-router'
import { useDispatch, useSelector } from 'react-redux'
import {
  getInvoices,
  getPaymentMethods,
  getPrices,
  getSubscription,
  paymentSelector,
  removePaymentMethod,
  setDefaultPaymentMethod,
  setupIntent,
  updateSubscription,
} from '../../../../../store/reducers/payments'
import AddPaymentMethod from './payment-method/add-payment-method/AddPaymentMethod'
import { format } from 'date-fns'

const Subscriptions = () => {
  // useEffect(() => {
  //   resource.get("/cabinet/payments/invoices").then((res) => console.log(res));
  // }, []);
  const history = useHistory()
  const payments = useSelector(paymentSelector)
  const [modal, setModal] = useState(false)
  const [planFilter, setPlanFilter] = useState('yearly') //false = monthly, true = yearly
  var time = new Date().getTime() // get your number
  var date = new Date(time) // create Date object

  const defaultPaymentMethod = payments.methods?.find(
    (e) => e.isDefault == true,
  )?.id
  const currentPlan = payments?.subscription

  const dateFormater = (e) => {
    const y = format(new Date(new Date(e * 1000).toDateString()), 'MM.dd.yyyy')
    return y
  }

  const dispatch = useDispatch()
  useEffect(() => {
    dispatch(getPaymentMethods())
    dispatch(getInvoices())
    dispatch(setupIntent())
    dispatch(getSubscription())
    dispatch(getPrices())
  }, [])

  const handleDeletePaymentMethod = (id) => {
    dispatch(removePaymentMethod(id))
  }
  const defaultHandle = (e) => {
    dispatch(setDefaultPaymentMethod(e))
  }

  return (
    <>
      {modal && <AddPaymentMethod close={() => setModal(false)} />}
      <div>
        <div className={styles.wrapper}>
          <div className={styles.header}>
            <h1>subscriptions</h1>
            <Typography component="p" variant="root" className={styles.text}>
              Upgrade your account to unlock more features.
            </Typography>
          </div>
          <div className={styles.option}>
            <Typography component="h3" variant="label" className={styles.title}>
              Your current plan
            </Typography>
            <div className={styles.planWrapper}>
              <Typography
                component="p"
                variant="titleHeavySecond"
                className={styles.planMainTitle}
              >
                {payments.subscription?.name ? 'pro' : 'free'}
              </Typography>
              <Typography
                component="p"
                variant="root"
                className={styles.upgrade}
              >
                {!payments.subscription?.name && <span>Upgrade to: Pro</span>}
              </Typography>
            </div>
          </div>
          <div className={styles.option}>
            <div className={styles.upgradeWrapper}>
              <div className={styles.upgradeWrapper__divWrapper}>
                <Typography
                  component="h3"
                  variant="label"
                  className={styles.title}
                >
                  Upgrade plan
                </Typography>
                <Typography
                  component="p"
                  variant="root"
                  className={styles.save}
                >
                  Save 10%
                </Typography>
              </div>
              <div className={styles.subscriptionButtons}>
                <div className={styles.btnsWrap}>
                  <button
                    type="button"
                    className={ClassNames([
                      styles.button,
                      { [styles.buttonActive]: planFilter == 'yearly' },
                    ])}
                    onClick={() => setPlanFilter('yearly')}
                  >
                    Yearly
                  </button>
                  <button
                    type="button"
                    className={ClassNames([
                      styles.button,
                      { [styles.buttonActive]: planFilter == 'monthly' },
                    ])}
                    onClick={() => setPlanFilter('monthly')}
                  >
                    Monthly
                  </button>
                </div>
              </div>
            </div>
            <div className={styles.plansWrapper}>
              {/* {plans &&
                plans.map(
                  ({ title, price, list, selected, buttonTitle }, idx) => (
                    <Plan
                      key={idx}
                      title={title}
                      price={price}
                      list={list}
                      selected={selected}
                      buttonTitle={buttonTitle}
                      filter={planFilter}
                      trigger={idx == 1}
                      prices={payments.prices.data}
                      defaultPaymentMethod={defaultPaymentMethod}
                      currentPlan={currentPlan}
                      idx={idx}
                    />
                  ),
                  
                )} */}
              <Plan
                currentPlan={currentPlan}
                filter={planFilter}
                prices={payments.prices.data}
                defaultPaymentMethod={defaultPaymentMethod}
              />
            </div>
          </div>
          <div className={styles.option}>
            <Typography component="h3" variant="label" className={styles.title}>
              Payment method
            </Typography>
            <Typography
              component="p"
              variant="smallText"
              className={styles.paymentText}
            >
              Here you can manage your payment method.
            </Typography>

            {payments.methods?.map((e, index) => {
              return (
                <div className={styles.pm_wpapper} key={index}>
                  <div className={styles.cardInfo}>
                    {e.brand == 'visa' && (
                      <svg
                        className={styles.cardInfo__logo}
                        width="32"
                        height="32"
                        viewBox="0 0 32 32"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M10.2524 25.5047C15.3623 25.5047 19.5047 21.3623 19.5047 16.2524C19.5047 11.1424 15.3623 7 10.2524 7C5.14242 7 1 11.1424 1 16.2524C1 21.3623 5.14242 25.5047 10.2524 25.5047Z"
                          fill="#EE0005"
                        />
                        <path
                          d="M21.7475 25.5047C26.8574 25.5047 30.9998 21.3623 30.9998 16.2524C30.9998 11.1424 26.8574 7 21.7475 7C16.6375 7 12.4951 11.1424 12.4951 16.2524C12.4951 21.3623 16.6375 25.5047 21.7475 25.5047Z"
                          fill="#F9A000"
                        />
                        <path
                          d="M12.4951 16.2522C12.4951 19.19 13.8646 21.8078 15.9997 23.5026C18.1348 21.8077 19.5044 19.19 19.5044 16.2522C19.5044 13.3144 18.1349 10.6967 15.9997 9.00186C13.8647 10.6968 12.4951 13.3145 12.4951 16.2522Z"
                          fill="#FF6300"
                        />
                      </svg>
                    )}
                    <Typography
                      component="h3"
                      variant="label"
                      className={styles.title}
                    >
                      {'**** ' + e.last_four}
                    </Typography>
                    <svg
                      className={styles.cardInfo__lock}
                      width="16"
                      height="16"
                      viewBox="0 0 16 16"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        fill-rule="evenodd"
                        clip-rule="evenodd"
                        d="M6.66087 3.34802C7.01654 2.97835 7.49894 2.77067 8.00194 2.77067C8.50493 2.77067 8.98733 2.97835 9.343 3.34802C9.69867 3.71768 9.89849 4.21906 9.89849 4.74184V6.71306H6.10538V4.74184C6.10538 4.21906 6.3052 3.71768 6.66087 3.34802ZM4.88281 6.71306V4.74184C4.88281 3.88205 5.21143 3.05748 5.79638 2.44951C6.38133 1.84155 7.17469 1.5 8.00194 1.5C8.82918 1.5 9.62254 1.84155 10.2075 2.44951C10.7924 3.05748 11.1211 3.88205 11.1211 4.74184V6.71306H11.1348C12.1649 6.71306 13 7.58099 13 8.65165V12.5614C13 13.6321 12.1649 14.5 11.1348 14.5H4.8652C3.83508 14.5 3 13.6321 3 12.5614V8.65165C3 7.58099 3.83508 6.71306 4.8652 6.71306H4.88281ZM4.8652 7.98373C4.51029 7.98373 4.22257 8.28277 4.22257 8.65165V12.5614C4.22257 12.9303 4.51029 13.2293 4.8652 13.2293H11.1348C11.4897 13.2293 11.7774 12.9303 11.7774 12.5614V8.65165C11.7774 8.28277 11.4897 7.98373 11.1348 7.98373H4.8652ZM8.0005 10.4436C7.91393 10.4436 7.84376 10.5165 7.84376 10.6065C7.84376 10.6965 7.91393 10.7694 8.0005 10.7694C8.08706 10.7694 8.15724 10.6965 8.15724 10.6065C8.15724 10.5165 8.08706 10.4436 8.0005 10.4436ZM6.90332 10.6065C6.90332 9.97672 7.39454 9.46616 8.0005 9.46616C8.60645 9.46616 9.09768 9.97672 9.09768 10.6065C9.09768 11.2363 8.60645 11.7469 8.0005 11.7469C7.39454 11.7469 6.90332 11.2363 6.90332 10.6065Z"
                        fill="#97A3B7"
                      />
                    </svg>
                    <Typography
                      component="p"
                      variant="smallText"
                      className={styles.cardInfo__expired}
                    >
                      Expires to:{' '}
                      {e.exp_month + '/' + e.exp_year.toString().slice(2)}
                    </Typography>
                    <Typography
                      component="p"
                      variant="smallText"
                      className={styles.cardInfo__expired}
                    >
                      {e.isDefault && 'Default'}
                    </Typography>
                    {/* <button className={styles.cardInfo__update}>Update </button> */}
                  </div>
                  {e.isDefault ? null : (
                    <div className={styles.row}>
                      <div
                        className={styles.hide}
                        onClick={() => {
                          defaultHandle(e.id)
                        }}
                      >
                        <Typography component="h6" variant="label">
                          {'Set default'}
                        </Typography>
                      </div>
                      <svg
                        onClick={() => {
                          handleDeletePaymentMethod(e.id)
                        }}
                        className={styles.pm_wpapper__remove}
                        width="32"
                        height="32"
                        viewBox="0 0 32 32"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          fill-rule="evenodd"
                          clip-rule="evenodd"
                          d="M13.9061 7.84777C13.925 7.82883 13.9508 7.81818 13.9776 7.81818H18.0225C18.0493 7.81818 18.0751 7.82883 18.094 7.84777C18.113 7.86671 18.1236 7.89241 18.1236 7.91919V10.0405H13.8765V7.91919C13.8765 7.89241 13.8871 7.86671 13.9061 7.84777ZM12.0562 10.0405V7.91919C12.0562 7.41019 12.2587 6.92204 12.619 6.56212C12.9793 6.2022 13.468 6 13.9776 6H18.0225C18.5321 6 19.0208 6.2022 19.3811 6.56212C19.7414 6.92204 19.9439 7.41019 19.9439 7.91919V10.0405H23.0567C23.0707 10.0402 23.0846 10.0402 23.0987 10.0405H24.0899C24.5925 10.0405 25 10.4475 25 10.9496C25 11.4517 24.5925 11.8587 24.0899 11.8587H23.9161L22.9773 23.112C22.9666 23.8739 22.6589 24.6024 22.1187 25.142C21.5687 25.6913 20.8228 26 20.045 26H11.9551C11.1773 26 10.4314 25.6913 9.88147 25.142C9.34125 24.6024 9.03357 23.8739 9.02282 23.112L8.084 11.8587H7.91011C7.40747 11.8587 7 11.4517 7 10.9496C7 10.4475 7.40747 10.0405 7.91011 10.0405H8.90144C8.91548 10.0402 8.92947 10.0402 8.94339 10.0405H12.0562ZM10.8396 22.9952L9.91053 11.8587H22.0896L21.1605 22.9952C21.1584 23.0203 21.1574 23.0455 21.1574 23.0707C21.1574 23.3653 21.0402 23.648 20.8316 23.8563C20.623 24.0647 20.34 24.1818 20.045 24.1818H11.9551C11.6601 24.1818 11.3772 24.0647 11.1686 23.8563C10.96 23.648 10.8428 23.3653 10.8428 23.0707C10.8428 23.0455 10.8417 23.0203 10.8396 22.9952ZM13.9776 14.0808C14.4802 14.0808 14.8877 14.4878 14.8877 14.9899V21.0505C14.8877 21.5526 14.4802 21.9596 13.9776 21.9596C13.4749 21.9596 13.0675 21.5526 13.0675 21.0505V14.9899C13.0675 14.4878 13.4749 14.0808 13.9776 14.0808ZM18.9327 14.9899C18.9327 14.4878 18.5252 14.0808 18.0226 14.0808C17.5199 14.0808 17.1125 14.4878 17.1125 14.9899V21.0505C17.1125 21.5526 17.5199 21.9596 18.0226 21.9596C18.5252 21.9596 18.9327 21.5526 18.9327 21.0505V14.9899Z"
                          fill="#1D2A64"
                        />
                      </svg>
                    </div>
                  )}
                </div>
              )
            })}
          </div>
          <button
            type="button"
            className={styles.addBtn}
            onClick={() => setModal(!modal)}
          >
            <img src={add} alt="icon" />
            Add payment method
          </button>
          <div className={styles.option}>
            <Typography component="h3" variant="label" className={styles.title}>
              Billing History
            </Typography>
            <Typography
              component="p"
              variant="smallText"
              className={styles.paymentText}
            >
              Here you can see the list of all your previous payments.
            </Typography>
            <div className={styles.billingHistory__wrapper}>
              <div className={styles.billingHistory__title}>
                <Typography
                  component="h3"
                  variant="label"
                  className={styles.title}
                >
                  Amount
                </Typography>
                <Typography
                  component="h3"
                  variant="label"
                  className={styles.title}
                >
                  Status
                </Typography>
                <Typography
                  component="h3"
                  variant="label"
                  className={styles.title}
                >
                  Date
                </Typography>
                <Typography
                  component="h3"
                  variant="label"
                  className={styles.title}
                />
              </div>
              {payments.invoices?.map((e, idx) => {
                return (
                  <div
                    key={idx + 'f'}
                    className={styles.billingHistory__itemWrapper}
                  >
                    <Typography
                      component="h3"
                      variant="label"
                      className={styles.title}
                    >
                      {'$' + e.amount_paid / 100}
                    </Typography>
                    <Typography
                      component="h3"
                      variant="label"
                      className={styles.title}
                    >
                      {e.status == "paid"
                        ? 'completed'
                        : 'declined'}
                    </Typography>
                    <Typography
                      component="h3"
                      variant="label"
                      className={styles.title}
                    >
                      {dateFormater(e.created)}
                    </Typography>
                    <div className={styles.billingHistory__invoice}>
                      <Typography
                        component="h3"
                        variant="label"
                        className={styles.title}
                      >
                        Invoice
                      </Typography>
                      <a href={e.invoice_pdf}>
                        <svg
                          width="16"
                          height="16"
                          viewBox="0 0 16 16"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <g clip-path="url(#clip0_3157_91269)">
                            <path
                              d="M1 7.42503L1 13.4988C1 14.3327 1.75466 15.0001 2.66547 15.0001H13.3471C14.2564 15.0034 14.9964 14.3311 14.9998 13.4988V7.42503C14.9976 6.58883 14.2579 5.91155 13.3445 5.9093L11.9034 5.9093C11.6752 5.9093 11.4903 6.07862 11.4903 6.28744C11.4903 6.49625 11.6752 6.66545 11.9034 6.66545L13.3445 6.66545C13.8019 6.66723 14.1722 7.00634 14.1738 7.42515V13.5025C14.1685 13.917 13.7972 14.2489 13.3445 14.2439H2.66547C2.21007 14.2439 1.82596 13.9158 1.82596 13.4988L1.82596 7.42503C1.83254 7.00457 2.20619 6.66652 2.66547 6.66545H4.13734C4.36543 6.66545 4.55025 6.49625 4.55025 6.28744C4.55025 6.07862 4.36543 5.9093 4.13734 5.9093H2.66547C1.75466 5.9093 1 6.59108 1 7.42503Z"
                              fill="#1D2A64"
                              stroke="#1D2A64"
                              stroke-width="0.8"
                              stroke-linejoin="round"
                            />
                            <path
                              d="M8.82379 1.87503C8.82379 1.39178 8.43204 1.00003 7.94879 1.00003C7.46554 1.00003 7.07379 1.39178 7.07379 1.87503L7.07379 9.13808L5.8551 7.91938C5.51339 7.57768 4.95937 7.57768 4.61766 7.91939C4.27595 8.26109 4.27595 8.81511 4.61766 9.15682L7.24158 11.7807C7.63211 12.1713 8.26527 12.1713 8.6558 11.7807L11.2797 9.15682C11.6214 8.81511 11.6214 8.2611 11.2797 7.91939C10.938 7.57768 10.384 7.57768 10.0423 7.91939L8.82379 9.13788L8.82379 1.87503Z"
                              fill="#1D2A64"
                            />
                          </g>
                          <defs>
                            <clipPath id="clip0_3157_91269">
                              <rect
                                width="16"
                                height="16"
                                fill="white"
                                transform="translate(0 16) rotate(-90)"
                              />
                            </clipPath>
                          </defs>
                        </svg>
                      </a>
                    </div>
                  </div>
                )
              })}
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default Subscriptions
