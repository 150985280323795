import { Route, Switch } from "react-router-dom";
import Typography from "../../components/ui/typography";

import styles from "./settings.module.sass";
import Sidebar from "./src/components/sidebar";
import Categories from "./src/screens/categories";
import General from "./src/screens/general";
import Notifications from "./src/screens/notifications";
import Support from "./src/screens/support";
import Subscriptions from "./src/screens/subscriptions";
import Security from "./src/screens/security-login";
import SharedTimelines from "./src/screens/shared-timelines/SharedTimelines";
import useMobile from "../../hooks/useMobile"
// import AddPaymentMethod from "./src/screens/subscriptions/payment-method/add-payment-method/AddPaymentMethod";

const Settings = () => {
  const isMobile = useMobile(428)

  return (
    <div className={styles.wrapper}>
      <header>
        <Typography component="h1" variant="pageTitle">
          Settings
        </Typography>
      </header>
      <div className={styles.container}>
        {!isMobile && <Sidebar />}
        <div className={styles.settings__wrapper}>
          <Switch>
            <Route path={`/settings/general/`}>
              <General />
            </Route>
            <Route path={`/settings/categories`}>
              <Categories />
            </Route>
            <Route path={`/settings/shared-timelines`}>
              <SharedTimelines />
            </Route>
            <Route path={`/settings/notifications`}>
              <Notifications />
            </Route>
            <Route path={`/settings/support`}>
              <Support />
            </Route>
            <Route path={`/settings/subscriptions`}>
              <Subscriptions />
            </Route>
            {/* <Route path={`/settings/add-payment-method`}>
              <AddPaymentMethod />
            </Route> */}
            <Route path={`/settings/security`}>
              <Security />
            </Route>
          </Switch>
        </div>
      </div>
    </div>
  );
};

export default Settings;
