import { set } from "date-fns";
import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useParams } from "react-router";
import useMobile from "../../hooks/useMobile";
import MannageMembers from "../../screens/mannage-members";
import UpdateEventForm from "../../screens/update-event";
import {
  getEvent,
  eventSelector,
  categoriesSelector,
  deleteState,
} from "../../store/reducers/timelines";

import Event from "../event/Event";
import Modal from "../ui/modal";

import styles from "./event-list.module.sass";

const EventList = ({ children, setCalendarState }) => {
  const [editModal, setEditModal] = React.useState(false);
  const [mannageModal, setMannageModal] = React.useState(false);

  const event = useSelector(eventSelector);

  const [activeEvent, setActiveEvent] = React.useState(null);
  const childrenArray = React.Children.toArray(children);
  const dispatch = useDispatch();
  const categories = useSelector(categoriesSelector);

  const eventRef = useRef(event);
  // const [savedEvent, setEvent] = useState({});

  useEffect(() => {
    eventRef.current = event;
    // setEvent(eventRef.current);
  }, [event]);

  const handleStartEdit = ({ openModal, eventId }) => {
    dispatch(getEvent(eventId));

    setCalendarState(false);
    setEditModal(openModal);
    //setActiveEvent(eventId);
  };

  const handleStartMannge = ({ openModal, eventId }) => {
    setMannageModal(openModal);
    setActiveEvent(eventId);
    dispatch(getEvent(eventId));
  };

  return (
    <>
      <div className={styles.wrapper}>
        {childrenArray.map((child) =>
          React.cloneElement(child, {
            editModal: handleStartEdit,
            mannageModal: handleStartMannge,
          })
        )}
        <Modal
          isOpen={editModal}
          setCalendarState={setCalendarState}
          onClose={() => setEditModal(false)}
          submit={() => {}}
          className={styles.modal__wrapper}
        >
          {(close, disabled) => (
            <div className={styles.wrapper}>
              <div className={styles.modal__header}>
                <h2>Edit Mode</h2>
                <button
                  className={styles.closeButton}
                  type="button"
                  onClick={() => close()}
                >
                  <svg
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M16.6771 7.32289C16.2466 6.89237 15.5486 6.89237 15.1181 7.32289L12 10.441L8.88192 7.32289C8.45141 6.89237 7.7534 6.89237 7.32289 7.32289C6.89237 7.7534 6.89237 8.45141 7.32289 8.88192L10.441 12L7.32289 15.1181C6.89237 15.5486 6.89237 16.2466 7.32289 16.6771C7.7534 17.1076 8.45141 17.1076 8.88192 16.6771L12 13.559L15.1181 16.6771C15.5486 17.1076 16.2466 17.1076 16.6771 16.6771C17.1076 16.2466 17.1076 15.5486 16.6771 15.1181L13.559 12L16.6771 8.88192C17.1076 8.45141 17.1076 7.7534 16.6771 7.32289Z"
                      fill="#1D2A64"
                    />
                  </svg>
                </button>
              </div>
              {eventRef.current && (
                <UpdateEventForm
                  categories={categories ? categories : null}
                  event={eventRef.current}
                  closeModal={close}
                  reset={false}
                  setCalendarState={setCalendarState}
                />
              )}
            </div>
          )}
        </Modal>
        <Modal
          setCalendarState={setCalendarState}
          isOpen={mannageModal}
          onClose={() => setMannageModal(false)}
          submit={() => {}}
          className={styles.modal__wrapper_mannage}
        >
          {(close, disabled) => (
            <div className={styles.wrapper}>
              <div className={styles.modal__header}>
                <h2>Manage Members</h2>
                <button
                  className={styles.closeButton}
                  type="button"
                  onClick={() => close()}
                >
                  <svg
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M16.6771 7.32289C16.2466 6.89237 15.5486 6.89237 15.1181 7.32289L12 10.441L8.88192 7.32289C8.45141 6.89237 7.7534 6.89237 7.32289 7.32289C6.89237 7.7534 6.89237 8.45141 7.32289 8.88192L10.441 12L7.32289 15.1181C6.89237 15.5486 6.89237 16.2466 7.32289 16.6771C7.7534 17.1076 8.45141 17.1076 8.88192 16.6771L12 13.559L15.1181 16.6771C15.5486 17.1076 16.2466 17.1076 16.6771 16.6771C17.1076 16.2466 17.1076 15.5486 16.6771 15.1181L13.559 12L16.6771 8.88192C17.1076 8.45141 17.1076 7.7534 16.6771 7.32289Z"
                      fill="#1D2A64"
                    />
                  </svg>
                </button>
              </div>
              <MannageMembers closeModal={close} eventId={activeEvent} />
            </div>
          )}
        </Modal>
      </div>
    </>
  );
};

export default EventList;

export const EventListSortDate = ({
  title,
  event = null,
  editModal,
  mannageModal,
  setEvent,
  setCategories,
}) => {
  const Title = title.split("_").join(" ");
  const isMobile = useMobile(428);

  if (!event) {
    return null;
  }
  return (
    <div className={styles.list}>
      <div className={styles.header}>
        <div className={styles.header__title}>{Title}</div>
        <div className={styles.header__separator} />
      </div>
      {Object.keys(event).map((e) => {
        const [day, date] = e.split("_");
        return (
          <div className={styles.container} key={e}>
            {!isMobile && (
              <aside className={styles.aside}>
                <div className={styles.day}>{day}</div>
                <div className={styles.date}>{date}</div>
                {/* <div className={styles.now}>today</div> */}
              </aside>
            )}
            <main className={styles.main}>
              {event[e].length > 0 &&
                event[e].map((ev) => {
                  return (
                    <Event
                      setEvent={setEvent}
                      setCategories={setCategories}
                      eventId={ev.id}
                      key={ev.id}
                      event={ev}
                      onOpenEditModal={editModal}
                      onOpenMannageModal={mannageModal}
                    />
                  );
                })}
            </main>
          </div>
        );
      })}
    </div>
  );
};
