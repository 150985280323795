import React from "react";
import ClassNames from "classnames";

import styles from "./inputControl.module.sass";
import image from "./close.svg";
import { ErrorMessage, useField } from "formik";
import Input from "../input";

const PasswordControl = ({
  name,
  type = "password",
  placeholder,
  label,
  className,
  ...props
}) => {
  const [visible, setVisible] = React.useState(false);
  const [field, meta] = useField(name);
  const htmlFor = `${type}-${Math.random()}`;
  return (
    <div
      className={ClassNames(styles.wrapper, className, styles.PhoneControl, {
        [styles.PhoneControl__visible]: visible,
      })}
    >
      <label
        className={ClassNames(styles.label, {
          [styles.label_error]: meta.error && meta.touched,
        })}
        htmlFor={htmlFor}
      >
        {label}
      </label>
      <div
        className={ClassNames(styles.input__container, {
          [styles.error_border]: meta.error && meta.touched,
        })}
      >
        {field.value && (
          <img
            src={image}
            onClick={(e) => {
              setVisible(!visible);
            }}
            alt="pass_input_img"
          />
        )}
        <Input
          className={styles.input}
          id={htmlFor}
          {...field}
          type={visible ? "text" : "password"}
          placeholder={placeholder}
          {...props}
        />
      </div>
      <div className={styles.error}>
        <ErrorMessage name={name} />
      </div>
    </div>
  );
};

export default PasswordControl;
