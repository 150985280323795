import styles from "./friend-events.module.sass";
import { Link } from "react-router-dom";
import FriendEventItem from "./FriendEventItem";
import Typography from "../../../components/ui/typography";
import { friendProfileSelector } from "../../../store/reducers/friends";
import { useSelector } from "react-redux";
const FriendEventsWrapper = ({ events }) => {
  const friendProfile = useSelector(friendProfileSelector);

  if (!events?.length) {
    return null;
  }
  return (
    <div className={styles.eventsComponent}>
      <div className={styles.titleWrapper}>
        <Typography
          className={styles.heroTitle}
          component="h2"
          variant="titleHeavy"
        >
          Entries
        </Typography>
        <Link
          className={styles.seeAll}
          to={{ pathname: `/`, search: `?friend=${friendProfile.id}` }}
        >
          See all
        </Link>
      </div>
      <div className={styles.eventsWrapper}>
        {events.map((e, idx) => (
          <FriendEventItem key={e.id} event={e} />
        ))}
      </div>
    </div>
  );
};
export default FriendEventsWrapper;
