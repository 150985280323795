import classNames from 'classnames'
import { format } from 'date-fns'
import { useDispatch, useSelector } from 'react-redux'
import Paginator from '../../../components/paginator'
import Typography from '../../../components/ui/typography'
import useQuery from '../../../hooks/useQuery'
import { Link } from 'react-router-dom'
import {
  acceptFriend,
  activitySelector,
  getPastNotifications,
  setNotificationAsPast,
  acceptEvent,
} from '../../../store/reducers/activities'

import image from './img.png'

import styles from './notification.module.sass'
import { useEffect } from 'react'
import ClassicButton from '../../../components/ui/buttons/ClassicButtonWithText'

const Notification = () => {
  const query = useQuery()
  const dispatch = useDispatch()
  const activity = useSelector(activitySelector)
  const param = query.get('notification')
  const page = query.get('pageNumber')

  const hidden = !!param && param !== 'new'

  useEffect(() => {
    if (hidden) {
      dispatch(getPastNotifications({ page }))
    }
  }, [dispatch, hidden, page])

  // updated if update param TODO:
  const friendsInvite = activity.friends

  const items = param ? activity[param] || [] : activity['new']
  console.log('Notification ~ items', items)

  if (!items) {
    return null
  }

  if (param === 'friend' && !items) {
    return null
  }

  const handleAccept = (e) => {
    const value = e.target.value
    dispatch(acceptFriend(value))
  }
  return (
    <div className={styles.wrapper}>
      {param === 'friends' &&
        friendsInvite?.map((item) => {
          return (
            <FriendInviteItem
              hidden={true}
              key={item.id}
              item={item}
              accept={handleAccept}
            />
          )
        })}
      {items?.data &&
        items?.data.map((e) => (
          <NotificationItem
            key={e.id}
            page={page}
            param={param}
            hidden={hidden}
            item={e}
          />
        ))}

      {items?.data?.length && (
        <Paginator
          className={styles.paginate}
          pageNumber={items?.meta?.current_page}
          totalPages={items?.meta?.last_page}
          size={2}
          rightOffset={1}
        />
      )}
    </div>
  )
}

export default Notification

export const NotificationItem = ({ hidden, param, page, item }) => {
  const dispatch = useDispatch()
  if (!item) {
    return null
  }

  const name = item?.friend?.firstName || ''
  const action = item?.message || ''
  const someEvent = item?.event?.title || ''

  const handleAccept = () => {
    dispatch(
      acceptEvent({
        eventId: item.event.id,
        id: item.id,
        param: param,
        page: page,
      }),
    )
  }

  const handleNotification = (id) => {
    dispatch(setNotificationAsPast({ id }))
  }
  return (
    <div className={styles.container}>
      <div
        className={classNames(styles.marker, {
          [styles.marker_hidden]: hidden,
        })}
      />
      {/* Yes, it has to be like that (link>link) */}
      <div className={styles.main}>
        <Link
          onClick={() => handleNotification(item.id)}
          to={{
            pathname: `/friend/${item.friend.id}/event/${item.event.id}`,
          }}
        >
          <Link
            to={{
              pathname: `/friend/${item.friend.id}`,
            }}
          >
            <img
              className={styles.image}
              src={item.friend.profile_photo_path}
              alt="event"
            />
          </Link>
          <div className={styles.content}>
            <h2 className={styles.title__container}>
              <Link
                to={{
                  pathname: `/friend/${item.friend.id}`,
                }}
              >
                <Typography
                  className={styles.title}
                  component="span"
                  variant="label"
                >
                  {name}
                </Typography>
              </Link>
              <Typography className={styles.title} component="span">
                {action}
              </Typography>
              <Typography
                className={styles.title}
                component="span"
                variant="label"
              >
                {someEvent}
              </Typography>
            </h2>

            <Typography
              className={styles.date}
              component="p"
              variant="smallText"
            >
              {format(new Date(item.created_at), 'PPPp')}
            </Typography>
          </div>
        </Link>
        {item?.message.indexOf('invited') === -1 ? null : (
          <div className={styles.action__wrapper}>
            {!item.isAccept ? (
              <button
                onClick={handleAccept}
                className={styles.acceptButton}
                type="button"
              >
                Join
              </button>
            ) : (
              <ClassicButton className={styles.acceptButtonCB} variant="gray">
                Accepted
              </ClassicButton>
            )}
            <div className={styles.arrow} />
          </div>
        )}
      </div>{' '}
    </div>
  )
}
export const FriendInviteItem = ({ hidden, item, accept }) => {
  return (
    <div className={styles.container}>
      <div
        className={classNames(styles.marker, {
          [styles.marker_hidden]: hidden,
        })}
      />
      <div className={styles.main}>
        <img
          className={styles.image}
          src={item.profile_photo_path}
          alt="event"
        />
        <div className={styles.content}>
          <h2 className={styles.title__container}>
            <Typography
              className={styles.title}
              component="span"
              variant="label"
            >{`${item?.firstName??""} ${item?.lastName??""}`}</Typography>
          </h2>
        </div>
        <div className={styles.button__wrapper}>
          <button className={styles.button}>Ignore</button>
          <button className={styles.button} onClick={accept} value={item.id}>
            Accept
          </button>
        </div>
      </div>
    </div>
  )
}
