import React, { useEffect, useRef, useState } from "react";
import styles from "./timelineFilter.module.sass";
import SearchInput from "../../../components/search-input/SearchInput";
import Typography from "../../../components/ui/typography";
import { useSelector } from "react-redux";
import {
  filteredCategories,
  friendInfoSelector,
  getCategories,
  getEvents,
  getFilterCategories,
  getFriendEvents,
  setCategories,
} from "../../../store/reducers/timelines";

import { useLocation } from "react-router-dom";
import { useDispatch } from "react-redux";
import { useGlobalCloseSmiles } from "../../../hooks/useCloseMenu";
import { useHistory } from "react-router";
import useQuery from "../../../hooks/useQuery";
import { push } from "connected-react-router";
import { userSelector } from "../../../store/reducers/user";
const TimelineDropdownFilter = () => {
  const [openModal, setModal] = useState(false);
  const friendInfo = useSelector(friendInfoSelector);
  const [userData, setUserData] = useState({});


  const [inputValue, setInputValue] = useState("");
  const [buttonValue, setButtonValue] = useState("All timelines");
  const query = useQuery();
  const ref = useRef(null);
  const userId = query.get("friend");

  useEffect(() => {
    friendInfo !== null && setUserData(friendInfo);
  }, [friendInfo]);

  useEffect(() => {
    if (userId === null) {
      setUserData(null);
    }
  }, [userId]);

  const location = useLocation();

  const filterItems = useSelector(filteredCategories);

  const user = useSelector(userSelector);

  const inputHandler = (value) => {
    setInputValue(value);
    dispatch(getFilterCategories({ inputValue: inputValue }));
  };
  const setModalHandler = () => {
    setModal(false);
  };
  useGlobalCloseSmiles(ref, setModalHandler, openModal);
  const dispatch = useDispatch();

  const onUserClickHandler = (friend) => {
    setInputValue("");
    const currentUserId = query.get("friend");
    if (currentUserId === `${friend.user.id}`) {
      return;
    }
    const payload = { user_id: friend?.user.id };

    setUserData(friendInfo);
    dispatch(getFriendEvents(payload));
    setModal(false);

    query.set("friend", friend?.user.id);
    dispatch(
      push({
        search: query.toString(),
      })
    );
  };

  const onAllTimelinesHandler = () => {
    setButtonValue("All timeliness");
    setInputValue("");
    setUserData(null);

    if (location.fox !== "") {
      dispatch(
        push({
          fox: "",
        })
      );
      setUserData(null);
      setModal(false);
      dispatch(getEvents());
    }
  };

  const onMyEventsClick = () => {
    setInputValue("");
    setUserData(null);
    const payload = { user_id: user.id };
    query.set("friend", user.id);
    dispatch(
      push({
        search: query.toString(),
      })
    );
    dispatch(getFriendEvents(payload));
    dispatch(getCategories());
    setButtonValue("My timeliness");
  };
  return (
    <div className={styles.modalWrapper}>
      <div
        onClick={() => {
          setModal(!openModal);
        }}
        className={styles.filterButton}
      >
        {userData !== null ? (
          <>
            <div className={styles.btnImg}>
              <img src={userData?.profile_photo_path} alt="miniImg" />
            </div>{" "}
            <span>
              {userData?.firstName} {userData?.lastName}
            </span>
          </>
        ) : (
          <>
            <span>{buttonValue}</span>
            <svg
              width="16"
              height="16"
              viewBox="0 0 16 16"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M4.4714 5.96585C4.21106 6.2262 4.21105 6.64831 4.4714 6.90866C4.73175 7.16901 5.15386 7.16901 5.41421 6.90866L7.77124 4.55163L10.1283 6.90866C10.3886 7.16901 10.8107 7.16901 11.0711 6.90866C11.3314 6.64831 11.3314 6.2262 11.0711 5.96585L8.09799 2.99277C7.91753 2.81231 7.62495 2.81231 7.44449 2.99277L4.4714 5.96585Z"
                fill="white"
              />
              <path
                d="M4.4714 10.0996C4.21106 9.83927 4.21106 9.41715 4.4714 9.15681C4.73175 8.89646 5.15386 8.89646 5.41421 9.15681L7.77124 11.5138L10.1283 9.15681C10.3886 8.89646 10.8107 8.89646 11.0711 9.15681C11.3314 9.41716 11.3314 9.83926 11.0711 10.0996L8.09799 13.0727C7.91753 13.2532 7.62495 13.2532 7.44449 13.0727L4.4714 10.0996Z"
                fill="white"
              />
            </svg>
          </>
        )}
      </div>
      {openModal && (
        <div ref={ref} className={styles.dropDown}>
          <div className={styles.dropDown_input}>
            <SearchInput callback={inputHandler} placeholder="Search friends" />
          </div>

          <div className={styles.dropDown_selectors}>
            <Typography
              onClick={() => {
                onMyEventsClick();
              }}
              component="span"
            >
              My timelines
            </Typography>
            <Typography
              onClick={() => onAllTimelinesHandler()}
              component="span"
            >
              All timelines
            </Typography>
          </div>
          <div className={styles.dropDown_friends}>
            {filterItems?.friendsCategories &&
              filterItems?.friendsCategories?.map((friend) => {
                return (
                  <div
                    onClick={() => onUserClickHandler(friend)}
                    className={styles.groupWrapper}
                    key={friend.user.id}
                  >
                    <div className={styles.userWrapper}>
                      <div className={styles.imageWrapper}>
                        <img
                          src={friend.user.profile_photo_path}
                          alt="profile mini avatar"
                        />
                      </div>
                      <Typography component="span">
                        {friend.user.firstName}{" "}
                        {friend.user.lastName && friend.user.lastName}
                      </Typography>
                    </div>
                  </div>
                );
              })}
          </div>
        </div>
      )}
    </div>
  );
};
export default TimelineDropdownFilter;
