import React from 'react'
import ClassNames from 'classnames'
import { Link } from 'react-router-dom'
import { Formik, Form } from 'formik'
import * as Yup from 'yup'
import styles from './restore-password.module.sass'
import logo from '../../components/assets/images/Logo.svg'
import bgc from './bgc.png'
import Typography from '../../components/ui/typography'
import { InputControl } from '../../components/ui/formik'
import { useDispatch } from 'react-redux'
import { resetPasswordEmail } from '../../store/reducers/auth'

const RestorePassword = (props) => {
  return (
    <div className={styles.container}>
      <div className={styles.wrapper}>
        <div className={styles.form__wrapper}>
          <Link to="/" className={styles.logo__wrapper}>
            <img className={styles.logo} src={logo} alt="" />
          </Link>
          <Typography className={styles.title} component="h1" variant="title">
            Restore Access
          </Typography>
          <Typography
            className={ClassNames(styles.title, styles.title_description)}
          >
            Fill in info to restore access to your account
          </Typography>

          <RestoreForm />
        </div>
        <div className={styles.background}>
          <img className={styles.image} src={bgc} alt="" />
        </div>
      </div>
    </div>
  )
}

export default RestorePassword

const RestoreForm = () => {
  const dispatch = useDispatch()
  return (
    <Formik
      initialValues={{
        email: '',
      }}
      onSubmit={(values, { setSubmitting }) => {
        const { email } = values
        dispatch(resetPasswordEmail({ email, setSubmitting }))
      }}
      validationSchema={Yup.object().shape({
        email: Yup.string().email().required(),
      })}
    >
      {({ values, isSubmitting, dirty, isValid }) => (
        <Form>
          <InputControl
            name="email"
            label="Email Address"
            placeholder="name@example.com"
            className={styles.input}
          />
          <button
            className={styles.button}
            type="submit"
            disabled={!isValid || isSubmitting || !dirty}
          >
            Send Restore Link
          </button>
        </Form>
      )}
    </Formik>
  )
}
