import hexToRgba from 'hex-to-rgba'
import React from 'react'
import styles from './description.module.sass'
import Typography from '../ui/typography/Typography'
import { format } from 'date-fns/esm'
import MoreButton from '../more-button'
import Modal from '../ui/modal'
import MannageMembers from '../../screens/mannage-members'
import UpdateEventForm from '../../screens/update-event'
import { useParams } from 'react-router-dom'
import { useSelector } from 'react-redux'
import { userSelector } from '../../store/reducers/user'
import Linkify from 'linkify-react'
import { parse } from 'date-fns'

const EventDescription = ({ categories, event }) => {
  const [editModal, setEditModal] = React.useState(false)
  const [mannageModal, setMannageModal] = React.useState(false)
  const { eventId, id } = useParams()
  const user = useSelector(userSelector)

  const onEdit = () => {
    setEditModal(true)
  }

  const onMannage = () => {
    setMannageModal(true)
  }

  if (!event) {
    return null
  }

  const { category } = event
  const { additionalCategory } = event

  const options = {
    /*...*/
  }
  const content = event.description

  const startDay = new Date(
    new Date(event.start_date).valueOf() +
      new Date(event.start_date).getTimezoneOffset() * 60 * 1000,
  )
  const endDay = new Date(
    new Date(event.end_date).valueOf() +
      new Date(event.end_date).getTimezoneOffset() * 60 * 1000,
  )

  return (
    <section className={styles.eventWrapper}>
      {user?.id !== parseInt(id) && (
        <div className={styles.ownerContainer}>
          <div className={styles.ownerContainer_image}>
            <img src={event?.owner.profile_photo_path} alt="" />
          </div>
          <Typography component="h3" variant="smallTextHeavy">
            {event?.owner.firstName} {event?.owner.lastName}
          </Typography>
        </div>
      )}

      <div className={styles.titleWrapper}>
        <Typography
          className={styles.evtTitle}
          component="h2"
          variant="pageTitle"
        >
          {event.title}
        </Typography>
        {(event?.owner_id === user?.id || event?.permissions === '0111') && (
          <MoreButton
            fill="#1D2A64"
            eventId={eventId}
            onEdit={onEdit}
            event={event}
            onMannage={onMannage}
          />
        )}
      </div>
      {event.start_date && (
        <Typography
          className={styles.date}
          component="span"
          variant="smallText"
        >
          {`${format(new Date(startDay), 'PPP')} - ${format(
            new Date(endDay),
            'PPP',
          )}`}
        </Typography>
      )}
      <div className={styles.categoryWrapper}>
        {category && (
          <ul className={styles.tagList}>
            <Typography
              component="li"
              variant="smallTextHeavy"
              style={{
                backgroundColor: hexToRgba(category.color + '', 0.1),
                color: category.textColor,
              }}
              className={styles.tagItem}
            >
              {category.name}
            </Typography>
          </ul>
        )}
        {additionalCategory && (
          <ul className={styles.tagList}>
            <Typography
              component="li"
              variant="smallTextHeavy"
              style={{
                backgroundColor: hexToRgba(additionalCategory.color + '', 0.1),
                color: additionalCategory.textColor,
              }}
              className={styles.tagItem}
            >
              {additionalCategory.name}
            </Typography>
          </ul>
        )}
      </div>

      <div className={styles.descriptionWrapper}>
        <Typography
          className={styles.descriptionTitle}
          component="h3"
          variant="smallTextHeavy"
        >
          Description
        </Typography>
        <Linkify tagName="pre" options={options} className={styles.description}>
          {content}
        </Linkify>
        {/* <Typography className={styles.description} component="p">
          {event.description}
        </Typography> */}
      </div>
      <Modal
        isOpen={editModal}
        onClose={() => setEditModal(false)}
        submit={() => {}}
        className={styles.modal__wrapper}
      >
        {(close, disabled) => (
          <div className={styles.wrapper}>
            <div className={styles.modal__header}>
              <h2>Edit Mode</h2>
              <button
                className={styles.closeButton}
                type="button"
                onClick={() => close()}
              >
                <svg
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M16.6771 7.32289C16.2466 6.89237 15.5486 6.89237 15.1181 7.32289L12 10.441L8.88192 7.32289C8.45141 6.89237 7.7534 6.89237 7.32289 7.32289C6.89237 7.7534 6.89237 8.45141 7.32289 8.88192L10.441 12L7.32289 15.1181C6.89237 15.5486 6.89237 16.2466 7.32289 16.6771C7.7534 17.1076 8.45141 17.1076 8.88192 16.6771L12 13.559L15.1181 16.6771C15.5486 17.1076 16.2466 17.1076 16.6771 16.6771C17.1076 16.2466 17.1076 15.5486 16.6771 15.1181L13.559 12L16.6771 8.88192C17.1076 8.45141 17.1076 7.7534 16.6771 7.32289Z"
                    fill="#1D2A64"
                  />
                </svg>
              </button>
            </div>
            <UpdateEventForm
              categories={categories}
              event={event}
              closeModal={close}
              reset={false}
            />
          </div>
        )}
      </Modal>
      <Modal
        isOpen={mannageModal}
        onClose={() => setMannageModal(false)}
        submit={() => {}}
        className={styles.modal__wrapper_mannage}
      >
        {(close, disabled) => (
          <div className={styles.wrapper}>
            <div className={styles.modal__header}>
              <h2>Manage Members</h2>
              <button
                className={styles.closeButton}
                type="button"
                onClick={() => close()}
              >
                <svg
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M16.6771 7.32289C16.2466 6.89237 15.5486 6.89237 15.1181 7.32289L12 10.441L8.88192 7.32289C8.45141 6.89237 7.7534 6.89237 7.32289 7.32289C6.89237 7.7534 6.89237 8.45141 7.32289 8.88192L10.441 12L7.32289 15.1181C6.89237 15.5486 6.89237 16.2466 7.32289 16.6771C7.7534 17.1076 8.45141 17.1076 8.88192 16.6771L12 13.559L15.1181 16.6771C15.5486 17.1076 16.2466 17.1076 16.6771 16.6771C17.1076 16.2466 17.1076 15.5486 16.6771 15.1181L13.559 12L16.6771 8.88192C17.1076 8.45141 17.1076 7.7534 16.6771 7.32289Z"
                    fill="#1D2A64"
                  />
                </svg>
              </button>
            </div>
            <MannageMembers closeModal={close} eventId={eventId} />
          </div>
        )}
      </Modal>
    </section>
  )
}
export default EventDescription
