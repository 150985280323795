import React, { useState } from "react";
import ClassNames from "classnames";
import "./App.sass";
import { ConnectedRouter } from "connected-react-router";
import { Redirect, Route, Switch, useParams } from "react-router";
import { Routes } from "./routes/Routes";
import NoMatch from "./screens/nomatch/NoMatch";
import { authSelector } from "./store/reducers/auth";
import { AppContainer } from "./components/ui/container";
import Sidebar from "./components/sidebar";
import Aside from "./components/aside";
import usePathname from "./hooks/usePathname";
import Alert from "./components/ui/alert";
import { setOnSuccess, setOnError } from "./store/reducers/timelines";
import {
  selectorOnError,
  timelinesLoadSelector,
  timelinesOnAddSuccess,
  timelinesSelector,
} from "./store/reducers/timelines";
import { activitySelector } from "./store/reducers/activities";
import Preloader from "./components/ui/preloader/Preloader";
import TimelineModal from "./components/event/TimelineModal";
import { useDispatch, useSelector } from "react-redux";
import MobileHeader from "./components/mobile-header"

function App({ history }) {
  const dispatch = useDispatch();
  const auth = useSelector(authSelector);
  const [smallContainer, setSmallContainer] = React.useState(false);
  const load = useSelector(timelinesLoadSelector);
  const success = useSelector(timelinesOnAddSuccess);
  const error = useSelector(selectorOnError);

  const notif = useSelector(activitySelector);

  const pathname = useSelector(
    ({
      router: {
        location: { pathname },
      },
    }) => pathname
  );
  const doneHandler = () => {
    dispatch(setOnSuccess(false));
    dispatch(setOnError(false));
  };
  const path = usePathname();

  const mainClassNames = path
    ? "main__full"
    : smallContainer || pathname.includes("/settings")
    ? "main__big"
    : "main";

  const onClickHandler = () => {
    setSmallContainer(!smallContainer);
  };

  return (
    <>
      <ConnectedRouter history={history}>
        <Alert />
        {!auth ? (
          <Switch>
            {Routes.map(
              (e) => e.auth === auth && <Route {...e} key={e.path} />
            )}
            <Route path="*">
              <Redirect to="/login" />
            </Route>
          </Switch>
        ) : (
          <AppContainer>
            {error && (
              <TimelineModal
                onClick={doneHandler}
                message="You can't add same timeline twice"
              />
            )}

            {success && (
              <TimelineModal
                onClick={doneHandler}
                message="Congratulations! You have added an entry to your timeline"
              />
            )}

            {load && <Preloader />}
            
            <Sidebar className="sidebar" notif={notif} />
            <main className={mainClassNames}>
            <MobileHeader />
              <Switch>
                {Routes.map(
                  (e) => e.auth === auth && <Route {...e} key={e.path} />
                )}
                <Route path="*" component={NoMatch} />
              </Switch>
            </main>
            <Aside
              className={ClassNames(
                { userContainer: !smallContainer },
                { userContainer__small: smallContainer }
              )}
              small={smallContainer}
              onClick={onClickHandler}
            />
          </AppContainer>
        )}
      </ConnectedRouter>
    </>
  );
}

export default App;
