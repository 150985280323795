import logo from '../assets/images/Logo.svg'
import hide from '../assets/images/Hide.svg'
import { Link } from 'react-router-dom'
import useMobile from '../../hooks/useMobile'
import { useHistory } from 'react-router-dom'

const MobileHeader = () => {
  const history = useHistory()
  const isMobile = useMobile(428)

  const isMobileProfilePage = history.location.pathname.includes(
    'mobile-profile',
  )
  
  const styles = {
    wrapper: {
      padding: '16px',
      display: 'flex',
      background: '#F7F9FB',
      'justify-content': 'space-between',
    },
    asideImg: {
      transform: isMobileProfilePage ? 'rotate(180deg)' : null,
    },
  }

  return (
    isMobile && (
      <div style={styles.wrapper}>
        <Link to="/">
          <img src={logo} alt="logo" />
        </Link>
        <img
          style={styles.asideImg}
          src={hide}
          alt="logo"
          onClick={() => {
            isMobileProfilePage
              ? history.goBack()
              : history.push('/mobile-profile')
          }}
        />
      </div>
    )
  )
}

export default MobileHeader
