import { push } from "connected-react-router";
import { createAction, handleActions } from "redux-actions";
import { all, call, put, select, take, takeEvery } from "redux-saga/effects";
import { resource } from "../../api";
import { successMessage } from "../../components/ui/alert/alertReducer";
import { getUser } from "./user";

const namespace = `friends`;

const initialState = {
  friendProfile: null,
  friends: null,
  friendsMyFriend: null,
  friendEvent: null,
  isLoad: false,
};
const SET_IS_LOAD = `${namespace}/SET_IS_LOAD`;
const GET_FRIEND_PROFILE = `${namespace}/GET_FRIEND_PROFILE`;
const SET_FRIEND_PROFILE = `${namespace}/SET_FRIEND_PROFILE`;
const RESET_FRIEND_PROFILE = `${namespace}/RESET_FRIEND_PROFILE`;

const SET_FRIENDS = `${namespace}/SET_FRIENDS`;
const GET_FRIENDS = `${namespace}/GET_FRIENDS`;

const REMOVE_FRIEND = `${namespace}/REMOVE_FRIEND`;
const REMOVE_FRIEND_FROM_LIST = `${namespace}/REMOVE_FRIEND_FROM_LIST`;
const SET_FRIENDS_MY_FRIEND = `${namespace}/SET_FRIENDS_MY_FRIEND`;
const GET_FRIENDS_MY_FRIEND = `${namespace}/GET_FRIENDS_MY_FRIEND`;

const INVITE_FRIEND = `${namespace}/INVITE_FRIEND`;

const SET_FRIEND_EVENT = `${namespace}/SET_FRIEND_EVENT`;
const GET_FRIEND_EVENT = `${namespace}/GET_FRIEND_EVENT`;

export const getFriendProfile = createAction(GET_FRIEND_PROFILE);
export const setFriendProfile = createAction(SET_FRIEND_PROFILE);
export const resetFriendProfile = createAction(RESET_FRIEND_PROFILE);

export const inviteFriend = createAction(INVITE_FRIEND);

export const getFriends = createAction(GET_FRIENDS);
export const setFriends = createAction(SET_FRIENDS);
export const removeFriend = createAction(REMOVE_FRIEND);
export const removeFriendFromList = createAction(REMOVE_FRIEND_FROM_LIST);
export const getFriendsMyFriend = createAction(GET_FRIENDS_MY_FRIEND);
export const setFriendsMyFriend = createAction(SET_FRIENDS_MY_FRIEND);
export const setIsLoad = createAction(SET_IS_LOAD);

export const setFriendEvent = createAction(SET_FRIEND_EVENT);
export const getFriendEvent = createAction(GET_FRIEND_EVENT);

export default handleActions(
  {
    [SET_FRIEND_PROFILE]: (state, { payload }) => ({
      ...state,
      friendProfile: payload,
    }),
    [SET_FRIENDS]: (state, { payload }) => ({ ...state, friends: payload }),
    [SET_FRIENDS_MY_FRIEND]: (state, { payload }) => ({
      ...state,
      friendsMyFriend: payload,
    }),
    [SET_IS_LOAD]: (state, { payload }) => ({ ...state, isLoad: payload }),
    [RESET_FRIEND_PROFILE]: (state) => ({
      ...state,
      friendProfile: initialState.friendProfile,
    }),
    [SET_FRIEND_EVENT]: (state, { payload }) => ({
      ...state,
      friendEvent: payload,
    }),
  },
  initialState
);

export const friendProfileSelector = (state) => state[namespace].friendProfile;
export const friendsSelector = (state) => state[namespace].friends;
export const friendsMyFriendSelector = (state) =>
  state[namespace].friendsMyFriend;
export const friendsLoad = (state) => state[namespace].isLoad;

export const friendEventSelector = (state) => state[namespace].friendEvent;

function* getFriendProfileRequest() {
  while (true) {
    const { payload } = yield take(GET_FRIEND_PROFILE);
    yield put(setIsLoad(true));
    try {
      const {
        data: { data },
      } = yield call(resource.get, `/user/${payload}`);
      yield put(setFriendProfile(data));
    } catch (err) {
      console.log(err);
    } finally {
      yield put(setIsLoad(false));
    }
  }
}

function* inviteFriendRequest() {
  while (true) {
    const { payload } = yield take(INVITE_FRIEND);
    try {
      yield call(resource.post, `/cabinet/friends/add/${payload}`);
      yield put(successMessage("An invitation has been sent"));
    } catch (err) {
      console.log(err);
    }
  }
}
function* removeFriendRequest() {
  while (true) {
    yield take(REMOVE_FRIEND);
    const friend = yield select(friendProfileSelector);
    console.log(friend);
    try {
      const response = yield call(
        resource.post,
        `/cabinet/friends/unfriend/${friend.id}`
      );
      if (response) {
        yield put(
          successMessage(`${friend.firstName} deleted from you friends`)
        );
        yield put(getUser());
        yield put(push("/friends/all"));
      }
    } catch (err) {
      console.log(err);
    }
  }
}
function* removeFriendList() {
  while (true) {
    const friend = yield take(REMOVE_FRIEND_FROM_LIST);

    try {
      const response = yield call(
        resource.post,
        `/cabinet/friends/unfriend/${friend.payload.id}`
      );
      if (response) {
        yield put(
          successMessage(`${friend.payload.name?? "" + friend.payload.lastName ?? ""} has been removed as a Connection.`)
        );
        yield put(getFriends());
        yield put(getUser());
        yield put(push("/friends/all"));
      }
    } catch (err) {
      console.log(err);
    }
  }
}

function* getFriendsRequest() {
  try {
    const {
      data: { data },
    } = yield call(resource.get, `/cabinet/friends`);
    yield put(setFriends(data));
  } catch (err) {
    console.log(err);
  }
}
function* getFriendsMyFriendRequest({ payload }) {
  try {
    const {
      data: { data },
    } = yield call(resource.get, `/user/${payload}/friends`);
    yield put(setFriendsMyFriend(data.user_friends));
  } catch (err) {
    console.log(err);
  }
}

function* getFriendEventRequest({ payload }) {
  try {
    const {
      data: { data },
    } = yield call(
      resource.get,
      `/user/${payload.id}/events/${payload.eventId}`
    );

    yield put(setFriendEvent(data));
  } catch (err) {
    console.log(err);
  }
}
export function* friendsSaga() {
  yield all([
    getFriendProfileRequest(),
    inviteFriendRequest(),
    takeEvery(GET_FRIENDS, getFriendsRequest),
    takeEvery(GET_FRIENDS_MY_FRIEND, getFriendsMyFriendRequest),
    takeEvery(GET_FRIEND_EVENT, getFriendEventRequest),
    removeFriendRequest(),
    removeFriendList(),
  ]);
}
