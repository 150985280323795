import { format } from 'date-fns'
import {
  all,
  call,
  fork,
  put,
  select,
  take,
  delay,
  takeEvery,
  takeLatest,
} from 'redux-saga/effects'
import { createAction, handleActions } from 'redux-actions'
import { resource } from '../../api'
import { setFriendEvent } from './friends'
import { da } from 'date-fns/locale'
import { dangerMessage } from '../../components/ui/alert/alertReducer'
import { getComments } from './comments'
const namespace = `timelines`

const GET_CATEGORIES = `${namespace}/GET_CATEGORIES`
const SET_CATEGORIES = `${namespace}/SET_CATEGORIES`
const GET_EVENTS = `${namespace}/GET_EVENTS`
const SET_EVENTS = `${namespace}/SET_EVENTS`
/////////////////////////////////////////////

const GET_FRIEND_EVENTS = `${namespace}/GET_FRIEND_EVENTS`
const SET_FRIEND_INFO = `${namespace}/SET_FRIEND_INFO`
/////////////////////////////////////////
const CREATE_EVENT = `${namespace}/CREATE_EVENT`

const SET_MEDIA = `${namespace}/SET_MEDIA`
const SET_COVER_IMAGE = `${namespace}/SET_COVER_IMAGE`
const DELETE_IMAGE = `${namespace}/DELETE_IMAGE`
const CREATE_IMAGE = `${namespace}/CREATE_IMAGE`

const SUBMIT_UPLOAD_FORM = `${namespace}/SUBMIT_UPLOAD_FORM`

const SET_EVENT = `${namespace}/SET_EVENT`
const GET_EVENT = `${namespace}/GET_EVENT`
const RESET_EVENT = `${namespace}/RESET_EVENT`

const GET_PERMISSIONS = `${namespace}/GET_PERMISSIONS`
const SET_PERMISSIONS = `${namespace}/SET_PERMISSIONS`

const GET_MEMBERS = `${namespace}/GET_MEMBERS`
const SET_MEMBERS = `${namespace}/SET_MEMBERS`
const RESET_MEMBERS = `${namespace}/RESET_MEMBERS`

const INVITE_USER = `${namespace}/INVITE_USER`

const GET_GOOGLE_IMAGE = `${namespace}/GET_GOOGLE_IMAGE`
const SET_GOOGLE_IMAGE = `${namespace}/SET_GOOGLE_IMAGE`
const GET_FILTER_CATEGORIES = `${namespace}/GET_FILTER_CATEGORIES`
const SET_FILTER_CATEGORIES = `${namespace}/SET_FILTER_CATEGORIES`
const LOAD = `${namespace}/LOAD`
const ON_SUCCESS = `${namespace}/ON_SUCCESS`

const SAVE_TIMELINE = `${namespace}/SAVE_TIMELINE`
const ON_ERROR = `${namespace}/ON_ERROR`

const CONFIRM_DELETE = `${namespace}/CONFIRM_DELETE`
const DELETE_EVENT = `${namespace}/DELETE_EVENT`
const GET_EVENT_ID = `${namespace}/GET_EVENT_ID`
const GET_FRIEND_EVENT = `friends/GET_FRIEND_EVENT`
const SET_CATEGORIES_DEFAULT = `friends/SET_CATEGORIES_DEFAULT`
const SET_CATEGORIES_CUSTOM = `friends/SET_CATEGORIES_CUSTOM`

export const getCategories = createAction(GET_CATEGORIES)
export const setCategoriesDefault = createAction(SET_CATEGORIES_DEFAULT)
export const setCategoriesCustom = createAction(SET_CATEGORIES_CUSTOM)

export const setCategories = createAction(SET_CATEGORIES)

export const getFilterCategories = createAction(GET_FILTER_CATEGORIES)
export const setFilterCategories = createAction(SET_FILTER_CATEGORIES)

export const createEvent = createAction(CREATE_EVENT)

export const getEvents = createAction(GET_EVENTS)
export const setEvents = createAction(SET_EVENTS)

export const getEvent = createAction(GET_EVENT)
export const setEvent = createAction(SET_EVENT)
export const resetEvent = createAction(RESET_EVENT)
export const setCoverImage = createAction(SET_COVER_IMAGE)
export const setMedia = createAction(SET_MEDIA)
export const deleteImage = createAction(DELETE_IMAGE)
export const createImage = createAction(CREATE_IMAGE)

// GET FRIEND EVENTS *************************

export const getFriendEvents = createAction(GET_FRIEND_EVENTS)
export const setFriendInfo = createAction(SET_FRIEND_INFO)
////////////////////////////////////////////////////
export const submitUploadForm = createAction(SUBMIT_UPLOAD_FORM)

export const getPermissions = createAction(GET_PERMISSIONS)
export const setPermissions = createAction(SET_PERMISSIONS)

export const getMembers = createAction(GET_MEMBERS)
export const setMembers = createAction(SET_MEMBERS)
export const resetMembers = createAction(RESET_MEMBERS)

export const inviteUser = createAction(INVITE_USER)

export const getGoogleImage = createAction(GET_GOOGLE_IMAGE)
export const setGoogleImage = createAction(SET_GOOGLE_IMAGE)

export const setLoad = createAction(LOAD)
export const setOnSuccess = createAction(ON_SUCCESS)
export const setOnError = createAction(ON_ERROR)
export const saveTimelineToMines = createAction(SAVE_TIMELINE)

export const confirmDeleteEvent = createAction(CONFIRM_DELETE)
export const deleteEvent = createAction(DELETE_EVENT)
export const getEventId = createAction(GET_EVENT_ID)

export const getFriendEvent = createAction(GET_FRIEND_EVENT)

const initialState = {
  categories: null,
  events: null,
  event: null,
  media: null,
  filterCategories: null,
  googlePhotos: null,
  permissions: null,
  members: null,
  load: false,
  onSuccess: false,
  onError: false,
  friendInfo: null,
  confirmDelete: false,
  eventId: NaN,
}

export default handleActions(
  {
    [SET_CATEGORIES]: (state, { payload }) => ({
      ...state,
      categories: payload,
    }),
    [SET_EVENTS]: (state, { payload }) => ({
      ...state,
      events: payload,
    }),
    [SET_FRIEND_INFO]: (state, { payload }) => ({
      ...state,
      friendInfo: payload,
    }),
    [SET_MEDIA]: (state, { payload }) => ({
      ...state,
      media: payload,
    }),
    [RESET_EVENT]: (state) => ({
      ...state,
      event: initialState.event,
      media: initialState.media,
    }),
    [SET_EVENT]: (state, { payload }) => ({ ...state, event: payload }),
    [SET_PERMISSIONS]: (state, { payload }) => ({
      ...state,
      permissions: payload,
    }),
    [SET_MEMBERS]: (state, { payload }) => ({ ...state, members: payload }),
    [SET_FILTER_CATEGORIES]: (state, { payload }) => ({
      ...state,
      filterCategories: payload,
    }),
    [RESET_MEMBERS]: (state) => ({ ...state, members: initialState.members }),
    [LOAD]: (state, { payload }) => ({ ...state, load: payload }),
    [ON_SUCCESS]: (state, { payload }) => ({ ...state, onSuccess: payload }),
    [ON_ERROR]: (state, { payload }) => ({ ...state, onError: payload }),
    [CONFIRM_DELETE]: (state, { payload }) => ({
      ...state,
      confirmDelete: payload,
    }),
    [GET_EVENT_ID]: (state, { payload }) => ({ ...state, eventId: payload }),
  },
  initialState,
)

export const timelinesSelector = (state) => state[namespace]
export const friendInfoSelector = (state) => state[namespace].friendInfo
export const timelinesPermissionsSelector = (state) =>
  state[namespace].permissions
export const timelinesMembersSelector = (state) => state[namespace].members
export const timelinesLoadSelector = (state) => state[namespace].load
export const filteredCategories = (state) => state[namespace].filterCategories
export const timelinesOnAddSuccess = (state) => state[namespace].onSuccess
export const selectorOnError = (state) => state[namespace].onError
export const eventSelector = (state) => state[namespace].event
export const categoriesSelector = (state) => state[namespace].categories
export const deleteState = (state) => state[namespace].confirmDelete
export const eventId = (state) => state[namespace].eventId
function* getTimelinesRequest() {
  while (true) {
    yield take(GET_CATEGORIES)
    yield put(setLoad(true))
    try {
      const { data } = yield call(resource.get, `/cabinet/timeline/category`)

      yield put(setCategories(data))
      yield put(setLoad(false))
    } catch (err) {
      console.log(err)
    }
  }
}
function* setDefaultCategoriesReqest() {
  while (true) {
    const { payload } = yield take(SET_CATEGORIES_DEFAULT)
    yield put(setLoad(true))
    try {
      const response = yield call(
        resource.post,
        `/cabinet/timeline/category/sort`,
        payload,
      )
      const { data } = response
      yield put(setCategories(data))
      yield put(setLoad(false))
    } catch (err) {
      console.log(err)
    } finally {
      yield put(setLoad(false))
    }
  }
}
function* setCustomCategoriesReqest() {
  while (true) {
    const { payload } = yield take(SET_CATEGORIES_CUSTOM)
    yield put(setLoad(true))
    try {
      const response = yield call(
        resource.post,
        `/cabinet/timeline/category/sort`,
        payload,
      )
      const { data } = response
      yield put(setCategories(data))
      yield put(setLoad(false))
    } catch (err) {
      console.log(err)
    } finally {
      yield put(setLoad(false))
    }
  }
}
function* sendSaveTimelineRequest({ payload }) {
  try {
    const { data } = yield call(
      resource.get,
      `/cabinet/event/${payload.payload}/pin`,
    )

    console.log(data, 'DATA')
    yield put(setLoad(false))

    yield put(setOnSuccess(true))
  } catch (err) {
    yield put(setOnError(true))
    console.log(err)
  }
}
function* deleteEventRequest({ payload }) {
  const { eventId } = payload

  try {
    const {
      data: { data },
    } = yield call(resource.delete, `cabinet/event/${eventId}`)
    console.log(data, 'DATA')
    yield put(setEvents(data))
  } catch (err) {
    console.log(err)
  } finally {
    yield put(confirmDeleteEvent(false))
  }
}
function* createEventRequest() {
  while (true) {
    const { payload } = yield take(CREATE_EVENT)
    console.log(payload, 'THIS IS PAYLOAD')
    const data = new FormData()

    const {
      timeline,
      calendar,
      eventTitle,
      location,
      description,
      allowMedia,
      highlight,
      allowFriends,
      preferences,
      images,
    } = payload.values

    data.append('step2[start_date]', format(calendar.startDate, 'yyyy-MM-dd'))
    // data.append("step1[name]", name);
    data.append(
      'step2[end_date]',
      calendar.endDate
        ? format(calendar.endDate, 'yyyy-MM-dd')
        : format(calendar.startDate, 'yyyy-MM-dd'),
    )

    data.append('step3[title]', eventTitle)
    location.length > 0 && data.append('step3[location]', location)
    description.length > 0 && data.append('step3[description]', description)
    data.append('step4[preferences]', preferences)
    data.append('step4[enableMedia]', allowMedia)
    data.append('step4[highlight]', highlight)
    data.append('step4[allow_friends_media]', allowFriends)

    const cover = images.find((e) => e.cover)
    const otherImages = images.filter((e) => !e.cover)
    if (cover) {
      const imageArray = [cover, ...otherImages]

      imageArray?.forEach((f) => {
        data.append('step5[files][]', f.file)
      })
    }

    yield put(setLoad(true))
    try {
      if (timeline.length === 1) {
        const response = yield call(
          resource.post,
          `/cabinet/event/create/${timeline[0]}`,
          data,
          {
            headers: {
              'Content-Type': 'multipart/form-data',
            },
          },
        )
        if (response.status === 200) {
          payload.push(response.data.data.owner_id, response.data.data.id)
        }
      } else {
        const response = yield call(
          resource.post,
          `/cabinet/event/create/${timeline[0]}/${timeline[1]}`,
          data,
          {
            headers: {
              'Content-Type': 'multipart/form-data',
            },
          },
        )
        if (response.status === 200) {
          payload.push(response.data.data.owner_id, response.data.data.id)
        }
      }
    } catch (err) {
      console.log(err)
    } finally {
      yield put(setLoad(false))
    }
  }
}

function* getEventsRequest() {
  while (true) {
    const { payload } = yield take(GET_EVENTS)
    yield put(setLoad(true))
    try {
      // const query = yield select(({ router: { location } }) => location.query);
      // console.log(payload, "QUERY");
      const {
        data: { data },
      } = yield call(resource.post, `/cabinet/event`, {
        ...payload,
      })
      yield put(setEvents(data))
    } catch (err) {
      console.log(err)
    } finally {
      yield put(setLoad(false))
    }
  }
}

function* getFriendEventsRequest({ payload }) {
  yield put(setLoad(true))

  try {
    // const query = yield select(({ router: { location } }) => location.query);
    // console.log(payload, "QUERY");
    const {
      data: { data },
    } = yield call(resource.post, `/cabinet/event/search`, {
      ...payload,
    })

    yield put(setEvents(data.events))
    yield put(setCategories(data.userCategories))
    yield put(setFriendInfo(data.userProfile))
  } catch (err) {
    console.log(err)
  } finally {
    yield put(setLoad(false))
  }
}
function* getEventRequest({ payload }) {
  try {
    const {
      data: { data },
    } = yield call(resource.get, `/cabinet/event/${payload}`)
    yield put(setEvent(data))
    if (data.media) {
      console.log('MEDIA')
      yield put(setMedia(data.media))
    }
  } catch (err) {
    console.log(err)
  }
}

function* setCoverImageRequest() {
  while (true) {
    const {
      payload: { eventId, imageId },
    } = yield take(SET_COVER_IMAGE)
    try {
      const {
        data: { data },
      } = yield call(
        resource.post,
        `/cabinet/event/${eventId}/media/${imageId}/setCover`,
      )

      if (data.media) {
        yield put(setMedia(data.media))
        yield put(getEvents())
      }
    } catch (err) {
      console.log(err)
    }
  }
}

function* deleteImageRequest() {
  while (true) {
    const {
      payload: { eventId, imageId },
    } = yield take(DELETE_IMAGE)
    try {
      const {
        data: { data },
      } = yield call(
        resource.delete,
        `/cabinet/event/${eventId}/media/${imageId}`,
      )
      if (data.media) {
        console.log(data, 'resp')
        yield put(setMedia(data.media))
        yield put(setFriendEvent(data))
      }
    } catch (err) {
      const message = err?.response?.data?.message
      if (message) {
        yield put(dangerMessage(message))
      }
    }
  }
}

function* createImageRequest() {
  while (true) {
    const {
      payload: { event_id, form },
    } = yield take(CREATE_IMAGE)
    yield put(setLoad(true))
    try {
      const {
        data: { data },
      } = yield call(resource.post, `/cabinet/event/${event_id}/media`, form)
      if (data.media) {
        yield put(setMedia(data.media))
        yield put(setFriendEvent(data))
        yield put(getEvents())
      }
    } catch (err) {
      console.log(err)
    } finally {
      yield put(setLoad(false))
    }
  }
}

function* submitUploadFormRequest() {
  while (true) {
    const {
      payload: { form, eventId, closeModal, id },
    } = yield take(SUBMIT_UPLOAD_FORM)
    try {
      const {
        data: { data },
      } = yield call(resource.post, `/cabinet/event/${eventId}`, form)
      if (data) {
        yield put(getEvents())
        yield put(getComments(eventId))
        closeModal()
        yield put(getFriendEvent({ eventId: eventId, id: id }))
      }
    } catch (err) {
      console.log(err)
    }
  }
}

function* getMembersRequest({ payload }) {
  const permissions = yield select(timelinesPermissionsSelector)
  if (!permissions) {
    yield fork(getPermissionsRequest)
  }
  yield put(setLoad(true))
  try {
    const {
      data: { data },
    } = yield call(resource.get, `/event/${payload}/members`)

    yield put(setMembers(data))
  } catch (err) {
    console.log(err)
  } finally {
    yield put(setLoad(false))
  }
}

function* getPermissionsRequest() {
  try {
    const {
      data: { data },
    } = yield call(resource.options, `/event/permissions`)
    yield put(setPermissions(data))
  } catch (err) {
    console.log(err)
  }
}

function* inviteUserRequest() {
  while (true) {
    const {
      payload: { eventId, userId, permissions },
    } = yield take(INVITE_USER)
    yield put(setLoad(true))
    try {
      const response = yield call(
        resource.put,
        `/cabinet/event/${eventId}/invite/${userId}`,
        permissions,
      )
      if (response) {
        yield put(getMembers(eventId))
      }
    } catch (err) {
      console.log(err)
    } finally {
      yield put(setLoad(false))
    }
  }
}
function* getFilterCategoriesReq({ payload }) {
  const { inputValue } = payload

  yield delay(300)
  try {
    const { data } = yield call(
      resource.get,
      `/getCategories?username=${inputValue}`,
    )

    yield put(setFilterCategories(data))
  } catch (err) {
    console.log(err)
  }
}

export function* timelinesSagas() {
  yield all([
    getTimelinesRequest(),
    createEventRequest(),
    getEventsRequest(),
    setCoverImageRequest(),
    deleteImageRequest(),
    createImageRequest(),
    submitUploadFormRequest(),
    takeEvery(GET_EVENT, getEventRequest),
    takeEvery(GET_MEMBERS, getMembersRequest),
    takeEvery(GET_PERMISSIONS, getPermissionsRequest),
    takeLatest(GET_FILTER_CATEGORIES, getFilterCategoriesReq),
    takeEvery(GET_FRIEND_EVENTS, getFriendEventsRequest),
    inviteUserRequest(),
    takeLatest(SAVE_TIMELINE, sendSaveTimelineRequest),
    takeLatest(DELETE_EVENT, deleteEventRequest),
    setDefaultCategoriesReqest(),
    setCustomCategoriesReqest(),
  ])
}
