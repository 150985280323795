import * as calendar from "./utils";
import classNames from "classnames";
import { format, isBefore, isPast, isWithinInterval } from "date-fns";
import React, { useRef, useState } from "react";
import { getDates } from "../../../store/reducers/combine-search";
import styles from "./range-calendar.module.sass";
import Typography from "../typography";
import { useField } from "formik";
import { useEffect } from "react";
import { useDispatch } from "react-redux";
import { useGlobalCloseSmiles } from "../../../hooks/useCloseMenu";
const weekDays = calendar.getWeekDays();

const RangeCalendar = React.forwardRef(
  (
    {
      name,
      setDates,
      handleCustom,
      fromSearch,
      fromTimeline,
      handleOpenCalendar,
      handleCalendarClose,
      timeLinePreSettings,
      dates,
    },
    ref
  ) => {
    const now = new Date();
    const dispatch = useDispatch();
    const [field] = useField(name);

    const [date, setDate] = useState(new Date());
    const [seconDate, setSeconDate] = useState(
      new Date(now.setMonth(now.getMonth() + 1))
    );
    // console.log(fromTimeline);
    const [isOpen, setIsOpen] = useState(
      fromSearch ? fromSearch : fromTimeline
    );

    useEffect(() => {
      setIsOpen(fromTimeline);
      fromSearch && setIsOpen(true);
    }, [fromTimeline, fromSearch]);

    const startDate = field.value?.startDate
      ? format(field.value?.startDate, "P")
      : "mm/dd/yyyy";
    const endDate = field.value?.endDate
      ? format(field.value?.endDate, "P")
      : "mm/dd/yyyy";

    useEffect(() => {
      if (
        field.value.startDate !== null &&
        field.value.endDate !== null &&
        fromSearch
      ) {
        setDates(field.value.startDate, field.value.endDate);
      }
      if (
        field.value.startDate !== "" &&
        field.value.endDate !== "" &&
        timeLinePreSettings
      ) {
        handleCalendarClose();
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [field.value]);

    return (
      <div
        ref={ref}
        className={classNames(styles.range__calendar, {
          [styles.range__reverse]: fromSearch,
        })}
      >
        <div onClick={() => handleOpenCalendar()} className={styles.header}>
          <div className={styles.range__value}>
            <span className={styles.label}>from</span>
            <Typography component="div" className={styles.pseudoInput}>
              {startDate}
            </Typography>
          </div>
          <div className={styles.range__value}>
            <span className={styles.label}>to</span>
            <Typography component="div" className={styles.pseudoInput}>
              {endDate}
            </Typography>
          </div>
        </div>
        {isOpen && (
          <div
            className={classNames(styles.container, {
              [styles.noBorder]: fromSearch,
              [styles.paddingTop]: fromTimeline,
            })}
          >
            <Calendar
              handleCustom={handleCustom}
              setDates={setDates}
              startDate={date}
              dateFromRadio={dates}
              onChangeLeafer={setDate}
              name={name}
              fromSearch={fromSearch}
            />
            <Calendar
              handleCustom={handleCustom}
              setDates={setDates}
              startDate={seconDate}
              dateFromRadio={dates}
              onChangeLeafer={setSeconDate}
              name={name}
              fromSearch={fromSearch}
            />
          </div>
        )}
      </div>
    );
  }
);

export default RangeCalendar;

const Calendar = ({
  startDate,
  dateFromRadio,
  onChangeLeafer,
  handleCustom,
  fromSearch,
  name,
}) => {
  const [field] = useField(name);

  const [, , helpersStart] = useField(`${name}.startDate`);
  const [, , helpersEnd] = useField(`${name}.endDate`);
  const start = field.value.startDate;
  const end = field.value.endDate;

  useEffect(() => {
    if (dateFromRadio) {
      helpersStart.setValue(dateFromRadio.from);
      helpersEnd.setValue(dateFromRadio.till);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dateFromRadio]);

  const items = calendar.getMonthData(
    startDate.getFullYear(),
    startDate.getMonth()
  );

  const onChangeMonth = (e) => {
    const { currentTarget } = e;
    const variant = +currentTarget.getAttribute("data-variant");
    const date = new Date(startDate);
    date.setMonth(date.getMonth() + variant);
    onChangeLeafer(date);
  };

  const onRangeChange = (e) => {
    const { currentTarget } = e;
    const value = currentTarget.getAttribute("data-value");
    if (fromSearch) {
      handleCustom(e);
    }

    if (!value) {
      return;
    }
    const date = new Date(Date.parse(value));
    if (!date) return;

    // if (isPast(addDays(date, 1))) {
    //     return
    // }

    if (!start) {
      helpersStart.setValue(date);
    }
    if (start && !end && !isBefore(date, start)) {
      helpersEnd.setValue(date);
    }
    if (start && !end && isBefore(date, start)) {
      helpersEnd.setValue(start);
      helpersStart.setValue(date);
    }
    if (start && end) {
      helpersEnd.setValue("");
      helpersStart.setValue("");
    }
  };
  return (
    <div className={styles.wrapper}>
      <Leafer currentDate={startDate} onChange={onChangeMonth} />
      <div className={classNames(styles.row, styles.title)}>
        {weekDays.map((e) => (
          <div className={styles.col} key={e.valueOf()}>
            {e && format(e, "ccccc")}
          </div>
        ))}
      </div>
      {items.map((item, i) => (
        <div className={classNames(styles.row, styles.row__values)} key={i}>
          {item.map((e, j) => {
            return (
              <div
                key={e.valueOf() + j}
                data-value={e}
                className={classNames(
                  styles.col,
                  styles.value,
                  {
                    [styles.value__range]:
                      start &&
                      end &&
                      isWithinInterval(new Date(e), {
                        start: new Date(start),
                        end: new Date(end),
                      }),
                  },
                  { [styles.value__range_isPast]: isPast(new Date(e)) },
                  {
                    [styles.value__range_now]: calendar.isEqual(
                      new Date(),
                      new Date(e)
                    ),
                  },
                  {
                    [styles.value__range_start]:
                      start && calendar.isEqual(e, start),
                  },
                  {
                    [styles.value__range_end]: end && calendar.isEqual(e, end),
                  },
                  {
                    [styles.value__range_equal]:
                      start && end && calendar.isEqual(start, end),
                  }
                )}
                onClick={onRangeChange}
              >
                {e && format(e, "d")}
              </div>
            );
          })}
        </div>
      ))}
    </div>
  );
};

const Leafer = ({ onChange, currentDate = new Date() }) => {
  return (
    <div className={classNames(styles.leafer)}>
      <button
        className={classNames(styles.leafer__button)}
        data-variant={-1}
        onClick={onChange}
        type="button"
      >
        <svg
          width="6"
          height="10"
          viewBox="0 0 6 10"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M5.70711 0.292893C5.31658 -0.0976311 4.68342 -0.0976311 4.29289 0.292893L0.292893 4.29289C-0.097631 4.68342 -0.097631 5.31658 0.292893 5.70711L4.29289 9.70711C4.68342 10.0976 5.31658 10.0976 5.70711 9.70711C6.09763 9.31658 6.09763 8.68342 5.70711 8.29289L2.41421 5L5.70711 1.70711C6.09763 1.31658 6.09763 0.683417 5.70711 0.292893Z"
            fill="#515970"
          />
        </svg>
      </button>
      <span className={styles.leafer__title}>
        {format(currentDate, "MMMM yyyy")}
      </span>
      <button
        className={styles.leafer__button}
        data-variant={1}
        onClick={onChange}
        type="button"
      >
        <svg
          width="6"
          height="10"
          viewBox="0 0 6 10"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M0.292893 0.292893C0.683417 -0.0976311 1.31658 -0.0976311 1.70711 0.292893L5.70711 4.29289C6.09763 4.68342 6.09763 5.31658 5.70711 5.70711L1.70711 9.70711C1.31658 10.0976 0.683417 10.0976 0.292893 9.70711C-0.0976311 9.31658 -0.0976311 8.68342 0.292893 8.29289L3.58579 5L0.292893 1.70711C-0.0976311 1.31658 -0.0976311 0.683417 0.292893 0.292893Z"
            fill="#515970"
          />
        </svg>
      </button>
    </div>
  );
};
