import React from "react";
import classNames from "classnames";
import styles from "../media-step.module.sass";
import ClassicButton from "../../../../components/ui/buttons/ClassicButtonWithText";

const FileGrid = React.memo(
  ({
    hi,
    selectedPhotos,
    confirmSelections,
    setChooserOpen,
    files,
    getNextPage,
    selectPhoto,
    ...props
  }) => {
    return (
      <div className={styles.fileChooser}>
        <div className={styles.chooserPanel}>
          <ClassicButton
            variant="gray"
            onClick={() => {
              setChooserOpen(false);
            }}
          >
            Cancel
          </ClassicButton>
        </div>
        <div className={styles.fileGrid}>
          {files?.map((singleFile, idx) => {
            return (
              <div
                key={idx + 365}
                onClick={() => selectPhoto(singleFile)}
                className={classNames(styles.photoItem, {
                  [styles.photoItemSelected]:
                    selectedPhotos.findIndex(
                      (item) => item.id === singleFile.id
                    ) > -1,
                })}
              >
                <div className={styles.photoItem__image}>
                  <img alt="preview" src={singleFile.baseUrl} />
                </div>
                <div className={styles.itemName}>{singleFile.filename}</div>
              </div>
            );
          })}
        </div>
        <div className={styles.loadMoreWrapper}>
          <ClassicButton onClick={getNextPage}>Load More</ClassicButton>
        </div>
        <div
          className={classNames(styles.chooserPanel, styles.chooserPanelBottom)}
        >
          <div>
            <ClassicButton type="button" onClick={confirmSelections}>
              Select photos
            </ClassicButton>
          </div>
        </div>
      </div>
    );
  }
);

export default FileGrid;
