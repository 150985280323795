import React, { useState } from 'react'
import styles from './subscriptions.module.sass'
import Typography from '../../../../../components/ui/typography'
import ClassNames from 'classnames'
import { useDispatch } from 'react-redux'
import {
  deleteSubscription,
  payments,
  paymentSelector,
  updateSubscription,
} from '../../../../../store/reducers/payments'

const Plan = ({
  title,
  price,
  list,
  selected,
  buttonTitle,
  trigger,
  filter,
  prices,
  defaultPaymentMethod,
  currentPlan,
}) => {
  const dispatch = useDispatch()
  let disabled
  let monthPlan
  let yearPlan

  const FreePlan = currentPlan?.name ? false : true
  const ProPlan = currentPlan?.name ? true : false
  console.log(currentPlan?.ends_at ? 1 : 0)

  if (filter == 'yearly') {
    yearPlan = prices?.find((e) => {
      return e.recurring.interval == 'year'
    })
    if (yearPlan) {
      disabled = currentPlan.stripe_price == yearPlan.id
    }
  }

  let buttonDesign =
    prices?.find((e) => {
      return e.recurring.interval == 'year'
    }).id == currentPlan?.stripe_price

  if (filter == 'monthly') {
    monthPlan = prices?.find((e) => {
      return e.recurring.interval == 'month'
    })
    if (monthPlan) {
      disabled = currentPlan.stripe_price == monthPlan.id
    }
  }

  const updatePlanHandler = () => {
    dispatch(
      updateSubscription({
        plan: monthPlan?.id ?? yearPlan?.id,
        payment: defaultPaymentMethod,
        subscription: filter,
      }),
    )
  }

  const deletePlanHandler = () => {
    dispatch(deleteSubscription())
  }

  const listFree = [
    '100 events',
    'Unlimited Media',
    '2 custom timelines',
    'Share events with Family and Friends',
  ]
  const listPro = [
    'Unlimited events',
    'Unlimited Media',
    'Unlimited custom timelines',
    'Share events with Family and Friends',
  ]
  const listComingSoon = [
    'Image editor tools',
    'Video editor tools',
    'Scrapbooking',
    'Image / Video scanning',
  ]
  return (
    <>
      <div className={styles.plan}>
        <Typography component="p" variant="label" className={styles.planTitle}>
          Free
        </Typography>
        <Typography
          component="p"
          variant="titleHeavySecond"
          className={styles.price}
        >
          $0.00
        </Typography>
        <ul className={styles.planList}>
          {listFree && listFree.map((item, idx) => <li key={idx}>{item}</li>)}
        </ul>
        <button
          type="button"
          onClick={() => deletePlanHandler()}
          disabled={FreePlan || currentPlan?.ends_at}
          className={ClassNames([
            styles.planButton,
            { [styles.selected]: FreePlan || currentPlan?.ends_at },
          ])}
        >
          {FreePlan ? 'Selected' : 'Downgrade'}
        </button>
      </div>
      <div className={styles.plan}>
        <Typography component="p" variant="label" className={styles.planTitle}>
          Pro User
        </Typography>
        <Typography
          component="p"
          variant="titleHeavySecond"
          className={styles.price}
        >
          {monthPlan ? '$' + monthPlan.unit_amount / 100 : null}
          {yearPlan ? '$' + yearPlan.unit_amount / 100 : null}
        </Typography>
        <ul className={styles.planList}>
          {listPro && listPro.map((item, idx) => <li key={idx}>{item}</li>)}
        </ul>
        <button
          type="button"
          onClick={() => updatePlanHandler()}
          disabled={
            (ProPlan && currentPlan?.name == 'yearly' && filter == 'monthly') ||
            (ProPlan &&
              currentPlan?.name == 'monthly' &&
              filter == 'monthly') ||
            disabled
          }
          className={ClassNames([
            styles.planButton,
            {
              [styles.selected]:
                (ProPlan &&
                  currentPlan?.name == 'yearly' &&
                  filter == 'monthly') ||
                (ProPlan &&
                  currentPlan?.name == 'monthly' &&
                  filter == 'monthly') ||
                disabled,
            },
          ])}
        >
          {ProPlan ? 'Selected' : 'Get Started'}
        </button>
      </div>
      <div className={styles.plan}>
        <Typography component="p" variant="label" className={styles.planTitle}>
          New Features
        </Typography>
        <Typography
          component="p"
          variant="titleHeavySecond"
          className={styles.price}
        >
          Coming Soon
        </Typography>
        <ul className={styles.planList}>
          {listComingSoon &&
            listComingSoon.map((item, idx) => <li key={idx}>{item}</li>)}
        </ul>
        <button
          type="button"
          onClick={() => updatePlanHandler()}
          disabled={disabled || buttonDesign}
          className={ClassNames([
            styles.planButton,
            { [styles.selected]: false },
          ])}
        >
          Learn More
        </button>
      </div>
    </>
  )
}

export default Plan
