import React, { useEffect } from 'react'
import { useField, ErrorMessage, useFormikContext } from 'formik'
import ClassNames from 'classnames'
import Input from '../input'

import styles from './inputControl.module.sass'

const InputControl = ({
  className,
  type = 'text',
  placeholder,
  name,
  label,
  disabled = false,
  labelClassName,
  mask,
  ...props
}) => {
  const [field, , meta] = useField({ name })

  const formikContext = useFormikContext()

  // useEffect(() => {
  //   if (mask == 'card_number') {
  //     console.log(55);
  //     let prepareValue = formikContext.values[name].replace(/\D/g, '')
  //     if (props.valueLength) {
  //       prepareValue = prepareValue.slice(0, props.valueLength)
  //     }
  //     if (formikContext.setFieldValue) {
  //       formikContext.setFieldValue(name, prepareValue)
  //     }
  //   }
  // }, [formikContext.values.card_number])

  const htmlFor = `${type}-${Math.random()}`
  return (
    <div className={ClassNames(styles.wrapper, className)}>
      <label
        className={ClassNames(styles.label, labelClassName, {
          [styles.label_error]: meta.error && meta.touched,
        })}
        htmlFor={htmlFor}
      >
        {label}
        <div className={styles.error}>
          <ErrorMessage name={name} />
        </div>
      </label>
      <div
        className={ClassNames(styles.input__container, {
          [styles.error_border]:
            formikContext?.errors[name] && formikContext?.touched[name],
        })}
      >
        <Input
          className={styles.input}
          id={htmlFor}
          {...field}
          type={type}
          placeholder={placeholder}
          disabled={disabled}
          maxLength={name == 'phone' ? '20' : 'none'}
          {...props}
        />
      </div>
    </div>
  )
}

export default InputControl
