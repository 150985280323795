import React, { useRef, useState } from "react";
import Typography from "../../../../../components/ui/typography";
import styles from "./shared.module.sass";
import CategoryItem from "./CategoryItem";
import classNames from "classnames";
import { useGlobalCloseSmiles } from "../../../../../hooks/useCloseMenu";

const FriendItem = ({ detail }) => {
  const [showCategories, setCategoriesOpener] = useState(false);
  // let followedTimelines = detail.timelines.filter(
  //   (item) => item.subscribed === true
  // );

  const ref = useRef(null);
  const handleCloseDropDown = () => {
    setCategoriesOpener(false);
  };
  useGlobalCloseSmiles(ref, handleCloseDropDown, showCategories);
  return (
    <div ref={ref} className={styles.friendItem}>
      <div
        onClick={() => setCategoriesOpener(!showCategories)}
        className={styles.friendWrapper}
      >
        <div className={styles.organizer}>
          <div className={styles.imageWrapper}>
            <img src={detail.profile_photo_path} alt="" />
          </div>
          <Typography variant="title" className={styles.userName}>
            {detail.firstName} {detail.lastName}
          </Typography>
        </div>
        <div className={styles.organizer1}>
          <Typography component="span">
            {/* Timelines followed: {followedTimelines.length} */}
          </Typography>
          <div
            className={classNames(styles.arrowWrapper, {
              [styles.turnArrow]: showCategories,
            })}
          >
            <svg
              width="10"
              height="6"
              viewBox="0 0 10 6"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M4.29289 0.292893C4.68342 -0.0976311 5.31658 -0.0976311 5.70711 0.292893L9.70711 4.29289C10.0976 4.68342 10.0976 5.31658 9.70711 5.70711C9.31658 6.09763 8.68342 6.09763 8.29289 5.70711L5 2.41421L1.70711 5.70711C1.31658 6.09763 0.683417 6.09763 0.292893 5.70711C-0.0976311 5.31658 -0.0976311 4.68342 0.292893 4.29289L4.29289 0.292893Z"
                fill="#1D2A64"
              />
            </svg>
          </div>
        </div>
      </div>
      <div
        className={classNames(styles.categoriesWrapper, {
          [styles.openWrapper]: showCategories,
        })}
      >
        {detail?.categories.map((timeline) => {
          return (
            <CategoryItem
              detailId={detail.id}
              timeline={timeline}
              key={timeline.id}
            />
          );
        })}
      </div>
    </div>
  );
};
export default FriendItem;
