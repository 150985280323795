import { all } from '@redux-saga/core/effects'
import { LOCATION_CHANGE, push } from 'connected-react-router'
import { matchPath } from 'react-router'
import { handleActions, createAction } from 'redux-actions'
import {
  call,
  delay,
  fork,
  put,
  select,
  take,
  takeEvery,
  takeLatest,
} from 'redux-saga/effects'
import { resource } from '../../api'
import { getRouteConfig, SEARCH_RESULTS, TIMELINE } from '../../routes/Routes'
import { routerSelector } from '../../selectors/selectors'

var qs = require('qs')
const namespace = `combineSearch`

const GET_EVENTS = `${namespace}/GET_EVENTS`
const GET_PEOPLES = `${namespace}/GET_PEOPLES`
const GET_COMBINE = `${namespace}/GET_COMBINE`

const SET_EVENTS = `${namespace}/SET_EVENTS`
const SET_PEOPLES = `${namespace}/SET_PEOPLES`
const SET_COMBINE = `${namespace}/SET_COMBINE`
const GET_DATES = `${namespace}/SET_DATES`
const RESET_COMBINE_SEARCH = `${namespace}/RESET_COMBINE_SEARCH`

const CHANGE_NAME = `${namespace}/CHANGE_NAME`

export const getEvents = createAction(GET_EVENTS)
export const getPeoples = createAction(GET_PEOPLES)
export const getCombine = createAction(GET_COMBINE)

export const setEvents = createAction(SET_EVENTS)
export const setPeoples = createAction(SET_PEOPLES)
export const setCombine = createAction(SET_COMBINE)
export const getDates = createAction(GET_DATES)
export const resetCombineSearch = createAction(RESET_COMBINE_SEARCH)

export const changeName = createAction(CHANGE_NAME)

const initialState = {
  events: null,
  peoples: null,
  combine: null,
}

export default handleActions(
  {
    [SET_EVENTS]: (state, { payload }) => ({ ...state, events: payload }),
    [SET_PEOPLES]: (state, { payload }) => ({ ...state, peoples: payload }),
    [SET_COMBINE]: (state, { payload }) => ({ ...state, combine: payload }),
    [RESET_COMBINE_SEARCH]: (state) => ({
      ...state,
      combine: null,
      peoples: null,
      events: null,
    }),
  },
  initialState,
)

export const combineSearchSelector = (state) => state[namespace]

const strategy = {
  entries: getEventRequest,
  people: getPeoplesRequest,
  combine: getCombineRequest,
}

function* onRouteEnter() {
  while (true) {
    const { payload } = yield take(LOCATION_CHANGE)

    const router = yield select(routerSelector)
    const { query } = router.location
    if (
      matchPath(payload.location.pathname, getRouteConfig(SEARCH_RESULTS)) &&
      query.param &&
      query.name &&
      strategy[query.param]
    ) {
      const { param, ...props } = query

      yield fork(strategy[query.param], props)
    }
    if (
      matchPath(payload.location.pathname, getRouteConfig(TIMELINE)) &&
      query.param &&
      query.name &&
      strategy[query.param]
    ) {
      const { param, ...props } = query
      yield fork(strategy[query.param], props)
    }
    if (!query.name) {
      yield put(setCombine(null))
    }
  }
}

function* getPeoplesRequest(payload) {
  try {
    const { data } = yield call(resource.get, `/user/search`, {
      params: { ...payload },
    })
    yield put(setPeoples(data))
  } catch (err) {
    console.log(err)
  }
}

function* getEventRequest(payload) {
  try {
    const { data } = yield call(resource.post, `/event/search`, {
      ...payload,
    })

    yield put(setEvents(data))
  } catch (err) {
    console.log(err)
  }
}
function* getEventRequestByDates() {
  while (true) {
    const { payload } = yield take(GET_DATES)

    try {
      const { data } = yield call(resource.post, `/event/search`, {
        ...payload,
      })

      yield put(setEvents(data))
    } catch (err) {
      console.log(err)
    }
  }
}

function* getCombineRequest(payload) {
  try {
    const {
      data: { data },
    } = yield call(resource.get, `search?query=${payload.name}`)
    yield put(setCombine(data))
  } catch (err) {
    console.log(err)
  }
}

function* changeNameRequest({ payload }) {
  yield delay(1000)
  const router = yield select(routerSelector)
  const { location } = router
  const search = new URLSearchParams(location.search)
  search.set('name', payload)

  yield put(
    push({
      pathname: location.pathname,
      search: search.toString(),
    }),
  )
}
export function* sagas() {
  yield fork(onRouteEnter)

  yield all([
    takeLatest(CHANGE_NAME, changeNameRequest),
    getEventRequestByDates(),
  ])
}
