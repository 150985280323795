import React from "react";
import ClassNames from "classnames";
import { Link } from "react-router-dom";
import { Formik, Form } from "formik";
import * as Yup from "yup";

import styles from "./login.module.sass";

import logo from "../../components/assets/images/Logo.svg";
import bgc from "./bgc.png";
import Typography from "../../components/ui/typography";
import { CheckboxControl, InputControl } from "../../components/ui/formik";
import { useDispatch } from "react-redux";
import { login, loginGoogle } from "../../store/reducers/auth";
import GoogleLogin from "react-google-login";

const Login = (props) => {
  const dispatch = useDispatch();
  const onSuccess = (res) => {
    dispatch(loginGoogle(res));
  };
  const onFailure = (res) => {
    console.log(res, "FAILUR");
  };
  return (
    <div className={styles.container}>
      <div className={styles.wrapper}>
        <div className={styles.form__wrapper}>
          <Link to="/" className={styles.logo__wrapper}>
            <img className={styles.logo} src={logo} alt="" />
          </Link>
          <Typography className={styles.title} component="h1" variant="title">
            Sign in.
          </Typography>
          <Typography
            className={ClassNames(styles.title, styles.title_description)}
          >
            Follow the steps below and sign in to your account.
          </Typography>
          <div className={styles.googleAuth__container}>
            <GoogleLogin
              clientId="536449628029-vcsre67t9rhb4d44u0ajerkchlj7mjj7.apps.googleusercontent.com"
              buttonText="Login"
              onSuccess={onSuccess}
              onFailure={onFailure}
              cookiePolicy="single_host_origin"
              // isSignedIn={true}
              render={(renderProps) => (
                <button
                  className={styles.googleAuth}
                  onClick={renderProps.onClick}
                  disabled={renderProps.disabled}
                >
                  <svg
                    width="20"
                    height="20"
                    viewBox="0 0 20 20"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <g clipPath="url(#clip0)">
                      <path
                        d="M19.9895 10.1871C19.9895 9.3677 19.9214 8.76976 19.7742 8.14969H10.1992V11.848H15.8195C15.7062 12.7671 15.0943 14.1512 13.7346 15.0813L13.7155 15.2051L16.7429 17.4969L16.9527 17.5173C18.8789 15.7789 19.9895 13.221 19.9895 10.1871Z"
                        fill="#4285F4"
                      />
                      <path
                        d="M10.1993 19.9314C12.9528 19.9314 15.2644 19.0455 16.9528 17.5175L13.7347 15.0813C12.8735 15.6682 11.7177 16.0779 10.1993 16.0779C7.50247 16.0779 5.21355 14.3395 4.39761 11.9366L4.27801 11.9466L1.13004 14.3273L1.08887 14.4391C2.76589 17.6946 6.21064 19.9314 10.1993 19.9314Z"
                        fill="#34A853"
                      />
                      <path
                        d="M4.39748 11.9366C4.18219 11.3166 4.05759 10.6521 4.05759 9.96565C4.05759 9.27908 4.18219 8.61473 4.38615 7.99466L4.38045 7.8626L1.19304 5.44366L1.08876 5.49214C0.397576 6.84305 0.000976562 8.36008 0.000976562 9.96565C0.000976562 11.5712 0.397576 13.0882 1.08876 14.4391L4.39748 11.9366Z"
                        fill="#FBBC05"
                      />
                      <path
                        d="M10.1993 3.85335C12.1142 3.85335 13.406 4.66166 14.1425 5.33716L17.0207 2.59106C15.253 0.985493 12.9527 0 10.1993 0C6.2106 0 2.76588 2.23671 1.08887 5.49212L4.38626 7.99463C5.21352 5.59181 7.50242 3.85335 10.1993 3.85335Z"
                        fill="#EB4335"
                      />
                    </g>
                    <defs>
                      <clipPath id="clip0">
                        <rect width="20" height="20" fill="white" />
                      </clipPath>
                    </defs>
                  </svg>
                  <span className={styles.googleAuth__text}>
                    Sign In with Google
                  </span>
                </button>
              )}
            />
            <span className={styles.googleAuth__text}>or</span>
          </div>
          <LoginForm />
        </div>
        <div className={styles.background}>
          <img className={styles.image} src={bgc} alt="" />
        </div>
      </div>
    </div>
  );
};

export default Login;

const LoginForm = () => {
  const dispatch = useDispatch();
  return (
    <Formik
      initialValues={{
        email: "",
        password: "",
        save: "",
      }}
      onSubmit={(values, { setSubmitting }) => {
        const { email, password } = values;
        dispatch(login({ email, password, setSubmitting }));
      }}
      validationSchema={Yup.object().shape({
        email: Yup.string().email().matches(/^(([^<>()\[\]\\.,;:\s@#!$%^&*()"]+(\.[^<>()\[\]\\.,;:\s@#"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,"Enter valid email").required(),
        password: Yup.string().min(8).max(255).required(),
        save: Yup.boolean(),
      })}
    >
      {({ values, isSubmitting, dirty, isValid }) => (
        <Form>
          <InputControl
            name="email"
            label="Email Address"
            placeholder="name@example.com"
            className={styles.input}
          />
          <InputControl
            name="password"
            label="Password"
            type="password"
            placeholder="Password"
            className={styles.input}
          />
          <div className={styles.policy__wrapper}>
            <CheckboxControl name="save" className={styles.policy} />
            <ul>
              <Typography variant="smallText" component="li">
                Save login info
              </Typography>
            </ul>
            <Link className={styles.forgotPassword} to="/restore-access">
              Forgot password?
            </Link>
          </div>
          <button
            className={styles.button}
            type="submit"
            disabled={!isValid || isSubmitting || !dirty}
          >
            Sign in
          </button>
          <div className={styles.link__wrapper}>
            <Typography component="span">Don’t have an account?</Typography>
            <Link className={styles.link} to="/registration">
              Sign up
            </Link>
          </div>
        </Form>
      )}
    </Formik>
  );
};
