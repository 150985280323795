export const notificationTypes = [
  {
    title: "Shared Events and Timelines",
    description:
      "Receive notifications when someone shares an event or timeline with you.",
    id: "events_timelines",
  },
  {
    title: "Comments on your Events",
    description:
      "Get notifications when someone comments or adds media to one of your events.",
    id: "comments",
  },
  {
    title: "Updates from friends",
    description:
      "Receive notifications when comments or media are added to a friends shared event.",
    id: "updates",
  },
  {
    title: "Tags",
    description:
      "Receive notifications when someone tags you in a comment or post.",
    id: "tags",
  },
  {
    title: "Reminders",
    description:
      "Receive reminders for upcoming events that you've created or that have been shared with you.",
    id: "reminders",
  },
  {
    title: "Marketing emails",
    description:
      "Receive notifications for marketing emails.",
    id: "marketing_emails",
  },
  {
    title: "Weekly Analytics",
    description:
      "Receive notifications for your weekly analytics report.",
    id: "weekly_analytic",
  },
];
