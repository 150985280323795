import { createAction, handleActions } from "redux-actions";
import { all, call, put, takeEvery } from "redux-saga/effects";
import { resource } from "../../api";

const namespace = `support`;

const initialState = {
  questions: [],
};

const GET_QUESTIONS = `${namespace}/GET_QUESTIONS`;
const SET_QUESTIONS = `${namespace}/SET_QUESTIONS`;

export const getQuestions = createAction(GET_QUESTIONS);
export const setQuestions = createAction(SET_QUESTIONS);

export default handleActions(
  {
    [SET_QUESTIONS]: (state, { payload }) => ({
      ...state,
      questions: payload,
    }),
  },
  initialState
);

export const questionsSelector = (state) => state[namespace].questions;

function* getQuestionsRequest() {
  try {
    const { data } = yield call(resource.get, `/faq/index`);
    yield put(setQuestions(data));
  } catch (err) {
    console.log(err);
  }
}

export function* support() {
  yield all([takeEvery(GET_QUESTIONS, getQuestionsRequest)]);
}
