import { privateName } from "@babel/types";
import classNames from "classnames";
import { useField } from "formik";
import React, { useEffect, useState } from "react";

import styles from "./inputControl.module.sass";

const RadioButtonControl = ({ name, className, error, checked, value }) => {
  const [field, , helpers] = useField({ name });
  const { setValue } = helpers;

  const handleChange = (e) => {
    if (value === "private") setValue(true);
    if (value === "public") setValue(false);
  };

  return (
    <label className={classNames(styles.radioButtonControl, className)}>
      <input
        type="radio"
        className={styles.input}
        onChange={(e) => handleChange(e)}
        name={name}
        value={value}
        checked={checked}
      />
      <div className={classNames(styles.visual, { [styles.error]: error })}>
        <svg
          width="8"
          height="6"
          viewBox="0 0 8 6"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M1 3L3 5L7 1"
            stroke="#131F55"
            strokeWidth="1.5"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </svg>
      </div>
    </label>
  );
};

export default RadioButtonControl;
