import React from 'react'
import ClassNames from 'classnames'

import styles from './progressbar.module.sass'
import Typography from '../ui/typography'
import InfoIconButton from '../ui/buttons'

const ProgressBar = ({ className, width }) => {
  console.log(width)
  return (
    <div className={ClassNames(styles.progressbar__wrapper, className)}>
      <div className={styles.progressbar__header}>
        <div className={styles.progressbar__header_container}>
          <Typography component="span" variant="smallTextHeavy">
            Verification
          </Typography>
          {width != 100 && (
            <>
              <div className={styles.show}>
                <InfoIconButton />
              </div>
              <div className={styles.tooltip}>
                Finish updating your profile to fully verify your account.
              </div>
            </>
          )}
        </div>
        <Typography component="span" variant="smallTextHeavy">
          {width}%
        </Typography>
      </div>
      <div className={styles.progressbar__container}>
        <div className={styles.progressbar} style={{ width: width + '%' }} />
      </div>
    </div>
  )
}

export default ProgressBar
