import React from "react";

const Base = React.forwardRef(
  (
    {
      children,
      component: Component,
      className,
      type,
      onChange,
      placeholder,
      id,
      disabled,
      style,
      onClick,
      ...props
    },
    ref
  ) => {
    return (
      <Component
        ref={ref}
        id={id}
        className={className}
        type={type}
        placeholder={placeholder}
        disabled={disabled}
        style={style}
        {...props}
        onChange={onChange}
        onClick={onClick}
      >
        {children}
      </Component>
    );
  }
);

export default Base;

Base.defaultProps = {
  component: "div",
};
