import classNames from "classnames";

import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useLocation, Link, useParams } from "react-router-dom";
import SearchInput from "../../components/search-input";

import Typography from "../../components/ui/typography";
import {
  friendProfileSelector,
  friendsMyFriendSelector,
  getFriendProfile,
  getFriendsMyFriend,
} from "../../store/reducers/friends";
import styles from "./friend-list.module.sass";

import { InviteButton } from "../friend-profile/FriendProfile";
import { DeleteButton } from "../friend-profile/header/UserHeader";

const FriendList = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const { id } = useParams();
  const friends = useSelector(friendsMyFriendSelector);
  const friend = useSelector(friendProfileSelector);
  const [inputValue, setValue] = useState("");
  console.log(friends);

  const arr = friends?.filter((friend) => {
    if (inputValue === "") {
      return friend;
    } else if (
      friend.firstName.toLowerCase().includes(inputValue.toLowerCase())
    ) {
      return friend;
    }
  });

  // const handleSearch = (value) => {
  //   dispatch(getFriends(value));
  // };
  useEffect(() => {
    dispatch(getFriendProfile(id));
    dispatch(getFriendsMyFriend(id));
  }, [id, dispatch]);
  if (!friend || !friends) {
    return null;
  }
  return (
    <div className={styles.wrapper}>
      <div className={styles.header}>
        <div className={styles.header__top}>
          <BackButton
            onClick={() => {
              history.goBack();
            }}
          >
            Back
          </BackButton>
        </div>
        <div className={styles.header__content}>
          <Title name={friend.firstName}>
            {friends?.length > 0 && friends.length}
          </Title>
          <SearchInput callback={setValue} size={3} />
        </div>
      </div>
      <List list={arr} />
    </div>
  );
};

export default FriendList;

function BackButton({ children, onClick }) {
  return (
    <button className={styles.button_back} type="button" onClick={onClick}>
      <div className={styles.arrow} />
      {children}
    </button>
  );
}

const Title = ({ children, name }) => {
  return (
    <div className={styles.title}>
      <Typography component="h1" variant="pageTitle">
        Connections
      </Typography>
      {children ? (
        <Typography component="p" variant="smallText">
          {name} has <b>{children}</b> connections
        </Typography>
      ) : null}
    </div>
  );
};

function List({ list }) {
  if (!list || !list.length) {
    return null;
  }
  return (
    <div>
      {list.map((e) => (
        <ListItem user={e} key={e.id} />
      ))}
    </div>
  );
}
function ListItem({ user }) {
  const location = useLocation();
  console.log("hi");
  return (
    <Link
      to={{
        pathname: `/friend/${user.id}`,
        state: {
          from: location.pathname,
        },
      }}
      className={styles.friend__wrapper}
    >
      <img
        src={user?.profile_photo_path}
        className={styles.friend__avatar}
        alt=""
      />
      <Typography component="h3" variant="titleHeavy">
        {user?.firstName}
      </Typography>
      <div className={classNames(styles.arrow, styles.arrow__right)} />
    </Link>
  );
}
