import { createBrowserHistory } from "history";
import { createStore, applyMiddleware, compose, combineReducers } from "redux";
import { routerMiddleware, connectRouter } from "connected-react-router";

export const history = createBrowserHistory();

const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__
  || compose;

export const configStore = (reducers, middlewares) => {
  return createStore(
    combineReducers({
      router: connectRouter(history),
      ...reducers,
    }),
    {},
    composeEnhancers(applyMiddleware(routerMiddleware(history), ...middlewares))
  );
};
