import React, { useState } from 'react'
import Typography from '../../../../../components/ui/typography'
import styles from './security.module.sass'
import ClassNames from 'classnames'
import * as Yup from 'yup'
import { useDispatch, useSelector } from 'react-redux'
import {
  updateMemory,
  profileSelector,
} from '../../../../../store/reducers/user'
import { ErrorMessage, Form, Formik } from 'formik'
import { InputControl } from '../../../../../components/ui/formik'
import { set } from 'date-fns'
const Memorialization = ({ setEdit }) => {
  const profile = useSelector(profileSelector)
  const [friendsEmail, setValue] = useState(
    profile?.friends_email?.length ? profile.friends_email : [],
  )
  const dispatch = useDispatch()
  const [checked, setChecked] = useState(
    profile.delete_after_death ? profile.delete_after_death : false,
  )

  const onChangeCheckbox = () => {
    setChecked(!checked)
  }
  const onSubmitEmail = (e) => {
    if (friendsEmail.includes(e.email)) return

    setValue([...friendsEmail, e.email])
  }

  const onSubmit = (e) => {
    e.preventDefault()
    dispatch(
      updateMemory({
        friends_email: friendsEmail,
        delete_after_death: checked,
      }),
    )
    setEdit(false)
  }
  return (
    <>
      <ul className={styles.list}>
        <li>
          Manage tribute posts on your profile, which includes deciding who can
          post and who can see posts deleting posts, and removing tags
        </li>
        <li>Respond to new friend requests</li>
        <li>Update your profile picture and cover photo</li>
      </ul>
      <Typography component="p" variant="smallText" className={styles.contact}>
        Your legacy contact can only manage posts made after you've passed away.
        They won't be able to post as you or see your messages.
      </Typography>
      <Formik
        enableReinitialize={true}
        initialValues={{
          email: '',
        }}
        onSubmit={(e) => onSubmitEmail(e)}
        validationSchema={Yup.object().shape({
          email: Yup.string().email('Please enter a valid email').required(),
        })}
      >
        {({ errors }) => {
          return (
            <Form className={styles.form}>
              {/* <input
                type="email"
                placeholder={'Enter friend’s email address...'}
                //value={friendsEmail}
                onChange={onChangeValue}
              /> */}
              <InputControl
                className={styles.input}
                name="email"
                placeholder="Enter friend’s email address..."
              />
              <button
                type="submit"
                className={errors.email ? styles.button : styles.addButton}
              >
                Add
              </button>
            </Form>
          )
        }}
      </Formik>
      {friendsEmail.map((e,i) => (
        <p className={styles.emails} key={i}>{e}</p>
      ))}
      <div className={styles.separator} />
      <div>
        <Typography component="h3" variant="label" className={styles.title}>
          Delete Account after Death
        </Typography>
        <Typography
          component="p"
          variant="smallText"
          className={styles.decision}
        >
          You've requested to have your account permanently deleted after death
          Once someone lets us know you've passed away, no one will be able to
          see your profile again If you want friends and family to be able to
          visit your profile and share memories in the future, you can choose to
          keep your Storyline account.
        </Typography>
      </div>
      <div className={styles.delete__wrapper}>
        <label className={styles.checkbox__wrapper}>
          <input
            type="checkbox"
            name="delete"
            className={styles.checkbox}
            onChange={onChangeCheckbox}
            checked={checked}
          />

          <div className={styles.visual}>
            <svg
              width="8"
              height="6"
              viewBox="0 0 8 6"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M1 3L3 5L7 1"
                stroke="#131F55"
                strokeWidth="1.5"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
            </svg>
          </div>
          <Typography component="span">Delete Account After Death</Typography>
        </label>
        <div className={styles.buttons}>
          <button
            type="button"
            onClick={() => {
              //setEdit(false);
              setChecked(profile.delete_after_death)
            }}
          >
            cancel
          </button>
          <button onClick={onSubmit} type="submit">
            save changes
          </button>
        </div>
      </div>
    </>
  )
}

export default Memorialization
