/* eslint-disable jsx-a11y/img-redundant-alt */
import React from "react";
import Typography from "../ui/typography";
import styles from "./search-dropdown.module.sass";
import { Link } from "react-router-dom";
const Entries = ({
  className,
  friendId,
  entryId,
  eventName,
  date,
  description,
  img,
}) => {
  return (
    <div className={className}>
      <Link
        to={{
          pathname: `/friend/${friendId}/event/${entryId}`,
        }}
      >
        <div className={styles.contentWrapper}>
          <div className={styles.contentWrapper__img}>
            <img alt="entry_image" src={img}></img>
          </div>
          <div className={styles.contentWrapper__text}>
            <Typography
              className={styles.entryHeader}
              component="h3"
              variant="label"
            >
              {eventName}
            </Typography>
            <div className={styles.contentWrapper__text_date}>
              <Typography
                className={styles.upcoming}
                component="span"
                variant="smallTextHeavy"
              >
                Upcoming on
              </Typography>
              <Typography component="span" variant="smallText">
                {date}
              </Typography>
            </div>
          </div>
        </div>
        <Typography className={styles.description} component="p">
          {description}
        </Typography>
      </Link>
    </div>
  );
};

export default Entries;
