import { Link, useParams, useLocation } from "react-router-dom";
import Typography from "../../../components/ui/typography";
import styles from "./friend-list.module.sass";
import FriendList from "./FriendList";

function FriendListSection({ count, friends }) {
  const { id } = useParams();
  const location = useLocation();

  const link =
    location.pathname.indexOf("home") === 1
      ? "/friends/all"
      : `/friend/${id}/friend-list`;
  return (
    <div>
      <div className={styles.titleWrapper}>
        <Typography
          className={styles.friendsTitle}
          variant="titleHeavy"
          component="h2"
        >
          Connections:
          <span className={styles.friendsCounter}>{count}</span>
        </Typography>
        <Link className={styles.link} to={link}>
          See all <div className={styles.arrow} />
        </Link>
      </div>

      <FriendList friends={friends} />
    </div>
  );
}

export default FriendListSection;
