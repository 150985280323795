import classNames from 'classnames'
import { Field, Form, Formik } from 'formik'
import * as yup from 'yup'
import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Link } from 'react-router-dom'
//import { InputControl } from '../../../../../../../components/ui/formik'
//import { addPaymentMethod, approveLoader } from '../../../../store/reducers/account';
// import Load from '../../../../components/ui/loader';
//import { STATES } from '../../../../utils/states';
import styles from './add-payment-method.module.sass'
import { InputControl } from '../../../../../../../components/ui/formik'
import ExpireInputControl from '../../../../../../../components/ui/formik/ExpireInputControl'
import {
  addPaymentMethods,
  getPaymentMethods,
  removePaymentMethod,
} from '../../../../../../../store/reducers/payments'
//import FloatControl from '../../../../../../../components/input-control/FloatControl'

const validationSchema = yup.object().shape({
  card_name: yup
    .string()
    .min(3)
    .max(16)
    .required('Please enter a valid card number'),
  card_number: yup
    .string()
    .min(16)
    .max(16)
    .matches(/^\d+$/, 'Card number is not valid')
    .required('Please enter a valid card number'),
  cvv: yup
    .string()
    .min(3)
    .max(4)
    .matches(/^\d+$/, 'Cvv is not valid')
    .required('Please enter a valid security code'),
  expiryDate: yup
    .string()
    .min(4)
    .max(4)
    .matches(/^\d+$/, 'Card number is not valid')
    .required('Please enter a valid date format'),
  // exp_month: yup
  //   .string()
  //   .min(2)
  //   .max(2)
  //   .required('Please enter a valid security code'),
  // exp_year: yup
  //   .string()
  //   .min(2)
  //   .max(4)
  //   .required('Please enter a valid security code'),
})

export default function AddPaymentMethod(close) {
  const dispatch = useDispatch()
  //const load = useSelector(approveLoader);

  const handleSubmit = (values, setErrors) => {
    dispatch(addPaymentMethods({ values, setErrors, closeModal: close }))
  }

  return (
    <div className={styles.overlay}>
      {/* {load && <Load />} */}

      <div className={styles.wrapper}>
        <svg
          onClick={() => close.close()}
          width="24"
          height="24"
          viewBox="0 0 24 24"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            fill-rule="evenodd"
            clip-rule="evenodd"
            d="M6.85761 5.71453C6.57074 5.42849 6.10563 5.42849 5.81876 5.71453C5.53189 6.00057 5.53189 6.46433 5.81876 6.75037L11.0318 11.9483L5.71515 17.2496C5.42828 17.5357 5.42828 17.9994 5.71515 18.2855C6.00202 18.5715 6.46713 18.5715 6.754 18.2855L12.0707 12.9842L17.2461 18.1446C17.5329 18.4306 17.998 18.4306 18.2849 18.1446C18.5718 17.8585 18.5718 17.3948 18.2849 17.1087L13.1095 11.9483L18.1813 6.89126C18.4682 6.60522 18.4682 6.14146 18.1813 5.85542C17.8944 5.56938 17.4293 5.56938 17.1425 5.85542L12.0707 10.9125L6.85761 5.71453Z"
            fill="#1D2A64"
          />
        </svg>
        <Formik
          initialValues={{
            card_name: '',
            card_number: '',
            cvv: '',
            //expiryDate: '',
            exp_month: '',
            exp_year: '',
          }}
          validationSchema={validationSchema}
          onSubmit={handleSubmit}
        >
          {({ isValid, dirty, isSubmitting }) => (
            <Form className={styles.form}>
              <div className={styles.form__container}>
                <InputControl
                  className={styles.field}
                  //component={InputControl}
                  placeholder="Card name"
                  label="Card name"
                  name="card_name"
                  message
                />
                <InputControl
                  className={styles.field}
                  //component={InputControl}
                  placeholder="xxxx-xxxx-xxxx-xxxx"
                  label="Card number"
                  name="card_number"
                  message
                  maxLength={16}
                  mask={'card_number'}
                />
                <InputControl
                  className={styles.field}
                  //component={InputControl}
                  placeholder="xxx"
                  label="cvv"
                  name="cvv"
                  message
                  maxLength={4}
                />
                <ExpireInputControl
                  className={styles.input__payment}
                  label="Expiry date"
                  name="expiryDate"
                  message
                  type="text"
                  // mask="XX / XX"
                  placeholder="XX / XX"
                />
                {/* <InputControl
                  className={styles.input__payment}
                  label="Expiry month"
                  name="exp_month"
                  message
                  type="text"
                  // mask="XX / XX"
                  placeholder="XX / XX"
                />
                <InputControl
                  className={styles.input__payment}
                  label="Expiry year"
                  name="exp_year"
                  message
                  type="text"
                  // mask="XX / XX"
                  placeholder="XX / XX"
                /> */}
              </div>

              <button
                className={styles.button}
                type="submit"
                disabled={!isValid || !dirty}
              >
                Add card
              </button>
            </Form>
          )}
        </Formik>
      </div>
    </div>
  )
}
