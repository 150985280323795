import React from "react";
import {
  BirtProfileIcon,
  GeoProfileIcon,
  HomeProfileIcon,
  SocialStatusProfileIcon,
  StudiedProfileIcon,
  WorksProfileIcon,
} from "../../../components/ui/icon/Icon";
import Typography from "../../../components/ui/typography";
import styles from "./about-me.module.sass";
import AboutMeItem from "./AboutMeItem";

const AboutMeWrapper = ({ profile }) => {
  console.log("AboutMeWrapper ~ profile", profile);
  if (!profile) {
    return null;
  }
  return (
    <div className={styles.aboutMeWrapper}>
      <Typography
        className={styles.aboutMeWrapper__title}
        component="h2"
        variant="titleHeavy"
      >
        About me
      </Typography>
      <div className={styles.personalInfoWrapper}>
        {profile.info.birthday && (
          <AboutMeItem icon={BirtProfileIcon} title="">
            {profile.info.birthday}
          </AboutMeItem>
        )}
        {profile.info.bornIn && (
          <AboutMeItem icon={HomeProfileIcon} title="Born in">
            {profile.info.bornIn}
          </AboutMeItem>
        )}
        {profile.info.liveIn && (
          <AboutMeItem icon={GeoProfileIcon} title="Live in">
            {profile.info.liveIn}
          </AboutMeItem>
        )}
        {profile.info.marriedTo && (
          <AboutMeItem icon={SocialStatusProfileIcon} title="Married to">
            {profile.info.marriedTo}
          </AboutMeItem>
        )}
        {profile.info.worksIn && (
          <AboutMeItem icon={WorksProfileIcon} title="Works in">
            {profile.info.worksIn}
          </AboutMeItem>
        )}
        {profile.info.studiedAt && (
          <AboutMeItem icon={StudiedProfileIcon} title="Studied at the ">
            {profile.info.studiedAt}
          </AboutMeItem>
        )}
      </div>
    </div>
  );
};

export default AboutMeWrapper;
