import ClassNames from 'classnames'
import React from 'react'
import { NavLink, Link, useLocation } from 'react-router-dom'
import styles from './sidebar.module.sass'

import logo from '../assets/images/Logo.svg'
import { ActivityIcon, SettingsIcon, TimelineIcon } from '../ui/icon/Icon'
import Banner from './banner'
import usePathname from '../../hooks/usePathname'
import { useDispatch, useSelector } from 'react-redux'
import { userSelector, getUser } from '../../store/reducers/user'
import useMobile from '../../hooks/useMobile'
import { paymentSelector } from '../../store/reducers/payments'

const Sidebar = ({ className, notif }) => {
  const ref = React.useRef(null)
  const user = useSelector(userSelector)
  //console.log("Sidebar ~ user", user);
  const dispatch = useDispatch()
  const isMobile = useMobile(428)
  const payments = useSelector(paymentSelector)

  // React.useEffect(() => {
  //   // if (ref.current) {
  //   dispatch(setTimeLineLink(ref.current));
  //   // }
  // }, [ref.current]);
  const location = useLocation()
  if (usePathname()) {
    return null
  }

  return (
    <>
      <section className={ClassNames(styles.sidebar__wrapper, className)}>
        <div className={styles.sidebar__container}>
          {!isMobile && (
            <Link className={styles.logo} to="/">
              <img src={logo} alt="logo" />
            </Link>
          )}

          <nav>
            <ul className={styles.list}>
              {/* <li>
              <NavLink
                className={styles.menu__item}
                activeClassName={styles.menu__item_active}
                to="/"
                exact
              >
                <HomeIcon />
                home
              </NavLink>
            </li> */}
              <li ref={ref}>
                <NavLink
                  className={styles.menu__item}
                  activeClassName={styles.menu__item_active}
                  to="/"
                  exact
                >
                  <TimelineIcon />
                  timeline
                </NavLink>
              </li>
              <li className={styles.activityItem}>
                <NavLink
                  className={styles.menu__item}
                  activeClassName={styles.menu__item_active}
                  to="/activity?notification=new"
                  exact
                >
                  <ActivityIcon />
                  activity
                  {/* {user?.hasNewActivity === true ? (
                    <div className={styles.marker}></div>
                  ) : (
                    <div className={styles.hidden}></div>
                  )} */}
                </NavLink>
              </li>
              <li>
                <NavLink
                  className={styles.menu__item}
                  activeClassName={styles.menu__item_active}
                  to={isMobile ? '/settings/mobile' : '/settings/general'}
                  exact
                  isActive={() => {
                    const path = location?.pathname.split('/')[1]
                    return path === 'settings'
                  }}
                >
                  <SettingsIcon />
                  settings
                </NavLink>
              </li>
            </ul>
          </nav>
          {!isMobile && !payments.subscription?.name && <Banner />}
        </div>
      </section>
    </>
  )
}

export default Sidebar
