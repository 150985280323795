/* eslint-disable jsx-a11y/img-redundant-alt */
import styles from "./friend-events.module.sass";
import Typography from "../../../components/ui/typography";
import { format } from "date-fns";
import { Link, useParams } from "react-router-dom";

const FriendEventItem = ({ event }) => {
  const { id } = useParams();
  console.log(54545,event);
  if (!event) {
    return null;
  }
  return (
    <div className={styles.eventWrapper}>
      <div className={styles.evtInnerWrapper}>
        <div className={styles.upperWrapper}>
          <img
            className={styles.eventAvatar}
            src={event.cover_img}
            alt="image"
          />
          <div className={styles.eventTitleWrapper}>
            <div className={styles.upperTitleWrapper}>
              <span className={styles.upperTitleWrapper__tag}>
                {event?.category?.name}
              </span>
              <span className={styles.upperTitleWrapper__commentsQuantity}>
                {event.comments_count}
              </span>
            </div>
            <div className={styles.bottomTitleWrapper}>
              <Typography
                className={styles.bottomTitleWrapper__title}
                component={Link}
                variant="titleHeavy"
                to={`/friend/${id}/event/${event.id}`}
              >
                {event.title}
              </Typography>
              <Typography
                component="div"
                variant="smallText"
                className={styles.bottomTitleWrapper__evtDate}
              >
                {`${format(new Date(event?.start_date), "PPP")} - ${format(
                  new Date(event?.end_date),
                  "PPP"
                )}`}
              </Typography>
            </div>
          </div>
        </div>
        <Typography className={styles.evtDescription} component="p">
          {event.description}
        </Typography>

        <Typography variant="root" component="p"></Typography>
        {/* <InvolvedUsers /> */}
      </div>
    </div>
  );
};

export default FriendEventItem;
