import React from "react";
import { Link } from "react-router-dom";
import styles from "./banner.module.sass";
import image from "./image.png";

const Banner = () => {
  return (
    <div className={styles.wrapper}>
      <h2>
        Upgrade to <br /> Pro User
      </h2>
      <img src={image} alt="bgc" />
      <Link to="#">Learn more</Link>
    </div>
  );
};

export default Banner;
