import { push } from 'connected-react-router'
import { createAction, handleActions } from 'redux-actions'
import { all, call, put, select, take, takeEvery } from 'redux-saga/effects'
import { resource } from '../../api'
import {
  dangerMessage,
  successMessage,
} from '../../components/ui/alert/alertReducer'

const namespace = `payments`

const GET_PAYMENT_METHODS = `${namespace}/GET_PAYMENT_METHODS`
const SET_PAYMENT_METHODS = `${namespace}/SET_PAYMENT_METHODS`
const ADD_PAYMENT_METHODS = `${namespace}/ADD_PAYMENT_METHODS`
const REMOVE_PAYMENT_METHOD = `${namespace}/REMOVE_PAYMENT_METHODS`
const SET_DEFAULT_PAYMENT_METHOD = `${namespace}/SET_DEFAULT_PAYMENT_METHOD`

const GET_INVOICES = `${namespace}/GET_INVOICES`
const SET_INVOICES = `${namespace}/SET_INVOICES`

const SETUP_INTENT = `${namespace}/SETUP_INTENT`
const SET_INTENT = `${namespace}/SET_INTENT`

const GET_SUBSCRIPTION = `${namespace}/GET_SUBSCRIPTION`
const SET_SUBSCRIPTION = `${namespace}/SET_SUBSCRIPTION`
const UPDATE_SUBSCRIPTION = `${namespace}/UPDATE_SUBSCRIPTION`
const DELETE_SUBSCRIPTION = `${namespace}/DELETE_SUBSCRIPTION`

const GET_PRICES = `${namespace}/GET_PRICES`
const SET_PRICES = `${namespace}/SET_PRICES`

export const getPaymentMethods = createAction(GET_PAYMENT_METHODS)
export const setPaymentMethods = createAction(SET_PAYMENT_METHODS)
export const addPaymentMethods = createAction(ADD_PAYMENT_METHODS)
export const removePaymentMethod = createAction(REMOVE_PAYMENT_METHOD)
export const setDefaultPaymentMethod = createAction(SET_DEFAULT_PAYMENT_METHOD)

export const getInvoices = createAction(GET_INVOICES)
export const setInvoices = createAction(SET_INVOICES)

export const setupIntent = createAction(SETUP_INTENT)
export const setIntent = createAction(SET_INTENT)

export const getSubscription = createAction(GET_SUBSCRIPTION)
export const setSubscription = createAction(SET_SUBSCRIPTION)
export const updateSubscription = createAction(UPDATE_SUBSCRIPTION)
export const deleteSubscription = createAction(DELETE_SUBSCRIPTION)


export const getPrices = createAction(GET_PRICES)
export const setPrices = createAction(SET_PRICES)

const initialState = {
  methods: null,
  invoices: [],
  intent: [],
  subscription: [],
  prices: [],
}

export default handleActions(
  {
    [SET_PAYMENT_METHODS]: (state, { payload }) => ({
      ...state,
      methods: payload,
    }),
    [SET_INVOICES]: (state, { payload }) => ({
      ...state,
      invoices: payload,
    }),
    [SET_INTENT]: (state, { payload }) => ({
      ...state,
      intent: payload,
    }),
    [SET_SUBSCRIPTION]: (state, { payload }) => ({
      ...state,
      subscription: payload,
    }),
    [SET_PRICES]: (state, { payload }) => ({
      ...state,
      prices: payload,
    }),
  },
  initialState,
)

export const paymentSelector = (state) => state[namespace]

function* addPaymentMethodsRequest() {
  while (true) {
    const { payload } = yield take(ADD_PAYMENT_METHODS)
    //yield put(setIsLoad(true));
    try {
      const { data } = yield call(
        resource.post,
        `/cabinet/payments`,
        payload.values,
      )
      yield put(push('/settings/subscriptions'))
      yield put(setPaymentMethods(data))

      yield put(payload.closeModal.close())
    } catch (err) {
      payload.setErrors.setErrors(err?.response?.data?.errors)
    } finally {
      //yield put(setIsLoad(false))
    }
  }
}

function* getPaymentMethodsRequest() {
  while (true) {
    const { payload } = yield take(GET_PAYMENT_METHODS)
    //yield put(setIsLoad(true));
    try {
      const { data } = yield call(
        resource.get,
        `/cabinet/payments/payment-methods`,
      )
      yield put(setPaymentMethods(data))
    } catch (err) {
      console.log(err)
    } finally {
      //yield put(setIsLoad(false))
    }
  }
}
function* removePaymentMethodRequest() {
  while (true) {
    const { payload } = yield take(REMOVE_PAYMENT_METHOD)
    //yield put(setIsLoad(true));
    try {
      yield call(resource.post, `/cabinet/payments/remove-payment`, {
        id: payload,
      })
      yield put(getPaymentMethods())
    } catch (err) {
      console.log(err)
    } finally {
      //yield put(setIsLoad(false))
    }
  }
}
function* setDefaultPaymentMethodRequest() {
  while (true) {
    const { payload } = yield take(SET_DEFAULT_PAYMENT_METHOD)
    //yield put(setIsLoad(true));
    try {
      const { data } = yield call(
        resource.post,
        `cabinet/payments/set-default-method`,
        {
          paymentMethodID: payload,
        },
      )
      yield put(setPaymentMethods(data))
    } catch (err) {
      console.log(err)
    } finally {
      //yield put(setIsLoad(false))
    }
  }
}
function* getInvoicesRequest() {
  while (true) {
    yield take(GET_INVOICES)
    //yield put(setIsLoad(true));
    try {
      const { data } = yield call(resource.get, `/cabinet/payments/invoices`)
      yield put(setInvoices(data))
    } catch (err) {
      // payload.setErrors.setErrors(err?.response?.data?.errors)
      console.log(err)
    } finally {
      //yield put(setIsLoad(false))
    }
  }
}
function* setupIntentRequest() {
  while (true) {
    yield take(SETUP_INTENT)
    //yield put(setIsLoad(true));
    try {
      const { data } = yield call(
        resource.get,
        `/cabinet/payments/setup-intent`,
      )
      yield put(setIntent(data))
    } catch (err) {
      // payload.setErrors.setErrors(err?.response?.data?.errors)
      console.log(err)
    } finally {
      //yield put(setIsLoad(false))
    }
  }
}
function* getSubscriptionRequest() {
  while (true) {
    yield take(GET_SUBSCRIPTION)
    //yield put(setIsLoad(true));
    try {
      const { data } = yield call(
        resource.get,
        `/cabinet/payments/subscription`,
      )
      yield put(setSubscription(data))
    } catch (err) {
      // payload.setErrors.setErrors(err?.response?.data?.errors)
      console.log(err)
    } finally {
      //yield put(setIsLoad(false))
    }
  }
}
function* updateSubscriptionRequest() {
  while (true) {
    const { payload } = yield take(UPDATE_SUBSCRIPTION)
    //yield put(setIsLoad(true));
    try {
      const { data } = yield call(
        resource.put,
        `/cabinet/payments/subscription`,
        payload,
      )
      if (data.subscription_updated) yield put(successMessage('Plan is update'))
      yield put(getSubscription())
    } catch (err) {
      // payload.setErrors.setErrors(err?.response?.data?.errors)
      console.log(err)
    } finally {
      //yield put(setIsLoad(false))
    }
  }
}
function* deleteSubscriptionRequest() {
  while (true) {
    yield take(DELETE_SUBSCRIPTION)
    //yield put(setIsLoad(true));
    try {
      const { data } = yield call( 
        resource.delete,
        `/cabinet/payments/subscription`,
      )
      yield put(setSubscription(data))
      yield put(successMessage("Your plan will downgraded"))
      
    } catch (err) {
      // payload.setErrors.setErrors(err?.response?.data?.errors)
      console.log(err)
    } finally {
      //yield put(setIsLoad(false))
    }
  }
}
function* getPricesRequest() {
  while (true) {
    yield take(GET_PRICES)
    //yield put(setIsLoad(true));
    try {
      const { data } = yield call(resource.get, `/cabinet/payments/prices`)
      yield put(setPrices(data))
    } catch (err) {
      // payload.setErrors.setErrors(err?.response?.data?.errors)
      console.log(err)
    } finally {
      //yield put(setIsLoad(false))
    }
  }
}
export function* payments() {
  yield all([
    getPaymentMethodsRequest(),
    addPaymentMethodsRequest(),
    removePaymentMethodRequest(),
    getInvoicesRequest(),
    setupIntentRequest(),
    getSubscriptionRequest(),
    updateSubscriptionRequest(),
    getPricesRequest(),
    setDefaultPaymentMethodRequest(),
    deleteSubscriptionRequest()
  ])
}
