import React, { useEffect, useMemo } from 'react'
import ClassNames from 'classnames'

import styles from './aside.module.sass'
import noActivity from './NoActivity.png'
import Typography from '../ui/typography'
import ProgressBar from '../progressbar'

import { format } from 'date-fns'

import usePathname from '../../hooks/usePathname'
import { useDispatch, useSelector } from 'react-redux'
import { getUser, userSelector } from '../../store/reducers/user'

import { Link, useHistory } from 'react-router-dom'
import AddUserButton from '../add-user-button'
import useMobile from '../../hooks/useMobile'
import { friendNotificationSelector } from '../../store/reducers/activities'
import { number } from 'prop-types'

// import Modal from "../ui/modal";
// import SearchUser from "../search-user";

const Aside = ({ className, small = false, onClick }) => {
  const path = usePathname(['/settings'])
  if (path) {
    return null
  }
  return <AsideBig className={className} onClick={onClick} small={small} />
}

const AsideBig = ({ className, onClick, small }) => {
  const user = useSelector(userSelector)
  const friendsReq = useSelector(friendNotificationSelector)
  const history = useHistory()
  const dispatch = useDispatch()
  const isMobile = useMobile(428)
  useEffect(() => {
    if (!user) {
      dispatch(getUser())
    }
  }, [dispatch, user])

  const username = useMemo(() => {
    const name = []
    if (!user) {
      return null
    }
    if (user.firstName) {
      name.push(user.firstName)
    }
    if (user.lastName) {
      name.push(user.lastNmae)
    }
    if (!user.firstName && !user.lastName) {
      name.push(user.email)
    }
    return name.join(' ')
  }, [user])
  if (!user) {
    return null
  }
  return (
    <div className={ClassNames(styles.wrapper, className)}>
      <div className={styles.header}>
        {!isMobile && (
          <>
            <ArrowButton onClick={onClick} rotate={small} />
            <EditButton
              onClick={() => {
                history.push('/settings/general')
              }}
            />
          </>
        )}
      </div>
      <div className={styles.container}>
        <Link
          to={`/home/${user.id}`}
          // className={ClassNames(styles.friend_image, {
          //   [styles.friend_image_small]: small,
          // })}
        >
          <div
            className={ClassNames(styles.avatar, {
              [styles.avatar__small]: small,
            })}
          >
            <img
              className={ClassNames(styles.avatar__image, {
                [styles.avatar__image_small]: small,
              })}
              src={user.profile_photo_path}
              alt="avatar"
            />
            <h2
              className={ClassNames(styles.avatar__title, {
                [styles.none]: small,
              })}
            >
              Hi, {username || ''}{' '}
            </h2>
          </div>
        </Link>

        <ProgressBar
          width={user?.fulfil_percentage}
          className={ClassNames(styles.progress, { [styles.none]: small })}
        />
        <Typography
          className={ClassNames(styles.description, { [styles.none]: small })}
          component="p"
        >
          {user?.about}
        </Typography>
        <PrimaryButton
          className={ClassNames(styles.updateButton, { [styles.none]: small })}
          onClick={() => {
            history.push('/settings/general')
          }}
        >
          Update Profile
        </PrimaryButton>
        <div className={ClassNames(styles.row, { [styles.none]: small })}>
          <Typography component="h2" variant="titleHeavy">
            Connections
          </Typography>
          <Typography
            component="span"
            className={styles.seeAllButton}
            onClick={() => {
              history.push(`/friends/all`)
            }}
          >
            See all
          </Typography>
        </div>
        <div
          className={ClassNames(styles.separator, { [styles.none]: !small })}
        />
        <div
          className={ClassNames(styles.file__wrapper, {
            [styles.file__wrapper_small]: small,
          })}
        >
          <AddUserButton
            className={ClassNames(styles.file, { [styles.file__small]: small })}
            onClick={() => {
              history.push('/timelines/search?param=people&name')
            }}
          />
          <FriendsSmall friends={user.userFriends} small={small} />
        </div>
        <div
          className={ClassNames(styles.separator, { [styles.none]: !small })}
        />
        <div className={ClassNames(styles.row, { [styles.none]: small })}>
          <Typography component="h2" variant="titleHeavy">
            Last activity
          </Typography>
          <Typography
            component="span"
            onClick={() => {
              history.push('/activity')
            }}
            className={styles.seeAllButton}
          >
            See all
          </Typography>
        </div>

        {friendsReq?.length
          ? friendsReq.map((friend) => (
              <FriendRequest key={friend.id} small={small} friend={friend} />
            ))
          : null}

        {user?.lastActivity.length
          ? user.lastActivity.map((activity) => (
              <Activity key={activity.id} small={small} event={activity} />
            ))
          : null}
        {!friendsReq?.length && !user?.lastActivity.length ? (
          <NoActivity className={ClassNames({ [styles.none]: small })} />
        ) : null}

        <NotifyButton className={ClassNames({ [styles.none]: !small })} />
      </div>
    </div>
  )
}

export default Aside

const ArrowButton = ({ rotate, onClick }) => (
  <button
    className={ClassNames(styles.button__arrow, {
      [styles.button__arrow_rotate]: rotate,
    })}
    type="button"
    onClick={onClick}
  >
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect width="24" height="24" rx="8" fill="white" />
      <rect
        x="0.5"
        y="0.5"
        width="23"
        height="23"
        rx="7.5"
        stroke="#D5D5D5"
        strokeOpacity="0.5"
      />
      <path
        d="M12 17L17 12L12 7"
        stroke="#9CA2BF"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <rect
        x="7.375"
        y="6.375"
        width="0.75"
        height="11.25"
        rx="0.375"
        stroke="#9CA2BF"
        strokeWidth="0.75"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  </button>
)

const EditButton = ({ onClick }) => (
  <button className={styles.button__edit} onClick={onClick}>
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M11 1C11.5288 1 11.9869 1.20949 12.3544 1.57991L14.4217 3.64718C14.7924 4.01786 15 4.47274 15 5C15 5.52385 14.794 6.06728 14.4217 6.43959L6.89021 13.9675C6.36661 14.5715 5.62438 14.9426 4.7564 15.0016H2H1V14.0016L1.00325 11.1647C1.06698 10.3758 1.43373 9.64229 1.98196 9.15991L9.56093 1.58101C9.93285 1.20718 10.4754 1 11 1ZM3.35157 10.6161C3.14601 10.7979 3.01885 11.0522 3 11.2452V13.0025L4.68578 13.004C4.95369 12.9851 5.20307 12.8604 5.42749 12.6054L9.80933 8.22354L7.77751 6.19172L3.35157 10.6161ZM9.19197 4.77776L11.2235 6.80933L12.9895 5.04339L10.9582 3.01212L9.19197 4.77776Z"
        fill="#1D2A64"
      />
    </svg>
  </button>
)
const PrimaryButton = ({ children, className, onClick }) => (
  <button
    className={ClassNames(styles.button__primary, className)}
    onClick={onClick}
  >
    {children}
  </button>
)
const NotifyButton = ({ className }) => (
  <button className={ClassNames(styles.button__notify, className)}>
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M18.448 10.1784C18.448 6.48977 16.414 3.92966 13.7822 3.16108C13.6869 2.9145 13.5363 2.68526 13.3315 2.49291C12.6321 1.8357 11.5421 1.8357 10.8426 2.49291C10.6383 2.68483 10.4879 2.91348 10.3926 3.15944C7.7574 3.92466 5.72612 6.47919 5.72612 10.1784C5.72612 12.5718 5.24358 13.9129 4.3503 14.8848C4.20095 15.0473 3.79632 15.4196 3.52222 15.6717L3.5222 15.6717L3.52219 15.6717C3.39251 15.791 3.29205 15.8835 3.26168 15.9141L3 16.1794V19.2782H8.53218C8.71925 20.2433 9.24289 20.889 10.1899 21.4668C11.3548 22.1777 12.8193 22.1777 13.9843 21.4668C14.82 20.9569 15.3803 20.1731 15.6077 19.2782H21.1741V16.1794L20.9124 15.9141C20.8849 15.8861 20.7885 15.7972 20.6625 15.681L20.6625 15.681L20.6624 15.6809C20.3892 15.4288 19.9767 15.0483 19.825 14.8827C18.9312 13.9062 18.448 12.5634 18.448 10.1784ZM13.6652 19.2782H10.432C10.5603 19.517 10.7792 19.6974 11.1366 19.9155C11.7202 20.2716 12.4539 20.2716 13.0375 19.9155C13.3078 19.7506 13.519 19.5323 13.6652 19.2782ZM18.4845 16.1099C18.6757 16.3187 19.0856 16.7005 19.3567 16.95V17.4608H4.81741V16.9504C5.08645 16.7039 5.49752 16.3224 5.68839 16.1147C6.90218 14.794 7.54353 13.0115 7.54353 10.1784C7.54353 6.74336 9.66333 4.73738 12.0871 4.73738C14.504 4.73738 16.6306 6.75371 16.6306 10.1784C16.6306 13.0024 17.2721 14.7855 18.4845 16.1099Z"
        fill="#1D2A64"
      />
      <circle cx="19" cy="5" r="5" fill="#EEF2F7" />
      <circle cx="19" cy="5" r="3" fill="#ED7F7F" />
    </svg>
  </button>
)

const NoActivity = ({ className }) => {
  return (
    <div className={ClassNames(styles.noActivity, className)}>
      <img src={noActivity} alt="no_activity" />
      <h2>No Activity</h2>
    </div>
  )
}

const Activity = ({ event, small }) => {
  return (
    <div
      className={ClassNames(styles.activityItem, { [styles.actItem]: small })}
    >
      <div
        className={ClassNames(styles.activeImageWrapper, {
          [styles.disActiveImageWrapper]: small,
        })}
      >
        <img src={event.friend.profile_photo_path} alt="mini_avatar" />
      </div>
      <div
        className={ClassNames({
          [styles.eventDescription]: !small,
          [styles.noDescription]: small,
        })}
      >
        <Link
          to={{
            pathname: `/friend/${event?.friend.id}/event/${event?.event.id}`,
          }}
        >
          <Typography
            component="span"
            className={styles.eventHeavy}
            variant="smallTextHeavy"
          >
            {event.friend.firstName}
          </Typography>
          <Typography
            component="span"
            className={styles.eventNormal}
            variant="smallText"
          >
            {event.message}
          </Typography>
          <Typography
            component="span"
            className={styles.eventHeavy}
            variant="smallTextHeavy"
          >
            {event.event.title}
          </Typography>
          <Typography
            component="span"
            className={styles.eventNormal}
            variant="smallText"
          >
            entry
          </Typography>
        </Link>

        <Typography
          className={styles.eventNormal}
          component="span"
          variant="smallText"
        >
          {format(Date.parse(event.created_at), 'PPPp')}
        </Typography>
      </div>
    </div>
  )
}
const FriendRequest = ({ friend, small }) => {
  const history = useHistory()
  return (
    <div
      className={ClassNames(styles.activityItem, { [styles.actItem]: small })}
    >
      <div
        className={ClassNames(styles.activeImageWrapper, {
          [styles.disActiveImageWrapper]: small,
        })}
      >
        <img src={friend.profile_photo_path} alt="mini_avatar" />
      </div>
      <div
        className={ClassNames({
          [styles.eventDescription]: !small,
          [styles.noDescription]: small,
        })}
      >
        <Link
          to="/activity?notification=friends"
          // onClick={() => {
          //   history.push('/activity?notification=friends')
          // }}
        >
          <Typography
            component="span"
            className={styles.eventHeavy}
            variant="smallTextHeavy"
          >
            {friend.firstName}
          </Typography>
          <Typography
            component="span"
            className={styles.eventNormal}
            variant="smallText"
          >
            {' requested add to friends'}
          </Typography>
          {/* <Typography
            component="span"
            className={styles.eventHeavy}
            variant="smallTextHeavy"
          >
            
          </Typography>
          <Typography
            component="span"
            className={styles.eventNormal}
            variant="smallText"
          >
            entry
          </Typography> */}
        </Link>

        {/* <Typography
          className={styles.eventNormal}
          component="span"
          variant="smallText"
        >
          {format(Date.parse(event.created_at), 'PPPp')}
        </Typography> */}
      </div>
    </div>
  )
}

// const AsideSmall: React.FC<{ className?: string, onClick?: () => void }> = ({ className, onClick }) => {
//     return (
//         <div className={className}>
//             <div className={styles.header}>
//                 <ArrowButton rotate onClick={onClick} />
//                 <EditButton />
//             </div>
//             <div className={styles.container}>
//                 <div className={styles.avatar__small}>
//                     <img className={styles.avatar__image_small} src={avatar} alt="avatar" />
//                 </div>
//                 <div className={styles.separator} />
//                 <File className={styles.file__small} />
//                 <div className={styles.separator} />
//                 <NotifyButton />
//             </div>
//         </div>
//     )
// }

const FriendsSmall = ({ friends, small = false }) => {
  if (!friends || friends.length <= 0) {
    return null
  }

  return (
    <>
      {friends.map((e) => (
        <Link
          to={`/friend/${e.id}`}
          className={ClassNames(styles.friend_image, {
            [styles.friend_image_small]: small,
          })}
          key={e.id}
        >
          <img src={e.profile_photo_path} alt="friend" />
        </Link>
      ))}
    </>
  )
}
