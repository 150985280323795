import React from "react";
import loader from "./loader.gif";
import styles from "./loader.module.sass";
const Preloader = () => {
  return (
    <div className={styles.loaderOverlay}>
      <div className={styles.loaderOverlay__image}>
        <img src={loader} alt="" />
      </div>
    </div>
  );
};
export default Preloader;
