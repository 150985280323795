import React, { useState, useEffect } from "react";
import styles from "./support.module.sass";
import Typography from "../../../../../components/ui/typography";
import down from "./icons/down.svg";
import up from "./icons/up.svg";
import { getQuestions } from "../../../../../store/reducers/support";
import { questionsSelector } from "../../../../../store/reducers/support";
import { useDispatch, useSelector } from "react-redux";
import { useGlobalCloseSmiles } from "../../../../../hooks/useCloseMenu";
import { useRef } from "react";

const Support = () => {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getQuestions());
  }, [dispatch]);

  const questions = useSelector(questionsSelector);

  return (
    <div className={styles.wrapper}>
      <div className={styles.header}>
        <h1>support</h1>
        <button type="button" className={styles.featureButton}>
          Feature Request
        </button>
      </div>
      {questions &&
        questions.map(({ id, title, content }) => (
          <QuestionAndAnswer key={id} title={title} content={content} />
        ))}
    </div>
  );
};

const QuestionAndAnswer = ({ title, content }) => {
  const [isOpen, setIsOpen] = useState(false);
  const toggleQuestion = () => {
    setIsOpen(!isOpen);
  };
  const ref = useRef();
  const handleClose = () => {
    setIsOpen(false);
  };
  useGlobalCloseSmiles(ref, handleClose, isOpen);
  return (
    <div className={styles.option}>
      <div className={styles.question} onClick={toggleQuestion}>
        <Typography component="h3" variant="label" className={styles.title}>
          {title}
        </Typography>
        <img
          src={isOpen ? up : down}
          alt="down-arrow"
          className={styles.arrow}
        />
      </div>
      {isOpen && (
        <Typography
          ref={ref}
          component="p"
          variant="root"
          className={styles.answer}
        >
          {content}
        </Typography>
      )}
    </div>
  );
};

export default Support;
