import React from "react";
import Typography from "../ui/typography";
import styles from "./search-dropdown.module.sass";
import { Link } from "react-router-dom";
const People = ({ img, friendId, name, isFriend }) => {
  return (
    <div className={styles.peopleWrapper}>
      <Link
        to={{
          pathname: `/friend/${friendId}`,
        }}
      >
        <div className={styles.contentWrapper__img}>
          <img src={img} alt="people avatar" />
        </div>
        <div>
          <Typography variant="label" component="h3">
            {name}
          </Typography>
          <Typography
            className={styles.friend}
            variant="smallText"
            component="span"
          >
            {isFriend ? "Friend" : "Add to friends"}
          </Typography>
        </div>
      </Link>
    </div>
  );
};
export default People;
