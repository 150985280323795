import { useEffect, useRef } from "react";
import { useSelector } from "react-redux";

export default function useQueryReRender(callback) {
  const location = useSelector(({ router: { location } }) => location);

  const { query } = location;

  const call = useRef();
  call.current = callback;

  useEffect(() => {
    if (Object.keys(query).length) {
      call.current(query);
    }
  }, [location.search, query]);
}
