import React, { useEffect } from "react";
import { useState } from "react";
import Typography from "../../../../../components/ui/typography";
import styles from "./shared.module.sass";
import { Switcher } from "../../../../../components/ui/formik";
import classNames from "classnames";
import { useDispatch } from "react-redux";
import {
  postUnsubscribe,
  postSubscribe,
} from "../../../../../store/reducers/settings";
const CategoryItem = ({ timeline, detailId }) => {
  const [switcherState, setSwitcherState] = useState(timeline?.subscribed);

  const dispatch = useDispatch();
  useEffect(() => {
    setSwitcherState(timeline?.subscribed);
  }, [timeline]);
  const switcherHandler = () => {
    if (switcherState) {
      dispatch(
        postUnsubscribe({ timelineId: timeline.id, detailId: detailId })
      );
    } else if (!switcherState) {
      dispatch(postSubscribe({ timelineId: timeline.id, detailId: detailId }));
    }
  };
  return (
    <div className={styles.categoryWrapper}>
      <div className={styles.categoryWrapper__info}>
        <div
          style={{ backgroundColor: timeline.color }}
          className={styles.categoryImgWrapper}
        >
          <img src={timeline.image} alt="" />
        </div>
        <Typography component="h3" variant="title">
          {timeline.name}
        </Typography>
      </div>

      <Switcher onChange={switcherHandler} switcherState={switcherState} />
    </div>
  );
};
export default CategoryItem;
