import { createAction, handleActions } from "redux-actions";
import {
  all,
  call,
  put,
  take,
  takeEvery,
  takeLatest,
} from "redux-saga/effects";
import { resource } from "../../api";
import { dangerMessage } from "../../components/ui/alert/alertReducer";
import event from "../../screens/event";
import { getFriendEvent } from "./friends";
const namespace = "comments";

const GET_COMMENTS = `${namespace}/GET_COMMENTS`;
const SET_COMMENTS = `${namespace}/SET_COMMENTS`;
const DELETE_COMMENT = `${namespace}/DELETE_COMMENT`;
const SEND_COMMENT = `${namespace}/SEND_COMMENT`;
const SEND_REPLY = `${namespace}/SEND_REPLY`;
const SEND_LIKE = `${namespace}/SEND_LiKE`;
const EDIT_COMMENT = `${namespace}/EDIT_COMMENT`;
const SET_IS_LOAD = `${namespace}/SET_IS_LOAD`;
const SET_ERROR = `${namespace}/SET_ERROR`;
const DELETE_LIKE = `${namespace}/DELETE_LIKE`;
const GET_MEDIA_COMMENTS = `${namespace}/GET_MEDIA_COMMENTS`;
const SET_MEDIA_COMMENTS = `${namespace}/SET_MEDIA_COMMENTS`;
const SEND_MEDIA_COMMENT = `${namespace}/SEND_MEDIA_COMMENT`;
const SEND_MEDIA_REPLY = `${namespace}/SEND_MEDIA_REPLY`;
const SEND_MEDIA_LIKE = `${namespace}/SEND_MEDIA_LIKE`;
const DELETE_MEDIA_LIKE = `${namespace}/DELETE_MEDIA_LIKE`;
const MEDIA_EDIT_COMMENT = `${namespace}/MEDIA_EDIT_COMMENT`;
const MEDIA_DELETE_COMMENT = `${namespace}/MEDIA_DELETE_COMMENT`;
const GET_APPROVES = `${namespace}/GET_APPROVES`;
const SET_APPROVES = `${namespace}/SET_APPROVES`;
const SET_REJECT = `${namespace}/SET_REJECT`;
const SET_APPROVE = `${namespace}/SET_APPROVE`;
const SET_ITEM = `${namespace}/SET_ITEM`;

export const getComments = createAction(GET_COMMENTS);
export const sendComment = createAction(SEND_COMMENT);
export const sendReply = createAction(SEND_REPLY);
export const sendLike = createAction(SEND_LIKE);
export const setComments = createAction(SET_COMMENTS);
export const deleteComment = createAction(DELETE_COMMENT);
export const editComment = createAction(EDIT_COMMENT);
export const deleteLike = createAction(DELETE_LIKE);

export const getMediaComments = createAction(GET_MEDIA_COMMENTS);
export const setMediaComments = createAction(SET_MEDIA_COMMENTS);
export const sendMediaComment = createAction(SEND_MEDIA_COMMENT);
export const sendMediaReply = createAction(SEND_MEDIA_REPLY);
export const sendMediaLike = createAction(SEND_MEDIA_LIKE);
export const deleteMediaLike = createAction(DELETE_MEDIA_LIKE);
export const setIsLoad = createAction(SET_IS_LOAD);
export const setError = createAction(SET_ERROR);
export const mediaEditComment = createAction(MEDIA_EDIT_COMMENT);
export const mediaDeleteComment = createAction(MEDIA_DELETE_COMMENT);

export const getApproves = createAction(GET_APPROVES);
export const setReject = createAction(SET_REJECT);
export const setApprove = createAction(SET_APPROVE);

export const setApproves = createAction(SET_APPROVES);

export const setItem = createAction(SET_ITEM);

export const initialState = {
  commentsToApprove: null,
  comments: null,
  isLoad: false,
  error: null,
  mediaComments: null,
  item: null,
};

export default handleActions(
  {
    [SET_COMMENTS]: (state, { payload }) => ({ ...state, comments: payload }),
    [SET_MEDIA_COMMENTS]: (state, { payload }) => ({
      ...state,
      mediaComments: payload,
    }),
    [SET_IS_LOAD]: (state, { payload }) => ({ ...state, isLoad: payload }),
    [SET_ERROR]: (state, { payload }) => ({ ...state, error: payload }),
    [SET_APPROVES]: (state, { payload }) => ({
      ...state,
      commentsToApprove: payload,
    }),
    [SET_ITEM]: (state, { payload }) => ({ ...state, item: payload }),
  },
  initialState
);

export const commentsSelector = (state) => state[namespace].comments;
export const mediaCommentsSelector = (state) => state[namespace].mediaComments;
export const commentsLoadSelector = (state) => state[namespace].isLoad;
export const approvesSelector = (state) => state[namespace].commentsToApprove;
export const isLoadSelector = (state) => state[namespace].isLoad;
export const itemSelector = (state) => state[namespace].item;

function* sendCommentRequest() {
  while (true) {
    const formData = new FormData();
    const {
      payload: { message, eventId, images, id },
    } = yield take(SEND_COMMENT);
    formData.append("body", message);
    images.forEach((image) => {
      formData.append("files[]", image.file);
    });
    yield put(setIsLoad(true));

    try {
      const {
        data: { data },
      } = yield call(
        resource.post,
        `/event/comment/${eventId}`,
        images.length > 0 ? formData : { body: message },
        images.length > 0 && {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }
      );

      yield put(setComments(data.comments));
      yield put(getFriendEvent({ id: id, eventId: eventId }));
    } catch (err) {
      yield put(setIsLoad(false));
      console.log(err);
      yield put(dangerMessage("First, enter the comment!"));
    } finally {
      yield put(setIsLoad(false));
    }
  }
}

function* sendDeleteCommentRequest() {
  while (true) {
    const {
      payload: { eventId, commentID },
    } = yield take(DELETE_COMMENT);
    console.log(eventId, commentID);
    try {
      const {
        data: { data },
      } = yield call(resource.delete, `/event/comment/${eventId}/${commentID}`);
      yield put(setComments(data.comments));
    } catch (err) {
      console.log(err);
    }
  }
}
function* sendMediaDeleteCommentRequest() {
  while (true) {
    const {
      payload: { eventId, commentID },
    } = yield take(MEDIA_DELETE_COMMENT);
    console.log(eventId, commentID);
    try {
      const {
        data: { data },
      } = yield call(resource.delete, `/event/comment/${eventId}/${commentID}`);
      yield put(setMediaComments(data.comments));
    } catch (err) {
      console.log(err);
    }
  }
}

function* editCommentRequest() {
  while (true) {
    const {
      payload: { body, eventId, commentId },
    } = yield take(EDIT_COMMENT);
    console.log(body, eventId, commentId, "im triggered");

    try {
      const {
        data: { data },
      } = yield call(resource.patch, `/event/comment/${eventId}/${commentId}`, {
        body,
      });
      yield put(setComments(data.comments));
    } catch (err) {
      console.log(err);
    } finally {
      yield put(setIsLoad(false));
    }
  }
}
function* editMediaCommentRequest() {
  while (true) {
    const {
      payload: { body, eventId, commentId },
    } = yield take(MEDIA_EDIT_COMMENT);
    console.log(body, eventId, commentId, "im trigerred too");
    try {
      const {
        data: { data },
      } = yield call(resource.patch, `/event/comment/${eventId}/${commentId}`, {
        body,
      });
      yield put(setMediaComments(data.comments));
    } catch (err) {
      console.log(err);
    } finally {
      yield put(setIsLoad(false));
    }
  }
}

export function* getCommentsRequest({ payload }) {
  try {
    const {
      data: { data },
    } = yield call(resource.get, `/event/comment/${payload}`);

    yield put(setComments(data.comments));
  } catch (err) {
    console.log(err);
  }
}

export function* getMediaCommentsRequest({ payload }) {
  const { eventId, mediaId } = payload;

  try {
    const {
      data: { data },
    } = yield call(resource.get, `/event/comment/${eventId}/media/${mediaId}`);

    yield put(setMediaComments(data.comments));
  } catch (err) {
    console.log(err);
  }
}

function* sendReplyRequest() {
  while (true) {
    const {
      payload: { eventId, body, parent_id },
    } = yield take(SEND_REPLY);
    try {
      const {
        data: { data },
      } = yield call(resource.post, `/event/comment/${eventId}`, {
        body,
        parent_id,
      });
      const { comments } = data;
      yield put(setComments(comments));
    } catch (err) {
      console.log(err);
    }
  }
}

function* sendMediaReplyRequest() {
  while (true) {
    const {
      payload: { eventId, body, parent_id, mediaId },
    } = yield take(SEND_MEDIA_REPLY);
    try {
      const {
        data: { data },
      } = yield call(
        resource.post,
        `/event/comment/${eventId}/media/${mediaId}`,
        {
          body,
          parent_id,
        }
      );
      const { comments } = data;
      yield put(setMediaComments(comments));
    } catch (err) {
      console.log(err);
    }
  }
}

function* sendLikeRequest({ payload: { eventId, commentId } }) {
  try {
    const {
      data: { data },
    } = yield call(
      resource.post,
      `/event/comment/${eventId}/${commentId}/like`
    );
    console.log("hi, from send LIKE");
    yield put(setComments(data.comments));
  } catch (err) {
    yield put(dangerMessage("You already liked it"));
  }
}

function* sendDeleteLikeRequest({ payload: { eventId, commentId } }) {
  try {
    const {
      data: { data },
    } = yield call(
      resource.post,
      `/event/comment/${eventId}/${commentId}/like/0`
    );
    console.log(data.comments);
    yield put(setComments(data.comments));
  } catch (err) {
    yield put(dangerMessage("Something went wrong"));
  }
}

function* sendMediaLikeRequest({ payload: { eventId, commentId } }) {
  try {
    const {
      data: { data },
    } = yield call(
      resource.post,
      `/event/comment/${eventId}/${commentId}/like`
    );

    yield put(setMediaComments(data.comments));
  } catch (err) {
    yield put(dangerMessage("You already liked it"));
  }
}

function* sendMediaDeleteLikeRequest({ payload: { eventId, commentId } }) {
  try {
    const {
      data: { data },
    } = yield call(
      resource.post,
      `/event/comment/${eventId}/${commentId}/like/0`
    );
    yield put(setMediaComments(data.comments));
  } catch (err) {
    yield put(dangerMessage("Something went wrong"));
  }
}

function* sendMediaCommentRequest() {
  while (true) {
    const {
      payload: { body, eventId, id, mediaId },
    } = yield take(SEND_MEDIA_COMMENT);
    console.log(mediaId, id, "from saga");
    try {
      const {
        data: {
          data: { comments },
        },
      } = yield call(
        resource.post,
        `/event/comment/${eventId}/media/${mediaId}`,
        { body }
      );

      yield put(setMediaComments(comments));
    } catch (err) {
      yield put(setIsLoad(false));
      console.log(err);
      yield put(dangerMessage("First, enter the comment!"));
    }
  }
}

export function* getApprovesRequest({ payload }) {
  const { eventId } = payload;
  try {
    const {
      data: { data },
    } = yield call(
      resource.get,
      `/event/comment/${eventId}/getToApprove
    `
    );

    yield put(setApproves(data.comments));
  } catch (err) {
    console.log(err);
  }
}

function* sendRejectRequest({ payload }) {
  const { eventId, commentId } = payload;
  try {
    const {
      data: { data },
    } = yield call(
      resource.post,
      `/event/comment/${eventId}/${commentId}/setStatus/0`
    );
    console.log(data, "dataimExpected");
    yield put(setApproves(data.comments));
  } catch (err) {
    console.log(err);
  }
}
function* sendApproveRequest({ payload }) {
  const { eventId, commentId } = payload;
  try {
    const {
      data: { data },
    } = yield call(
      resource.post,
      `/event/comment/${eventId}/${commentId}/setStatus/1`
    );
    console.log(data, "dataimExpected");
    yield put(setApproves(data.comments));
    yield put(getComments(eventId));
  } catch (err) {
    console.log(err);
  }
}
export function* commentsSaga() {
  return yield all([
    takeLatest(SET_REJECT, sendRejectRequest),
    takeLatest(SET_APPROVE, sendApproveRequest),
    takeEvery(GET_APPROVES, getApprovesRequest),
    sendCommentRequest(),
    sendMediaReplyRequest(),
    takeEvery(GET_COMMENTS, getCommentsRequest),
    sendReplyRequest(),
    editCommentRequest(),
    editMediaCommentRequest(),
    sendDeleteCommentRequest(),
    sendMediaDeleteCommentRequest(),
    sendMediaCommentRequest(),
    takeLatest(SEND_MEDIA_LIKE, sendMediaLikeRequest),
    takeLatest(DELETE_MEDIA_LIKE, sendMediaDeleteLikeRequest),
    takeLatest(SEND_LIKE, sendLikeRequest),
    takeLatest(DELETE_LIKE, sendDeleteLikeRequest),
    takeEvery(GET_MEDIA_COMMENTS, getMediaCommentsRequest),
  ]);
}
