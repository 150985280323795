import React, { useEffect } from "react";

import GalleryGridItem from "./GalleryGridItem";
import styles from "../media.module.sass";

const GalleryGrid = ({ eventData, handleGetItem }) => {
  return (
    <div className={styles.gridWrapper}>
      {eventData?.media?.map((item) => {
        return (
          <GalleryGridItem onClick={handleGetItem} key={item.id} item={item} />
        );
      })}
    </div>
  );
};
export default GalleryGrid;
