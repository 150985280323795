/* eslint-disable jsx-a11y/img-redundant-alt */
import React from "react";
import styles from "./event.module.sass";
import modalImage from "../assets/images/modalImage.png";
import ClassicButton from "../ui/buttons/ClassicButtonWithText";

const TimelineModal = React.forwardRef(
  ({ message, onClick, btnText = "Done" }, ref) => {
    return (
      <div className={styles.modalOverlay}>
        <div ref={ref} className={styles.modalOverlay__modal}>
          <div className={styles.modalOverlay__modal_image}>
            <img src={modalImage} alt="modal image" />
          </div>
          <p>{message}</p>
          <ClassicButton
            onClick={onClick}
            className={styles.modalOverlay__modal_btn}
          >
            {btnText}
          </ClassicButton>
        </div>
      </div>
    );
  }
);
export default TimelineModal;
