import { useDispatch } from "react-redux";
import { categoryUpdate } from "../../../../../store/reducers/settings";
import Checkbox from "../../../../../components/ui/checkbox";

const CheckboxControl = ({
  name,
  value,
  className,
  timelineId,
  disabled = false,
}) => {
  // const [field, meta, helpers] = useField({ name });
  // const { value } = meta;
  // const { setValue } = helpers;

  const dispatch = useDispatch();
  // let payload = { [name]: !value, id: timelineId };
  const handleChange = () => {
    dispatch(categoryUpdate([{ [name]: !value }, { id: timelineId }]));
    // setValue(!value);
  };

  return (
    <Checkbox
      name={name}
      checked={!!value}
      onChange={handleChange}
      className={className}
      id={timelineId}
      // error={!!meta.error}
      disabled={disabled}
    />
  );
};

export default CheckboxControl;
