import classNames from "classnames";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { disableCategoriesSwitchers } from "../../../../store/reducers/settings";
import {
  getCategories,
  timelinesSelector,
} from "../../../../store/reducers/timelines";
import styles from "./switcher.module.sass";

function Switcher({
  label,
  className,
  id,
  switcherState,
  onChange = () => console.log("hi"),
}) {
  const htmlFor = id ? id : `checkbox-${Math.random()}`;

  return (
    <>
      <input
        className={styles.switcher}
        type="checkbox"
        id={htmlFor}
        checked={switcherState ? switcherState : false}
        onChange={(e) => onChange(e)}
      />
      <label
        className={classNames(styles.switcher__label, className)}
        htmlFor={htmlFor}
      >
        {label}
      </label>
    </>
  );
}

export default Switcher;
