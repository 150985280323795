import React, { useEffect, useState } from "react";
import Typography from "../../../../../components/ui/typography";
import { Switcher } from "../../../../../components/ui/formik";
import styles from "./notifications.module.sass";
import { notificationTypes } from "./notificationsObj";
import { useDispatch } from "react-redux";
import {
  getNotifications,
  postNotifications,
} from "../../../../../store/reducers/settings";
import { useSelector } from "react-redux";
import { notificationSelector } from "../../../../../store/reducers/settings";

const Notifications = () => {
  const dispatch = useDispatch();
  const notifications = useSelector(notificationSelector);

  const [notificationStatus, setStatus] = useState(notifications);

  useEffect(() => {
    dispatch(getNotifications());
  }, []);

  useEffect(() => {
    setStatus(notifications);
  }, [notifications]);

  useEffect(() => {
    if (notificationStatus) dispatch(postNotifications(notificationStatus));
  }, [notificationStatus]);

  const switcherHandler = ({ target }) => {
    if (notificationStatus) {
      setStatus((prevState) => ({
        ...prevState,
        [target.id]: !notificationStatus[target.id],
      }));
    }
  };

  return (
    <div className={styles.wrapper}>
      <div className={styles.header}>
        <h1>notifications</h1>
      </div>

      <Notification
        title={notificationTypes[0].title}
        description={notificationTypes[0].description}
        switcherState={notificationStatus?.events_timelines}
        id={notificationStatus && Object.keys(notificationStatus)[0]}
        switcherHandler={switcherHandler}
      />
      <Notification
        title={notificationTypes[1].title}
        description={notificationTypes[1].description}
        switcherState={notificationStatus?.comments}
        id={notificationStatus && Object.keys(notificationStatus)[1]}
        switcherHandler={switcherHandler}
      />
      <Notification
        title={notificationTypes[2].title}
        description={notificationTypes[2].description}
        switcherState={notificationStatus?.updates}
        id={notificationStatus && Object.keys(notificationStatus)[2]}
        switcherHandler={switcherHandler}
      />
      <Notification
        title={notificationTypes[3].title}
        description={notificationTypes[3].description}
        switcherState={notificationStatus?.tags}
        id={notificationStatus && Object.keys(notificationStatus)[3]}
        switcherHandler={switcherHandler}
      />
      <Notification
        title={notificationTypes[4].title}
        description={notificationTypes[4].description}
        switcherState={notificationStatus?.reminders}
        id={notificationStatus && Object.keys(notificationStatus)[4]}
        switcherHandler={switcherHandler}
      />
      <Notification
        title={notificationTypes[5].title}
        description={notificationTypes[5].description}
        switcherState={notificationStatus?.marketing_emails}
        id={notificationStatus && Object.keys(notificationStatus)[5]}
        switcherHandler={switcherHandler}
      />
      <Notification
        title={notificationTypes[6].title}
        description={notificationTypes[6].description}
        switcherState={notificationStatus?.weekly_analytic}
        id={notificationStatus && Object.keys(notificationStatus)[6]}
        switcherHandler={switcherHandler}
      />
    </div>
  );
};

const Notification = ({
  title,
  id,
  switcherHandler,
  description,
  switcherState,
}) => {
  return (
    <div className={styles.notification}>
      <Typography component="h3" variant="label">
        {title}
      </Typography>
      <Typography component="p" variant="smallText">
        {description}
      </Typography>
      <Switcher
        onChange={switcherHandler}
        id={id}
        switcherState={switcherState}
        className={styles.switcher}
      />
    </div>
  );
};
export default Notifications;
