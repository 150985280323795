import styles from "./radio.module.sass";
import classNames from "classnames";
const RadioButton = ({ type, id, checked, onChange, date, handleDates }) => {
  return (
    <div
      className={classNames(styles.custom_input, {
        [styles.inactive]: !checked,
      })}
    >
      <input
        onClick={(e) => handleDates(e)}
        type="radio"
        id={id}
        checked={checked}
        name="radio-group"
        onChange={(e) => onChange(e)}
        date={date}
      />

      <label className={styles.custom} htmlFor={`${id}`}>
        {type}
      </label>
    </div>
  );
};
export default RadioButton;
