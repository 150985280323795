import React, { useState, useRef, useEffect } from 'react'
import styles from './comments.module.sass'
import Typography from '../ui/typography/Typography'
import ClassNames from 'classnames'
import { useDispatch, useSelector } from 'react-redux'
import classNames from 'classnames'
import {
  deleteComment,
  deleteLike,
  deleteMediaLike,
  sendLike,
  sendMediaLike,
  sendMediaReply,
  sendReply,
  mediaDeleteComment,
} from '../../store/reducers/comments'
import { userSelector } from '../../store/reducers/user'
import Picker from 'emoji-picker-react'

import { useGlobalClose, useGlobalCloseSmiles } from '../../hooks/useCloseMenu'

const UserComment = ({ comment, eventId, getEditData, mediaId, isLiked }) => {
  console.log('UserComment ~ mediaId', mediaId)
  const [reply, setReply] = useState(false)
  const [showDropDown, setDropDown] = useState(false)
  const dispatch = useDispatch()
  const user = useSelector(userSelector)
  const ref = useRef()

  const handleSubmitReply = (body) => {
    if (mediaId) {
      const payload = { body, eventId, mediaId, parent_id: comment.id }
      dispatch(sendMediaReply(payload))
    } else {
      const payload = {
        body,
        eventId,
        parent_id: comment.id,
      }
      dispatch(sendReply(payload))
    }
    setReply(false)
  }

  const handleLike = () => {
    if (mediaId) {
      if (isLiked === false) {
        dispatch(sendMediaLike({ eventId, commentId: comment.id }))
      } else {
        dispatch(deleteMediaLike({ eventId, commentId: comment.id }))
      }
    } else {
      if (isLiked === false) {
        console.log('what?')
        dispatch(
          sendLike({
            eventId,
            commentId: comment.id,
          }),
        )
      }
      if (isLiked === true) {
        dispatch(
          deleteLike({
            eventId,
            commentId: comment.id,
          }),
        )
      }
    }
  }

  const handleDelete = () => {
    if (mediaId) {
      dispatch(
        mediaDeleteComment({
          eventId,
          commentID: comment.id,
        }),
      )
    } else {
      dispatch(
        deleteComment({
          eventId,
          commentID: comment.id,
        }),
      )
    }

    // setDropDown(false);
  }

  const handleEdit = () => {
    getEditData({
      commentId: comment.id,
      comment: comment.body,
    })
    // setDropDown(false);
  }

  const editorBoolean = user?.id === comment.author.id
  const handleCloseDropDown = () => {
    setDropDown(false)
  }

  const handleCloseReply = () => {
    setReply(false)
  }

  useEffect(() => {
    if (showDropDown && ref.current) {
      window.addEventListener('click', handleCloseDropDown)
    } else {
      window.removeEventListener('click', handleCloseDropDown)
    }
    return () => window.removeEventListener('click', handleCloseDropDown)
  }, [showDropDown])

  if (!comment) {
    return null
  }
  return (
    <div
      className={ClassNames(styles.comment, {
        [styles.comment2]: comment.parent_id !== 0,
      })}
    >
      <div className={styles.avatarWrapper}>
        <img
          className={styles.userAvatar}
          src={comment.profile_photo_path}
          alt="user avatar"
        />
      </div>

      <div className={styles.groupWrapper}>
        <Typography className={styles.userName} component="h3" variant="label">
          {comment.author.firstName}
        </Typography>
        <Typography className={styles.userComment} component="p">
          {comment.body}
        </Typography>
        {comment.media && comment.media.length > 0  && <img src={comment.media[0].url} className={styles.commentImage} alt="media img"/>}
        <div className={styles.likeReply}>
          <div className={styles.likeReply}>
            <div className={styles.likesContainer}>
              <button className={styles.likeButton} onClick={handleLike} />
              <Typography component="span" className={styles.likeCounter}>
                {comment.likes}
              </Typography>
            </div>
            <div className={styles.buttonsContainer}>
              <div className={styles.buttonWrapper}>
                <input
                  type="button"
                  onClick={() => {
                    setReply(!reply)
                  }}
                  className={styles.replyButton}
                />
                {editorBoolean && (
                  <button
                    onClick={() => setDropDown(!showDropDown)}
                    className={styles.menuBtn}
                  ></button>
                )}
              </div>
              {showDropDown && (
                <div ref={ref} className={styles.dropDown}>
                  <button
                    onClick={() => handleEdit()}
                    className={styles.editButton}
                  >
                    Edit
                  </button>
                  <button
                    onClick={() => handleDelete()}
                    className={styles.deleteButton}
                  >
                    Delete
                  </button>
                </div>
              )}
            </div>
          </div>
        </div>
        {reply && (
          <ReplyForm
            mediaId={mediaId}
            handleClose={handleCloseReply}
            onSubmit={handleSubmitReply}
          />
        )}
      </div>
    </div>
  )
}

export default React.memo(UserComment)

export function ReplyForm({ onSubmit, mediaId, handleClose }) {
  const [value, setValue] = useState('')
  const [emojis, setChosenEmoji] = useState(null)
  const [emoji, setEmoji] = useState([])
  const [emojiState, setEmojiState] = useState(false)
  const [response, setResponse] = useState('')
  const ref = useRef()
  const emojiRef = useRef()

  const handleCloseEmoji = () => {
    setEmojiState(false)
  }
  const handleOpenSmiles = (e) => {
    e.preventDefault()
    setEmojiState(true)
  }

  useEffect(() => {
    emoji && setResponse(value.concat(emoji))
  }, [emoji, value])
  useEffect(() => {
    emojis && setEmoji((oldState) => [[...oldState, emojis.emoji].join('')])
  }, [emojis])
  useGlobalClose(ref, handleClose)

  const handleChange = ({ target: { value } }) => {
    setValue(value)
    setEmoji([])
  }

  useGlobalCloseSmiles(emojiRef, handleCloseEmoji, emojiState)

  const handleSubmit = (e) => {
    e.preventDefault()
    onSubmit(response)
    setValue('')
  }
  const handleEmojiClick = (e, emojiObject) => {
    setChosenEmoji(emojiObject)
  }
  return (
    <form
      onSubmit={handleSubmit}
      className={styles.replyInput}
      action="send reply"
    >
      <div className={styles.refWrapper} ref={ref}>
        <div className={styles.customizationWrapper}>
          <input
            value={response}
            onChange={handleChange}
            className={styles.textInput}
            type="text"
            placeholder="Write your reply..."
          />
          <button
            onClick={(e) => handleOpenSmiles(e)}
            className={styles.smileBtn}
            type="button"
          ></button>
          <div
            ref={emojiRef}
            className={classNames(styles.emojiWrapper, {
              [styles.noEmoji]: !emojiState,
              [styles.expanderEmoji]: mediaId,
            })}
          >
            <Picker
              pickerStyle={{ width: '100%' }}
              onEmojiClick={handleEmojiClick}
            ></Picker>
          </div>
        </div>
        <input
          className={classNames(styles.sendBtn, {
            [styles.smallerizer]: mediaId,
          })}
          value="Send"
          type="submit"
        />
      </div>
    </form>
  )
}
