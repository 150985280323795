import classNames from "classnames";
import React, { useState } from "react";

import styles from "./search-input.module.sass";

const SearchInput = ({
  size,
  callback,
  className,
  placeholder = "Search...",
}) => {
  const [value, setValue] = useState("");
  const handleChange = ({ target: { value } }) => {
    setValue(value);
    callback(value);
  };
  return (
    <div className={classNames(styles.search__wrapper, className)}>
      <button type="button">
        <svg
          width="24"
          height="24"
          viewBox="0 0 24 24"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M16.3646 15.2443C18.3041 12.7534 18.129 9.14958 15.8391 6.85969C13.3595 4.3801 9.33927 4.3801 6.85969 6.85969C4.3801 9.33927 4.3801 13.3595 6.85969 15.8391C9.14958 18.129 12.7534 18.3041 15.2443 16.3646C15.255 16.3768 15.2662 16.3887 15.2779 16.4003L18.6451 19.7675C18.9551 20.0775 19.4576 20.0775 19.7675 19.7675C20.0775 19.4576 20.0775 18.9551 19.7675 18.6451L16.4003 15.2779C16.3887 15.2662 16.3768 15.255 16.3646 15.2443ZM14.7166 7.98211C16.5763 9.8418 16.5763 12.857 14.7166 14.7166C12.857 16.5763 9.8418 16.5763 7.98211 14.7166C6.12242 12.857 6.12242 9.8418 7.98211 7.98211C9.8418 6.12242 12.857 6.12242 14.7166 7.98211Z"
            fill="#1D2A64"
          />
        </svg>
      </button>
      <input
        type="text"
        className={styles.search}
        placeholder={placeholder}
        value={value}
        onChange={handleChange}
      />
    </div>
  );
};

export default SearchInput;
