import React from "react";
import styles from "./comments.module.sass";
import Typography from "../ui/typography/Typography";

const CommentsTitle = ({ commentsQuantity }) => {
  return (
    <div className={styles.commentTitleWrapper}>
      <Typography
        className={styles.sectionTitle}
        component="h3"
        variant="label"
      >
        comments
      </Typography>
      <div>
        <Typography
          className={styles.commentCounter}
          variant="label"
          component="span"
        >
          {commentsQuantity}
        </Typography>
      </div>
    </div>
  );
};

export default CommentsTitle;
