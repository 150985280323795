import styles from "./button.module.sass";

const InfoIconButton = () => (
  <button className={styles.button__infoIcon}>
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M8 2.5C4.96243 2.5 2.5 4.96243 2.5 8C2.5 11.0376 4.96243 13.5 8 13.5C11.0376 13.5 13.5 11.0376 13.5 8C13.5 4.96243 11.0376 2.5 8 2.5ZM1.5 8C1.5 4.41015 4.41015 1.5 8 1.5C11.5899 1.5 14.5 4.41015 14.5 8C14.5 11.5899 11.5899 14.5 8 14.5C4.41015 14.5 1.5 11.5899 1.5 8ZM7.5 5.33337C7.5 5.05723 7.72386 4.83337 8 4.83337H8.00667C8.28281 4.83337 8.50667 5.05723 8.50667 5.33337C8.50667 5.60952 8.28281 5.83337 8.00667 5.83337H8C7.72386 5.83337 7.5 5.60952 7.5 5.33337ZM7.33331 7.5C7.05717 7.5 6.83331 7.72386 6.83331 8C6.83331 8.27614 7.05717 8.5 7.33331 8.5H7.49998V10.6667C7.49998 10.9428 7.72384 11.1667 7.99998 11.1667H8.66665C8.94279 11.1667 9.16665 10.9428 9.16665 10.6667C9.16665 10.3905 8.94279 10.1667 8.66665 10.1667H8.49998V8C8.49998 7.72386 8.27612 7.5 7.99998 7.5H7.33331Z"
        fill="#1D2A64"
      />
    </svg>
  </button>
);

export default InfoIconButton;
