import { createAction, handleActions } from "redux-actions";
import { all, call, delay, put, takeLatest } from "redux-saga/effects";
import { resource } from "../../api";

const namespace = `search`;

const GET_USERS = `${namespace}/GET_USERS`;
const SET_USERS = `${namespace}/SET_USERS`;
const GET_FRIENDS = `${namespace}/GET_FRIENDS`;
const SET_FRIENDS = `${namespace}/SET_FRIENDS`;
const RESET_FRIENDS = `${namespace}/RESET_FRIENDS`;

const SET_IS_LOAD = `${namespace}/SET_IS_LOAD`;

export const getUsers = createAction(GET_USERS);
export const setUsers = createAction(SET_USERS);

export const getFriends = createAction(GET_FRIENDS);
export const setFriends = createAction(SET_FRIENDS);
export const resetFriends = createAction(RESET_FRIENDS);

export const seetIsLoad = createAction(SET_IS_LOAD);

const initialState = {
  users: null,
  isLoad: false,
  friends: null
};

export default handleActions(
  {
    [SET_IS_LOAD]: (state, { payload }) => ({ ...state, isLoad: payload }),
    [SET_USERS]: (state, { payload }) => ({ ...state, users: payload }),
    [SET_FRIENDS]: (state, { payload }) => ({ ...state, friends: payload }),
    [RESET_FRIENDS]: (state) => ({ ...state, friends: null }),
  },
  initialState
);

export const searchUsersSelector = (state) => state[namespace].users;
export const searchFriendsSelector = (state) => state[namespace].friends;

function* getFriendsRequest({ payload }) {
  console.log(payload,'PAYLOAD');

  yield delay(1000);
  try {
    yield put(seetIsLoad(true));
    const {
      data: { data },
    } = yield call(resource.get, `/cabinet/friends/search?name=${payload}`);
    console.log(data, "SEARCH");
    yield put(setFriends(data))
  } catch (err) {
  } finally {
    yield put(seetIsLoad(false));
  }
}
function* getUsersRequest({ payload }) {
  yield delay(1000);
  try {
    yield put(seetIsLoad(true));

    const {
      data: { data },
    } = yield call(resource.get, `/user/search?name=${payload}`);
    console.log(data, "SEARCH");
    yield put(setUsers(data))
  } catch (err) {
  } finally {
    yield put(seetIsLoad(false));
  }
}

export const sagas = function* () {
  yield all([
    takeLatest(GET_FRIENDS, getFriendsRequest),
    takeLatest(GET_USERS, getUsersRequest),
  ]);
};
