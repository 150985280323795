import format from "date-fns/format";
import React from "react";
import hexToRgba from "hex-to-rgba";
import Typography from "../ui/typography";

import styles from "./event.module.sass";
import { useHistory, useParams } from "react-router";
import hero from "./hero.png";
import MoreButton from "../more-button";
import { Link } from "react-router-dom";
import { useSelector } from "react-redux";
import { userSelector } from "../../store/reducers/user";
import { useDispatch } from "react-redux";
import useMobile from "../../hooks/useMobile";

import {
  deleteState,
  saveTimelineToMines,
} from "../../store/reducers/timelines";
import Linkify from "linkify-react";

const Event = ({
  event,
  setCategories,
  setEvent,
  onOpenEditModal,
  onOpenMannageModal,
  media,
}) => {
  //console.log("event", event);
  const [hover, setHover] = React.useState(false);
  const user = useSelector(userSelector);
  const dispatch = useDispatch();
  const params = useParams();
  const history = useHistory();
  const { id, eventId } = useParams();
  const isMobile = useMobile(428);

  const bgc = event?.category?.color
    ? hexToRgba(event?.category?.color, 0.1)
    : "green";

  const onEdit = () => {
    onOpenEditModal({ openModal: true, eventId: event.id });
  };
  const onMannage = () => {
    onOpenMannageModal({ openModal: true, eventId: event.id });
  };

  if (!event) {
    return null;
  }
  const saveTimeline = (e) => {
    console.log(e);
    e.preventDefault();
    const payload = event?.id || null;
    dispatch(saveTimelineToMines({ payload }));
  };

  let x = 115;

  const startDay = new Date(
    new Date(event.start_date).valueOf() +
      new Date(event.start_date).getTimezoneOffset() * 60 * 1000,
  )
  const endDay = new Date(
    new Date(event.end_date).valueOf() +
      new Date(event.end_date).getTimezoneOffset() * 60 * 1000,
  )

  return (
    <Link
      className={styles.wrapper}
      onMouseEnter={() => {
        setHover(true);
      }}
      onMouseLeave={() => {
        setHover(false);
      }}
      style={{
        border: hover
          ? `1px solid ${event?.category?.color}`
          : "1px solid transparent",
        backgroundColor: bgc + "",
      }}
      to={`/friend/${event.owner_id}/event/${event.id}`}
    >
      <div className={styles.container}>
        <img
          src={event.cover_img || hero}
          alt="cover_event_image"
          className={styles.contImg}
        />
        <div className={styles.info__wrapper}>
          {isMobile ? null : (
            <div className={styles.actions}>
              <Typography
                component="span"
                variant="smallTextHeavy"
                className={styles.type}
                style={{ backgroundColor: event?.category?.color }}
              >
                <div className={styles.ownerImg}>
                  <img
                    src={event?.owner?.profile_photo_path}
                    alt="owner foto"
                  />
                </div>
                <span className={styles.owenerName}>
                  {event?.owner?.firstName}
                </span>
                <span className={styles.owenerName}>
                  {event?.owner?.lastName}
                </span>
              </Typography>
              <Typography
                component="span"
                variant="smallTextHeavy"
                className={styles.type}
                style={{ backgroundColor: event?.category?.color }}
              >
                {event?.category?.name}
              </Typography>
              {event.additionalCategory && (
                <Typography
                  component="span"
                  variant="smallTextHeavy"
                  className={styles.type}
                  style={{ backgroundColor: event?.additionalCategory?.color }}
                >
                  {event?.additionalCategory?.name}
                </Typography>
              )}
              <div className={styles.actions__right}>
                {event?.assignedFriends?.map((e) => {
                  x += 10;
                  return (
                    <React.Fragment>
                      <div
                        className={styles.friendsImg}
                        style={{ right: x }}
                        key={e.id}
                      >
                        <img src={e?.profile_photo_path} alt="friends avatar" />
                      </div>
                    </React.Fragment>
                  );
                })}
                <CommentsButton commentCount={event.comments_count} />
                {user?.id === event?.owner_id && (
                  <MoreButton
                    event={event}
                    eventId={event.id}
                    fill="#1D2A64"
                    onEdit={onEdit}
                    onMannage={onMannage}
                  />
                )}

                {user?.id !== event?.owner?.id && (
                  <div
                    onClick={(e) => saveTimeline(e)}
                    className={styles.addToMyTimeline}
                  >
                    <svg
                      width="16"
                      height="20"
                      viewBox="0 0 16 20"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        fillRule="evenodd"
                        clipRule="evenodd"
                        d="M2.93671 1.81818C2.64128 1.81818 2.35795 1.93525 2.14905 2.14362C1.94014 2.35199 1.82278 2.63461 1.82278 2.92929V17.0707C1.82278 17.3654 1.94014 17.648 2.14905 17.8564C2.35795 18.0648 2.64128 18.1818 2.93671 18.1818H13.0633C13.3587 18.1818 13.6421 18.0648 13.851 17.8564C14.0599 17.648 14.1772 17.3654 14.1772 17.0707V6.84616C14.1121 6.8609 14.0443 6.86869 13.9747 6.86869H9.92405C9.41376 6.86869 8.92437 6.66649 8.56354 6.30657C8.20271 5.94665 8 5.4585 8 4.94949V1.81818H2.93671ZM8.91139 0H2.93671C2.15785 0 1.41088 0.308621 0.860142 0.85797C0.309402 1.40732 0 2.1524 0 2.92929V17.0707C0 17.8476 0.309402 18.5927 0.860142 19.142C1.41088 19.6914 2.15784 20 2.93671 20H13.0633C13.8422 20 14.5891 19.6914 15.1399 19.142C15.6906 18.5927 16 17.8476 16 17.0707V5.9596C16 5.71849 15.904 5.48726 15.7331 5.31677L10.6698 0.266267C10.4988 0.095779 10.267 0 10.0253 0H8.91139ZM9.82278 1.99272V4.94949C9.82278 4.97628 9.83345 5.00198 9.85244 5.02092C9.87144 5.03986 9.89719 5.05051 9.92405 5.05051H12.8883L9.82278 1.99272ZM8.93351 8.78064C8.93748 8.26913 8.53344 7.85762 8.03107 7.86152C7.52869 7.86541 7.11823 8.28323 7.11426 8.79475L7.09738 10.9724L4.91975 10.9893C4.40823 10.9932 3.99041 11.4037 3.98652 11.9061C3.98262 12.4084 4.39413 12.8125 4.90565 12.8085L7.08328 12.7916L7.0664 14.9693C7.06243 15.4808 7.46647 15.8923 7.96884 15.8884C8.47122 15.8845 8.88168 15.4667 8.88565 14.9552L8.90253 12.7775L11.0802 12.7606C11.5917 12.7567 12.0095 12.3462 12.0134 11.8438C12.0173 11.3415 11.6058 10.9374 11.0943 10.9414L8.91663 10.9583L8.93351 8.78064Z"
                        fill="#1D2A64"
                      />
                    </svg>
                  </div>
                )}
              </div>
            </div>
          )}
          <Typography
            component="h2"
            variant="titleHeavy"
            className={styles.name}
          >
            {event.title}
          </Typography>
          <Typography
            component="span"
            variant="smallText"
            className={styles.date}
          >
            {event.start_date
              ? `${format(new Date(startDay), "P")} - ${format(
                  new Date(endDay),
                  "P"
                )}`
              : "sorry"}
          </Typography>
        </div>
      </div>
      {!isMobile && (
        <Typography className={styles.description} component="div">
          <pre className={styles.descriptionBox}>{event.description}</pre>
        </Typography>
      )}
    </Link>
  );
};

export default Event;

const CommentsButton = ({ commentCount }) => {
  return (
    <button className={styles.commentsButton}>
      <svg
        width="20"
        height="20"
        viewBox="0 0 20 20"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M10 3.77778C6.56356 3.77778 3.77778 6.56356 3.77778 10C3.77778 13.4364 6.56356 16.2222 10 16.2222C11.0745 16.2222 12.1079 15.9496 13.0231 15.4398L13.2596 15.3081L15.4943 15.4943L15.3081 13.2596L15.4398 13.0231C15.9496 12.1079 16.2222 11.0745 16.2222 10C16.2222 6.56356 13.4364 3.77778 10 3.77778ZM2 10C2 5.58172 5.58172 2 10 2C14.4183 2 18 5.58172 18 10C18 11.2835 17.6962 12.526 17.1239 13.643L17.4404 17.4404L13.643 17.1239C12.526 17.6962 11.2835 18 10 18C5.58172 18 2 14.4183 2 10ZM6.44444 8.51852C6.44444 8.0276 6.84241 7.62963 7.33333 7.62963H12.6667C13.1576 7.62963 13.5556 8.0276 13.5556 8.51852C13.5556 9.00944 13.1576 9.40741 12.6667 9.40741H7.33333C6.84241 9.40741 6.44444 9.00944 6.44444 8.51852ZM7.33333 10.5926C6.84241 10.5926 6.44444 10.9906 6.44444 11.4815C6.44444 11.9724 6.84241 12.3704 7.33333 12.3704H10.2963C10.7872 12.3704 11.1852 11.9724 11.1852 11.4815C11.1852 10.9906 10.7872 10.5926 10.2963 10.5926H7.33333Z"
          fill="#1D2A64"
        />
      </svg>
      <span>{commentCount}</span>
    </button>
  );
};
