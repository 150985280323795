import { Form, Formik } from "formik";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import ImageComposition from "../../components/image-composition";
import {
  CheckboxControl,
  InputControl,
  Textarea,
} from "../../components/ui/formik";
import RadioButtonControl from "../../components/ui/formik/RadioButtonControl";
import SelectControl from "../../components/ui/formik/select/SelectControl";
import RangePicker from "../../components/ui/range-picker";
import Typography from "../../components/ui/typography";
import {
  createImage,
  deleteImage,
  getCategories,
  getEvent,
  resetEvent,
  setCoverImage,
  submitUploadForm,
  timelinesSelector,
} from "../../store/reducers/timelines";
import { useParams } from "react-router";
import styles from "./update-event.module.sass";
import classNames from "classnames";
import GooglePick from "../create-timeline/media-step/GooglePicker/GooglePick";
import ClassNames from "classnames";

const UpdateEventForm = ({
  closeModal,
  setCalendarState,
  event,
  categories,
  reset = true,
}) => {
  const dispatch = useDispatch();
  // const params = useParams();
  // const { eventId } = params;

  // React.useEffect(() => {
  //   if (!categories) {
  //     dispatch(getCategories());
  //     dispatch(getEvent(eventId));
  //   }
  //   return () => {
  //     if (reset) {
  //       dispatch(resetEvent());
  //     }
  //   };
  // }, [categories, dispatch, reset]);

  // if (!categories?.default.length || !event) {
  //   console.log("KEEEE");
  //   dispatch(getEvent(eventId));
  //   return null;
  // }

  const startDate = new Date(event?.start_date);
  const endDate = new Date(event?.end_date);
  let options = categories?.default.reduce((acc, e) => {
    acc = [...acc, { label: e.name, value: e.id }];
    return acc;
  }, []);

  let customOptions = categories?.custom.reduce((acc, cat) => {
    acc = [...acc, { label: cat.name, value: cat.id }];
    return acc;
  }, []);

  const handleCancel = () => {
    closeModal();
    setCalendarState(true);
  };
  const handleSubmit = (values) => {
    dispatch(submitUploadForm({ form: values, eventId: event?.id, closeModal, id: event?.owner.id  }));
  };
  console.log(157,event);
  return (
    <div>
      <Formik
        enableReinitialize={true}
        initialValues={{
          media: [],
          title: event?.title,
          description: event?.description ? event.description : "",
          timeline_id: event?.timeline_id,
          additional_timeline_id: event.additional_timeline_id,
          allow_friends_media: event?.allow_friends_media,
          private: event?.private,
          moderate_comments: event?.moderate_comments,
          calendar: {
            startDate: startDate,
            endDate: endDate,
          },
        }}
        onSubmit={handleSubmit}
      >
        {({ values }) => {
          return (
            <Form className={styles.form}>
              <div className={styles.row}>
                <div className={styles.rowOrganizer}>
                  <InputControl
                    className={styles.input}
                    name="title"
                    placeholder="Enter your caption"
                    label="ENTRY"
                  />
                </div>

                <RangePicker name="calendar" className={styles.rangePicker} />
              </div>
              <div className={styles.row}>
                <Textarea
                  label="Description"
                  wrapperClassName={styles.textarea}
                  name="description"
                />
              </div>
              <div className={styles.row}>
                <SelectControl
                  name="timeline_id"
                  className={styles.select}
                  labelClassName={styles.select__label}
                  label="category"
                  options={options}
                />
                <SelectControl
                  name="additional_timeline_id"
                  className={styles.select}
                  labelClassName={styles.select__label}
                  label="custom category"
                  options={customOptions}
                />
                <div className={styles.includeFields__wrapper}>
                  <span className={styles.select__label}>Type</span>
                  <div className={styles.includeFields__content}>
                    <label className={styles.includeFields__container}>
                      <RadioButtonControl
                        name="private"
                        value="public"
                        className={styles.includeFields__checkbox}
                        checked={!values.private}
                      />
                      <Typography component="span">Public</Typography>
                    </label>
                    <label className={styles.includeFields__container}>
                      <RadioButtonControl
                        name="private"
                        value="private"
                        className={styles.includeFields__checkbox}
                        checked={values.private}
                      />
                      <Typography component="span">Private</Typography>
                    </label>

                    <label className={styles.includeFields__container}>
                      <CheckboxControl
                        name="allow_friends_media"
                        className={ClassNames(
                          styles.includeFields__checkbox,
                          styles.checkboxCustom
                        )}
                      />
                      <Typography component="span">
                        Allow friends to add media
                      </Typography>
                    </label>
                    <label className={styles.includeFields__container}>
                      <CheckboxControl
                        name="moderate_comments"
                        className={ClassNames(
                          styles.includeFields__checkbox,
                          styles.checkboxCustom
                        )}
                      />
                      <Typography component="span">
                        Auto-approve comments
                      </Typography>
                    </label>
                  </div>
                </div>
              </div>
              <div className={styles.row}>
                <Media name="media" media={event?.media} id={event?.id} />
              </div>
              <div className={styles.button__group}>
                <button
                  className={styles.button}
                  type="button"
                  onClick={handleCancel}
                >
                  cancel
                </button>
                <button className={styles.button} type="submit">
                  save
                </button>
              </div>
            </Form>
          );
        }}
      </Formik>
    </div>
  );
};

export default UpdateEventForm;

const Media = ({ id, media }) => {
  const ref = React.useRef(null);
  const dispatch = useDispatch();

  const [googleFiles, setFiles] = useState([]);

  useEffect(() => {
    if (googleFiles.length > 0) {
      const data = new FormData();
      googleFiles.forEach((file) => {
        data.append("files[]", file);
      });
      dispatch(createImage({ event_id: id, form: data }));
    }
  }, [dispatch, googleFiles, id]);

  const setCoverImageRequest = (eventId, imageId) => {
    const payload = {
      eventId,
      imageId,
    };
    dispatch(setCoverImage(payload));
  };
  const deleteImageRequest = (eventId, imageId) => {
    const payload = {
      eventId,
      imageId,
    };
    dispatch(deleteImage(payload));
  };

  const handleChangeImage = (e) => {
    const files = [...e.target.files];

    const data = new FormData();
    files.forEach((file) => {
      data.append("files[]", file);
    });

    dispatch(createImage({ event_id: id, form: data }));
  };
  return (
    <div className={styles.media}>
      <span className={styles.media__title}>media</span>
      <div className={styles.media__container}>
        <div className={styles.media__header}>
          <div className={styles.media__headerDescription}>
            <div className={classNames(styles.organizer, styles.organizer1)}>
              <div className={styles.media__icon}>
                <svg
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M4 2H20C21.1046 2 22 2.89543 22 4V20C22 21.1046 21.1046 22 20 22H4C2.89543 22 2 21.1046 2 20V4C2 2.89543 2.89543 2 4 2ZM4 4V15.5858L8 11.5858L11.5 15.0858L18 8.58579L20 10.5858V4H4ZM4 20V18.4142L8 14.4142L13.5858 20H4ZM20 20H16.4142L12.9142 16.5L18 11.4142L20 13.4142V20ZM14 8C14 6.34315 12.6569 5 11 5C9.34315 5 8 6.34315 8 8C8 9.65685 9.34315 11 11 11C12.6569 11 14 9.65685 14 8ZM10 8C10 7.44772 10.4477 7 11 7C11.5523 7 12 7.44772 12 8C12 8.55228 11.5523 9 11 9C10.4477 9 10 8.55228 10 8Z"
                    fill="#97A3B7"
                  />
                </svg>
              </div>
              <ul>
                <li>High resolution images</li>
                <li>PNG, JPG, GIF up to 10MB</li>
              </ul>
            </div>
            <div className={styles.organizer}>
              <div className={styles.media__icon}>
                <svg
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M3 5H15C16.1046 5 17 5.89543 17 7V8.38184L21.5528 6.10544C22.2177 5.77299 23 6.25649 23 6.99987V16.9999C23 17.7432 22.2177 18.2267 21.5528 17.8943L17 15.6179V17C17 18.1046 16.1046 19 15 19H3C1.89543 19 1 18.1046 1 17V7C1 5.89543 1.89543 5 3 5ZM17 13.3818L21 15.3818V8.6179L17 10.6179V13.3818ZM3 6.99976V16.9998H15V6.99976H3Z"
                    fill="#97A3B7"
                  />
                </svg>
              </div>
              <ul>
                <li>Video</li>
                <li>MP4 or WEBM, 4:3, 60sec</li>
              </ul>
            </div>
          </div>
        </div>
        <div className={styles.main}>
          {media?.length > 0 &&
            media.map((e) => {
              return (
                <ImageComposition
                  key={e.cover_img}
                  eventId={id}
                  file={e}
                  cover={e.cover}
                  setCoverImage={setCoverImageRequest}
                  deleteImage={deleteImageRequest}
                />
              );
            })}
        </div>
        <div className={styles.media__actions}>
          <h1>Add media to entry from</h1>
          <GooglePick className={styles.googleBtn} helper={setFiles}>
            Upload from Google Photos
          </GooglePick>
          <Typography className={styles.orDivider} component="span">
            Or
          </Typography>
          <label className={styles.uploadFile}>
            <input
              ref={ref}
              type="file"
              accept="video/*,image/*"
              multiple
              onChange={handleChangeImage}
            />
            <svg
              width="17"
              height="16"
              viewBox="0 0 17 16"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M7.625 10.6128C7.625 11.0961 8.01675 11.4878 8.5 11.4878C8.98325 11.4878 9.375 11.0961 9.375 10.6128V4.34978L11.5937 6.56847C11.9354 6.91018 12.4894 6.91018 12.8311 6.56847C13.1728 6.22676 13.1728 5.67274 12.8311 5.33103L9.20721 1.70711C8.81668 1.31658 8.18352 1.31658 7.79299 1.70711L4.16907 5.33103C3.82736 5.67274 3.82736 6.22676 4.16907 6.56847C4.51078 6.91018 5.0648 6.91018 5.40651 6.56847L7.625 4.34998V10.6128Z"
                fill="#1D2A64"
              />
              <path
                d="M1.5 10.625C1.5 10.1418 1.89175 9.75 2.375 9.75C2.85825 9.75 3.25 10.1418 3.25 10.625V13.25H13.75V10.625C13.75 10.1418 14.1418 9.75 14.625 9.75C15.1082 9.75 15.5 10.1418 15.5 10.625V13.25C15.5 14.2165 14.7165 15 13.75 15H3.25C2.2835 15 1.5 14.2165 1.5 13.25V10.625Z"
                fill="#1D2A64"
              />
            </svg>
            Upload
          </label>
        </div>
      </div>
    </div>
  );
};
