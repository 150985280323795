import React, { Suspense } from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import './index.css';
import App from './App';
import { configStore, history } from './store'
import createMiddlewares, { sagaWatch } from './store/middlewares'
import { reducers } from './store/reducers';


export const store = configStore(reducers, createMiddlewares());
sagaWatch()

const Load = () => <div>Load...</div>

ReactDOM.render(
    <Provider store={store}>
      <Suspense fallback={<Load />}>
        {/* <ErrorBoundary> */}
        <App history={history} />
        {/* </ErrorBoundary> */}
      </Suspense>
    </Provider>
  ,
  document.getElementById('root')
);

