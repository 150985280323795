// import React, { useEffect, useRef, useState } from "react";
// // import CommentSection from "../../components/activity-comments/CommentsSection";

// import { useHistory, useParams } from "react-router";
// import { useDispatch, useSelector } from "react-redux";
// import {
//   friendEventSelector,
//   getFriendEvent,
// } from "../../store/reducers/friends";
// import EventGallery from "../../components/event-gallery/EventGallery";
// import EventDescription from "../../components/event-description/EventDescription";
// import BackButton from "../../components/back-button";
// import CommentSection from "../../components/activity-comments";
// import GalleryModal from "./galleryModal/GalleryModal";
// import Typography from "../../components/ui/typography/Typography";
// import styles from "./event.module.sass";
// import {
//   commentsSelector,
//   getComments,
//   itemSelector,
//   setItem,
// } from "../../store/reducers/comments";
// import ImageLimitation from "./modalWarning/ImageLimitation";
// import ApprovesModal from "./approvesModal/ApprovesModal";
// import { getApproves } from "../../store/reducers/comments";
// import { approvesSelector } from "../../store/reducers/comments";
// import { isLoadSelector } from "../../store/reducers/comments";
// import Preloader from "../../components/ui/preloader/Preloader";
// import {
//   getCategories,
//   timelinesLoadSelector,
//   timelinesSelector,
//   deleteState,
//   confirmDeleteEvent,
//   deleteEvent,
// } from "../../store/reducers/timelines";
// import TimelineModal from "../../components/event/TimelineModal";
// import { useGlobalCloseSmiles } from "../../hooks/useCloseMenu";
// const Event = () => {
//   const { id, eventId } = useParams();
//   const event = useSelector(friendEventSelector);
//   console.log("Event ~ event", event);

//   const [totalSize, setSize] = useState(0);
//   const [openGallery, setOpenModal] = useState(false);
//   const comments = useSelector(commentsSelector);
//   const dispatch = useDispatch();
//   const { categories } = useSelector(timelinesSelector);
//   const deleteConfirmation = useSelector(deleteState);
//   const [openWarning, setWarning] = useState(false);
//   const [openApproves, setApproves] = useState(false);
//   const approves = useSelector(approvesSelector);
//   const isLoad = useSelector(isLoadSelector);
//   const load = useSelector(timelinesLoadSelector);
//   const ref = useRef(null);
//   const params = useParams();
//   const history = useHistory();
//   const item = useSelector(itemSelector);

//   useEffect(() => {
//     if (item?.id) {
//       setOpenModal(true);
//     }
//   }, [item]);

//   const handleDelete = () => {
//     dispatch(deleteEvent({ eventId: eventId }));
//     params.eventId && history.push("/");
//   };

//   const handleCloseModal = () => {
//     dispatch(confirmDeleteEvent(false));
//   };

//   useEffect(() => {
//     approves?.length > 0 && setApproves(true);
//   }, [approves]);

//   const handleCloseApproves = () => {
//     setApproves(false);
//   };

//   const handleCloseWarning = () => {
//     setWarning(false);
//   };

//   const handleGetItem = (item) => {
//     dispatch(setItem(item));
//   };

//   useEffect(() => {
//     dispatch(getApproves({ eventId }));
//   }, []);

//   const handleClose = () => {
//     setOpenModal(false);
//     dispatch(setItem(null));
//   };

//   useEffect(() => {
//     dispatch(getComments(eventId));
//   }, [dispatch, eventId]);

//   useEffect(() => {
//     dispatch(getFriendEvent({ id, eventId }));
//   }, [dispatch, eventId, id, openApproves]);

//   useEffect(() => {
//     dispatch(getFriendEvent({ id, eventId }));
//     dispatch(getCategories());
//   }, [id, dispatch, eventId]);
//   useGlobalCloseSmiles(ref, handleCloseModal, deleteConfirmation);

//   if (!event) {
//     return null;
//   }
//   let x = -15;

//   return (
//     <div className={styles.thisWrapper}>
//       {(isLoad || load) && <Preloader />}
//       {deleteConfirmation && (
//         <TimelineModal
//           ref={ref}
//           btnText="Confirm"
//           onClick={handleDelete}
//           message="Please confirm to delete entry"
//         />
//       )}
//       {openApproves && (
//         <ApprovesModal
//           openApproves={openApproves}
//           handleCloseApproves={handleCloseApproves}
//           approves={approves}
//         />
//       )}
//       {openWarning ||
//         (totalSize > 470 && (
//           <ImageLimitation
//             openWarning={openWarning}
//             handleCloseWarning={handleCloseWarning}
//           />
//         ))}

//       {openGallery && (
//         <GalleryModal
//           openGallery={openGallery}
//           handleClose={handleClose}
//           item={item}
//           event={event}
//           getItem={handleGetItem}
//         />
//       )}

//       <div>
//         <BackButton>Back</BackButton>
//       </div>
//       <EventDescription categories={categories} event={event} />
//       <EventGallery
//         handleGetItem={handleGetItem}
//         media={event.media}
//         slidesToShow={5}
//       />
//       {event.assignedFriends.length > 0 ? (
//         <div className={styles.membBox}>
//           <Typography
//             className={styles.membTitle}
//             component="h3"
//             variant="label"
//           >
//             members
//           </Typography>
//           <div className={styles.membIconBox}>
//             {event?.assignedFriends?.map((e) => {
//               x += 10;
//               return (
//                 <React.Fragment>
//                   <div
//                     className={styles.membersIcons}
//                     style={{ left: x }}
//                     key={e.id}
//                   >
//                     <img src={e?.profile_photo_path} alt="friends avatar" />
//                   </div>
//                 </React.Fragment>
//               );
//             })}
//             <button className={styles.closeMembButton} type="button">
//               <svg
//                 className={styles.membButtonSvg}
//                 width="14"
//                 height="15"
//                 viewBox="0 0 14 15"
//                 fill="none"
//                 xmlns="http://www.w3.org/2000/svg"
//               >
//                 <path
//                   fill-rule="evenodd"
//                   clip-rule="evenodd"
//                   d="M10 0C10.5288 0 10.9869 0.209494 11.3544 0.579913L13.4217 2.64718C13.7924 3.01786 14 3.47274 14 4C14 4.52385 13.794 5.06728 13.4217 5.43959L5.89021 12.9675C5.36661 13.5715 4.62438 13.9426 3.7564 14.0016H1H0V13.0016L0.00324765 10.1647C0.0669805 9.37582 0.43373 8.64229 0.981963 8.15991L8.56093 0.581008C8.93285 0.207181 9.47538 0 10 0ZM2.35157 9.61608C2.14601 9.79788 2.01885 10.0522 2 10.2452V12.0025L3.68578 12.004C3.95369 11.9851 4.20307 11.8604 4.42749 11.6054L8.80933 7.22354L6.77751 5.19172L2.35157 9.61608ZM8.19197 3.77776L10.2235 5.80933L11.9895 4.04339L9.95824 2.01212L8.19197 3.77776Z"
//                   fill="#1D2A64"
//                 />
//               </svg>
//               Manage
//             </button>
//           </div>
//         </div>
//       ) : (
//         <>
//           <Typography
//             className={styles.membTitle}
//             component="h3"
//             variant="label"
//           >
//             members
//           </Typography>
//           <p className={styles.membNoMemb}>no members yet</p>
//         </>
//       )}

//       <CommentSection
//         event={event}
//         setWarning={setWarning}
//         comments={comments}
//         eventId={eventId}
//         isFriendEvent={true}
//         setSize={setSize}
//         openGallery={openGallery}
//       />
//     </div>
//   );
// };
// export default Event;

import React, { useEffect, useRef, useState } from "react";
// import CommentSection from "../../components/activity-comments/CommentsSection";

import { useHistory, useParams } from "react-router";
import { useDispatch, useSelector } from "react-redux";
import {
  friendEventSelector,
  getFriendEvent,
} from "../../store/reducers/friends";
import EventGallery from "../../components/event-gallery/EventGallery";
import EventDescription from "../../components/event-description/EventDescription";
import BackButton from "../../components/back-button";
import CommentSection from "../../components/activity-comments";
import GalleryModal from "./galleryModal/GalleryModal";
import Typography from "../../components/ui/typography/Typography";
import styles from "./event.module.sass";
import {
  commentsSelector,
  getComments,
  itemSelector,
  setItem,
} from "../../store/reducers/comments";
import ImageLimitation from "./modalWarning/ImageLimitation";
import ApprovesModal from "./approvesModal/ApprovesModal";
import { getApproves } from "../../store/reducers/comments";
import { approvesSelector } from "../../store/reducers/comments";
import { isLoadSelector } from "../../store/reducers/comments";
import Preloader from "../../components/ui/preloader/Preloader";
import {
  getCategories,
  timelinesLoadSelector,
  timelinesSelector,
  deleteState,
  confirmDeleteEvent,
  deleteEvent,
} from "../../store/reducers/timelines";
import TimelineModal from "../../components/event/TimelineModal";
import { useGlobalCloseSmiles } from "../../hooks/useCloseMenu";
import Modal from "../../components/ui/modal/index";
import MannageMembers from "../../screens/mannage-members/MannageMembers";
import MoreButton from "../../components/more-button/MoreButton";

const Event = () => {
  const { id, eventId } = useParams();
  const event = useSelector(friendEventSelector);
  console.log("Event ~ event", event);

  const [totalSize, setSize] = useState(0);
  const [openGallery, setOpenModal] = useState(false);
  const comments = useSelector(commentsSelector);
  const dispatch = useDispatch();
  const { categories } = useSelector(timelinesSelector);
  const deleteConfirmation = useSelector(deleteState);
  const [openWarning, setWarning] = useState(false);
  const [openApproves, setApproves] = useState(false);
  const approves = useSelector(approvesSelector);
  console.log("Event ~ approves", approves);
  const isLoad = useSelector(isLoadSelector);
  const load = useSelector(timelinesLoadSelector);
  const ref = useRef(null);
  const params = useParams();
  const history = useHistory();
  const item = useSelector(itemSelector);

  const [mannageButton, setMannageButton] = React.useState(false);

  const onMannageButton = () => {
    setMannageButton(true);
  };

  useEffect(() => {
    if (item?.id) {
      setOpenModal(true);
    }
  }, [item]);

  const handleDelete = () => {
    dispatch(deleteEvent({ eventId: eventId }));
    params.eventId && history.push("/");
  };

  const handleCloseModal = () => {
    dispatch(confirmDeleteEvent(false));
  };

  useEffect(() => {
    approves?.length > 0 && setApproves(true);
  }, [approves]);

  const handleCloseApproves = () => {
    setApproves(false);
  };

  const handleCloseWarning = () => {
    setWarning(false);
  };

  const handleGetItem = (item) => {
    dispatch(setItem(item));
  };

  useEffect(() => {
    dispatch(getApproves({ eventId }));
  }, []);

  const handleClose = () => {
    setOpenModal(false);
    dispatch(setItem(null));
  };

  useEffect(() => {
    dispatch(getComments(eventId));
  }, [dispatch, eventId]);

  useEffect(() => {
    dispatch(getFriendEvent({ id, eventId }));
  }, [dispatch, eventId, id, openApproves]);

  useEffect(() => {
    dispatch(getFriendEvent({ id, eventId }));
    dispatch(getCategories());
  }, [id, dispatch, eventId]);
  useGlobalCloseSmiles(ref, handleCloseModal, deleteConfirmation);

  if (!event) {
    return null;
  }
  let x = -10;

  return (
    <div className={styles.thisWrapper}>
      {(isLoad || load) && <Preloader />}
      {deleteConfirmation && (
        <TimelineModal
          ref={ref}
          btnText="Confirm"
          onClick={handleDelete}
          message="Please confirm to delete entry"
        />
      )}
      {openApproves && (
        <ApprovesModal
          openApproves={openApproves}
          handleCloseApproves={handleCloseApproves}
          approves={approves}
        />
      )}
      {openWarning ||
        (totalSize > 470 && (
          <ImageLimitation
            openWarning={openWarning}
            handleCloseWarning={handleCloseWarning}
          />
        ))}

      {openGallery && (
        <GalleryModal
          openGallery={openGallery}
          handleClose={handleClose}
          item={item}
          event={event}
          getItem={handleGetItem}
        />
      )}

      <div>
        <BackButton>Back</BackButton>
      </div>
      <EventDescription categories={categories} event={event} />
      <EventGallery
        handleGetItem={handleGetItem}
        media={event.media}
        slidesToShow={5}
      />
      {event.assignedFriends.length > 0 ? (
        <div className={styles.membBox}>
          <Typography
            className={styles.membTitle}
            component="h3"
            variant="label"
          >
            members
          </Typography>
          <div className={styles.membIconBox}>
            {event?.assignedFriends?.map((e) => {
              x += 10;
              return (
                <React.Fragment>
                  <div
                    className={styles.membersIcons}
                    style={{ left: x }}
                    key={e.id}
                  >
                    <img src={e?.profile_photo_path} alt="friends avatar" />
                  </div>
                </React.Fragment>
              );
            })}
            <button
              className={styles.closeMembButton}
              type="button"
              onClick={onMannageButton}
              event={event}
            >
              <svg
                className={styles.membButtonSvg}
                width="14"
                height="15"
                viewBox="0 0 14 15"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  fill-rule="evenodd"
                  clip-rule="evenodd"
                  d="M10 0C10.5288 0 10.9869 0.209494 11.3544 0.579913L13.4217 2.64718C13.7924 3.01786 14 3.47274 14 4C14 4.52385 13.794 5.06728 13.4217 5.43959L5.89021 12.9675C5.36661 13.5715 4.62438 13.9426 3.7564 14.0016H1H0V13.0016L0.00324765 10.1647C0.0669805 9.37582 0.43373 8.64229 0.981963 8.15991L8.56093 0.581008C8.93285 0.207181 9.47538 0 10 0ZM2.35157 9.61608C2.14601 9.79788 2.01885 10.0522 2 10.2452V12.0025L3.68578 12.004C3.95369 11.9851 4.20307 11.8604 4.42749 11.6054L8.80933 7.22354L6.77751 5.19172L2.35157 9.61608ZM8.19197 3.77776L10.2235 5.80933L11.9895 4.04339L9.95824 2.01212L8.19197 3.77776Z"
                  fill="#1D2A64"
                />
              </svg>
              Manage
            </button>
          </div>
          <Modal
            isOpen={mannageButton}
            onClose={() => setMannageButton(false)}
            submit={() => {}}
            className={styles.modal__wrapper_mannage}
          >
            {(close, disabled) => (
              <div className={styles.wrapper}>
                <div className={styles.modal__header}>
                  <h2>Manage Members</h2>
                  <button
                    className={styles.closeButton}
                    type="button"
                    onClick={() => close()}
                  >
                    <svg
                      width="24"
                      height="24"
                      viewBox="0 0 24 24"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M16.6771 7.32289C16.2466 6.89237 15.5486 6.89237 15.1181 7.32289L12 10.441L8.88192 7.32289C8.45141 6.89237 7.7534 6.89237 7.32289 7.32289C6.89237 7.7534 6.89237 8.45141 7.32289 8.88192L10.441 12L7.32289 15.1181C6.89237 15.5486 6.89237 16.2466 7.32289 16.6771C7.7534 17.1076 8.45141 17.1076 8.88192 16.6771L12 13.559L15.1181 16.6771C15.5486 17.1076 16.2466 17.1076 16.6771 16.6771C17.1076 16.2466 17.1076 15.5486 16.6771 15.1181L13.559 12L16.6771 8.88192C17.1076 8.45141 17.1076 7.7534 16.6771 7.32289Z"
                        fill="#1D2A64"
                      />
                    </svg>
                  </button>
                </div>
                <MannageMembers closeModal={close} eventId={eventId} />
              </div>
            )}
          </Modal>
        </div>
      ) : (
        <>
          <Typography
            className={styles.membTitle}
            component="h3"
            variant="label"
          >
            members
          </Typography>
          <p className={styles.membNoMemb}>no members yet</p>
        </>
      )}

      <CommentSection
        event={event}
        setWarning={setWarning}
        comments={comments}
        eventId={eventId}
        isFriendEvent={true}
        setSize={setSize}
        openGallery={openGallery}
      />
    </div>
  );
};
export default Event;
