import classNames from 'classnames'
import {
  format,
  isBefore,
  isPast,
  isWithinInterval,
  yearsToMonths,
} from 'date-fns'
import React, { useEffect, useMemo, useRef, useState } from 'react'
import * as calendar from './utils'
import getYear from 'date-fns/getYear'
import styles from './range-picker.module.sass'
import { useField } from 'formik'
import { useGlobalCloseSmiles } from '../../../hooks/useCloseMenu'
import { useHistory } from 'react-router-dom'

const weekDays = calendar.getWeekDays()

const RangePicker = ({ name, noText, className }) => {
  const history = useHistory()
  const isTimelineEditPage = history.location.pathname.includes('event')
  const now = new Date()
  const [chosenYear, setYeaR] = useState(now.getFullYear())
  const [chosenYear2, setYear2] = useState(
    new Date(now.setMonth(now.getMonth() + 1)).getFullYear(),
  )
  const ref = React.useRef(null)
  const dateRef = React.useRef({ chosenYear, chosenYear2 })
  const [yearTouched, setYearState] = useState(false)
  const [daysTouched, setDaysState] = useState(false)

  const [field] = useField(name)
  const [date, setDate] = React.useState(new Date())
  const [seconDate, setSeconDate] = React.useState(
    new Date(now.setMonth(now.getMonth())),
  )

  const [openDropdown, setOpenDropdown] = React.useState(false)
  const htmlFor = `text-${Math.random()}`

  const startDate = field.value?.startDate
    ? format(field.value?.startDate, 'P')
    : null

  const endDate = field.value?.endDate
    ? format(field.value?.endDate, 'P')
    : null

  const firstYear = getYear(new Date(1900, 1, 1))
  const yearNow = getYear(new Date())
  const arrOfYears = []

  for (let i = yearNow; i >= firstYear; i--) {
    arrOfYears.push(i)
  }
  const firstYear2 = getYear(new Date(1900, 1, 1))
  const yearNow2 = getYear(new Date()) + 1
  const arrOfYears2 = []

  for (let i = yearNow2; i >= firstYear2; i--) {
    arrOfYears2.push(i)
  }
  const inputValue = useMemo(() => {
    const value = startDate ? [startDate] : []
    if (value.length && endDate) {
      value.push(endDate)
      return value.join(' - ')
    }
    return value.join('')
  }, [startDate, endDate])

  const setClose = () => {
    setOpenDropdown(false)
  }

  useGlobalCloseSmiles(ref, setClose, openDropdown)
  return (
    <div className={classNames(styles.RangePicker, className)}>
      <div className={styles.info}>
        <div className={styles.info__input_wrapper}>
          <svg
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M16.6143 3.23077C16.6143 2.82718 16.2881 2.5 15.8857 2.5C15.4833 2.5 15.1571 2.82718 15.1571 3.23077V4.44872H8.84286V3.23077C8.84286 2.82718 8.51666 2.5 8.11429 2.5C7.71191 2.5 7.38571 2.82718 7.38571 3.23077V4.44872H6.17143C4.69604 4.44872 3.5 5.64837 3.5 7.12821V11.0256V18.8205C3.5 20.3004 4.69604 21.5 6.17143 21.5H17.8286C19.304 21.5 20.5 20.3004 20.5 18.8205V11.0256V7.12821C20.5 5.64837 19.304 4.44872 17.8286 4.44872H16.6143V3.23077ZM19.0429 10.2949V7.12821C19.0429 6.45555 18.4992 5.91026 17.8286 5.91026H16.6143V7.12821C16.6143 7.5318 16.2881 7.85897 15.8857 7.85897C15.4833 7.85897 15.1571 7.5318 15.1571 7.12821V5.91026H8.84286V7.12821C8.84286 7.5318 8.51666 7.85897 8.11429 7.85897C7.71191 7.85897 7.38571 7.5318 7.38571 7.12821V5.91026H6.17143C5.5008 5.91026 4.95714 6.45555 4.95714 7.12821V10.2949H19.0429ZM4.95714 11.7564H19.0429V18.8205C19.0429 19.4932 18.4992 20.0385 17.8286 20.0385H6.17143C5.5008 20.0385 4.95714 19.4932 4.95714 18.8205V11.7564ZM10.3 14.9231C10.3 14.5195 10.6262 14.1923 11.0286 14.1923H12C12.0503 14.1923 12.0994 14.1974 12.1468 14.2072C12.4788 14.2753 12.7286 14.5699 12.7286 14.9231L12.7286 17.8462C12.7286 18.2497 12.4024 18.5769 12 18.5769C11.5976 18.5769 11.2714 18.2497 11.2714 17.8462V15.6538H11.0286C10.6262 15.6538 10.3 15.3267 10.3 14.9231Z"
              fill="#1D2A64"
            />
          </svg>
          <input
            id={htmlFor}
            type="text"
            className={styles.info__input}
            readOnly
            placeholder="mm/dd/yyyy"
            onClick={() => setOpenDropdown(!openDropdown)}
            value={inputValue}
          />
        </div>
        {!noText && (
          <label htmlFor={htmlFor} className={styles.info__label}>
            Select a Date or Dates
          </label>
        )}
      </div>
      {openDropdown && (
        <div
          className={
            !isTimelineEditPage
              ? styles.container
              : styles.container__timelineEdit
          }
          ref={ref}
        >
          <Calendar
            id={1}
            chosenYear={chosenYear}
            setYeaR={setYeaR}
            arrOfYears={arrOfYears}
            startDate={date}
            onChangeLeafer={setDate}
            name={name}
            dateRef={dateRef}
            value={chosenYear}
            value2={chosenYear2}
            setYearState={setYearState}
            yearTouched={yearTouched}
            setDaysState={setDaysState}
            daysTouched={daysTouched}
            styles={{ transform: 'translate(13px, 10px)' }}
          />
          <Calendar
            id={2}
            dateRef={dateRef}
            chosenYear={chosenYear2}
            setYeaR2={setYear2}
            arrOfYears={arrOfYears2}
            startDate={seconDate}
            onChangeLeafer={setSeconDate}
            name={name}
            value={chosenYear}
            value2={chosenYear2}
            setYearState={setYearState}
            yearTouched={yearTouched}
            setDaysState={setDaysState}
            daysTouched={daysTouched}
          />
        </div>
      )}
    </div>
  )
}

export default RangePicker

const Calendar = ({
  id,
  startDate,
  setYeaR,
  setYeaR2,
  arrOfYears,
  onChangeLeafer,
  chosenYear,
  chosenYear2,
  name,
  dateRef,
  setYearState,
  yearTouched,
  setDaysState,
}) => {
  const [field] = useField(name)
  const [, , helpersStart] = useField(`${name}.startDate`)
  const [, , helpersEnd] = useField(`${name}.endDate`)
  const start = field.value.startDate
  const end = field.value.endDate

  const items = calendar.getMonthData(
    startDate.getFullYear(),
    startDate.getMonth(),
  )

  const setChosenYear = (e) => {
    const { target } = e

    if (id === 1) {
      const date = new Date(field.value.startDate)

      helpersStart.setValue(new Date(date.setYear(target.id)))
      if (end && isBefore(end, date)) {
        return
      }

      setYeaR((chosenYear) => {
        chosenYear = target.id
        dateRef.current.chosenYear = target.id
        return chosenYear
      })
      onChangeLeafer(date)
    }
    if (id === 2) {
      if (!field.value.startDate) {
        return
      }

      const date = new Date(
        field.value.endDate
          ? field.value.endDate
          : new Date().setYear(target.id),
      )

      let testValue = new Date(date.setYear(target.id))
      if (isBefore(testValue, field.value.startDate)) {
        return
      }

      helpersEnd.setValue(new Date(date.setYear(target.id)))

      setYeaR2((chosenYear2) => {
        chosenYear2 = target.id
        dateRef.current.chosenYear2 = target.id
        return chosenYear2
      })

      // helpersEnd.setValue(date);
      onChangeLeafer(date)
    }
    setYearState(true)
    setDaysState(false)
  }

  const onChangeMonth = (e) => {
    e.stopPropagation()
    const { currentTarget } = e

    const variant = +currentTarget.getAttribute('data-variant')

    const date = new Date(startDate)
    if (id === 1) {
      if (end && isBefore(end, date)) {
        return
      }
      helpersStart.setValue(date)
    }
    if (id === 2) {
      if (!start) {
        return
      }
      if (isBefore(date, field.value.startDate)) {
        return
      }
      helpersEnd.setValue(date)
    }

    date.setMonth(date.getMonth() + variant)
    onChangeLeafer(date)
  }

  const onRangeChange = (e) => {
    e.stopPropagation()
    const { currentTarget } = e
    const value = currentTarget.getAttribute('data-value')

    const date = new Date(Date.parse(value))

    if (value.length < 1) {
      return
    }
    if (id === 1) {
      if (end) {
        if (!isBefore(date, end)) {
          return
        }
      }
      helpersStart.setValue(date)
    }
    if (start && !isBefore(date, start) && id === 2) {
      helpersEnd.setValue(date)
    }
    if (start && !end && isBefore(date, start) && id !== 1) {
      helpersEnd.setValue(start)
      helpersStart.setValue(date)
    }
    // if (start && end) {
    //   helpersEnd.setValue("");
    //   helpersStart.setValue("");

    // }
    setDaysState(true)
    setYearState(false)
  }
  const onRangeChangeNoYears = (e) => {
    e.stopPropagation()
    const { currentTarget } = e
    const value = currentTarget.getAttribute('data-value')
    const id1Date = new Date(Date.parse(value)).setYear(
      parseInt(dateRef.current.chosenYear),
    )
    const id2Date = new Date(
      new Date(Date.parse(value)).setYear(
        parseInt(dateRef.current.chosenYear2),
      ),
    )

    if (value.length < 1) {
      return
    }
    if (id === 1) {
      if (end) {
        if (!isBefore(id1Date, end)) {
          return
        }
      }
      helpersStart.setValue(id1Date)
    }
    if (start && !isBefore(id2Date, start) && id === 2) {
      helpersEnd.setValue(id2Date)
    }
    if (start && !end && isBefore(id2Date, start) && id !== 1) {
      helpersEnd.setValue(start)
      helpersStart.setValue(id2Date + 1)
    }
    // if (start && end) {
    //   helpersEnd.setValue("");
    //   helpersStart.setValue("");

    // }
    setDaysState(true)
  }
  const rangeHandler = (e) => {
    if (yearTouched) {
      onRangeChangeNoYears(e)
    } else {
      onRangeChange(e)
    }
  }

  return (
    <div className={styles.wrapper}>
      <Leafer
        chosenYear={chosenYear}
        setChosenYear={setChosenYear}
        arrOfYears={arrOfYears}
        currentDate={startDate}
        onChange={onChangeMonth}
      />
      <div className={classNames(styles.row, styles.title)}>
        {weekDays.map((e) => (
          <div className={styles.col} key={e.valueOf()}>
            {e && format(e, 'ccccc')}
          </div>
        ))}
      </div>
      {items.map((item, i) => (
        <div className={classNames(styles.row, styles.row__values)} key={i}>
          {item.map((e, j) => {
            return (
              <div
                key={e.valueOf() + j}
                data-value={e}
                className={classNames(
                  styles.col,
                  styles.value,
                  {
                    [styles.value__range]:
                      start &&
                      end &&
                      isBefore(start, end) &&
                      isWithinInterval(new Date(e), {
                        start: new Date(start),
                        end: new Date(end),
                      }),
                  },
                  { [styles.value__range_isPast]: isPast(new Date(e)) },
                  {
                    [styles.value__range_now]: calendar.isEqual(
                      new Date(),
                      new Date(e),
                    ),
                  },
                  {
                    [styles.value__range_start]:
                      start && calendar.isEqual(e, start),
                  },
                  {
                    [styles.value__range_end]: end && calendar.isEqual(e, end),
                  },
                  {
                    [styles.value__range_equal]:
                      start && end && calendar.isEqual(start, end),
                  },
                )}
                onClick={rangeHandler}
              >
                {e && format(e, 'd')}
              </div>
            )
          })}
        </div>
      ))}
    </div>
  )
}

const Leafer = ({
  chosenYear,
  onChange,
  arrOfYears,
  setChosenYear,
  currentDate = new Date(),
}) => {
  const [dropdownState, setDropdown] = useState(false)

  const ref = useRef(null)

  const handleClose = () => {
    setDropdown(false)
  }
  const handleYears = (e) => {
    setChosenYear(e)
    setDropdown(false)
  }

  useGlobalCloseSmiles(ref, handleClose, dropdownState)

  return (
    <div className={classNames(styles.leafer)}>
      <button
        className={classNames(styles.leafer__button)}
        data-variant={-1}
        onClick={onChange}
        type="button"
      >
        <svg
          width="6"
          height="10"
          viewBox="0 0 6 10"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M5.70711 0.292893C5.31658 -0.0976311 4.68342 -0.0976311 4.29289 0.292893L0.292893 4.29289C-0.097631 4.68342 -0.097631 5.31658 0.292893 5.70711L4.29289 9.70711C4.68342 10.0976 5.31658 10.0976 5.70711 9.70711C6.09763 9.31658 6.09763 8.68342 5.70711 8.29289L2.41421 5L5.70711 1.70711C6.09763 1.31658 6.09763 0.683417 5.70711 0.292893Z"
            fill="#515970"
          />
        </svg>
      </button>
      <span className={styles.leafer__title}>
        {format(currentDate, 'MMMM')}
      </span>
      <div className={styles.yearDropDown}>
        <span onClick={() => setDropdown(true)}>
          {chosenYear ? chosenYear : format(currentDate, 'y')}{' '}
          <svg
            width="10"
            height="6"
            viewBox="0 0 10 6"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M1 1L5 5L9 1"
              stroke="#1D2A64"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
          </svg>
        </span>
        <div
          ref={ref}
          className={classNames(styles.yearDropDown_yearsWrapper, {
            [styles.open]: dropdownState,
          })}
        >
          {arrOfYears.map((year, idx) => {
            return (
              <span
                className={styles.yearDropDown_yearsWrapper__year}
                id={year}
                key={idx}
                onClick={(e) => handleYears(e)}
              >
                {year}
              </span>
            )
          })}
        </div>
      </div>
      <button
        className={styles.leafer__button}
        data-variant={1}
        onClick={onChange}
        type="button"
      >
        <svg
          width="6"
          height="10"
          viewBox="0 0 6 10"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M0.292893 0.292893C0.683417 -0.0976311 1.31658 -0.0976311 1.70711 0.292893L5.70711 4.29289C6.09763 4.68342 6.09763 5.31658 5.70711 5.70711L1.70711 9.70711C1.31658 10.0976 0.683417 10.0976 0.292893 9.70711C-0.0976311 9.31658 -0.0976311 8.68342 0.292893 8.29289L3.58579 5L0.292893 1.70711C-0.0976311 1.31658 -0.0976311 0.683417 0.292893 0.292893Z"
            fill="#515970"
          />
        </svg>
      </button>
    </div>
  )
}
