import { Form, Formik } from "formik";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Textarea } from "../components/ui/formik";
import Typography from "../components/ui/typography";
import {
  getUser,
  profileSelector,
  submitAbout,
  userSelector,
} from "../store/reducers/user";

import styles from "./settins/src/screens/general/general.module.sass";

const Test = () => {
  const [edit, setEdit] = useState(false);
  const dispatch = useDispatch();
  const user = useSelector(userSelector);
  const profile = useSelector(profileSelector);
  useEffect(() => {
    dispatch(getUser());
  }, [dispatch]);

  if (!user || !profile) {
    return null;
  }
  const initialValues = { about: user.about };

  const handleCancel = (setEdit, resetForm) => {
    resetForm({ values: initialValues });
    setEdit(false);
  };
  const aboutSubmit = (values) => {
    dispatch(submitAbout(values));
  };

  return (
    <div>
      <Formik
        initialValues={initialValues}
        onSubmit={aboutSubmit}
        user={user}
        profile={profile}
      >
        {({ values, resetForm }) => (
          <>
            <div className={styles.edit__wrapper}>
              <Typography
                component="h4"
                variant="label"
                className={styles.label}
              >
                About me: <b>+10%</b>
              </Typography>
              <button
                type="button"
                onClick={() => {
                  setEdit(!edit);
                  // resetForm()
                }}
              >
                <svg
                  width="16"
                  height="16"
                  viewBox="0 0 16 16"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M11 1C11.5288 1 11.9869 1.20949 12.3544 1.57991L14.4217 3.64718C14.7924 4.01786 15 4.47274 15 5C15 5.52385 14.794 6.06728 14.4217 6.43959L6.89021 13.9675C6.36661 14.5715 5.62438 14.9426 4.7564 15.0016H2H1V14.0016L1.00325 11.1647C1.06698 10.3758 1.43373 9.64229 1.98196 9.15991L9.56093 1.58101C9.93285 1.20718 10.4754 1 11 1ZM3.35157 10.6161C3.14601 10.7979 3.01885 11.0522 3 11.2452V13.0025L4.68578 13.004C4.95369 12.9851 5.20307 12.8604 5.42749 12.6054L9.80933 8.22354L7.77751 6.19172L3.35157 10.6161ZM9.19197 4.77776L11.2235 6.80933L12.9895 5.04339L10.9582 3.01212L9.19197 4.77776Z"
                    fill="#1D2A64"
                  />
                </svg>
              </button>
            </div>

            <section className={styles.form__section}>
              <Form>
                <div className={styles.textarea__container}>
                  <Textarea
                    name="about"
                    disabled={!edit}
                    label="Short about me"
                    className={styles.textarea}
                  />
                </div>

                {edit && (
                  <div className={styles.buttons}>
                    <button
                      type="button"
                      onClick={() => {
                        handleCancel(setEdit, resetForm);
                      }}
                    >
                      cancel
                    </button>
                    <button type="submit">save changes</button>
                  </div>
                )}
              </Form>
            </section>
            <div className={styles.separator} />
          </>
        )}
      </Formik>
    </div>
  );
};

export default Test;
