import React from 'react'
import ClassNames from 'classnames'
import { Link } from 'react-router-dom'
import { Formik, Form } from 'formik'
import * as Yup from 'yup'
import styles from './new-password.module.sass'
import logo from '../../components/assets/images/Logo.svg'
import bgc from './bgc.png'
import Typography from '../../components/ui/typography'
import { InputControl } from '../../components/ui/formik'
import { useDispatch } from 'react-redux'
import { setPassword } from '../../store/reducers/auth'

const NewPassword = (props) => {
  const params = new URLSearchParams(props.location.search)
  const email = params.get('email')
  const token = props.match.params.token

  return (
    <div className={styles.container}>
      <div className={styles.wrapper}>
        <div className={styles.form__wrapper}>
          <Link to="/" className={styles.logo__wrapper}>
            <img className={styles.logo} src={logo} alt="" />
          </Link>
          <Typography className={styles.title} component="h1" variant="title">
            Restore Access
          </Typography>
          <Typography
            className={ClassNames(styles.title, styles.title_description)}
          >
            Fill in info to restore access to your account
          </Typography>

          <RestoreForm email={email} token={token} />
        </div>
        <div className={styles.background}>
          <img className={styles.image} src={bgc} alt="" />
        </div>
      </div>
    </div>
  )
}

export default NewPassword

const RestoreForm = (props) => {
  const { token, email } = props
  const dispatch = useDispatch()
  return (
    <Formik
      initialValues={{
        password: '',
      }}
      onSubmit={(values, { setSubmitting }) => {
        const { password, password_confirmation } = values
        dispatch(
          setPassword({
            password,
            password_confirmation,
            email,
            token,
            setSubmitting,
          }),
        )
      }}
      validationSchema={Yup.object().shape({
        password: Yup.string().min(8).max(255).required(),
        password_confirmation: Yup.string()
          .oneOf([Yup.ref('password'), null], 'Passwords must match')
          .required(),
      })}
    >
      {({ isSubmitting, dirty, isValid }) => (
        <Form>
          <InputControl
            name="password"
            label="Password"
            type="password"
            placeholder="at least 8 characters"
            className={styles.input}
          />
          <InputControl
            name="password_confirmation"
            label="Confirm password"
            type="password"
            placeholder="at least 8 characters"
            className={styles.input}
          />
          <button
            className={styles.button}
            type="submit"
            disabled={!isValid || isSubmitting || !dirty}
          >
            Save New Password
          </button>
        </Form>
      )}
    </Formik>
  )
}
