import { createAction, handleActions } from 'redux-actions'
import {
  all,
  call,
  put,
  take,
  delay,
  takeLatest,
  takeEvery,
} from 'redux-saga/effects'
import { resource } from '../../api'
import { setCategories } from './timelines'
import { successMessage } from '../../components/ui/alert/alertReducer'
import { setLoad } from './timelines'
const namespace = 'settings'

const GET_NOTIFICATION = `${namespace}/GET_NOTIFICATION`
const POST_NOTIFICATION = `${namespace}/POST_NOTIFICATION`
const DISABLE_CATEGORY_SWITCHER = `${namespace}/DISABLE_CATEGORY_SWITCHER`
const POST_CATEGORY_UPDATE = `${namespace}/POST_CATEGORY_UPDATE`
const CREATE_NEW_TIMELINE = `${namespace}/CREATE_NEW_TIMELINE`
const SET_OPEN_CATEGORY_ID = `${namespace}/SET_OPEN_CATEGORY_ID`

export const getNotifications = createAction(GET_NOTIFICATION)
export const postNotifications = createAction(POST_NOTIFICATION)
export const createNewTimeline = createAction(CREATE_NEW_TIMELINE)
export const disableCategoriesSwitchers = createAction(
  DISABLE_CATEGORY_SWITCHER,
)
export const categoryUpdate = createAction(POST_CATEGORY_UPDATE)
export const setOpenCategoryId = createAction(SET_OPEN_CATEGORY_ID)

// SHARED TIMELINES*****************************
const GET_SHARE_DETAILS = `${namespace}/GET_SHARE_DETAILS`
const SET_SHARE_DETAILS = `${namespace}/SET_SHARE_DETAILS`
const POST_UNSUBSCRIBE = `${namespace}/POST_UNSUBSCRIBE`
const POST_SUBSCRIBE = `${namespace}/POST_SUBSCRIBE`

export const getShareDetails = createAction(GET_SHARE_DETAILS)
export const setShareDetails = createAction(SET_SHARE_DETAILS)

export const postUnsubscribe = createAction(POST_UNSUBSCRIBE)
export const postSubscribe = createAction(POST_SUBSCRIBE)

const initialState = {
  notification: null,
  sharedDetails: null,
  opened_category_id: null,
}

export default handleActions(
  {
    [GET_NOTIFICATION]: (state, { payload }) => ({
      ...state,
      notification: payload,
    }),
    [SET_SHARE_DETAILS]: (state, { payload }) => ({
      ...state,
      sharedDetails: payload,
    }),
    [SET_OPEN_CATEGORY_ID]: (state, { payload }) => ({
      ...state,
      opened_category_id: payload,
    }),
  },
  initialState,
)
// SELECTORS*******************************************
export const notificationSelector = (state) => state[namespace].notification
export const shareDetailsSelector = (state) => state[namespace].sharedDetails
export const openCategoryIdSelector = (state) => state[namespace].opened_category_id

// REQUESTS***************************************************
function* getNotificationRequest() {
  while (true) {
    yield take(GET_NOTIFICATION)

    try {
      const {
        data: { data },
      } = yield call(resource.get, `/cabinet/notifications`)

      yield put(getNotifications(data))
    } catch (err) {
      console.log(err)
    }
  }
}

function* postNotificationRequest() {
  const { payload } = yield take(POST_NOTIFICATION)

  try {
    const {
      data: { data },
    } = yield call(resource.post, `/cabinet/notifications`, {
      notificationSettings: payload,
    })
    yield put(getNotifications(data))
  } catch (err) {
    console.log(err)
  }
}
function* disableCategoriesSwitcher({ payload }) {
  console.log(payload)
  try {
    const { data } = yield call(
      resource.post,
      `/cabinet/timeline/category/${payload}/switch-active`,
    )
    console.log(data, 'log Data')
    yield put(setCategories(data))
  } catch (err) {
    console.log(err)
  }
}

function* postCategoryUpdate({ payload }) {
  try {
    const { data } = yield call(
      resource.post,
      `cabinet/timeline/category/${payload[1].id}`,
      payload[0],
    )

    yield put(setCategories(data))
  } catch (err) {
    console.log(err)
  }
}

function* createTimelineRequest({ payload }) {
  console.log(payload)
  try {
    const { data } = yield call(
      resource.post,
      `/cabinet/timeline/category`,
      payload,
    )
    console.log(data, 'data im expected')
    yield put(setCategories(data))
    yield put(successMessage('You have successfully created a custom timeline.'))
  } catch (err) {
    console.log(err)
  }
}
// SHARED TIMELINES*****************************
function* getShareDetailsRequest({ payload }) {
  console.log(payload)
  yield put(setLoad(true))
  try {
    const { data } = yield call(resource.get, `/cabinet/sharedTimelines`)

    yield put(setShareDetails(data))
  } catch (err) {
    console.log(err)
  } finally {
    yield put(setLoad(false))
  }
}
function* postRequestUnsubscribe({ payload }) {
  console.log(payload)
  const { detailId, timelineId } = payload
  try {
    const { data } = yield call(
      resource.post,
      `/cabinet/sharedTimelines/unsubscribe/${detailId}/${timelineId}`,
    )

    yield put(setShareDetails(data))
  } catch (err) {
    console.log(err)
  }
}
function* postRequestSubscribe({ payload }) {
  console.log(payload)
  const { detailId, timelineId } = payload
  try {
    const { data } = yield call(
      resource.post,
      `/cabinet/sharedTimelines/subscribe/${detailId}/${timelineId}`,
    )

    yield put(setShareDetails(data))
  } catch (err) {
    console.log(err)
  }
}

export function* settingsSaga() {
  yield all([
    takeEvery(GET_SHARE_DETAILS, getShareDetailsRequest),
    takeLatest(POST_UNSUBSCRIBE, postRequestUnsubscribe),
    takeLatest(POST_SUBSCRIBE, postRequestSubscribe),
    getNotificationRequest(),
    takeLatest(POST_NOTIFICATION, postNotificationRequest),
    takeEvery(DISABLE_CATEGORY_SWITCHER, disableCategoriesSwitcher),
    takeEvery(POST_CATEGORY_UPDATE, postCategoryUpdate),
    takeEvery(CREATE_NEW_TIMELINE, createTimelineRequest),
    setOpenCategoryId()
  ])
}
