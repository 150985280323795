import React from "react";
import ClassNames from "classnames";
import Base from "../base";

import styles from "./input.module.sass";

const Input = ({
  className,
  component = "input",
  type,
  placeholder,
  id,
  disabled,
  ...props
}) => {
  return (
    <Base
      className={ClassNames(styles.input, className)}
      component={component}
      type={type}
      id={id}
      placeholder={placeholder}
      disabled={disabled}
      {...props}
    />
  );
};

export default Input;
