import React from 'react'
import Typography from '../../../../../components/ui/typography'
import styles from './categories.module.sass'
import { Form, Formik } from 'formik'
import InputControl from '../../../../../components/ui/formik/InputControl'
import CheckboxControl from '../../../../../components/ui/formik/CheckboxControl'
import colors from './colorsArr'
import ColorRadioButton from '../../../../../components/ui/formik/ColorRadioButton'
import ClassicButton from '../../../../../components/ui/buttons/ClassicButtonWithText'
import { useGlobalCloseSmiles } from '../../../../../hooks/useCloseMenu'
import { createNewTimeline } from '../../../../../store/reducers/settings'
import { useDispatch } from 'react-redux'
import {
  dangerMessage,
  successMessage,
} from '../../../../../components/ui/alert/alertReducer'
import useMobile from '../../../../../hooks/useMobile'

const CustomTimeline = React.forwardRef(({ closeModal, modalState }, ref) => {
  //useGlobalCloseSmiles(ref, closeModal, modalState)
  const dispatch = useDispatch()
  const isMobile = useMobile(428)

  return (
    <div className={styles.modalOverlay}>
      <div ref={ref} className={styles.modalWrapper}>
        <div className={styles.modalWrapper__btnWrapper}>
          {isMobile && (
            <Typography component="h1" variant="title">
              Create new timeline
            </Typography>
          )}
          <button
            onClick={() => closeModal()}
            className={styles.modalWrapper__closeButton}
          ></button>
        </div>
        {!isMobile && (
          <Typography component="h1" variant="title">
            Create new timeline
          </Typography>
        )}

        <Typography component="span">
          You can create a new custom timeline category here.
        </Typography>
        <Formik
          initialValues={{
            name: '',
            has_location: true,
            has_media: true,
            allow_comments: true,
            has_image: true,
            color: '',
            moderate_comments: false,
          }}
          onSubmit={(values) => {
            if (values.name.length > 2 && values.color.length > 3) {
               dispatch(createNewTimeline(values))
              closeModal()
            } else if (values.name.length < 2) {
              dispatch(
                dangerMessage(
                  'The name of your timeline must contain a minimum of three letters.',
                ),
              )
            } else {
              dispatch(
                dangerMessage(
                  'Please choose a color for your custom timeline.',
                ),
              )
            }
          }}
        >
          {({ values }) => {
            return (
              <Form className={styles.newTimeLine_form}>
                <label htmlFor="">
                  {/* <Typography className={styles.inputSpan} component="span">
                    Name your custom timeline
                  </Typography> */}
                  <InputControl
                    placeholder={'Enter name here'}
                    name="name"
                    label="Name your custom timeline"
                    className={styles.input}
                    labelClassName={styles.input__label}
                  />
                </label>

                <Typography component="h2" className variant="title">
                  General settings
                </Typography>
                <Typography component="span">
                  Select the following fields to include in this timeline:
                </Typography>
                <div className={styles.newTimeLine_form__checkBoxes}>
                  <label htmlFor="">
                    <CheckboxControl
                      name="has_location"
                      className={styles.modalCheckbox}
                    />
                    <Typography component="span">Location</Typography>
                  </label>
                  <label htmlFor="">
                    <CheckboxControl
                      name="has_image"
                      className={styles.modalCheckbox}
                    />
                    <Typography component="span">Images</Typography>
                  </label>
                  <label htmlFor="">
                    <CheckboxControl
                      name="has_media"
                      className={styles.modalCheckbox}
                    />
                    <Typography component="span">Media</Typography>
                  </label>
                </div>
                <Typography className={styles.colorTimeline} component="span">
                  Choose a color to represent your custom timeline
                </Typography>

                <div className={styles.colorWrapper}>
                  {colors.map((color, idx) => {
                    return (
                      <ColorRadioButton
                        className={styles.color__item}
                        idx={idx}
                        htmlFore={color}
                        id={color}
                        name="color"
                        key={color}
                        value={`${color}`}
                      />
                    )
                  })}
                </div>
                <Typography component="h2" variant="title">
                  Comments settings
                </Typography>
                <div className={styles.commentsSettings}>
                  <label htmlFor="">
                    <CheckboxControl
                      name="allow_comments"
                      className={styles.modalCheckbox}
                    />
                    <Typography component="span">Allow comments</Typography>
                  </label>
                  <label>
                    <CheckboxControl
                      className={styles.modalCheckbox}
                      name="moderate_comments"
                    />
                    <Typography className={styles.approved} component="span">
                      Auto-approve comment
                    </Typography>
                  </label>
                </div>
                <div className={styles.submitContainer}>
                  <ClassicButton
                    onClick={closeModal}
                    className={styles.submitContainer__thisIsCancel}
                    variant="gray"
                  >
                    Cancel
                  </ClassicButton>
                  <input
                    type="submit"
                    value="Save"
                    className={styles.submitContainer__thisIsSubmit}
                  />
                </div>
              </Form>
            )
          }}
        </Formik>
      </div>
    </div>
  )
})
export default CustomTimeline
