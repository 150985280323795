import React, { useEffect, useState } from 'react'
import styles from './security.module.sass'
import { useDispatch, useSelector } from 'react-redux'
import Typography from '../../../../../components/ui/typography'
import {
  getUser,
  userSelector,
  avatarSelector,
  profileSelector,
} from '../../../../../store/reducers/user'
import info from './icons/info.svg'
import Memorialization from './Memorialization'
import useMobile from '../../../../../hooks/useMobile'
import { useHistory } from 'react-router'

const Security = () => {
  const dispatch = useDispatch()
  const avatar = useSelector(avatarSelector)
  const user = useSelector(userSelector)
  const profile = useSelector(profileSelector)
  const isMobile = useMobile(428)
  const history = useHistory()

  const [edit, setEdit] = useState(false)
  const onEdit = () => {
    setEdit(!edit)
  }
  useEffect(() => {
    if (!user) {
      dispatch(getUser())
    }
  }, [user, dispatch])
  if (!user) {
    return null
  }

  return (
    <div className={styles.wrapper}>
      <div className={styles.header}>
        <h1>security & login</h1>
      </div>
      {user?.loggedWithGoogle && (
        <div className={styles.option}>
          <Typography component="h3" variant="label" className={styles.title}>
            You are logged in with Google account:
          </Typography>
          <div className={styles.avatar__container}>
            <img src={avatar} alt="avatar" />
            <div className={styles.user__info}>
              <Typography
                component="h3"
                variant="label"
                className={styles.name}
              >{`${user.firstName ?? ''} ${user.lastName ?? ''}`}</Typography>
              <Typography
                component="p"
                variant="smallText"
                className={styles.email}
              >
                {user.email}
              </Typography>
            </div>
            {!isMobile && (
              <button
                type="button"
                className={styles.button}
                onClick={() => {
                  history.push('/settings/general')
                }}
              >
                Change
              </button>
            )}
          </div>
          <Typography
            component="p"
            variant="smallText"
            className={styles.agreement}
          >
            <img src={info} alt="" />
            With this account, you also agree to sync media to your
            <a href="https://photos.google.com/" target="_blank">
              Google Photos
            </a>
            account.
          </Typography>
          {isMobile && (
            <button type="button" className={styles.button}>
              Change
            </button>
          )}
        </div>
      )}

      <div>
        <div className={styles.edit__wrapper}>
          <Typography component="h4" variant="label" className={styles.title}>
            Memorialization Settings
          </Typography>
          <button type="button" onClick={onEdit}>
            <svg
              width="16"
              height="16"
              viewBox="0 0 16 16"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M11 1C11.5288 1 11.9869 1.20949 12.3544 1.57991L14.4217 3.64718C14.7924 4.01786 15 4.47274 15 5C15 5.52385 14.794 6.06728 14.4217 6.43959L6.89021 13.9675C6.36661 14.5715 5.62438 14.9426 4.7564 15.0016H2H1V14.0016L1.00325 11.1647C1.06698 10.3758 1.43373 9.64229 1.98196 9.15991L9.56093 1.58101C9.93285 1.20718 10.4754 1 11 1ZM3.35157 10.6161C3.14601 10.7979 3.01885 11.0522 3 11.2452V13.0025L4.68578 13.004C4.95369 12.9851 5.20307 12.8604 5.42749 12.6054L9.80933 8.22354L7.77751 6.19172L3.35157 10.6161ZM9.19197 4.77776L11.2235 6.80933L12.9895 5.04339L10.9582 3.01212L9.19197 4.77776Z"
                fill="#1D2A64"
              />
            </svg>
          </button>
        </div>
        <Typography
          component="p"
          variant="smallText"
          className={styles.decision}
        >
          Decide what happens to your account after you pass away.
        </Typography>
        {edit && <Memorialization setEdit={setEdit} />}
      </div>
    </div>
  )
}

export default Security
