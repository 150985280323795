import React from "react";
import styles from "../media.module.sass";
import { useSelector } from "react-redux";
import { userSelector } from "../../../store/reducers/user";
import GalleryGridMore from "./GalleryGridMore";
import { useParams } from "react-router";
const GalleryGridItem = ({ item, onClick }) => {
  const user = useSelector(userSelector);
  const isVideo = item.type.indexOf("video");
  const { id } = useParams();
  console.log(user.id);
  return (
    <div className={styles.morePosition}>
      {user.id === parseInt(id) && <GalleryGridMore imageId={item.id} />}
      <div onClick={() => onClick(item)} className={styles.itemWrapper}>
        {item?.uploaded_by?.id !== user?.id && (
          <div className={styles.itemWrapper__userPhoto}>
            <img src={item?.uploaded_by?.profile_photo_path} alt="" />
          </div>
        )}
        {isVideo === 0 && (
          <div className={styles.itemWrapper__icon}>
            <svg
              width="23"
              height="32"
              viewBox="0 0 23 32"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path d="M23 16L0 32V0L23 16Z" fill="white" />
            </svg>
          </div>
        )}
        <img src={item.cover_img} alt="Gallery preview item" />
      </div>
    </div>
  );
};
export default GalleryGridItem;
