import React, { useEffect, useState } from "react";
import BackButton from "../../components/back-button";
import Typography from "../../components/ui/typography";
import styles from "./media.module.sass";
import GalleryGrid from "./Components/GalleryGrid";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router";
import {
  getFriendEvent,
  friendEventSelector,
} from "../../store/reducers/friends";
import GalleryModal from "../event/galleryModal/GalleryModal";
import event from "../event";

const MediaScreen = () => {
  const dispatch = useDispatch();
  const [item, getItem] = useState({});
  const [openGallery, setOpenModal] = useState(false);
  const { id, eventId } = useParams();

  useEffect(() => {
    dispatch(getFriendEvent({ id, eventId }));
  }, [id, dispatch, eventId]);
  const eventData = useSelector(friendEventSelector);
  console.log(eventData);

  useEffect(() => {
    if (item.id) {
      setOpenModal(true);
    }
  }, [item]);

  const handleClose = () => {
    setOpenModal(false);
    getItem({});
  };
  const handleGetItem = (item) => {
    getItem(item);
  };

  return (
    <div className={styles.mediaWrapper}>
      {openGallery && (
        <GalleryModal
          openGallery={openGallery}
          handleClose={handleClose}
          item={item}
          getItem={getItem}
          event={eventData}
        />
      )}

      <BackButton>Back</BackButton>
      <Typography className={styles.mediaTitle} variant="title" component="h1">
        Media
      </Typography>
      <Typography
        className={styles.mediaWrapper__quantity}
        variant="root"
        component="span"
      >
        {eventData?.media.length} items
      </Typography>
      <GalleryGrid handleGetItem={handleGetItem} eventData={eventData} />
    </div>
  );
};
export default MediaScreen;
