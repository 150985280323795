import React, { Children } from "react";
import Typography from "../typography";
import styles from "./button.module.sass";
import classNames from "classnames";

const ClassicButton = ({
  children,
  variant,
  type = "button",
  className,
  onClick,
}) => {
  return (
    <>
      <button
        type={type}
        onClick={onClick}
        className={classNames(styles.classicButton, className, {
          [styles.grayButton]: variant === "gray",
          [styles.addButton]: variant === "addButton",
        })}
      >
        {children}
      </button>
    </>
  );
};
export default ClassicButton;
