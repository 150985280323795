import { NavLink } from "react-router-dom";

import styles from "./sidebar.module.sass";

import general from "./icons/general.svg";
import categories from "./icons/categories.svg";
import notifications from "./icons/notifications.svg";
import support from "./icons/support.svg";
import subscriptions from "./icons/subscriptions.svg";
import security from "./icons/security.svg";
import shared from "./icons/shared.svg";
import { useDispatch, useSelector } from "react-redux";
import { logoutAction } from "../../../../../store/reducers/auth";
import { userSelector } from "../../../../../store/reducers/user";

const Sidebar = () => {
  const dispatch = useDispatch();

  return (
    <nav className={styles.wrapper}>
      <ul>
        <li>
          <NavLink to={`/settings/general`} activeClassName={styles.active}>
            <img src={general} alt="icon" />
            General
          </NavLink>
        </li>
        <li>
          <NavLink to={`/settings/categories`} activeClassName={styles.active}>
            <img src={categories} alt="icon" />
            Categories
          </NavLink>
        </li>
        <li>
          <NavLink
            to={`/settings/shared-timelines`}
            activeClassName={styles.active}
          >
            <img src={shared} alt="icon" />
            Shared Timelines
          </NavLink>
        </li>
        <li>
          <NavLink
            to={`/settings/notifications`}
            activeClassName={styles.active}
          >
            <img src={notifications} alt="icon" />
            Notifications
          </NavLink>
        </li>
        <li>
          <NavLink
            to={`/settings/subscriptions`}
            activeClassName={styles.active}
          >
            <img src={subscriptions} alt="icon" />
            Subscriptions
          </NavLink>
        </li>
        <li>
          <NavLink to={`/settings/security`} activeClassName={styles.active}>
            <img src={security} alt="icon" />
            Security & Login
          </NavLink>
        </li>
        <li>
          <NavLink to={`/settings/support`} activeClassName={styles.active}>
            <img src={support} alt="icon" />
            Support
          </NavLink>
        </li>
        <li className={styles.logoutWrapper}>
          <svg
            width="20"
            height="20"
            viewBox="0 0 20 20"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M9.4688 1.25H3.12665C2.08421 1.25 1.25002 2.19333 1.25002 3.33184V16.6839C1.24588 17.8204 2.08628 18.7455 3.12665 18.7497H9.4688C10.514 18.747 11.3607 17.8224 11.3635 16.6806V14.8792C11.3635 14.594 11.1518 14.3629 10.8908 14.3629C10.6298 14.3629 10.4183 14.594 10.4183 14.8792V16.6806C10.4161 17.2523 9.99216 17.7152 9.46865 17.7173H3.12192C2.60388 17.7107 2.189 17.2465 2.1952 16.6806V3.33184C2.1952 2.76258 2.60535 2.28244 3.12665 2.28244H9.4688C9.99438 2.29068 10.4169 2.75774 10.4183 3.33184V5.17167C10.4183 5.45679 10.6298 5.68782 10.8908 5.68782C11.1518 5.68782 11.3635 5.45679 11.3635 5.17167V3.33184C11.3635 2.19333 10.5112 1.25 9.4688 1.25Z"
              fill="#1D2A64"
            />
            <path
              d="M7.19341 10.542H17.1281L14.8313 13.0413L14.8249 13.0479C14.6462 13.247 14.649 13.5663 14.8313 13.7617C15.0156 13.9633 15.3149 13.9633 15.4995 13.7617L18.556 10.423C18.6741 10.3293 18.7457 10.1811 18.7501 10.0218C18.7542 9.86277 18.6908 9.71036 18.5782 9.60914L15.522 5.76366C15.3504 5.54926 15.052 5.52747 14.8555 5.71507C14.6587 5.9038 14.6374 6.23089 14.8084 6.44803L17.2331 9.50953H7.19341C6.93239 9.50953 6.72074 9.74072 6.72074 10.0257C6.72074 10.3108 6.93239 10.542 7.19341 10.542Z"
              fill="#1D2A64"
            />
            <path
              d="M9.4688 1.25H3.12665C2.08421 1.25 1.25002 2.19333 1.25002 3.33184V16.6839C1.24588 17.8204 2.08628 18.7455 3.12665 18.7497H9.4688C10.514 18.747 11.3607 17.8224 11.3635 16.6806V14.8792C11.3635 14.594 11.1518 14.3629 10.8908 14.3629C10.6298 14.3629 10.4183 14.594 10.4183 14.8792V16.6806C10.4161 17.2523 9.99216 17.7152 9.46865 17.7173H3.12192C2.60388 17.7107 2.189 17.2465 2.1952 16.6806V3.33184C2.1952 2.76258 2.60535 2.28244 3.12665 2.28244H9.4688C9.99438 2.29068 10.4169 2.75774 10.4183 3.33184V5.17167C10.4183 5.45679 10.6298 5.68782 10.8908 5.68782C11.1518 5.68782 11.3635 5.45679 11.3635 5.17167V3.33184C11.3635 2.19333 10.5112 1.25 9.4688 1.25Z"
              stroke="#1D2A64"
              strokeWidth="0.8"
            />
            <path
              d="M7.19341 10.542H17.1281L14.8313 13.0413L14.8249 13.0479C14.6462 13.247 14.649 13.5663 14.8313 13.7617C15.0156 13.9633 15.3149 13.9633 15.4995 13.7617L18.556 10.423C18.6741 10.3293 18.7457 10.1811 18.7501 10.0218C18.7542 9.86277 18.6908 9.71036 18.5782 9.60914L15.522 5.76366C15.3504 5.54926 15.052 5.52747 14.8555 5.71507C14.6587 5.9038 14.6374 6.23089 14.8084 6.44803L17.2331 9.50953H7.19341C6.93239 9.50953 6.72074 9.74072 6.72074 10.0257C6.72074 10.3108 6.93239 10.542 7.19341 10.542Z"
              stroke="#1D2A64"
              strokeWidth="0.8"
            />
          </svg>
          <button
            className={styles.logoutWrapper__logout}
            onClick={() => {
              dispatch(logoutAction());
            }}
          >
            Logout
          </button>
        </li>
      </ul>
    </nav>
  );
};

export default Sidebar;
